import React from 'react';
import PropTypes from 'prop-types';
import { InlineBlinkingLiveDot } from 'components/InlineBlinkingLiveDot';
import { LinkIfHref } from 'components/Link';

import styles from './styles.module.scss';

const PlayerInfo = ({ flagText, title, url }) => {
  if (!title) return null;

  return (
    <div className={styles.info} data-testid="player-info">
      <LinkIfHref href={url} className={styles.link}>
        {
          flagText && (
            <span className={styles.flag}>
              <InlineBlinkingLiveDot className={styles.dot} />
              <span>
                {flagText}
              </span>
            </span>
          )
        }
        <span className={styles.title}>
          {title}
        </span>
      </LinkIfHref>
    </div>
  );
};

PlayerInfo.propTypes = {
  flagText: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};

PlayerInfo.defaultProps = {
  flagText: null,
  title: null,
  url: null,
};

export { PlayerInfo };
