import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Border } from 'components/MsnbcDaily/Border';
import Link from 'components/Link';

import styles from './styles.module.scss';

function Header(props) {
  const { t } = useTranslation();

  const {
    description,
    title,
    titleUrl,
  } = props;

  const renderTitle = () => {
    const titleText = title || t('MSNBC Daily');
    if (titleUrl) {
      return (
        <Link to={titleUrl}>
          { titleText }
        </Link>
      );
    }

    return titleText;
  };

  return (
    <div className={styles.msnbcDailyHeader} data-testid={styles.msnbcDailyHeader}>
      <Link href="#msnbc-daily-start" id="msnbc-daily-start" className={styles.scrollLink} />
      <Border />
      <div className={styles.inner}>
        <h2 className={styles.title}>
          {renderTitle()}
        </h2>
        {description && (
          <div className={styles.description}>
            {description}
          </div>
        )}
      </div>
    </div>
  );
}

Header.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  titleUrl: PropTypes.string,
};

Header.defaultProps = {
  description: null,
  title: null,
  titleUrl: null,
};

export { Header };
