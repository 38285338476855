import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RawImage from 'components/RawImage';
import { loadProviderByID as loadProviderByIdAction } from 'redux/modules/tve';
import classNames from 'classnames';

import './styles.themed.scss';

const block = 'tve-provider';

const mapStateToProps = ({ shared, tve }) => ({
  hasTempPass: tve.hasTempPass,
  provider: tve.provider,
  selected: tve.selected,
  vertical: shared.vertical,
});

const mapDispatchToProps = (dispatch) => ({
  loadProviderById: (vertical, provider) => dispatch(loadProviderByIdAction(vertical, provider)),
});

class Logo extends React.Component {
  static propTypes = {
    loadProviderById: PropTypes.func.isRequired,
    hasTempPass: PropTypes.bool.isRequired,
    provider: PropTypes.string,
    selected: PropTypes.shape({
      logo2xWhite: PropTypes.string,
      logoColor: PropTypes.string,
    }),
    vertical: PropTypes.string.isRequired,
    useColorLogo: PropTypes.bool,
    inheritHeight: PropTypes.bool,
  };

  static contextTypes = {
    store: PropTypes.shape({}),
  };

  static defaultProps = {
    provider: null,
    selected: null,
    useColorLogo: false,
    inheritHeight: false,
  };

  componentDidMount() {
    this.loadProvider();
  }

  componentDidUpdate({ provider: prevProv }) {
    const { provider: nextProv } = this.props;
    if (!prevProv && nextProv) {
      this.loadProvider();
    }
  }

  loadProvider = () => {
    const { vertical, provider, loadProviderById } = this.props;

    if (provider) {
      loadProviderById(vertical, provider);
    }
  }

  render() {
    const {
      hasTempPass,
      provider,
      selected,
      useColorLogo,
      inheritHeight,
    } = this.props;

    if (hasTempPass || !provider) {
      return null;
    }

    const logoToUse = useColorLogo ? 'logoColor' : 'logo2xWhite';

    if (selected && typeof selected[logoToUse] !== 'undefined') {
      return (
        <div className={classNames(`${block}`, inheritHeight ? '' : `${block}-base`)}>
          <RawImage src={selected[logoToUse]} />
        </div>
      );
    }
    return provider;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
