import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Breakpoints from 'lib/Breakpoints';

import { getLayout } from './layouts';

import './styles.themed.scss';

export const settings = {
  defaultMinTeases: 5,
  defaultMaxTeases: 9,
  liveBlogMinTeases: 2,
  liveBlogMaxTeases: 4,
};

function TeaseLayout(props) {
  const {
    columnType,
    isRailAdjacent,
    layoutType: type,
    render,
    totalTeases,
  } = props;

  if (!totalTeases) return null;

  const layout = getLayout(type, isRailAdjacent, totalTeases);

  const wrapperRef = React.useRef();
  const [flexColumn, setFlexColumn] = React.useState(false);

  const breakpointListener = () => {
    // Special grid handling required for the liveblog type with 4 items
    if (type === 'blog' && columnType === '__column--2') {
      const element = wrapperRef?.current ?? null;
      if (element && window && typeof window.getComputedStyle === 'function') {
        const children = element?.children ?? [];
        if (children.length === 4) {
          if (Breakpoints.isM()) {
            const heights = Array.from(children)
              .map((el) => parseInt(window.getComputedStyle(el).height, 10));
            const tallest = Math.max(...heights);
            if (tallest) {
              // Enable flex column
              setFlexColumn(true);
              // Fix row height
              element.style.height = `${tallest}px`;
            }
          } else {
            // Remove overrides
            setFlexColumn(false);
            element.style.height = null;
          }
        }
      }
    }
  };

  React.useEffect(() => {
    Breakpoints.getMediumMQL().addListener(breakpointListener);
    // Call handler right away so state gets updated with initial window size
    breakpointListener();
    // Remove event listener on cleanup
    return () => Breakpoints.getMediumMQL().removeListener(breakpointListener);
  }, []); // Empty array ensures that effect is only run on mount

  if (type === 'blog') {
    // Check that number of variations is between min and max
    if (!(totalTeases >= settings.liveBlogMinTeases && totalTeases <= settings.liveBlogMaxTeases)) {
      return null;
    }

    return (
      <div
        className={classNames(
          'layout-subgrid-container',
          `cover-spread-layout-${totalTeases}-slot-${isRailAdjacent ? 'rail' : 'full'}--live-blog`,
          { 'cover-spread-layout--flex-column': flexColumn },
        )}
        ref={wrapperRef}
        data-test="cover-spread-layout-blog"
        data-testid="cover-spread-layout-blog"
      >
        {render(layout)}
      </div>
    );
  }

  if (!(totalTeases >= settings.defaultMinTeases && totalTeases <= settings.defaultMaxTeases)) {
    return null;
  }

  return (
    <div
      className={classNames(
        'layout-subgrid-container',
        `cover-spread-layout-${totalTeases}-slot-${isRailAdjacent ? 'rail' : 'full'}${columnType}`,
      )}
      data-test="cover-spread-layout-default"
      data-testid="cover-spread-layout-default"
    >
      {render(layout)}
    </div>
  );
}

TeaseLayout.propTypes = {
  columnType: PropTypes.string,
  isRailAdjacent: PropTypes.bool,
  layoutType: PropTypes.string,
  render: PropTypes.func,
  totalTeases: PropTypes.number,
};

TeaseLayout.defaultProps = {
  columnType: '',
  isRailAdjacent: false,
  layoutType: 'default',
  render: () => null,
  totalTeases: 0,
};

export { TeaseLayout };
