import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18next from 'i18next';

import { package as packagePropType } from 'lib/CustomPropTypes';
import Breakpoints from 'lib/Breakpoints';
import { timeFrom } from 'lib/DateTime';

import KeyUpdates from 'components/KeyUpdates';
import LeadBorderTop from 'components/LeadBorderTop';
import Link from 'components/Link';
import NewsTease from 'components/NewsTease';
import StripedEyebrow from 'components/StripedEyebrow';
import TeasePicture from 'components/TeasePicture';
import TypeIcon from 'components/TypeIcon';
import { Unibrow } from 'components/Unibrow';
import VideoPlayer from 'components/VideoPlayer';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import styles from './styles.module.scss';

class BigStoryTakeover extends React.Component {
  static propTypes = {
    content: packagePropType.isRequired,
    pkgClassName: PropTypes.string,
  };

  static defaultProps = {
    pkgClassName: 'pkg bigStoryTakeover',
  };

  constructor(props) {
    super(props);
    this.state = {
      showVideo: false,
    };
  }

  // eslint-disable-next-line react/destructuring-assignment
  getFirstItem = () => (this.props?.content?.items ?? [])[0];

  isBreakingNews = () => this.getFirstItem()?.item?.breakingNews ?? false;

  getImageConfig = () => {
    const firstItem = this.getFirstItem();

    if (firstItem.type === 'video') {
      return {
        useSuffix: true,
        responsiveFlavors: {
          s: AIMS_FLAVORS.FOCAL_758x379,
          m: AIMS_FLAVORS.FOCAL_920x460,
          l: AIMS_FLAVORS.FOCAL_600x333, // 16:9 for inline player
          xl: AIMS_FLAVORS.FOCAL_860x484, // 16:9 for inline player
        },
      };
    }
    return {
      useSuffix: false,
      responsiveFlavors: {
        s: AIMS_FLAVORS.FOCAL_758x379,
        m: AIMS_FLAVORS.FOCAL_920x460,
        l: AIMS_FLAVORS.FOCAL_600x300,
        xl: AIMS_FLAVORS.FOCAL_920x480,
      },
    };
  }

  handleClick = (e) => {
    e.preventDefault();
    if (Breakpoints.isSorM()) {
      // Redirect to canonical video page
      const firstItem = this.getFirstItem();
      window.location = firstItem?.item?.url?.primary ?? '#';
    } else {
      // Show video player
      this.setState({ showVideo: true });
    }
  }

  video = () => {
    const firstItem = this.getFirstItem();

    const { showVideo } = this.state;

    if (firstItem.type === 'video' && showVideo) {
      return (
        <div className={styles.bigStoryTakeoverVideo}>
          <VideoPlayer
            video={firstItem.item}
            autoPlay
            manualPlay
            onRef={(player) => { this.player = player; }}
          />
        </div>
      );
    }
    return null;
  }

  playButton = () => {
    const { showVideo } = this.state;
    const { type } = this.getFirstItem();

    if (type === 'video' && !showVideo) {
      return (
        <button
          type="button"
          className={styles.bigStoryTakeoverPlayButton}
          onClick={this.handleClick}
        >
          <TypeIcon className={styles.bigStoryTakeoverPlayIcon} type="video" />
        </button>
      );
    }
    return null;
  }

  flag = () => {
    const {
      computedValues: {
        flag,
      },
    } = this.getFirstItem();

    const breakingNews = this.isBreakingNews();

    if (breakingNews) {
      return (
        <div className={styles.bstBreakingFlagWrapper}>
          <StripedEyebrow>{flag || i18next.t('BREAKING')}</StripedEyebrow>
        </div>
      );
    }
    return null;
  }

  unibrow = () => {
    const { computedValues: { unibrow } = {} } = this.getFirstItem();

    // Using padding-top help define wrapper height for background color
    return (
      <Unibrow className={styles.bigStoryTakeoverUnibrow} unibrow={unibrow} />
    );
  }

  headline = () => {
    const {
      computedValues: {
        headline,
        url,
      } = {},
    } = this.getFirstItem();

    return (
      <h2 className={styles.bigStoryTakeoverHeadline}>
        <Link to={url}>
          {headline}
        </Link>
      </h2>
    );
  }

  timestamp = () => {
    const {
      content: {
        metadata: packageMetadata,
      },
    } = this.props;

    const { item, metadata: itemMetadata } = this.getFirstItem();
    if (!item) return null;

    const metadata = {
      ...packageMetadata,
      ...itemMetadata,
    };
    if (metadata.showTimestamp === false) {
      return null;
    }

    const { dateCreated, datePublished } = item;

    // Using padding-bottom help define wrapper height for background color
    return (
      <p className={styles.bigStoryTakeoverTimestamp}>
        {dateCreated !== datePublished ? (
          `${i18next.t('Updated')} ${i18next.t('time_ago', { time: timeFrom(datePublished) })}`
        ) : (
          `${i18next.t('time_ago', { time: timeFrom(dateCreated) })}`
        )}
      </p>
    );
  }

  related = () => {
    const {
      content: {
        metadata: packageMetadata,
      },
    } = this.props;

    const { related } = this.getFirstItem();

    if (Array.isArray(related) && related.length) {
      return (
        <div className="pkg newsTease layout-subgrid-container">
          {related.map((relatedItem, index) => (
            <NewsTease
              key={relatedItem.id}
              total={related.length}
              content={{
                ...relatedItem,
                metadata: {
                  ...packageMetadata,
                  ...relatedItem.metadata,
                },
              }}
              index={index}
            />
          ))}
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      content: {
        metadata,
        id: packageId,
        packageIndex,
      },
      pkgClassName,
    } = this.props;

    const firstItem = this.getFirstItem();
    if (!firstItem) return null;

    const { id: contentId, computedValues: { url } } = firstItem;

    const breakingNews = this.isBreakingNews();

    const { responsiveFlavors, useSuffix } = this.getImageConfig();

    const isVideo = firstItem.type === 'video';

    return (
      <div
        className={classNames(
          'layout-grid-container',
          pkgClassName,
          `pkg-index-${packageIndex + 1}`, // using +1 to mirror css index (e.g. nth-child(1))
          'clearfix',
          styles.bigStoryTakeover,
          { [styles.bigStoryTakeoverBreakingNews]: breakingNews },
        )}
        data-packageid={packageId}
      >
        <LeadBorderTop types={['collectionLead']} customStyles={styles.bigStoryTakeoverBorderTop} />
        <div className="layout-grid-item grid-col-8-l grid-col-9-xl">
          <div data-contentid={contentId}>
            <div className={styles.bigStoryTakeoverMeta}>
              {this.flag()}
              {this.unibrow()}
              {this.headline()}
              {this.timestamp()}
            </div>

            <section className={styles.bigStoryTakeoverTease}>
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              <Link
                className={styles.bigStoryTakeoverHeroLink}
                to={isVideo ? null : url}
                onClick={isVideo ? this.handleClick : null}
              >
                {/* eslint-enable jsx-a11y/anchor-is-valid */}
                {this.playButton()}
                <TeasePicture
                  responsiveFlavors={responsiveFlavors}
                  useSuffix={useSuffix}
                  className={styles.bigStoryTakeoverHeroImage}
                  type={firstItem.type}
                  computedValues={firstItem.computedValues}
                  lazyload={false}
                  placeholder
                />
              </Link>
              {this.video()}
            </section>
          </div>
          <div className="dn db-l" data-test="bst__related-desktop" data-testid="bst__related-desktop">
            {this.related()}
          </div>
        </div>

        <div className="layout-grid-item grid-col-4-l grid-col-3-xl">
          {metadata.listItems && (
            <KeyUpdates
              breaking={breakingNews}
              updates={(metadata.listItems || '').split(/\r?\n/)}
              url={metadata.updatesUrl}
              buttonText={metadata.buttonText}
              sectionTitle={metadata.sectionTitle}
            />
          )}
        </div>

        <div className="layout-grid-item grid-col-12 dn-l" data-test="bst__related-mobile" data-testid="bst__related-mobile">
          {this.related()}
        </div>
      </div>
    );
  }
}

export default BigStoryTakeover;
