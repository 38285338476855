import React from 'react';
import PropTypes from 'prop-types';

import ChildrenPropType from 'lib/CustomPropTypes/children';

// wrapper/higher-order component for the Intersection Observer API
// see more here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API

// Named this component `IntObserver` so as to not clobber the global
// `IntersectionObserver` from the API itself

export default class IntObserver extends React.Component {
  static propTypes = {
    callback: PropTypes.func.isRequired,
    children: ChildrenPropType,
    options: PropTypes.shape({
      root: PropTypes.node,
      rootMargin: PropTypes.string,
      threshold: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    }),
    root: PropTypes.node,
    rootMargin: PropTypes.string,
    threshold: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  };

  static defaultProps = {
    children: null,
    options: null,
    root: null,
    rootMargin: '0px',
    threshold: null,
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.intersectionObserver = null;
  }

  componentDidMount() {
    const {
      callback,
      options,
      root,
      rootMargin,
      threshold,
    } = this.props;

    const opts = {
      root,
      rootMargin,
      threshold,
      ...options,
    };

    if (typeof callback === 'function') {
      this.intersectionObserver = new IntersectionObserver(callback, opts);
      this.intersectionObserver.observe(this.containerRef.current);
    }
  }

  componentWillUnmount() {
    if (this.intersectionObserver && this.containerRef.current) {
      this.intersectionObserver.unobserve(this.containerRef.current);
    }
  }

  render() {
    const { children } = this.props;
    return (
      <div
        ref={this.containerRef}
      >
        {children}
      </div>
    );
  }
}
