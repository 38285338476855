import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'components/Link';

import styles from './styles.module.scss';

function Navigation(props) {
  const {
    navigation,
    placement,
  } = props;

  if (!navigation || !navigation.length) {
    return null;
  }

  return (
    <ul className={classNames(styles.navigation, styles[placement])}>
      {navigation.map((item) => (
        <li className={styles.item} key={item.linkUrl}>
          <Link href={item.linkUrl}>
            {item.linkTitle}
          </Link>
        </li>
      ))}
    </ul>
  );
}

Navigation.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  placement: PropTypes.string.isRequired,
};

Navigation.defaultProps = {
  navigation: [],
};

export { Navigation };
