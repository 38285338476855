import React from 'react';

import { package as PackagePropType } from 'lib/CustomPropTypes';

import { SingleStoryline } from './SingleStoryline';
import './styles.themed.scss';

export function Storyline({ content }) {
  if (!content) return null;

  const {
    id,
    items,
    metadata = {},
  } = content;

  const { showBottomBorder = true } = metadata;

  if (!items || !items.length) return null;

  return (
    <section
      data-packageid={id}
      className="pkg storyline"
    >
      <SingleStoryline content={content} />
      {showBottomBorder && (<hr className="pkg-bottom-border" />)}
    </section>
  );
}

Storyline.propTypes = {
  content: PackagePropType.isRequired,
};
