/**
 * Appends search params to URL
 * @param {string} url
 * @param {object} params Object of paramName:value
 * @returns {string} url with appended params
 */
export function appendSearchParam(url, params) {
  if (!url || !params) {
    return url;
  }

  const [urlTemp, anchorTemp] = url.split('#');

  const newURL = Object.keys(params).reduce((acc, name) => {
    const value = params[name];
    const re = new RegExp(`[?&]${name}=`, 'g');
    if (
      !name
      || !value
      || re.test(acc)
    ) {
      return acc;
    }

    const separate = acc.indexOf('?') === -1 ? '?' : '&';
    return `${acc}${separate}${name}=${value}`;
  }, urlTemp);

  const anchor = anchorTemp ? `#${anchorTemp}` : '';

  return `${newURL}${anchor}`;
}
