import React from 'react';
import PropTypes from 'prop-types';
import ButtonHoverAnimation from '../../ButtonHoverAnimation';

import './styles.themed.scss';

const Footer = ({ actionButtonText, actionButtonUrl, infoText }) => (
  <div className="msnbcDaily__footer" data-testid="msnbcDaily__footer">
    {infoText && (
      <p className="msnbcDaily__footer-info-text" data-testid="msnbcDaily__footer-info-text">
        {infoText}
      </p>
    )}
    {
      actionButtonText && actionButtonUrl && (
        <ButtonHoverAnimation
          title={actionButtonText}
          type="link"
          url={actionButtonUrl}
          additionalClasses="msnbcDaily__action-button"
        />
      )
    }
  </div>
);

Footer.propTypes = {
  actionButtonText: PropTypes.string,
  actionButtonUrl: PropTypes.string,
  infoText: PropTypes.string,
};

Footer.defaultProps = {
  actionButtonText: '',
  actionButtonUrl: '',
  infoText: '',
};

export { Footer };
