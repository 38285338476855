import React from 'react';
import PropTypes from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import classNames from 'classnames';

import { ACCESS_ENABLER_OBJ, configTempPass } from 'lib/tve';

import Countdown from 'components/TVE/Countdown';
import SignIn from 'components/TVE/SignIn';

import { block } from './helper';

const HIDE_AFTER_MILLISECONDS = 30 * 1000;

class CTAAuthedTemporary extends React.Component {
  timeout = null;

  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
    };
  }

  componentDidMount() {
    this.timeout = setTimeout(this.hideCountdownLabel, HIDE_AFTER_MILLISECONDS);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  hideCountdownLabel = () => {
    this.setState({ hidden: true });
  }

  getExpiration = () => {
    const { expirationName } = configTempPass;
    const { cookies } = this.props;
    return parseInt(cookies.get(expirationName), 10);
  }

  render() {
    const { hidden } = this.state;

    return (
      <div className={`${block} ${block}__authed-temporary`} data-testid="call-to-action-authed-temporary">
        <div className={`${block}__text-wrap`}>
          <span
            className={classNames(
              `${block}__text`,
              `${block}__text__temppass`,
              {
                [`${block}__text__temppass--hidden`]: hidden,
              },
            )}
          >
            <span className={`${block}__text__temppass__label--small`}>
              Daily preview expires in
            </span>
            <span className={`${block}__text__temppass__label--large`}>
              Your Daily preview expires in
            </span>
          </span>
          <span className={`${block}__countdown`}>
            <Countdown
              end={this.getExpiration()}
              onEndCallback={() => {
                if (
                  window[ACCESS_ENABLER_OBJ]
                  && typeof window[ACCESS_ENABLER_OBJ].logout === 'function'
                ) {
                  window[ACCESS_ENABLER_OBJ].logout();
                }
              }}
            />
          </span>
        </div>
        <SignIn withLock />
      </div>
    );
  }
}

export default withCookies(CTAAuthedTemporary);
