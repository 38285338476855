const headerPackages = [
  'collectionsLead',
  'leadSectionFront',
  'magazineLead',
  'showLead',
  'waffle',
];

const headerLightPackages = [
  'bigHeadline',
  'bigStoryTakeover',
  'bigStoryTakeoverPlus2',
  'bigStoryTakeoverPlus3',
  'bigStoryTakeoverPlus4',
];

const headerTransparentPackages = [
  'leadSectionFront',
];

const verticalsWithHeaderLightPackages = [
  'msnbc',
  'news',
];

// Packages that modify their own height or hide overflow content
const railClipPackages = [
  'teaseList',
];

// Packages that define special layout handling in the layout
const railListPackages = [
  'teaseList',
  'aLaCarte',
];

export const getFirstPackageFromPackages = (packages) => packages?.length
  && packages[0];

export const packageHasTitleOrType = (
  pkg,
  packageType,
) => (pkg.metadata?.title) || pkg.type === packageType;

export const isLeadPackageAdjacent = (packageType) => ['collectionsLead'].includes(packageType);

export const makeStartingCountMapOfPackageTypes = (packages) => {
  const countMapOfPackageTypes = packages.reduce((accumulator, pkg) => {
    const { type } = pkg;
    accumulator[type] = 1;

    return accumulator;
  }, {});

  return countMapOfPackageTypes;
};

export const getNthPackageOfType = (packages, packageType, nthOccurring) => {
  const countMapOfPackageTypes = makeStartingCountMapOfPackageTypes(packages);

  const packagesByTypeOrder = packages.reduce((accumulator, pkg) => {
    const pkgKey = `${pkg.type}_${countMapOfPackageTypes[pkg.type]}`;

    accumulator[pkgKey] = { ...pkg };
    countMapOfPackageTypes[pkg.type] += 1;

    return accumulator;
  }, {});

  const computedKey = `${packageType}_${nthOccurring}`;
  const nthPackageOfType = packagesByTypeOrder[computedKey];

  return nthPackageOfType;
};

/**
 * Return first layout with packages.
 */
export const getFirstLayout = (
  layouts = [],
) => {
  if (Array.isArray(layouts)) {
    return layouts.find((l) => l.packages?.length) || { packages: [] };
  }
  return { packages: [] };
};

/**
 * Return first package of first populated layout.
 */
const getFirstPackage = (
  layouts,
) => getFirstPackageFromPackages(getFirstLayout(layouts).packages);

/**
 * Return type of first package of first populated layout.
 */
export const getFirstPackageType = (
  layouts,
) => (getFirstPackage(layouts) || {}).type;

/**
 * Test if layout contains one of the configured header packages.
 */
export const hasHeaderPackage = (
  layouts = [],
  packageTypes = headerPackages,
) => {
  const firstPackageType = getFirstPackageType(layouts);
  return packageTypes.includes(firstPackageType);
};

/**
 * Test if layout contains one of the flagged transparent header packages.
 */
function hasHeaderTransparentLightPackage(layouts, vertical) {
  return hasHeaderPackage(layouts, headerTransparentPackages) && vertical.includes('today');
}

/**
 * Test if layout contains one of the flagged light header packages.
 */
export const hasHeaderLightPackage = (
  layouts,
  vertical,
) => verticalsWithHeaderLightPackages.includes(vertical)
  && hasHeaderPackage(layouts, headerLightPackages);

/**
 * Returns a function that retrieves metadata configuration value for the given field.
 *
 * @param {object} pkgMetadata
 */
export function getItemMetadataValue(pkgMetadata) {
  // Return a function to check item and package metadata
  return (field, itemMetadata) => itemMetadata?.[field] ?? pkgMetadata?.[field];
}

/**
 * Flatten packages to an array of unique 'type' strings.
 *
 * @param {array} packages
 * @param {number} zone
 */
const getPkgTypesInZone = (packages, zone) => packages.reduce((acc, pkg) => {
  if (pkg.zone === zone && !acc.includes(pkg.type)) {
    acc.push(pkg.type);
  }
  return acc;
}, []);

/**
 * Searches an array of package types for a given type or array of types.
 *
 * @param {array} packagesInZone
 * @param {array|string} packagesToCheck
 */
const doesTypeArrayHavePkgTypes = (packagesInZone, packagesToCheck) => {
  if (Array.isArray(packagesToCheck)) {
    return packagesToCheck.filter((p) => packagesInZone.includes(p)).length > 0;
  }
  if (typeof packagesToCheck === 'string') {
    return packagesInZone.includes(packagesToCheck);
  }
  return false;
};


const trimNullPackageItems = (items) => items.filter((article) => article !== null);

export {
  railClipPackages,
  railListPackages,
  getPkgTypesInZone,
  doesTypeArrayHavePkgTypes,
  trimNullPackageItems,
  hasHeaderTransparentLightPackage,
};
