import React from 'react';

export function LoadingAnimation() {
  return (
    <svg width="79" height="38" viewBox="0 0 79 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M78.4704 36.2458C77.6297 16.0911 60.3937 -1.05596e-05 39.2535 -6.8633e-06C18.26 -3.19268e-06 1.11146 15.8738 0.0536043 35.8356C0.0169321 36.4875 6.49366e-06 37.1394 6.60812e-06 37.794L10.178 37.794C10.178 37.1612 10.2006 36.5255 10.2457 35.9008C11.2612 21.3417 23.8822 9.80026 39.2535 9.80026C54.6813 9.80026 67.3417 21.4313 68.2726 36.0665C68.3093 36.6396 68.329 37.2128 68.329 37.794L78.5042 37.794C78.5042 37.2779 78.4957 36.7564 78.4704 36.2458Z">
        <animate
          attributeName="fill"
          values="#BEBEBE;#DADADA;#999999;#BEBEBE"
          dur="1s"
          repeatCount="indefinite"
        />
      </path>
      <path d="M39.5656 12.5134C25.6828 12.5134 14.3265 22.9678 13.4373 36.1841C13.395 36.7331 13.3809 37.2904 13.3809 37.8532L23.5629 37.8532C23.5629 37.2494 23.5996 36.6485 23.6702 36.0611C24.5904 28.3685 31.3709 22.3669 39.5656 22.3669C47.8055 22.3669 54.6114 28.4259 55.4752 36.1786C55.5373 36.7304 55.5684 37.2849 55.5684 37.8531L65.7504 37.8531C65.7504 37.3095 65.7335 36.7796 65.6996 36.2441C64.8415 22.9979 53.4682 12.5134 39.5656 12.5134Z">
        <animate
          attributeName="fill"
          values="#DADADA;#999999;#BEBEBE;#DADADA"
          dur="1s"
          repeatCount="indefinite"
        />
      </path>
      <path d="M39.4088 25.3397C32.9853 25.3398 27.6352 29.7042 26.4737 35.4821C26.3204 36.2482 26.238 37.0413 26.238 37.8532L52.5796 37.8532C52.5796 37.2436 52.5342 36.6501 52.4461 36.0729C51.5374 30.0036 46.0482 25.3397 39.4088 25.3397Z">
        <animate
          attributeName="fill"
          values="#999999;#BEBEBE;#DADADA;#999999"
          dur="1s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
}
