import React from 'react';
import { CoreVideoPlayer } from 'components/CoreVideoPlayer';
import { VideoContainer } from 'components/VideoContainer';
import { VideoControlRack } from 'components/VideoContainer/VideoControlRack';
import { TapToUnmuteButton } from 'components/VideoContainer/TapToUnmuteButton';
import { OmegaFeatureFlagSwitch } from 'components/OmegaVideoPlayer/OmegaSwitch';
import Info from 'components/VideoLive/Info';
import { ScheduleLive } from 'components/VideoLive/ScheduleLive';
import Banner from 'components/VideoLive/Banner';
import { OmegaVideoPlayer } from 'components/OmegaVideoPlayer';
import { STREAM_KEYS } from 'lib/playmaker';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import NDPVideo from '../LiveVideoEmbed/NDPVideo';
import 'assets/styles/main.scss';
import 'assets/styles/toolkit.scss';
import './styles.themed.scss';

const LiveVideo = (props) => {
  const { content } = props;
  const { vertical } = content.context;

  const verticalPlaymakerKeyMap = {
    news: STREAM_KEYS.NEWS_NOW,
    today: STREAM_KEYS.TODAY_ALL_DAY,
    MSNBC: STREAM_KEYS.MSNBC_TVE,
    noticias: STREAM_KEYS.NOTICIAS_AHORA,
  };

  const playmakerKey = verticalPlaymakerKeyMap[vertical];

  const {
    items: [item],
  } = content;

  const packageMetadata = (content?.metadata) || {};
  const isLinearVideo = packageMetadata?.playmakerToggle ?? true;

  return (
    <>
      <section className="video-live">
        <div className="layout-grid-container">
          <div className="video-live__inner">
            <div className="video-live__stage">
              {isLinearVideo ? (
                <OmegaFeatureFlagSwitch
                  ifOmega={(
                    <div className="video-live__core-video-container">
                      <OmegaVideoPlayer
                        stream={playmakerKey}
                        autoplay
                        mutedAutoplay
                        stickyEnabled
                      />
                    </div>
                  )}
                >
                  <div className="video-live__core-video-container">
                    <VideoContainer stickyEnabled>
                      <TapToUnmuteButton />
                      <CoreVideoPlayer stream={playmakerKey} autoplay mutedAutoplay />
                      <VideoControlRack />
                    </VideoContainer>
                  </div>
                </OmegaFeatureFlagSwitch>
              ) : (
                <NDPVideo item={item} isRail={false} />
              )}

              <div className="video-live__info-container">
                <Info />
              </div>
            </div>

            <div className="video-live__schedule-container">
              <ScheduleLive />
            </div>
          </div>
        </div>

        <Banner />
      </section>
    </>
  );
};

LiveVideo.propTypes = {
  content: PackagePropType.isRequired,
};

export { LiveVideo };
