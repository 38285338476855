import React from 'react';
import { useTranslation } from 'react-i18next';

import { Disclaimer } from 'components/packages/SelectCover/Disclaimer';
import { ImageCard } from 'components/packages/SelectCover/ImageCard';
import { TagLine } from 'components/packages/SelectCover/TagLine';

import { package as PackagePropType } from 'lib/CustomPropTypes';

import styles from './styles.module.scss';

function SelectCover(props) {
  const { t } = useTranslation();

  const {
    content: {
      items = [],
      metadata: packageMetadata,
      metadata: {
        disclaimerKey,
        disclaimerOverride,
        listHeader = t('LATEST IN SELECT'),
        tagLine = t('Trusted information and inspiration to help you upgrade your life.'),
      } = {},
    },
  } = props;

  if (!Array.isArray(items) || !items.length) {
    return null;
  }

  return (
    <div className={`pkg ${styles.selectCover}`}>
      <TagLine value={tagLine} />
      <Disclaimer
        disclaimerKey={disclaimerKey}
        disclaimerOverride={disclaimerOverride}
      />
      <div className={styles.content}>
        <div className={styles.imageTeases}>
          {typeof items[0] === 'object' && (
            <ImageCard
              card={items[0]}
              packageMetadata={packageMetadata}
              type="primary"
            />
          )}
          <div className={styles.imageTeasesSecondary}>
            {typeof items[1] === 'object' && (
              <ImageCard
                card={items[1]}
                packagedMetadata={packageMetadata}
                type="secondary"
              />
            )}
            {typeof items[2] === 'object' && (
              <ImageCard
                card={items[2]}
                packagedMetadata={packageMetadata}
                type="secondary"
              />
            )}
          </div>
        </div>

        <div className={styles.latestWrapper}>
          <div className={styles.latestHeader}>
            {listHeader}
          </div>
          <div className={styles.latestCards}>
            {items.slice(3).map((card) => (
              <ImageCard
                key={card.id}
                card={card}
                packagedMetadata={packageMetadata}
                type="latest"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

SelectCover.propTypes = {
  content: PackagePropType,
};

SelectCover.defaultProps = {
  content: {},
};

export { SelectCover };
