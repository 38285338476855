import { fiveSlotLayout, fiveSlotRailLayout } from './fiveSlotLayout';
import { sixSlotLayout, sixSlotRailLayout } from './sixSlotLayout';
import { sevenSlotLayout, sevenSlotRailLayout } from './sevenSlotLayout';
import { eightSlotLayout, eightSlotRailLayout } from './eightSlotLayout';
import { nineSlotLayout, nineSlotRailLayout } from './nineSlotLayout';
import { liveBlogLayouts, liveBlogRailLayouts } from './liveBlogLayouts';

const getRailLayout = (type, totalTeases) => {
  if (type === 'blog') {
    return liveBlogRailLayouts[totalTeases];
  }

  switch (totalTeases) {
    case 6:
      return sixSlotRailLayout;
    case 7:
      return sevenSlotRailLayout;
    case 8:
      return eightSlotRailLayout;
    case 9:
      return nineSlotRailLayout;
    case 5:
    default:
      return fiveSlotRailLayout;
  }
};

export const getLayout = (type, isRailAdjacent, totalTeases) => {
  if (isRailAdjacent) return getRailLayout(type, totalTeases);

  if (type === 'blog') {
    return liveBlogLayouts[totalTeases];
  }

  switch (totalTeases) {
    case 6:
      return sixSlotLayout;
    case 7:
      return sevenSlotLayout;
    case 8:
      return eightSlotLayout;
    case 9:
      return nineSlotLayout;
    case 5:
    default:
      return fiveSlotLayout;
  }
};
