import React from 'react';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import BadgeLabel from 'components/BadgeLabel';
import { LinkIfHref } from 'components/Link';
import { BylineList } from 'components/Byline';
import ContentTimestamp from 'components/ContentTimestamp';
import { getBadgeType } from '../../utils/containerFunctions';
import './styles.themed.scss';

/**
 * @param {object} root0
 * @param {object} root0.leadItemContent object containing filtered lead item & other objects
 * @returns {React.ReactElement} Component
 */
export function LeadItem({ leadItemContent }) {
  const {
    item: leadHeadlineItem,
    headlineSize,
  } = leadItemContent;

  if (!leadHeadlineItem) return null;

  const {
    metadata,
    computedValues,
    item,
  } = leadHeadlineItem;

  const hasBadge = metadata?.hasBadge;
  const badge = metadata?.badge;
  const hasEyebrow = metadata?.hasEyebrow !== false;
  const showAuthor = metadata?.showAuthor && item?.authors?.length > 0;
  const showDek = metadata?.showDek;
  const showTimestamp = metadata?.showTimestamp;
  const leadHeadlineUrl = metadata?.embedHeadlineUrl || computedValues?.url;

  return (
    <div className="multi-storyline-container__lead-item-container">
      {
        hasEyebrow && (
          <BadgeLabel
            badgeType={getBadgeType(badge, hasBadge)}
            badgeText={metadata?.badgeText}
            eyebrowText={computedValues?.unibrow?.text}
            url={metadata?.embedHeadlineUrl || computedValues?.url?.primary}
          />
        )
      }
      <div className={`lead-headline ${headlineSize}`}>
        <LinkIfHref href={leadHeadlineUrl}>
          {computedValues?.headline}
        </LinkIfHref>
      </div>
      {showDek && (
        <>
          <div className="dek-top-spacer" />
          <div className="lead-item-dek">
            {computedValues?.dek}
          </div>
        </>
      )}
      {(showAuthor || showTimestamp) && (
        <>
          <div className="author-top-spacer" />
          <div className="lead-item-byline">
            {showAuthor && (
              <BylineList
                authors={item?.authors}
              />
            )}
            {showAuthor && showTimestamp && (
              <span className="ma1">
                /
              </span>
            )}
            {showTimestamp && (
              <ContentTimestamp
                dateCreated={item?.dateCreated}
                datePublished={item?.datePublished}
                delimiter=", "
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

LeadItem.propTypes = {
  leadItemContent: PackagePropType.isRequired,
};
