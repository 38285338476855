import i18next from 'i18next';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import TitleLogo from 'components/TitleLogo';

import './styles.themed.scss';

export function PackageTitleTopBorder(props) {
  const {
    additionalClasses,
    logoUrl,
    seeAllText,
    seeAllUrl,
    title,
    icid,
    HTag,
  } = props;

  if (!title && !logoUrl) {
    return null;
  }

  const headingClasses = classNames(
    'package-title-top-border__title',
  );

  const containerClasses = classNames(
    'package-title-top-border',
    'relative',
    'df',
    'flex-column',
    additionalClasses,
  );

  const isTodayVertical = false;
  const isNotAlternateDisplay = false;

  const link = seeAllUrl
    ? (
      <Link
        className={classNames(
          'package-title-top-border__link',
          {
            'package-title-top-border__link--alt-color': isTodayVertical && isNotAlternateDisplay,
          },
        )}
        to={seeAllUrl}
        icid={icid}
      >
        {seeAllText || i18next.t('See All')}
      </Link>
    )
    : null;

  return (
    <div className={containerClasses} data-testid="package-title-top-border">
      <HTag className={headingClasses}>
        <TitleLogo logoUrl={logoUrl} title={title} />
        {link}
      </HTag>
    </div>
  );
}

PackageTitleTopBorder.propTypes = {
  additionalClasses: PropTypes.string,
  logoUrl: PropTypes.string,
  seeAllText: PropTypes.string,
  seeAllUrl: PropTypes.string,
  title: PropTypes.string,
  icid: PropTypes.string,
  HTag: PropTypes.string,
};

PackageTitleTopBorder.defaultProps = {
  additionalClasses: '',
  logoUrl: '',
  seeAllText: '',
  seeAllUrl: '',
  title: '',
  icid: null,
  HTag: 'h2',
};
