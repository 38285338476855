import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import './styles.themed.scss';

import { FeatureFlagContext } from 'lib/ContextTypes';

const verticals = {
  news: {
    text: 'Get the latest breaking news and stay on top of the stories that matter most.',
  },
  today: {
    // eslint-disable-next-line max-len
    text: 'TODAY All Day is a 24/7 streaming channel from NBC News where viewers can find everything they love about TODAY.',
  },
  noticias: {
    text: 'Las últimas noticias y las historias más importantes para la comunidad latina.',
  },
};

function Info() {
  const {
    query: { vertical },
  } = useRouter();

  const { 'feature-flag-logo-update': isNewsNowLogoUpdateEnabled } = useContext(FeatureFlagContext);

  const { text } = verticals[vertical];

  return (
    <div className="video-live-info">
      {vertical === 'news' && isNewsNowLogoUpdateEnabled ? (
        <div className={`video-live-info__logo video-live-info__logo--${vertical}-updated`} />
      ) : (
        <div className={`video-live-info__logo video-live-info__logo--${vertical}`} />
      )}

      <span className="video-live-info__txt">{text}</span>
    </div>
  );
}

export default Info;
