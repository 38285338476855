import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import * as CustomPropTypes from 'lib/CustomPropTypes';

import classNames from 'classnames';

import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import TypeIcon from 'components/TypeIcon';
import { Unibrow } from 'components/Unibrow';

import { timeFrom } from 'lib/DateTime';
import { imageAltForItem } from 'lib/imageAlt';
import { isVideoLive } from 'lib/videoUtils';

import buildUrl from 'lib/buildUrl';

import AIMS_FLAVORS from 'lib/aimsFlavors';

import './styles.themed.scss';

class NewsTease extends React.Component {
  static propTypes = {
    total: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    content: CustomPropTypes.content,
  }

  static contextTypes = {
    vertical: PropTypes.string,
  }

  static defaultProps = {
    content: {},
  }

  liveTitle() {
    const { content: { type, item } } = this.props;

    if (type === 'video' && isVideoLive(item)) {
      return 'Live Video';
    }
    if (type === 'liveBlog') {
      return 'Live'; // TODO: We currently don't have liveBlogs
    }
    return null;
  }

  shouldDisplayImage() {
    const { content } = this.props;

    const {
      computedValues: { teaseImage },
      metadata = {},
    } = content;

    return teaseImage && metadata.showTeaseImage !== false;
  }

  teaseImage() {
    const { content, total, index } = this.props;

    const {
      type,
      computedValues: { url, teaseImage },
    } = content;

    if (!this.shouldDisplayImage()) {
      return null;
    }

    let sizes = {
      s: AIMS_FLAVORS.FOCAL_920x460,
      m: AIMS_FLAVORS.FOCAL_460x230,
    };
    if (total === 3 && index === 0) {
      sizes = {
        s: AIMS_FLAVORS.FOCAL_920x460,
        xl: AIMS_FLAVORS.FOCAL_460x230,
      };
    }

    const isFirstOfThree = total === 3 && index === 0;

    return (
      <div
        className={classNames(
          'news-tease__image-container',
          {
            'grid-fr-half-m': total === 1,
            'grid-fr-half-xl self-start-xl': total === 2,
            'news-tease__image-container--first-item': isFirstOfThree,
          },
        )}
      >
        <Link to={url}>
          <TeasePicture
            className={type === 'video' ? 'video' : null}
            responsiveFlavors={sizes}
            url={teaseImage.url.primary}
            alt={imageAltForItem(content)}
            lazyload={false}
            type={type}
            computedValues={content.computedValues}
          />
          <TypeIcon type={type} />
        </Link>
      </div>
    );
  }

  timestamp() {
    const {
      content: {
        metadata = {},
        item,
      },
    } = this.props;

    if (!item || metadata.showTimestamp === false) {
      return null;
    }

    const { dateCreated, datePublished } = item;
    const isUpdated = dateCreated !== datePublished;

    return (
      <p className="news-tease__timestamp">
        {isUpdated && 'UPDATED '}
        {i18next.t('time_ago', { time: timeFrom(isUpdated ? datePublished : dateCreated) })}
      </p>
    );
  }

  dek() {
    const { content } = this.props;

    const {
      computedValues: { dek },
      metadata = {},
    } = content;

    if (!dek || metadata.showDek === false) {
      return null;
    }

    return (
      <p className="news-tease__description">
        {dek}
      </p>
    );
  }

  render() {
    const { content, total, index } = this.props;

    const url = buildUrl(content);

    const {
      id,
      computedValues: {
        unibrow, headline,
      },
    } = content;

    const liveTitle = this.liveTitle();

    let grid = '';
    const isFirstOfThree = total === 3 && index === 0;

    if (total === 1) {
      grid = 'grid-col-12-m';
    } else if (total === 2) { // 2 items
      grid = 'grid-col-6-m';
    } else if (isFirstOfThree) { // 3 items, index 0
      grid = 'grid-col-12-m grid-col-4-xl';
    } else if (total === 3 && index !== 0) { // 3 items, index 1 and 2
      grid = 'grid-col-6-m grid-col-4-xl';
    } else { // 4 items
      grid = `grid-col-6-m grid-col-6-l grid-col-${12 / total}-xl`;
    }

    const shouldDisplayImage = this.shouldDisplayImage();

    return (
      <article
        data-testid="newsTease"
        className={classNames(
          'news-tease__container',
          'layout-subgrid-item',
          grid,
          `news-tease__col-${total}`,
        )}
        data-contentid={id}
      >
        {unibrow && (
          <div>
            <Unibrow
              className="news-tease__unibrow"
              data-test="newstease__unibrow"
              unibrow={unibrow}
            />
          </div>
        )}
        <div className="news-tease__ruler" />
        <div
          className={classNames('news-tease__info-wrapper', {
            'news-tease__info-wrapper--one-col': total === 1,
            'news-tease__info-wrapper--two-col': total === 2,
          })}
        >
          {this.teaseImage()}
          <div
            className={classNames('news-tease__info', {
              'news-tease__info--multi-col': total !== 1,
              'news-tease__info--one-col--no-image':
                !shouldDisplayImage && total === 1,
              'news-tease__info--one-col--with-image grid-fr-half-m':
                shouldDisplayImage && total === 1,
              'grid-fr-half-xl': shouldDisplayImage && total === 2,
            })}
          >
            <Link to={url}>
              <h3 className="news-tease__headline">
                {liveTitle && (
                  <span className="news-tease__live-title">
                    {liveTitle}
                    {' '}
                    /
                    {' '}
                  </span>
                )}
                {headline}
              </h3>
            </Link>
            {this.timestamp()}
            {this.dek()}
          </div>
        </div>
      </article>
    );
  }
}

export default NewsTease;
