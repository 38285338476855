import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { timeFrom, isBefore } from 'lib/DateTime';

const ONE_MINUTE = 60000; // Milliseconds
const FIFTEEN_MINUTES = ONE_MINUTE * 15;

function TimeAgo({ displayDate, isInline }) {
  const { t } = useTranslation();

  const justUpdated = isBefore(displayDate, FIFTEEN_MINUTES);
  const showJustNow = isBefore(displayDate, ONE_MINUTE);

  return (
    <span
      data-testid="lbp-card__time"
      className={classNames('lbp-card__time', {
        'lbp-card__time--just-updated': justUpdated,
        isInline,
      })}
    >
      <span
        className={classNames('lbp-card__time__just-now', {
          'lbp-card__time--show': showJustNow,
        })}
        data-testid="lbp-card__time__just-now"
      >
        {`${t('Just now')}`}
      </span>
      <span
        className={classNames('lbp-card__time__text', {
          'lbp-card__time--show': !showJustNow,
        })}
        data-testid="lbp-card__time__text"
      >
        {`${t('time_ago', { time: timeFrom(displayDate) })}`}
      </span>
    </span>
  );
}

TimeAgo.propTypes = {
  displayDate: PropTypes.string.isRequired,
  isInline: PropTypes.bool,
};

TimeAgo.defaultProps = {
  isInline: false,
};

export default TimeAgo;
