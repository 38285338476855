import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import classNames from 'classnames';

import { package as PackagePropType } from 'lib/CustomPropTypes';
import { timeFrom, getFormattedDateString } from 'lib/DateTime';
import { IMAGE_SIZES } from 'lib/imageUtils';

import Link, { LinkIfHref } from 'components/Link';
import TypeIcon from 'components/TypeIcon';
import TeasePicture from 'components/TeasePicture';
import TitleLogo from 'components/TitleLogo';
import { Unibrow } from 'components/Unibrow';

import { Duration } from './Duration';
import { KeyUpdates } from './KeyUpdates';

import config from './config';

import styles from './styles.module.scss';


class Pancake extends React.Component {
  static propTypes = {
    content: PackagePropType.isRequired,
    vertical: PropTypes.string.isRequired,
    railAdjacent: PropTypes.bool,
    pkgClassName: PropTypes.string,
  };

  static defaultProps = {
    railAdjacent: false,
    pkgClassName: 'pkg pancake',
  }

  getItem = (i) => {
    const { content: { items } } = this.props;
    return items[i];
  }

  getKey = (i) => {
    if (this.getItem(i)) {
      return this.getItem(i).id;
    }
    return `item-${i}`;
  }

  getLayout = (artVariant = false) => {
    const { railAdjacent } = this.props;
    if (artVariant && artVariant !== false) {
      return `${railAdjacent ? 'rail' : 'full'}${artVariant}`;
    }
    return railAdjacent ? 'rail' : 'full';
  }

  getLength = () => {
    const { content: { items } } = this.props;
    return items.length;
  }

  getConfig = (key, i = false, artVariant = false) => {
    const lay = this.getLayout(artVariant);
    const len = this.getLength();
    if (config[key]?.[len]?.[lay]) {
      if (i !== false) {
        return config[key][len][lay][i] || false;
      }
      return config[key][len][lay];
    }
    return false;
  }

  getImageSizes = (i) => {
    const images = this.getConfig('images', i) || [];
    return images.reduce((accumulator, flavor, idx) => {
      accumulator[IMAGE_SIZES[idx]] = flavor;
      return accumulator;
    }, {});
  }

  getPicture = (i) => {
    if (!this.hasMetaFlag('showTeaseImage', i, true)) {
      return false;
    }
    const item = this.getItem(i);
    const sizes = this.getImageSizes(i);
    if (Object.keys(sizes).length >= 1 && item.computedValues?.teaseImage) {
      return (
        <TeasePicture
          responsiveFlavors={sizes}
          className={styles.teasePicture}
          type={item.type}
          computedValues={item.computedValues}
          placeholder={false}
          retina
        />
      );
    }
    return false;
  }

  /**
   * Assemble picture-tease alignment classes
   */
  getFloatClasses = (i) => {
    const float = this.getConfig('float', i);
    if (float?.length) {
      return float.map((val, key) => {
        if (typeof val === 'number') {
          // flOn, flOff, flOnM, flOffM, ...
          return styles[`fl_${val ? 1 : 0}${key > 0 ? `_${IMAGE_SIZES[key]}` : ''}`];
        }
        return null;
      })
        .filter((v) => v)
        .join(' ');
    }
    return null;
  }

  getHeadlineClasses = (i) => {
    const variant = this.hasMetaFlag('showTeaseImage', i, true) ? false : 'NoArt';
    const fonts = this.getConfig('headlineFonts', i, variant);
    const ret = [];
    if (fonts?.length) {
      // Convert integers to toolkit classes.
      let f = 0;
      while (f < fonts.length) {
        if (fonts[f]) {
          const s = parseInt(fonts[f], 10) - (this.isSerif() ? 2 : 0);
          ret.push(`f${s}${f > 0 ? `-${IMAGE_SIZES[f]}` : ''}`);
        }
        f += 1;
      }
    }
    return ret;
  }

  getTease = (i) => {
    const item = this.getItem(i);
    const isVideo = item?.type && item.type === 'video';
    const picture = this.getPicture(i);

    return item && (
      <div className={classNames(styles.tease, this.getFloatClasses(i))} data-contentid={item.id} data-testid="tease-container">
        {!!picture && (
          <Link
            to={item.computedValues.url}
            className={styles.teasePictureLink}
          >
            {picture}
            <TypeIcon type={item.type} className={styles.typeIcon} />
          </Link>
        )}
        <div className={styles.info}>
          {this.unibrow(item)}
          {this.headline(item, i)}
          {isVideo && !picture && (
            <Duration item={item} />
          )}
          {!isVideo && this.getTimestamp(i)}
        </div>
      </div>
    );
  }

  getTimestamp = (i) => {
    if (this.hasMetaFlag('showTimestamp', i, false)) {
      const { datePublished } = this.getItem(i).item;
      if (!datePublished) {
        return null;
      }
      const date = new Date(datePublished);
      const sixtyDaysAgo = (new Date()).getTime() - (60 * 86400 * 1000);
      const publishedMoreThanSixtyDaysAgo = date.getTime() < sixtyDaysAgo;
      let ts = null;
      if (publishedMoreThanSixtyDaysAgo) {
        ts = getFormattedDateString(datePublished);
      } else {
        ts = `${i18next.t('time_ago', { time: timeFrom(datePublished) })}`;
      }
      return (
        <div className="founders-mono gray-80 f2 mt1 ttc">{ts}</div>
      );
    }
    return null;
  }

  isSerif = () => {
    const { vertical } = this.props;
    return ['today'].includes(vertical);
  }

  hasMetaFlag = (flag, i, defaultVal = true) => {
    const { content: { items, metadata: { [flag]: pkgFlag } } } = this.props;
    if (typeof pkgFlag !== 'undefined') {
      return pkgFlag;
    }
    const { metadata: { [flag]: teaseFlag } } = items[i] || {};
    if (typeof teaseFlag !== 'undefined') {
      return teaseFlag;
    }
    return defaultVal;
  }

  unibrow = (item) => {
    const { unibrow } = item.computedValues || {};
    if (!unibrow) {
      return null;
    }

    const taxonomy = item?.item?.taxonomy;
    // taxonomy and primaryVertical are nullable properties from API
    const vertical = (taxonomy?.primaryVertical?.name) || '';

    return (
      <Unibrow
        unibrow={unibrow}
        className={classNames(
          styles.unibrow,
          { [styles.opinion]: vertical.toLowerCase() === 'think' },
        )}
      />
    );
  }

  headline = (item, i) => {
    const { headline, url } = item.computedValues || {};
    return (
      <h3
        className={classNames(
          styles.headline,
          this.getHeadlineClasses(i),
        )}
      >
        <Link to={url}>
          {headline}
        </Link>
      </h3>
    );
  }

  getTitleOrLogo = () => {
    const {
      vertical,
      content: {
        metadata: {
          title,
          titleUrl,
          logoUrl,
        } = {},
      },
    } = this.props;


    return (logoUrl || title) ? (
      <h2 data-testid="pancake-title" className={vertical === 'news' ? styles.title : styles.titleWithUnderline}>
        <LinkIfHref href={titleUrl}>
          <TitleLogo
            logoUrl={logoUrl}
            title={title}
          />
        </LinkIfHref>
      </h2>
    ) : null;
  }

  layout2 = () => {
    const {
      content: { metadata } = {},
      railAdjacent,
    } = this.props;

    const wrapContent = ['layout-grid-item', 'grid-col-12', 'grid-col-6-m'];
    const wrapRail = ['layout-grid-item', 'grid-col-12', 'grid-col-6-m'];

    const itemMain0 = ['layout-subgrid-item', 'grid-col-12', 'grid-col-6-m'];
    const itemMain1 = ['layout-subgrid-item', 'grid-col-12', 'grid-col-6-m'];

    if (railAdjacent) {
      itemMain0.push('grid-col-12-l', 'grid-col-6-xl');
      itemMain1.push('grid-col-12-l', 'grid-col-6-xl');
    } else {
      wrapContent.push('grid-col-8-l', 'grid-col-9-xl');
      wrapRail.push('grid-col-4-l', 'grid-col-3-xl');

      itemMain0.push('grid-col-7-5-l grid-col-8-xl');
      itemMain1.push('grid-col-4-5-l grid-col-4-xl');
    }

    return (
      <>
        <div className={classNames(styles.contentColumn, wrapContent)}>
          <div className="layout-subgrid-container">
            <div className="layout-subgrid-item grid-col-12">
              {this.getTitleOrLogo()}
            </div>
            <div className="layout-subgrid-item grid-col-12">
              <div className="layout-subgrid-container">
                <div className={classNames(styles.item0, styles.M, itemMain0)}>
                  {this.getTease(0)}
                </div>
                <div className={classNames(styles.item1, styles.S, itemMain1)}>
                  {this.getTease(1)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.keyUpdates, wrapRail)}>
          <KeyUpdates metadata={metadata} />
        </div>
      </>
    );
  }

  layout3 = () => {
    const {
      content: { metadata } = {},
      railAdjacent,
    } = this.props;

    const wrapContent = ['layout-grid-item', 'grid-col-12', 'grid-col-6-m'];
    const wrapRail = ['layout-grid-item', 'grid-col-12', 'grid-col-6-m'];

    const wrapItemMain = ['layout-subgrid-item', 'grid-col-12', 'grid-col-12-m'];
    const wrapItemSub = ['layout-subgrid-item', 'grid-col-12', 'grid-col-12-m'];

    const itemMain = ['layout-subgrid-item', 'grid-col-12'];
    const itemSub = ['layout-subgrid-item', 'grid-col-12'];

    if (railAdjacent) {
      itemSub.push('grid-col-6-xl');
    } else {
      wrapContent.push('grid-col-8-l', 'grid-col-9-xl');
      wrapRail.push('grid-col-4-l', 'grid-col-3-xl');

      wrapItemMain.push('grid-col-7-5-l', 'grid-col-8-xl');
      wrapItemSub.push('grid-col-4-5-l', 'grid-col-4-xl');

      itemMain.push('grid-col-12-l');
      itemSub.push('grid-col-12-xl');
    }

    return (
      <>
        <div className={classNames(styles.contentColumn, wrapContent)}>
          <div className="layout-subgrid-container">
            <div className="layout-subgrid-item grid-col-12">
              {this.getTitleOrLogo()}
            </div>
            <div className={classNames(wrapItemMain)}>
              <div className="layout-subgrid-container">
                <div className={classNames(styles.item0, styles.M, itemMain)}>
                  {this.getTease(0)}
                </div>
                <div className={classNames(styles.item1, styles.M, itemMain, 'dn db-l dn-xl')}>
                  {this.getTease(1)}
                </div>
              </div>
            </div>
            <div className={classNames(wrapItemSub)}>
              <div className="layout-subgrid-container">
                <div className={classNames(styles.item1, styles.S, itemSub, 'dn-l db-xl')}>
                  {this.getTease(1)}
                </div>
                <div className={classNames(styles.item2, styles.S, itemSub)}>
                  {this.getTease(2)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.keyUpdates, wrapRail)}>
          <KeyUpdates metadata={metadata} />
        </div>
      </>
    );
  }

  layout4 = () => {
    const {
      content: { metadata } = {},
      railAdjacent,
    } = this.props;

    const wrapContent = ['layout-grid-item', 'grid-col-12', 'grid-col-6-m'];
    const wrapRail = ['layout-grid-item', 'grid-col-12', 'grid-col-6-m'];

    const wrapItemMain = ['layout-subgrid-item', 'grid-col-12-m'];
    const wrapItemSub = ['layout-subgrid-item', 'grid-col-12-m'];

    const itemMain = [
      ['layout-subgrid-item', 'grid-col-12', 'grid-col-6-m'],
      ['layout-subgrid-item', 'grid-col-12', 'grid-col-6-m'],
    ];
    const itemSub = [
      ['layout-subgrid-item', 'grid-col-12'],
      ['layout-subgrid-item', 'grid-col-12'],
    ];

    if (railAdjacent) {
      itemMain[0].push('grid-col-12-l', 'grid-col-6-xl');
      itemMain[1].push('grid-col-12-l', 'grid-col-6-xl');

      itemSub[0].push('grid-col-6-xl');
      itemSub[1].push('grid-col-6-xl');
    } else {
      wrapContent.push('grid-col-8-l', 'grid-col-9-xl');
      wrapRail.push('grid-col-4-l', 'grid-col-3-xl');

      wrapItemMain.push('grid-col-12-l', 'grid-col-8-xl');
      wrapItemSub.push('grid-col-12-l', 'grid-col-4-xl');

      itemMain[0].push('grid-col-7-5-l', 'grid-col-6-xl');
      itemMain[1].push('grid-col-4-5-l', 'grid-col-6-xl');

      itemSub[0].push('grid-col-7-5-l', 'grid-col-12-xl');
      itemSub[1].push('grid-col-4-5-l', 'grid-col-12-xl');
    }

    return (
      <>
        <div className={classNames(styles.contentColumn, wrapContent)}>
          <div className="layout-subgrid-container">
            <div className="layout-subgrid-item grid-col-12">
              {this.getTitleOrLogo()}
            </div>
            <div className={classNames(wrapItemMain)}>
              <div className="layout-subgrid-container">
                <div className={classNames(styles.item0, styles.M, itemMain[0])}>
                  {this.getTease(0)}
                </div>
                <div className={classNames(styles.item1, styles.M, itemMain[1])}>
                  {this.getTease(1)}
                </div>
              </div>
            </div>
            <div className={classNames(wrapItemSub)}>
              <div className="layout-subgrid-container">
                <div className={classNames(styles.item2, styles.S, itemSub[0])}>
                  {this.getTease(2)}
                </div>
                <div className={classNames(styles.item3, styles.S, itemSub[1])}>
                  {this.getTease(3)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.keyUpdates, wrapRail)}>
          <KeyUpdates metadata={metadata} />
        </div>
      </>
    );
  }

  layout5 = () => {
    const { railAdjacent } = this.props;

    const itemClassM = ['layout-subgrid-item', 'grid-col-12', 'grid-col-6-m'];
    const wrapClassS = ['layout-subgrid-item', 'grid-col-12'];
    const itemClassS = ['layout-subgrid-item', 'grid-col-12', 'grid-col-6-m'];

    if (railAdjacent) {
      itemClassM.push('grid-col-12-l', 'grid-col-6-xl');
      wrapClassS.push('dn-l', 'db-xl');
      itemClassS.push('grid-col-6-xl');
    } else {
      itemClassM.push('grid-col-4-xl');
      wrapClassS.push('grid-col-4-xl');
      itemClassS.push('grid-col-12-xl');
    }

    return (
      <>
        <div className="layout-grid-item grid-col-12">
          {this.getTitleOrLogo()}
        </div>
        <div className={classNames(styles.item0, 'layout-grid-item grid-col-12 grid-col-6-m')}>
          {this.getTease(0)}
        </div>
        <div className="layout-grid-item grid-col-12 grid-col-6-m">
          <div className="layout-subgrid-container">
            <div className={classNames(styles.item1, styles.M, itemClassM)}>
              {this.getTease(1)}
            </div>
            <div className={classNames(styles.item2, styles.M, itemClassM)}>
              {this.getTease(2)}
            </div>
            <div className={classNames(wrapClassS)}>
              <div className="layout-subgrid-container">
                <div className={classNames(styles.item3, styles.S, itemClassS)}>
                  {this.getTease(3)}
                </div>
                <div className={classNames(styles.item4, styles.S, itemClassS)}>
                  {this.getTease(4)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!!railAdjacent && (
          <>
            {[3, 4].map((i) => (
              <div
                key={this.getKey(i)}
                className={classNames(styles.S, 'layout-grid-item grid-col-6-l dn db-l dn-xl')}
              >
                {this.getTease(i)}
              </div>
            ))}
          </>
        )}
      </>
    );
  }

  layout6 = () => {
    const { railAdjacent } = this.props;

    const itemClassM = ['layout-grid-item', 'grid-col-12', 'grid-col-6-m', 'grid-col-6-l'];
    const wrapClassS = ['layout-grid-item', 'grid-col-12'];
    const itemClassS = ['layout-subgrid-item', 'grid-col-12', 'grid-col-3-m'];

    if (railAdjacent) {
      itemClassS.push('grid-col-6-l', 'grid-col-3-xl');
    } else {
      itemClassM.push('grid-col-2-xl');
      wrapClassS.push('grid-col-4-xl');
      itemClassS.push('grid-col-3-l', 'grid-col-6-xl');
    }

    return (
      <>
        <div className="layout-grid-item grid-col-12">
          {this.getTitleOrLogo()}
        </div>
        <div className={classNames(styles.item0, 'layout-grid-item grid-col-12 grid-col-6-m')}>
          {this.getTease(0)}
        </div>
        <div className={classNames(styles.item1, styles.M, itemClassM)}>
          {this.getTease(1)}
        </div>
        <div className={classNames(wrapClassS)}>
          <div className="layout-subgrid-container">
            {[2, 3, 4, 5].map((i) => (
              <div
                key={this.getKey(i)}
                className={classNames(styles[`item${i}`], styles.S, itemClassS)}
              >
                {this.getTease(i)}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }

  layout7 = () => {
    const { railAdjacent } = this.props;

    const itemClassL = ['layout-grid-item', 'grid-col-12', 'grid-col-6-m'];
    const wrapClassM = ['layout-grid-item', 'grid-col-12', 'grid-col-6-m'];
    const itemClassM = ['layout-subgrid-item', 'grid-col-12'];
    const wrapClassS = ['layout-grid-item', 'grid-col-12'];
    const itemClassS = ['layout-subgrid-item', 'grid-col-12', 'grid-col-3-m'];

    if (railAdjacent) {
      itemClassS.push('grid-col-6-l', 'grid-col-3-xl');
    } else {
      itemClassL.push('grid-col-4-xl');
      wrapClassM.push('grid-col-4-xl');
      itemClassM.push('grid-col-6-xl');
      wrapClassS.push('grid-col-4-xl');
      itemClassS.push('grid-col-3-l', 'grid-col-6-xl');
    }

    return (
      <>
        <div className="layout-grid-item grid-col-12">
          {this.getTitleOrLogo()}
        </div>
        <div className={classNames(styles.item0, itemClassL)}>
          {this.getTease(0)}
        </div>
        <div className={classNames(wrapClassM)}>
          <div className="layout-subgrid-container">
            <div className={classNames(styles.item1, styles.M, itemClassM)}>
              {this.getTease(1)}
            </div>
            <div className={classNames(styles.item2, styles.M, itemClassM)}>
              {this.getTease(2)}
            </div>
          </div>
        </div>
        <div className={classNames(wrapClassS)}>
          <div className="layout-subgrid-container">
            {[3, 4, 5, 6].map((i) => (
              <div
                key={this.getKey(i)}
                className={classNames(styles[`item${i}`], styles.S, itemClassS)}
              >
                {this.getTease(i)}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }

  layout8 = () => {
    const { railAdjacent } = this.props;

    const wrapClassM = ['layout-grid-item', 'grid-col-12', 'grid-col-6-m'];
    const itemClassM = ['layout-subgrid-item', 'grid-col-12'];
    const itemClassS = ['layout-subgrid-item', 'grid-col-12', 'grid-col-6-l'];

    if (railAdjacent) {
      wrapClassM.push('grid-col-12-l', 'grid-col-6-xl');
      itemClassM.push('grid-col-6-l');
    } else {
      wrapClassM.push('grid-col-6-l', 'grid-col-6-xl');
      itemClassM.push('grid-col-12-l');
      itemClassS.push('grid-col-4-xl');
    }

    return (
      <>
        <div className="layout-grid-item grid-col-12">
          {this.getTitleOrLogo()}
        </div>
        <div className={classNames(wrapClassM)}>
          <div className="layout-subgrid-container">
            {[0, 1].map((i) => (
              <div
                key={this.getKey(i)}
                className={classNames(styles[`item${i}`], styles.M, itemClassM, {
                  'grid-col-12-xl': railAdjacent,
                  [`grid-col-${!i ? 8 : 4}-xl`]: !railAdjacent,
                })}
              >
                {this.getTease(i)}
              </div>
            ))}
          </div>
        </div>
        <div className={classNames(wrapClassM)}>
          <div className="layout-subgrid-container">
            {[2, 3, 4, 5, 6, 7].map((i) => (
              <div
                key={this.getKey(i)}
                className={classNames(styles[`item${i}`], styles.S, itemClassS)}
              >
                {this.getTease(i)}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      content: {
        id,
        items = [],
        metadata = {},
      },
      railAdjacent,
      pkgClassName,
    } = this.props;

    const { length } = items;
    const { showBottomBorder = false } = metadata;

    const containerClasses = classNames(
      pkgClassName,
      styles.pancake,
      styles[`items${length}`],
      {
        [styles.railAdjacent]: railAdjacent,
        [styles.fullWidth]: !railAdjacent,
      },
      'layout-grid-container gutter-collapse',
    );

    const layoutFunc = this[`layout${length}`];

    return !items.includes(null) && (
      <section className={containerClasses} data-packageid={id}>
        {!!layoutFunc && layoutFunc(items)}
        {showBottomBorder && (<hr data-testid="pancake-bottom-border" className={styles.pancakeBottomBorder} />)}
      </section>
    );
  }
}

export { Pancake };
