import React from 'react';
import styles from './styles.module.scss';

const WordsLoadingAnimation = () => (
  <div className={styles.wordsLoading} />
);

const GridLoadingAnimation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="375"
    height="468"
    fill="none"
    viewBox="0 0 375 468"
    className={styles.gridLoading}
  >
    <rect x="58" y="58" width="40" height="40" className="loading-gradient" />
    <rect x="58" y="14" width="40" height="40" className="loading-gradient" />
    <rect x="58" y="102" width="40" height="40" className="loading-gradient" />
    <rect x="58" y="146" width="40" height="40" className="loading-gradient" />
    <rect x="58" y="190" width="40" height="40" className="loading-gradient" />
    <rect x="58" y="234" width="40" height="40" className="loading-gradient" />
    <rect x="58" y="278" width="40" height="40" className="loading-gradient" />
    <rect x="58" y="322" width="40" height="40" className="loading-gradient" />
    <rect x="58" y="366" width="40" height="40" className="loading-gradient" />
    <rect x="58" y="410" width="40" height="40" className="loading-gradient" />
    <rect x="14" y="58" width="40" height="40" className="loading-gradient" />
    <rect x="14" y="14" width="40" height="40" className="loading-gradient" />
    <rect x="14" y="102" width="40" height="40" className="loading-gradient" />
    <rect x="14" y="146" width="40" height="40" className="loading-gradient" />
    <rect x="14" y="190" width="40" height="40" className="loading-gradient" />
    <rect x="14" y="234" width="40" height="40" className="loading-gradient" />
    <rect x="14" y="278" width="40" height="40" className="loading-gradient" />
    <rect x="14" y="322" width="40" height="40" className="loading-gradient" />
    <rect x="14" y="366" width="40" height="40" className="loading-gradient" />
    <rect x="14" y="410" width="40" height="40" className="loading-gradient" />
    <rect x="102" y="58" width="40" height="40" className="loading-gradient" />
    <rect x="102" y="14" width="40" height="40" className="loading-gradient" />
    <rect x="102" y="102" width="40" height="40" className="loading-gradient" />
    <rect x="102" y="146" width="40" height="40" className="loading-gradient" />
    <rect x="102" y="190" width="40" height="40" className="loading-gradient" />
    <rect x="102" y="234" width="40" height="40" className="loading-gradient" />
    <rect x="102" y="278" width="40" height="40" className="loading-gradient" />
    <rect x="102" y="322" width="40" height="40" className="loading-gradient" />
    <rect x="102" y="366" width="40" height="40" className="loading-gradient" />
    <rect x="102" y="410" width="40" height="40" className="loading-gradient" />
    <rect x="146" y="58" width="40" height="40" className="loading-gradient" />
    <rect x="146" y="14" width="40" height="40" className="loading-gradient" />
    <rect x="146" y="102" width="40" height="40" className="loading-gradient" />
    <rect x="146" y="146" width="40" height="40" className="loading-gradient" />
    <rect x="146" y="190" width="40" height="40" className="loading-gradient" />
    <rect x="146" y="234" width="40" height="40" className="loading-gradient" />
    <rect x="146" y="278" width="40" height="40" className="loading-gradient" />
    <rect x="146" y="322" width="40" height="40" className="loading-gradient" />
    <rect x="146" y="366" width="40" height="40" className="loading-gradient" />
    <rect x="146" y="410" width="40" height="40" className="loading-gradient" />
    <rect x="190" y="58" width="40" height="40" className="loading-gradient" />
    <rect x="190" y="14" width="40" height="40" className="loading-gradient" />
    <rect x="190" y="102" width="40" height="40" className="loading-gradient" />
    <rect x="190" y="146" width="40" height="40" className="loading-gradient" />
    <rect x="190" y="190" width="40" height="40" className="loading-gradient" />
    <rect x="190" y="234" width="40" height="40" className="loading-gradient" />
    <rect x="190" y="278" width="40" height="40" className="loading-gradient" />
    <rect x="190" y="322" width="40" height="40" className="loading-gradient" />
    <rect x="190" y="366" width="40" height="40" className="loading-gradient" />
    <rect x="190" y="410" width="40" height="40" className="loading-gradient" />
    <rect x="234" y="58" width="40" height="40" className="loading-gradient" />
    <rect x="234" y="14" width="40" height="40" className="loading-gradient" />
    <rect x="234" y="102" width="40" height="40" className="loading-gradient" />
    <rect x="234" y="146" width="40" height="40" className="loading-gradient" />
    <rect x="234" y="190" width="40" height="40" className="loading-gradient" />
    <rect x="234" y="234" width="40" height="40" className="loading-gradient" />
    <rect x="234" y="278" width="40" height="40" className="loading-gradient" />
    <rect x="234" y="322" width="40" height="40" className="loading-gradient" />
    <rect x="234" y="366" width="40" height="40" className="loading-gradient" />
    <rect x="234" y="410" width="40" height="40" className="loading-gradient" />
    <rect x="278" y="58" width="40" height="40" className="loading-gradient" />
    <rect x="278" y="14" width="40" height="40" className="loading-gradient" />
    <rect x="278" y="102" width="40" height="40" className="loading-gradient" />
    <rect x="278" y="146" width="40" height="40" className="loading-gradient" />
    <rect x="278" y="190" width="40" height="40" className="loading-gradient" />
    <rect x="278" y="234" width="40" height="40" className="loading-gradient" />
    <rect x="278" y="278" width="40" height="40" className="loading-gradient" />
    <rect x="278" y="322" width="40" height="40" className="loading-gradient" />
    <rect x="278" y="366" width="40" height="40" className="loading-gradient" />
    <rect x="278" y="410" width="40" height="40" className="loading-gradient" />
    <rect x="322" y="58" width="40" height="40" className="loading-gradient" />
    <rect x="322" y="14" width="40" height="40" className="loading-gradient" />
    <rect x="322" y="102" width="40" height="40" className="loading-gradient" />
    <rect x="322" y="146" width="40" height="40" className="loading-gradient" />
    <rect x="322" y="190" width="40" height="40" className="loading-gradient" />
    <rect x="322" y="234" width="40" height="40" className="loading-gradient" />
    <rect x="322" y="278" width="40" height="40" className="loading-gradient" />
    <rect x="322" y="322" width="40" height="40" className="loading-gradient" />
    <rect x="322" y="366" width="40" height="40" className="loading-gradient" />
    <rect x="322" y="410" width="40" height="40" className="loading-gradient" />
  </svg>
);

export { GridLoadingAnimation, WordsLoadingAnimation };
