import React from 'react';
import styles from './styles.module.scss';

function Border() {
  return (
    <div className={styles.msnbcDailyBorder} data-testid="msnbcDailyBorder" />
  );
}

export { Border };
