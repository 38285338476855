/* About packageDefaults.js:
  The main function, `setPackageDefaults`, populates the packageMetadata,
  aka `content.metadata` with default values if unset, validates settings,
  and calculates some values, such as layoutTemplate.

  It does this by calling sub-routines that specialize in particular
  areas, mainly for organizational purposes.

  NOTE:
    Work is done in place, so no returning new objects.
    The top level object, `content`, is returned for destructuring.
*/

/**
 *
 * @param {object} content
 * @returns {object} modified content object with package defaults
 */
export function setLayoutDefaults(content) {
  if (!content) return null;
  const { metadata, items } = content;
  if (!metadata || !items) return content;

  metadata.columns = metadata.columns ? Number(metadata.columns) : 2;

  const {
    columns,
    showVerticalBorderA,
    showVerticalBorderB,
  } = metadata;

  // Populate columns with items.
  const colIndices = [...Array(columns).keys()].map((i) => i + 1);
  const colNumbers = colIndices.reduce((ac, colNumber) => {
    const colName = `numberOfItemsInColumn${colNumber}`;
    const curatorValue = metadata[colName];
    metadata[colName] = curatorValue ? Number(curatorValue) : 1;
    ac.push(metadata[colName]);
    return ac;
  }, []);
  // ignoring curator `content.metadata.layoutTemplate`, doesn't apply to this mode.
  metadata.layoutTemplate = colNumbers.join('-');

  // @@MOVE: should be in own function, e.g. setBorderDefaults or setMiscDefaults
  // police showVerticalBorderA/B
  metadata.showVerticalBorderA = showVerticalBorderA ?? true;
  metadata.showVerticalBorderB = (columns > 2) && (showVerticalBorderB ?? true);

  return content;
}

/**
 *
 * @param {object} content
 * @returns {object} modified content object with playmaker defaults
 */
export function setPlaymakerDefaults(content) {
  if (!content) return null;
  const { metadata } = content;
  if (!metadata) return content;

  const {
    playmakerToggle,
    showLiveVideoBadge,
    playmakerBadge,
    playmakerColumn,
  } = metadata;

  // default playmakerColumn
  metadata.playmakerColumn = playmakerColumn ?? 'Column1Top';

  if (playmakerToggle && showLiveVideoBadge && !playmakerBadge) {
    // default playmakerBadge
    // eslint-disable-next-line no-param-reassign
    content.metadata.playmakerBadge = 'BREAKING';
  }
  if (playmakerToggle && !showLiveVideoBadge && playmakerBadge) {
    // eslint-disable-next-line no-param-reassign
    delete (content.metadata.playmakerBadge);
  }
  return content;
}

/**
 * Top Level Function, calls others.
 * @param {object} content
 * @returns {object} modified content object with package & playmaker defaults
 */
export function setPackageDefaults(content) {
  const DEBUG = process.env.NEXT_PUBLIC_MULTISTORY_DEBUG;

  if (!content) return null;

  if (DEBUG) {
    console.log(`(pd.i112).x CONTENT ${content.name} type: ${content.subType}`, { content });
    console.log(`(pd.i114).x received content.metadata ${content.name}`, { ...content.metadata });
  }

  // Processing steps
  //
  setPlaymakerDefaults(content);
  setLayoutDefaults(content);
  //
  /// /

  if (DEBUG) console.log(`(pd.i124).x processed content.metadata ${content.name}`, { ...content.metadata });

  return content;
}
