import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import Breakpoints, { BREAKPOINTS } from 'lib/Breakpoints';
import { schedule as schedulePropType } from 'lib/CustomPropTypes/TVE';

import LoadingText from 'components/packages/Waffle/LoadingPlaceholders/LoadingText';

import NavigationButton from './NavigationButton';
import Program from './Program';
import ScheduleProvider from '../ScheduleProvider';

import './styles.themed.scss';

const block = 'tve__schedule';

class Schedule extends React.Component {
  static propTypes = {
    epgLoading: PropTypes.bool,
    epgUpcoming: PropTypes.arrayOf(
      PropTypes.shape(schedulePropType),
    ),
  };

  static defaultProps = {
    epgLoading: false,
    epgUpcoming: [],
  };

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.state = {
      page: 1,
      pages: 3,
      translateAmount: 0,
    };
  }

  componentDidMount() {
    // Add a breakpoint change listener
    Breakpoints.listenToAll(this.onBreakpointChange);
  }

  componentWillUnmount() {
    // Remove the breakpoint change listener
    Breakpoints.removeFromAll(this.onBreakpointChange);
  }

  onBreakpointChange = (event) => {
    // https://stackoverflow.com/questions/44830917/window-matchmedia-listener-firing-twice
    if (event.matches || (!event.matches && Breakpoints.isXL())) {
      this.setState({ page: 1, translateAmount: 0 }, this.moveSchedule);
    }
  }

  isHorizontal = () => Breakpoints.isM() || Breakpoints.isL();

  // Return the number of pages for the schedule pagination
  // Between 1240px and 1440px only 3 items are visible - 12 / 3 = 4 pages
  getPages = () => {
    const { innerWidth } = window;
    return (innerWidth >= BREAKPOINTS.xl && innerWidth < BREAKPOINTS.xxl) ? 4 : 3;
  }

  moveScheduleBack = () => {
    const pages = this.getPages();
    const { page } = this.state;
    if (page > 1) {
      this.setState({
        // Decriment page
        page: page - 1,
        pages,
      }, this.moveSchedule);
    }
  }

  moveScheduleForward = () => {
    const pages = this.getPages();
    const { page } = this.state;
    if (page < pages) {
      this.setState({
        // Increment page
        page: page + 1,
        pages,
      }, this.moveSchedule);
    }
  }

  moveSchedule = () => {
    const { page, pages } = this.state;
    const { current: list } = this.listRef;

    let translateAmount = 0;
    if (this.isHorizontal()) {
      if (page > 1) {
        const { scrollWidth } = list;
        translateAmount = Math.floor(scrollWidth / pages) * (page - 1) * -1;
      }
    } else if (page > 1) {
      const { height } = list.getBoundingClientRect();
      translateAmount = (height / pages) * (page - 1) * -1;
    }

    this.setState({ translateAmount });
  }

  render() {
    const {
      epgUpcoming,
      epgLoading,
    } = this.props;

    const {
      page,
      pages,
      translateAmount,
    } = this.state;

    // Schedule movement based on orientation
    let transform = 'none';
    if (translateAmount) {
      transform = `translate${this.isHorizontal() ? 'X' : 'Y'}(${translateAmount}px)`;
    }

    return (
      <div
        className={block}
        data-testid={block}
      >
        <NavigationButton
          direction="prev"
          enabled={page > 1}
          onClick={this.moveScheduleBack}
        />

        <div
          className={`${block}__list-wrap`}
          data-testid={`${block}__list-wrap`}
        >
          <div
            className={`${block}__list`}
            data-testid={`${block}__list`}
            ref={this.listRef}
            style={{
              transform,
            }}
          >
            {epgLoading && (
              <div className={`${block}__loading`}>
                <LoadingText />
              </div>
            )}
            {epgUpcoming.map((row, i) => (
              <Program
                index={i}
                key={row.guid}
                time={row.startTimeFormatted}
                title={get(row, 'program.title', null)}
              />
            ))}
          </div>
        </div>

        <NavigationButton
          direction="next"
          onClick={this.moveScheduleForward}
          enabled={page < pages}
        />
      </div>
    );
  }
}

export default ScheduleProvider(Schedule);
