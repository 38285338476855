import React from 'react';

import styles from './styles.module.scss';

export function Loading() {
  return (
    <div className={styles.loading} data-test="my-news-loading" data-testid="my-news-loading">
      {Array(6).fill(1).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i}>
          <div />
          <div />
        </div>
      ))}
    </div>
  );
}
