import React from 'react';
import PropTypes from 'prop-types';
import weatherSVGs from 'components/packages/LocalNews/weather';
import dayjs from 'dayjs';

const HourlyWeather = ({ hourlyData }) => {
  const {
    localTime,
    precipitationChance,
    precipitationType,
    temperature,
    iconCode,
  } = hourlyData;

  return (
    <div className="local-news-weather__hourly" data-test="local-news-weather__hourly">
      <p>{dayjs(localTime).format('h A')}</p>
      <img src={weatherSVGs[`icon${iconCode}`]} alt="weather icon" />
      <h2>
        {temperature}
        °
      </h2>
      <img src={weatherSVGs[precipitationType || 'precip']} alt="precipitation type weather icon" />
      <span>
        {precipitationChance}
        %
      </span>
    </div>
  );
};

export const ContentPropType = {
  hourlyData: PropTypes.arrayOf({
    localTime: PropTypes.string,
    precipitationChance: PropTypes.number,
    precipitationType: PropTypes.string,
    temperature: PropTypes.number,
    iconCode: PropTypes.number,
  }).isRequired,
};

HourlyWeather.propTypes = ContentPropType;

export default HourlyWeather;
