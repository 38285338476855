import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  package as PackagePropType,
  layoutContext as LayoutContextPropType,
} from 'lib/CustomPropTypes';
import { imageAltForImage } from 'lib/imageAlt';
import { teaseCard as paidContent } from 'lib/paidContentUtils';

import LeadBorderTop from 'components/LeadBorderTop';
import TeaseCard from 'components/TeaseCard';
import TeasePicture from 'components/TeasePicture';
import SlideshowTeasePicture from 'components/SlideshowTeasePicture';
import PackageTitle from 'components/PackageTitle';
import AIMS_FLAVORS from '../../../lib/aimsFlavors';
import './styles.themed.scss';

function image(item, index, options = {}) {
  const { todayPaidContent, select } = options;
  const { type } = item;

  let slides;
  if (item.type === 'slideshow') {
    slides = item.slides || item.item.slides;
  }

  switch (type) {
    case 'slideshow':
      if (index === 0) {
        return (
          <div className="slideshow__pictures" data-count={`${slides.length + 1}+`}>
            <TeasePicture
              responsiveFlavors={{
                s: AIMS_FLAVORS.FOCAL_360x360,
                m: AIMS_FLAVORS.FOCAL_460x460,
                l: AIMS_FLAVORS.FOCAL_260x260,
                xl: AIMS_FLAVORS.FOCAL_190x190,
              }}
              url={slides[0]?.image ? slides[0].image.url.primary : ''}
              className="slideshow__picture"
              alt={imageAltForImage(slides[0].image)}
            />
            <TeasePicture
              responsiveFlavors={{
                s: AIMS_FLAVORS.FOCAL_360x360,
                m: AIMS_FLAVORS.FOCAL_230x230,
                l: AIMS_FLAVORS.FOCAL_130x130,
                xl: AIMS_FLAVORS.FOCAL_190x190,
              }}
              url={slides[1]?.image ? slides[1].image.url.primary : ''}
              className="slideshow__picture"
              alt={imageAltForImage(slides[1].image)}
            />
            <TeasePicture
              responsiveFlavors={{
                s: AIMS_FLAVORS.FOCAL_360x360,
                m: AIMS_FLAVORS.FOCAL_460x230,
                l: AIMS_FLAVORS.FOCAL_260x130,
                xl: AIMS_FLAVORS.FOCAL_190x190,
              }}
              url={slides[2]?.image ? slides[2].image.url.primary : ''}
              className="slideshow__picture"
              alt={imageAltForImage(slides[2]?.image)}
            />
          </div>
        );
      }

      return (
        <SlideshowTeasePicture
          slides={slides}
          sizes={{
            s: 360, m: 230, l: 130, xl: 190,
          }}
        />
      );

    default:
      if (select) {
        return index === 0 ? (
          <TeasePicture
            responsiveFlavors={{
              s: AIMS_FLAVORS.FOCAL_380x190,
              m: AIMS_FLAVORS.FOCAL_230x230,
              l: AIMS_FLAVORS.FOCAL_920x460,
              xl: AIMS_FLAVORS.FOCAL_260x260,
            }}
            type={type}
            computedValues={item.computedValues}
          />
        ) : (
          <TeasePicture
            responsiveFlavors={{
              s: AIMS_FLAVORS.FOCAL_380x190,
              m: AIMS_FLAVORS.FOCAL_230x230,
              l: AIMS_FLAVORS.FOCAL_300x300,
              xl: AIMS_FLAVORS.FOCAL_260x260,
            }}
            type={type}
            computedValues={item.computedValues}
          />
        );
      }
      if ((todayPaidContent && index === 1) || index === 2) {
        return (
          <TeasePicture
            responsiveFlavors={{
              s: AIMS_FLAVORS.FOCAL_758x379,
              m: AIMS_FLAVORS.FOCAL_920x460,
              l: AIMS_FLAVORS.FOCAL_920x460,
              xl: AIMS_FLAVORS.FOCAL_300x300,
            }}
            type={type}
            computedValues={item.computedValues}
          />
        );
      }

      return (
        <TeasePicture
          responsiveFlavors={{
            s: AIMS_FLAVORS.FOCAL_758x379,
            m: AIMS_FLAVORS.FOCAL_460x460,
            l: AIMS_FLAVORS.FOCAL_260x260,
            xl: AIMS_FLAVORS.FOCAL_300x300,
          }}
          type={type}
          computedValues={item.computedValues}
        />
      );
  }
}

function getTease(
  content,
  packageMetadata,
  index,
  hasPaidContentAndToday,
  vertical,
  isMobileOnlyComponent,
  pkgType,
) {
  return (
    <TeaseCard
      content={content}
      vertical={vertical}
      {...packageMetadata}
      {...content.metadata}
      key={content.id || index}
      isMobileOnlyComponent={isMobileOnlyComponent}
      pkgType={pkgType}
    >
      {image(content, index, {
        todayPaidContent: hasPaidContentAndToday,
        select: vertical === 'select',
      })}
    </TeaseCard>
  );
}

function ThreeUp({
  alternateTitleFormat,
  content,
  pkgClassName,
  isFirstLayout,
  isMobileOnlyComponent,
}, contexts) {
  if (!content || !(content.items?.[0])) return null;
  const {
    id, items, metadata = {}, packageIndex,
  } = content;

  const { vertical } = contexts;
  const hasPaidContent = items.findIndex((item) => (item?.type) === paidContent.type) !== -1;
  const hasPaidContentAndToday = hasPaidContent && vertical === 'today';
  const pkgType = content?.type;
  const isFirstLayoutAndPackage = isFirstLayout && !packageIndex;
  const classes = classNames(
    pkgClassName,
    {
      [`pkg-index-${packageIndex}`]: packageIndex >= 0,
      'three-up__accent-line': isFirstLayoutAndPackage,
    },
    'relative',
  );

  const { showBottomBorder = false } = metadata;

  return (
    <section
      className={classes}
      data-packageid={id}
      data-test="three-up__section"
      data-testid="three-up__section"
    >
      {metadata?.title && (
        <PackageTitle
          alternateDisplayFormat={alternateTitleFormat}
          metadata={metadata}
        />
      )}
      <div
        className={classNames(
          'three-up__articles',
          {
            'tease-card--slideshow': !hasPaidContentAndToday,
            'tease-card--with-paid-content-slideshow': hasPaidContentAndToday,
          },
        )}
      >
        {!(metadata?.title)
          && <LeadBorderTop types={['collectionLead']} />}
        {items.map((item, i) => {
          if (!item) return null;
          if (item.type === 'ad' || i < 2) {
            return getTease(item,
              metadata, i, hasPaidContentAndToday, vertical, isMobileOnlyComponent, pkgType);
          }
          return getTease(item,
            metadata, i, hasPaidContentAndToday, vertical, isMobileOnlyComponent, pkgType);
        })}
      </div>
      {showBottomBorder && (<hr data-testid="pkg-bottom-border" className="pkg-bottom-border" />)}
    </section>
  );
}

ThreeUp.propTypes = {
  alternateTitleFormat: PropTypes.bool,
  content: PackagePropType.isRequired,
  pkgClassName: PropTypes.string,
  isFirstLayout: PropTypes.bool,
  isMobileOnlyComponent: PropTypes.bool,
};

ThreeUp.defaultProps = {
  alternateTitleFormat: false,
  pkgClassName: 'pkg threeUp',
  isFirstLayout: false,
  isMobileOnlyComponent: false,
};

ThreeUp.contextTypes = {
  ...LayoutContextPropType,
};

export default ThreeUp;
