import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

function PinnedLabel({ label, isBreakingNews, isInline }) {
  const { t } = useTranslation();

  const defaultText = t('Pinned');
  const text = label || defaultText;

  const pinnedLabelClasses = classNames(
    'lbp-card__pinned',
    {
      'lbp-card__pinned--breaking': isBreakingNews,
      isInline,
    },
  );

  return (
    <span
      className={pinnedLabelClasses}
      data-test="lbp-card__pinned"
    >
      {text}
    </span>
  );
}

PinnedLabel.propTypes = {
  label: PropTypes.string,
  isBreakingNews: PropTypes.bool,
  isInline: PropTypes.bool,
};

PinnedLabel.defaultProps = {
  label: '',
  isBreakingNews: false,
  isInline: false,
};

export default PinnedLabel;
