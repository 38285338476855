import React from 'react';

import getPackage from 'components/packages/getPackage';
import { PackageGrid } from 'components/packages/PackageGrid';
import staticGridCompatibility from 'components/packages/PackageGrid/compatibilityMaps/static.json';
import flexGridCompatibility from 'components/packages/PackageGrid/compatibilityMaps/flex.json';

import pipe from 'lib/pipe';

// settings for each package grid
const {
  packages: STATIC_GRID_PACKAGES,
  zoneTypeToSize: STATIC_GRID_SIZES_BY_ZONE_TYPE,
} = staticGridCompatibility;

const {
  packages: FLEX_GRID_PACKAGES,
  zoneTypeToSize: FLEX_GRID_SIZES_BY_ZONE_TYPE,
} = flexGridCompatibility;
const alternatingColorPackages = [
  'twoUp',
  'straightUp',
  'postList',
  'bacon',
  'videoPkg',
  'feeds',
];
const alternatingBkgPackages = [
  'twoUp',
];
let stripeBackgroundIndex = 0;
let pkgTitleColorIndex = -1;

const applyPackageContext = ({ packages, context }) => {
  const {
    applyAlternateTitleFormat = false,
    isMobileOnlyComponent = false,
  } = context;

  let mirror = true;
  let boxFlexCount = 0;

  const shouldMirror = () => {
    mirror = !mirror;
    return mirror;
  };

  return packages.map((pkg, index) => {
    const props = {};

    const isFirstPackage = index === 0;
    const isUpPackage = pkg.type === 'oneUp'
      || pkg.type === 'twoUp'
      || pkg.type === 'threeUp'
      || pkg.type === 'fourUp';

    if (isFirstPackage && isUpPackage) {
      props.alternateTitleFormat = applyAlternateTitleFormat;
    }

    if (alternatingBkgPackages.includes(pkg.type)) {
      if (stripeBackgroundIndex % 2 === 0) props.alternateAccentStripe = true;
      stripeBackgroundIndex += 1;
    }

    if (alternatingColorPackages.includes(pkg.type)) {
      // context to alternate title underline color
      pkgTitleColorIndex = (pkgTitleColorIndex + 1) % 3;
    }

    props.isMobileOnlyComponent = isMobileOnlyComponent;

    if (pkg.type === 'sevenUp' || pkg.type === 'ad') {
      boxFlexCount += 1;
      props.enumerator = boxFlexCount;
    }

    if (pkg.type === 'sevenUp') {
      props.adSlot = 'boxflex';
    }

    if (pkg.type === 'ad') {
      props.slot = pkg.metadata.slot || 'boxflex';
      if (index === packages.length - 1 && !pkg.isCustomAd) {
        props.sticky = true;
      }
    }

    const { packageIndex = index } = pkg;

    return {
      ...pkg,
      props,
      context: {
        ...context,
        shouldMirror,
        packageIndex,
        pkgTitleColorIndex,
      },
    };
  });
};

/**
 * Qualifier function to check if a provided package should be rendered as a set. If returns an object,
 * triggers logic in getPackageSections to combine qualifying adjacent elements in the array of
 * packages.
 * Returns the type of package grid to use and how many columns a package spans (used in Flex Grid only)
 *
 * @param {object} package - Package object with a type and subtype property.
 */
const packageIsCompatableInGrid = ({
  type, subType: packageSubType, context: { isFluidWidthPage },
}) => {
  const { [type]: compatableStaticGridPkg } = STATIC_GRID_PACKAGES;
  const { [type]: compatableFlexGridPkg } = FLEX_GRID_PACKAGES;

  let compatiblePackageType = null;
  let packageColSpan = 1;

  // check if compatible as a 3 column Static Grid package
  if (compatableStaticGridPkg) {
    const { requiredSubType } = compatableStaticGridPkg;
    const staticGridPkg = requiredSubType ? requiredSubType[packageSubType] : true;
    compatiblePackageType = staticGridPkg ? 'static' : null;
  }

  // check if compatible with Flex Grid package
  if ((!compatableStaticGridPkg || isFluidWidthPage) && compatableFlexGridPkg) {
    const { requiredSubType } = compatableFlexGridPkg;
    const flexGridPkg = requiredSubType ? requiredSubType[packageSubType] : compatableFlexGridPkg;
    compatiblePackageType = flexGridPkg ? 'flex' : null;
    // get custom column span amount, if available
    packageColSpan = flexGridPkg?.colSpan || 1;
  }

  return compatiblePackageType ? { type: compatiblePackageType, colSpan: packageColSpan } : false;
};

const getPackageSections = (packages) => packages.reduce((acc, pkg) => {
  const sections = [...acc];

  // get package options if in a grid
  const packageGridOption = packageIsCompatableInGrid(pkg);

  // Package Grid lays out compatible packages in a grid
  if (packageGridOption) {
    const previousSection = sections.pop();

    // props to pass into the section object
    const { type, colSpan } = packageGridOption;

    // Make sure ads don't split grid
    if (previousSection && previousSection.packages[0].type === 'ad') {
      const sectionBeforePrevious = sections.pop();
      if (
        !sectionBeforePrevious
        || !sectionBeforePrevious.grid
        || sectionBeforePrevious.grid?.type !== type
      ) {
        return [...acc, {
          // track an array of column span values per package
          grid: { type, pkgColSpans: [colSpan] },
          packages: [pkg],
        }];
      }
      // Move package to previous grid, ad goes below
      return [
        ...sections,
        {
          grid: {
            type,
            // track an array of column span values per package
            pkgColSpans: sectionBeforePrevious.grid.pkgColSpans.concat([colSpan]),
          },
          packages: [
            ...sectionBeforePrevious.packages,
            pkg,
          ],
        },
        previousSection,
      ];
    }

    // If first package in grid type, create new section
    if (
      !previousSection
      || !previousSection.grid
      || previousSection.grid?.type !== type
    ) {
      return [...acc, {
        // track an array of column span values per package
        grid: { type, pkgColSpans: [colSpan] },
        packages: [pkg],
      }];
    }

    // Previous section is grid, modify that section to add this package
    return [
      ...sections,
      {
        grid: {
          type,
          // track an array of column span values per package
          pkgColSpans: previousSection.grid.pkgColSpans.concat([colSpan]),
        },
        packages: [
          ...previousSection.packages,
          pkg,
        ],
      },
    ];
  }

  // remove an ad if it has been placed right before Taboola
  if (
    pkg.type === 'partnerRecirc'
    && sections[sections.length - 1]?.packages[0]?.type === 'ad'
  ) {
    sections.pop();
  }

  // Package is not grid-able, should be block-level
  return [...sections, { grid: false, packages: [pkg] }];
}, []);

// Flatten package section list into components
const getPackageComponent = (pkg) => getPackage(pkg, pkg.props, pkg.context);
const getPackageComponents = (sections) => sections.reduce((acc, section) => {
  const { grid, packages } = section;
  const packageComponents = packages.map(getPackageComponent);

  // Wrap components with grid if grid-able
  let components = packageComponents;
  if (grid) {
    // Select global context from first package
    const { railContext, isRailAdjacent } = packages[0].context;
    const isInRail = !!railContext;

    let zoneType = 'normal';
    if (isInRail) zoneType = 'rail';
    if (isRailAdjacent) zoneType = 'railAdjacent';

    let gridSizeByZoneType = STATIC_GRID_SIZES_BY_ZONE_TYPE;

    if (grid.type === 'flex') {
      gridSizeByZoneType = FLEX_GRID_SIZES_BY_ZONE_TYPE;
    }

    const packageGrid = (
      <PackageGrid
        key="grid"
        zoneType={zoneType}
        pkgColSpans={grid.pkgColSpans}
        gridType={grid.type}
        maxPerRow={gridSizeByZoneType[zoneType]}
      >
        {packageComponents}
      </PackageGrid>
    );
    components = [packageGrid];
  }

  return [
    ...acc,
    ...components,
  ];
}, []);

const getPackagesForZone = (zone, pkgs, context) => {
  const { zonePackageTypes, adsDisabled: curationAdsDisabled, packageAdsDisabled } = context;
  const packagesInZone = pkgs.filter((pkg) => pkg.zone === zone);

  // Disable ads if top-level curation flag is enabled
  // or packages have been individually flagged for ignoring ad logic.
  const adsDisabled = curationAdsDisabled || packageAdsDisabled;

  const packagePipeline = pipe(
    applyPackageContext,
    getPackageSections,
    getPackageComponents,
  );

  // Use passed packages type list, or generate based on packages in this function call
  // FullWidth splices its packages so the full list needs to be passed manually on context
  return packagePipeline({
    packages: packagesInZone,
    context: {
      ...context,
      adsDisabled,
      zone,
      zonePackageTypes: zonePackageTypes || packagesInZone.map((pkg) => pkg.type),
    },
  });
};

export default getPackagesForZone;
