import React from 'react';
import classNames from 'classnames';
import { package as PackagePropType } from 'lib/CustomPropTypes';

import { LinkIfHref } from 'components/Link/LinkIfHref';
import { BreakpointContext } from 'lib/ContextTypes';
import { STREAM_KEYS } from 'lib/playmaker';
import { LiveVideoEmbed } from 'components/packages/LiveVideoEmbed';
import { FeaturedEmbed } from 'components/packages/CoverSpread/Featured/Embed';
import { MainArtTease } from './MainArtTease/index';
import { NoArtTease } from './NoArtTease/index';
import { layouts as packageLayouts } from './layouts';
import {
  SMORGASBORD_COMPONENT_TYPES,
  SMORGASBORD_PLAYMAKER_DEFAULTS,
} from './constants';

import './styles.themed.scss';

const block = 'smorgasbord';
const { PLAYMAKER_FLAG, PLAYMAKER_PLAY_BEHAVIOR } = SMORGASBORD_PLAYMAKER_DEFAULTS;

/**
 * @typedef {object} SmorgasbordComponentWrapper
 * @property {Smorgasbord} component
 * @property {number} [columnPosition = 0]
 */

/**
 * @param {SmorgasbordComponentWrapper[]} column column of components
 * @returns {SmorgasbordComponentWrapper[]} column sorted by columnPosition
 */
export const sortColumnByPosition = (column) => column.sort((
  { columnPosition: firstPosition },
  { columnPosition: secondPosition },
) => (firstPosition - secondPosition));

/**
 * Smorgasbord Package - Full-width Package for MSNBC Zone A Redesign
 * - Receives props from API, including a list of items to display and package metadata (threeKey/fourKey/etc)
 * - Picks the correct layout config based on metadata
 * - Converts the content items into React Components in each "slot" based on the config
 * - Places the React components into their respective columns
 * - Re-orders the columns to reflect the config
 * @param {object} props The React props passed to the component
 * @param {Package} props.content The Package's content
 * @returns {JSX.Element} Component
 */
const Smorgasbord = (props) => {
  const {
    content: {
      context,
      id: packageId,
      items: originalItems,
      metadata: packageMetadata,
      subType,
    } = {},
    content = {},
  } = props;
  // - we validate against 'originalItems' and manipulate/render 'items'
  // - if original items > 8, still allow render but drop excess
  // - slice implicitly clones (i.e. if slice is ever removed, it needs to be replaced with [...clone])
  const items = originalItems.slice(0, 8);
  const NUM_OF_REQUIRED_ITEMS = subType === 'withRailVideo' ? 8 : 7;
  if (originalItems.length !== NUM_OF_REQUIRED_ITEMS) return null;

  const layout = subType === 'withRailVideo' ? 'threeKeyVisuals' : 'threeKeyVisualsWithoutRail';
  const packageLayout = packageLayouts(packageMetadata)[layout];

  const {
    playmakerToggle = packageLayout.playmakerToggleDefault,
    title: packageHeadline,
    titleUrl: packageHeadlineUrl,
    showDek: packageShowDek = false,
    showEyebrow: packageShowEyebrow = true,
    showByline: packageShowByline = true,
    showBottomBorder = false,
  } = packageMetadata;

  const { isS, hasCheckedViewportSize } = React.useContext(BreakpointContext);

  // TODO: update to use util function that takes in packageMetadata and layout info with defaults, returns copy of packageMetadata with extra info
  if (playmakerToggle) packageMetadata.playmakerToggle = playmakerToggle;

  let slots = [];

  if (layout === 'threeKeyVisuals') {
    const { playmakerEnabledLayoutConfig, playmakerDisabledLayoutConfig } = packageLayout;
    slots = playmakerToggle ? playmakerEnabledLayoutConfig : playmakerDisabledLayoutConfig;

    // if playmaker is off and last item is article, remove it
    if (!playmakerToggle && items[7]?.type === 'article') {
      items.splice(7, 1);
    }
  }

  if (layout === 'threeKeyVisualsWithoutRail') {
    const { liveVideoLayoutConfig, liveVideoDisabledLayoutConfig } = packageLayout;
    slots = playmakerToggle ? liveVideoLayoutConfig : liveVideoDisabledLayoutConfig;
  }

  const itemsWithSlotMetadata = slots
    .map((slot, idx) => (items[idx] ? { ...items[idx], ...slot } : null))
    .filter(Boolean);

  const columns = itemsWithSlotMetadata.reduce(
    (acc, item, idx) => {
      const {
        column, componentType, id, size, maxRelatedItems,
      } = item;
      let component = null;

      const {
        showByline: contentShowByline,
        showEyebrow: contentShowEyebrow,
        showDek: contentShowDek,
      } = item?.metadata || {};

      const shouldShowDek = contentShowDek !== undefined ? contentShowDek : packageShowDek;
      const shouldShowEyebrow = contentShowEyebrow !== undefined
        ? contentShowEyebrow : packageShowEyebrow;
      const shouldShowByline = contentShowByline !== undefined
        ? contentShowByline : packageShowByline;

      // if rail video and last item is video or playmaker
      if (layout === 'threeKeyVisuals' && idx === 7
      && (item.type === 'video' || componentType === SMORGASBORD_COMPONENT_TYPES.PLAYMAKER)) {
        // content.items needs to be an array of the last item
        // package metadata needs to include playmaker defaults
        const playmakerMetadata = playmakerToggle ? {
          ...packageMetadata,
          // for Smorgasbord package, playmakerFlag and playmakerPlayBehavior defaults set here
          playmakerFlag: ('playmakerFlag' in packageMetadata)
            ? packageMetadata.playmakerFlag
            : PLAYMAKER_FLAG,
          playmakerId: ('playmakerId' in packageMetadata) ? packageMetadata.playmakerId : '2007680',
          playmakerPlayBehavior: ('playmakerPlayBehavior' in packageMetadata)
            ? packageMetadata.playmakerPlayBehavior
            : PLAYMAKER_PLAY_BEHAVIOR,
        } : packageMetadata;

        // defaults for last item for LiveVideoEmbed
        const itemMetadata = {
          ...item.metadata,
          brand: ('brand' in item.metadata) ? item.metadata.brand : STREAM_KEYS.MSNBC_TVE,
          showDek: shouldShowDek,
        };

        const railVideoContent = {
          ...content,
          context: { ...context, isRailLayout: true },
          items: [{ ...item, metadata: itemMetadata }],
          metadata: playmakerMetadata,
        };

        const shouldRenderByBreakpoint = hasCheckedViewportSize && !isS;

        component = (
          <LiveVideoEmbed
            content={railVideoContent}
            pkgClassName={`live-video-embed--${block}`}
            shouldRenderByBreakpoint={shouldRenderByBreakpoint}
          />
        );
      } else if (
        item.type === 'custom') {
        component = (<FeaturedEmbed block={block} content={item} key={id} />);
      } else if (componentType === SMORGASBORD_COMPONENT_TYPES.VISUAL) {
        component = (
          <MainArtTease
            item={{ ...item, slotIndex: idx }}
            key={id}
            size={size}
            showByline={shouldShowByline}
            showEyebrow={shouldShowEyebrow}
            maxRelatedItems={maxRelatedItems}
            showDek={shouldShowDek}
            packageMetadata={packageMetadata}
            componentType={componentType}
            layout={layout}
          />
        );
      } else if (componentType === SMORGASBORD_COMPONENT_TYPES.NO_VISUAL) {
        component = (
          <NoArtTease
            key={id}
            item={item}
            showByline={shouldShowByline}
            showEyebrow={shouldShowEyebrow}
          />
        );
      } else if (componentType === SMORGASBORD_COMPONENT_TYPES.PLAYMAKER) {
        component = (
          <MainArtTease
            item={{ ...item, slotIndex: idx }}
            key={id}
            size={size}
            showByline={shouldShowByline}
            showEyebrow={shouldShowEyebrow}
            maxRelatedItems={maxRelatedItems}
            showDek={shouldShowDek}
            packageMetadata={packageMetadata}
            componentType={componentType}
            layout={layout}
          />
        );
      }

      if (component) {
        acc[column].push({ component, columnPosition: item.columnPosition });
      }

      return acc;
    },
    { 0: [], 1: [], 2: [] },
  );

  // sort columns by column position
  Object.keys(columns).forEach((columnKey) => sortColumnByPosition(columns[columnKey]));

  return (
    <>
      {packageHeadline && (
        <LinkIfHref href={packageHeadlineUrl} className="smorgasbord__headlinelink">
          <h2 className="smorgasbord__headline">{packageHeadline}</h2>
        </LinkIfHref>
      )}
      <div className={classNames('pkg', block)}>
        <div
          className={classNames({
            [`${block}--${layout}`]: layout,
          })}
          data-packageid={packageId}
        >
          <div className="smorgasbord__column1">{columns[0].map(({ component }) => component)}</div>
          <div className="smorgasbord__column2">{columns[1].map(({ component }) => component)}</div>
          <div className="smorgasbord__column3">{columns[2].map(({ component }) => component)}</div>
        </div>
        {showBottomBorder && (<hr className="pkg-bottom-border" />)}

      </div>
    </>
  );
};

Smorgasbord.propTypes = {
  content: PackagePropType.isRequired,
};

export default Smorgasbord;
