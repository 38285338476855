import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export function useBaconSlider() {
  const baconSliderRef = useRef();
  const [containerMeasurements, setContainerMeasurements] = useState({});

  useEffect(() => {
    baconSliderRef.current?.addMeasurementsChangeHandler(setContainerMeasurements);

    return () => {
      baconSliderRef.current?.removeMeasurementsChangeHandler();
    };
  }, []);

  // memoize the array of page dots to avoid unnecessary renders if the total pages hasn't changed
  const pageControls = useMemo(() => Array.from(
    // make an array with the length of totalPages
    { length: containerMeasurements.totalPages },
    // map the new entries to an object with the page number and a function to go to that page
    (_, i) => ({
      page: i + 1,
      isActivePage: i + 1 === containerMeasurements.currentPage,
      goToThisPage: () => baconSliderRef.current?.goToPage(i + 1),
    }),
  ), [containerMeasurements.totalPages, containerMeasurements.currentPage]);

  return {
    baconSliderRef,
    totalPages: containerMeasurements.totalPages,
    currentPage: containerMeasurements.currentPage,
    hasMultiplePages: containerMeasurements.totalPages > 1,
    goToPage: baconSliderRef.current?.goToPage,
    goToNextPage: baconSliderRef.current?.goToNextPage,
    goToPrevPage: baconSliderRef.current?.goToPrevPage,
    pageControls,
  };
}
