import React from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { content as contentPropType, waffleMetadata } from 'lib/CustomPropTypes';

import { CardOverlay } from 'components/CardOverlay';
import { Headline } from 'components/packages/Waffle/Headline';
import { TeaseImage } from 'components/packages/Waffle/TeaseImage';
import { TextSummary } from 'components/packages/Waffle/TextSummary';
import { ToggleButton } from 'components/packages/Waffle/ToggleButton';
import { Unibrow } from 'components/Unibrow';
import { Badge } from 'components/packages/Waffle/Badge';
import Ad from 'components/Ad';
import ButtonHoverAnimation from 'components/ButtonHoverAnimation';
import Link from 'components/Link';
import { Save } from 'components/SocialShareMenu/Save';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { PKG_SAVE } from 'lib/brandFeatures';

import styles from './styles.module.scss';

class Card extends React.Component {
  static propTypes = {
    card: contentPropType,
    cardsLength: PropTypes.number,
    vertical: PropTypes.string,
    adsOn: PropTypes.bool,
    metadata: waffleMetadata,
    index: PropTypes.number,
    useModal: PropTypes.bool,
  };

  static contextTypes = {
    isFluidWidthPage: PropTypes.bool,
  };

  static defaultProps = {
    card: {},
    cardsLength: 0,
    vertical: 'news',
    adsOn: false,
    metadata: {},
    index: 0,
    useModal: true,
  };

  constructor(props) {
    super(props);
    this.state = { activateShowMore: false };
  }

  getType = () => {
    const { card: { type } } = this.props;
    return type;
  };

  isAd = () => {
    const type = this.getType();
    return type === 'boxflex' || type === 'boxinline' || type === 'midresponsive';
  };

  isBoxflex = () => this.getType() === 'boxflex';

  updateShowMore = (currentState) => {
    this.setState({ activateShowMore: currentState });
  }

  render() {
    const {
      card: {
        computedValues: cardComputedValues,
        id,
        type,
        metadata: itemMetadata = {},
        item: cardItem,
      },
      card,
      cardsLength,
      vertical,
      metadata: packageMetadata,
      index,
    } = this.props;

    // normalized for Automated Waffle data
    const item = cardItem || card;

    const { nativeAd, source } = item || {};
    const { organization: { name: sponsorName } = {} } = source || {};
    const { showEyebrow = true } = packageMetadata || {};
    const { showTeaseImage: showTease = true } = itemMetadata || {};
    const { activateShowMore } = this.state;

    // transform item shape if it doesn't have computedValues
    // necessary for Automated Waffle which returns a different data format
    const computedValues = cardComputedValues || {
      url: card.url?.primary,
      teaseImage: card.teaseImage,
      alternateTeaseImages: card.alternateTeaseImages,
      dek: card.description?.tease || card.description?.primary,
      headline: card.headline?.tease || card.headline?.primary,
      unibrow: card.unibrow,
    };

    const buttonText = itemMetadata.buttonText || i18next.t('See story');
    const cardIsLinkable = !!(computedValues.url);
    const cardIsNotLinkable = !cardIsLinkable;

    const eyebrowIsLinkable = !!(computedValues.unibrow?.url?.primary);

    // For Recipe's in Waffle pkg Editorial prefers the Primary Headline (BENTO-22865)
    // If the Tease Headline matches Curator's headline (computedValues Headline)
    // an override headline has not been set. Recipes by default display the
    // Primary Headline else display the Curator's override headline.
    // Non-recipe content types continue to use the computedValues Headline.
    const isRecipe = type === 'recipe';
    const hasOverridenHeadline = computedValues.headline !== item?.headline?.tease;
    const teaseHeadline = isRecipe && !hasOverridenHeadline
      ? item?.headline.primary
      : computedValues.headline;

    const showDek = itemMetadata.showDek !== undefined
      ? itemMetadata.showDek
      : packageMetadata.showDek;

    const hasMoreInfo = itemMetadata?.moreInfoText
      && itemMetadata.moreInfoText !== '';

    const { isFluidWidthPage } = this.context;
    const badgePosition = vertical === 'today' ? styles.badgeRightPosition : styles.badgeLeftPosition;
    const unibrowClasses = isFluidWidthPage ? styles.unibrowSpacing
      : classNames(styles.unibrow, styles.unibrowSpacing, { [styles.linkable]: eyebrowIsLinkable });

    const showSave = getFeatureConfigForBrand(PKG_SAVE, vertical);

    return (
      <li
        data-contentid={id}
        ref={(waffleCard) => { this.waffleCard = waffleCard; }}
        key={id}
        className={classNames(
          'waffleCard',
          styles.waffleCard,
          {
            [styles.nativeAd]: nativeAd,
            [styles.stickyAd]: this.isBoxflex(),
            [styles.disabled]: cardIsNotLinkable,
            [styles.showEyebrow]: showEyebrow,
            [styles.hasMoreInfo]: hasMoreInfo,
          },
        )}
      >
        {!this.isAd() ? (
          <div
            className={styles.inner}
            data-testid="waffle-card--noAd"
          >
            <Link
              to={computedValues.url}
              className={styles.link}
              target={itemMetadata.openInNewTab ? '_blank' : '_self'}
            >
              <TeaseImage
                computedValues={computedValues}
                showTease={showTease}
                vertical={vertical}
              />
              {showSave ? (
                <Save
                  contentId={id}
                  contentType={type}
                  options={{ isThumbnail: true, showCTA: true }}
                />
              ) : null}
              <Badge
                cardsLength={cardsLength}
                className={classNames(styles.badge, badgePosition)}
                index={index}
                metadata={{ ...packageMetadata, ...itemMetadata }}
                data-testid="badge"
              />
            </Link>
            <section className={styles.section}>
              {showEyebrow ? (
                <Unibrow
                  unibrow={computedValues.unibrow}
                  size="h5"
                  className={unibrowClasses}
                  hasDefaultTextStyle={isFluidWidthPage}
                />
              ) : null}
              <Headline
                headline={teaseHeadline}
                metadata={itemMetadata}
                nativeAd={nativeAd}
                sponsorName={sponsorName}
                url={computedValues.url}
                type={type}
                isFluidWidthPage={isFluidWidthPage}
                item={item}
              />
              <TextSummary
                dekText={computedValues.dek}
                mobileExpand
                moreInfoClasses={
                  classNames('dn-m', { db: activateShowMore, dn: !activateShowMore })
                }
                moreInfoText={itemMetadata.moreInfoText}
                showDek={showDek}
                showMoreInfo={activateShowMore}
                textClasses={styles.textSummary}
              />
              {cardIsLinkable && activateShowMore && (
                <ButtonHoverAnimation
                  additionalClasses={styles.showMoreBtn}
                  url={computedValues.url}
                  type="link"
                  title={buttonText}
                  target={itemMetadata.openInNewTab ? '_blank' : '_self'}
                />
              )}
            </section>
            {hasMoreInfo && (
              <>
                <CardOverlay isActive={activateShowMore} useModal>
                  <section className={styles.scrollableMoreInfo}>
                    <Unibrow
                      unibrow={computedValues.unibrow}
                      size="h5"
                      className={classNames(
                        styles.unibrow,
                        { [styles.linkable]: eyebrowIsLinkable },
                      )}
                    />
                    <Headline
                      headline={computedValues.headline}
                      metadata={itemMetadata}
                      nativeAd={nativeAd}
                      sponsorName={sponsorName}
                      url={computedValues.url}
                    />
                    <TextSummary
                      dekText={computedValues.dek}
                      moreInfoText={itemMetadata.moreInfoText}
                      showDek={showDek}
                      showMoreInfo
                      textClasses={styles.textSummary}
                    />
                    {cardIsLinkable && (
                      <ButtonHoverAnimation
                        url={computedValues.url}
                        type="link"
                        title={buttonText}
                        target={itemMetadata.openInNewTab ? '_blank' : '_self'}
                      />
                    )}
                  </section>
                  <ToggleButton
                    className={styles.toggleBtnOverlay}
                    activeText={i18next.t('Less')}
                    inactiveText={i18next.t('More')}
                    onChangeCallback={this.updateShowMore}
                    isActive={activateShowMore}
                  />
                </CardOverlay>
                <ToggleButton
                  className={styles.toggleBtn}
                  activeText={i18next.t('Less')}
                  inactiveText={i18next.t('More')}
                  onChangeCallback={this.updateShowMore}
                  isActive={activateShowMore}
                />
              </>
            )}
          </div>
        ) : (
          <Ad
            {...packageMetadata}
            sticky={this.isBoxflex()}
            offsetViewport={type === 'boxinline' ? 50 : null}
            slot={type}
            adClass={styles.ad}
            testId="waffle-card--ad"
          />
        )}
      </li>
    );
  }
}

export { Card };
