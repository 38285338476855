export const parsePersonNames = (author) => {
  /* Takes a person object and returns the person object with the name broken into parts. */
  const display = (author?.name ?? '').trim();
  const authorNames = display.split(' ');
  let title;
  let first;
  let middle;
  let last;
  let suffix;

  if (authorNames.length === 5) {
    title = authorNames[0].trim();
    first = authorNames[1].trim();
    middle = authorNames[2].trim();
    last = authorNames[3].trim();
    suffix = authorNames[4].trim();
  }
  if (authorNames.length === 4) {
    if (authorNames[0] === 'Dr.' || authorNames[0] === 'Rev.') {
      title = authorNames[0].trim();
      first = authorNames[1].trim();
      middle = authorNames[2].trim();
      last = authorNames[3].trim();
    } else {
      first = authorNames[0].trim();
      middle = authorNames[1].trim();
      last = authorNames[2].trim();
      suffix = authorNames[3].trim();
    }
  }
  if (authorNames.length === 3) {
    if (authorNames[0] === 'Dr.' || authorNames[0] === 'Rev.') {
      title = authorNames[0].trim();
      first = authorNames[1].trim();
      last = authorNames[2].trim();
    } else {
      first = authorNames[0].trim();
      middle = authorNames[1].trim();
      last = authorNames[2].trim();
    }
  } else {
    first = authorNames[0]?.trim();
    last = authorNames[1]?.trim();
  }

  return {
    ...author,
    name: {
      display,
      title,
      first,
      middle,
      last,
      suffix,
    },
  };
};
