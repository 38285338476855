import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Picture } from 'components/Picture';
import Link from 'components/Link';
import TypeIcon from 'components/TypeIcon';

import { AIMS_FLAVORS } from 'lib/aimsFlavors';
import { modifyIfUrl } from 'lib/urlUtils';

import styles from './styles.module.scss';

export function EventCard({
  id,
  headline,
  text,
  ctaText,
  ctaURL,
  showCTA,
  openInNewTab,
  imageURL,
  imageAlt,
  showArt,
  showPlayIcon,
  isEmbed,
}) {
  const shouldRenderCTA = showCTA && ctaText;

  const hrefTarget = (openInNewTab && '_blank') || (isEmbed ? '_parent' : '_self');

  const ctaURLWithTracking = modifyIfUrl(ctaURL, (parsedUrl) => {
    parsedUrl.searchParams.set('icid', 'timeline');
  });

  return (
    <div
      className={styles.eventCard}
      data-contentid={id}
    >
      <div className={classnames(styles.eventContent, { [styles.hasCTA]: shouldRenderCTA })}>
        <h3 className={styles.eventHeadline}>{headline}</h3>
        {showArt && imageURL && (
          <div className={styles.eventArt}>
            <Picture
              responsiveFlavors={{
                s: AIMS_FLAVORS.FOCAL_100x100,
                m: AIMS_FLAVORS.FOCAL_100x100,
                l: AIMS_FLAVORS.FOCAL_100x100,
                xl: AIMS_FLAVORS.FOCAL_100x100,
              }}
              url={imageURL}
              alt={imageAlt}
              className={styles.eventImage}
            />
            {showPlayIcon && <TypeIcon className={styles.playIcon} type="video" />}
          </div>
        )}
        <p className={styles.eventText}>{text}</p>
        {shouldRenderCTA && (
          <Link href={ctaURLWithTracking} target={hrefTarget} rel="noopener" className={styles.eventCTA}>
            {ctaText}
            {' '}
            &rsaquo;
          </Link>
        )}
      </div>
    </div>
  );
}

EventCard.propTypes = {
  id: PropTypes.string,
  headline: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  ctaText: PropTypes.string,
  ctaURL: PropTypes.string,
  showCTA: PropTypes.bool,
  openInNewTab: PropTypes.bool,
  imageURL: PropTypes.string,
  imageAlt: PropTypes.string,
  showArt: PropTypes.bool,
  showPlayIcon: PropTypes.bool,
  isEmbed: PropTypes.bool,
};

EventCard.defaultProps = {
  id: null,
  ctaText: null,
  ctaURL: null,
  showCTA: false,
  openInNewTab: false,
  imageURL: null,
  imageAlt: null,
  showArt: false,
  showPlayIcon: false,
  isEmbed: false,
};
