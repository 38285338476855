import AIMS_FLAVORS from 'lib/aimsFlavors';

export const sevenSlotLayout = {
  tease0: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_380x190,
        l: AIMS_FLAVORS.FOCAL_90x90,
        xl: AIMS_FLAVORS.FOCAL_90x90,
      },
      display: 'db dn-m db-l',
    },
    headlineSizes: 'f7 f5-m f6-l f5-xl',
    headlineTextPositioning: 'pl4-m pl0-l',
    mediaLabel: {
      eyebrowDisplay: 'db dn-m',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn di-m dn-l',
    },
    grid: 'layout-subgrid-item grid-col-12',
  },
  tease1: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_90x90,
        l: AIMS_FLAVORS.FOCAL_90x90,
        xl: AIMS_FLAVORS.FOCAL_90x90,
      },
      display: 'db dn-m db-l',
    },
    headlineSizes: 'f6 f5-m f6-l f5-xl',
    headlineTextPositioning: 'pl4-m pl0-l',
    mediaLabel: {
      eyebrowDisplay: 'dn',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn di-m dn-l',
    },
    grid: 'layout-subgrid-item grid-col-12',
  },
  tease2: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_90x90,
        l: AIMS_FLAVORS.FOCAL_90x90,
        xl: AIMS_FLAVORS.FOCAL_90x90,
      },
      display: 'db dn-m db-l',
    },
    headlineSizes: 'f6 f5-m f6-l f5-xl',
    headlineTextPositioning: 'pl4-m pl0-l',
    mediaLabel: {
      eyebrowDisplay: 'dn',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn di-m dn-l',
    },
    grid: 'layout-subgrid-item grid-col-12',
  },
  tease3: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_380x190,
        m: AIMS_FLAVORS.FOCAL_200x100,
        l: AIMS_FLAVORS.FOCAL_200x100,
        xl: AIMS_FLAVORS.FOCAL_90x90,
      },
      display: 'db',
    },
    headlineSizes: 'f7 f5-m f6-l f5-xl',
    headlineTextPositioning: '',
    mediaLabel: {
      eyebrowDisplay: 'db dn-xl',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn',
    },
    grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-12-xl',
  },
  tease4: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_90x90,
        m: AIMS_FLAVORS.FOCAL_200x100,
        l: AIMS_FLAVORS.FOCAL_200x100,
        xl: AIMS_FLAVORS.FOCAL_90x90,
      },
      display: 'db',
    },
    headlineSizes: 'f6 f5-m f6-l f5-xl',
    headlineTextPositioning: '',
    mediaLabel: {
      eyebrowDisplay: 'dn db-m dn-xl',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn',
    },
    grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-12-xl',
  },
  tease5: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_90x90,
        m: AIMS_FLAVORS.FOCAL_200x100,
        l: AIMS_FLAVORS.FOCAL_200x100,
        xl: AIMS_FLAVORS.FOCAL_90x90,
      },
      display: 'db',
    },
    headlineSizes: 'f6 f5-m f6-l f5-xl',
    headlineTextPositioning: '',
    mediaLabel: {
      eyebrowDisplay: 'dn db-m dn-xl',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn',
    },
    grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-12-xl',
  },
};

export const sevenSlotRailLayout = {
  tease0: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_380x190,
        m: AIMS_FLAVORS.FOCAL_200x100,
        l: AIMS_FLAVORS.FOCAL_200x100,
      },
      display: 'db dn-m dn-l',
    },
    headlineSizes: 'f6 f5-m f6-l f5-xl',
    headlineTextPositioning: 'pl4-xl',
    mediaLabel: {
      eyebrowDisplay: 'db',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn di-xl',
    },
    grid: 'layout-subgrid-item grid-col-12',
  },
  tease1: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_90x90,
      },
      display: 'db dn-m',
    },
    headlineSizes: 'f6 f5-m f6-l f5-xl',
    headlineTextPositioning: 'pl4-xl',
    mediaLabel: {
      eyebrowDisplay: 'db',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn di-xl',
    },
    grid: 'layout-subgrid-item grid-col-12',
  },
  tease2: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_90x90,
      },
      display: 'db dn-m',
    },
    headlineSizes: 'f6 f5-m f6-l f5-xl',
    headlineTextPositioning: 'pl4-xl',
    mediaLabel: {
      eyebrowDisplay: 'db',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn di-xl',
    },
    grid: 'layout-subgrid-item grid-col-12',
  },
  tease3: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_380x190,
        m: AIMS_FLAVORS.FOCAL_200x100,
        l: AIMS_FLAVORS.FOCAL_200x100,
      },
      display: 'db db-m dn-l',
    },
    headlineSizes: 'f6 f5-m f6-l f5-xl',
    headlineTextPositioning: 'pl4-xl',
    mediaLabel: {
      eyebrowDisplay: 'db',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn di-xl',
    },
    grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-12-l',
  },
  tease4: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_90x90,
        m: AIMS_FLAVORS.FOCAL_200x100,
        l: AIMS_FLAVORS.FOCAL_200x100,
      },
      display: 'db db-m dn-l',
    },
    headlineSizes: 'f6 f5-m f6-l f5-xl',
    headlineTextPositioning: 'pl4-xl',
    mediaLabel: {
      eyebrowDisplay: 'db',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn di-xl',
    },
    grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-12-l',
  },
  tease5: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_90x90,
        m: AIMS_FLAVORS.FOCAL_200x100,
        l: AIMS_FLAVORS.FOCAL_200x100,
      },
      display: 'db db-m dn-l',
    },
    headlineSizes: 'f6 f5-m f6-l f5-xl',
    headlineTextPositioning: 'pl4-xl',
    mediaLabel: {
      eyebrowDisplay: 'db',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn di-xl',
    },
    grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-12-l',
  },
};
