import React from 'react';
import PropTypes from 'prop-types';
import { SocialShareMenu } from 'components/SocialShareMenu';

import { TVE_CANONICAL_ROUTE } from 'lib/tve';
import './styles.themed.scss';

const block = 'tve__info';

function Info({
  description, isLive, share, title,
}) {
  return (
    <div className={block}>
      {isLive && (
        <div className={`${block}__live`}>Live</div>
      )}
      {title && (
        <h3 className={`${block}__title`}>
          {title}
        </h3>
      )}
      {description && (
        <p className={`${block}__description`}>
          {description}
        </p>
      )}
      {share && (
        <div className={`${block}__share`}>
          <span className={`${block}__share__label`}>
            Share this
            {isLive ? ' live ' : ' '}
            video
          </span>
          <SocialShareMenu
            trackingEventName="tve_video_socialShare"
            headline="Watch MSNBC TV Live"
            pageRegion="live-video"
            url={`https://www.msnbc.com/${TVE_CANONICAL_ROUTE}`}
            actions={{ copy: false, save: false, sms: false }}
          />
        </div>
      )}
    </div>
  );
}

Info.propTypes = {
  description: PropTypes.string,
  isLive: PropTypes.bool,
  share: PropTypes.bool,
  title: PropTypes.string,
};

Info.defaultProps = {
  description: null,
  isLive: true,
  share: true,
  title: null,
};

export default Info;
