import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const TagLine = ({ value }) => (
  <p className={styles.tagline}>
    { value }
  </p>
);

TagLine.propTypes = {
  value: PropTypes.string.isRequired,
};

export { TagLine };
