import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import { imageAltForItem } from 'lib/imageAlt';
import { getTeaseUrl } from 'lib/teaseUtils';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import { formatDuration } from 'lib/DateTime';

import styles from './styles.module.scss';

const block = 'baguette-visual';

export const BaguetteVisual = ({
  item, showArt, showImageOnlyInWide, hideImageInNarrowZone,
}) => {
  const { computedValues, type, item: innerItem } = item || {};
  const { url } = computedValues || {};

  const visualSizes = { s: AIMS_FLAVORS.FOCAL_80x80 };
  const isImage = type === 'article';
  const isVideo = type === 'video';
  const onlyShowVideoPlay = !showArt && isVideo;
  const showVideo = showArt && isVideo;
  const imageUrl = onlyShowVideoPlay ? null : getTeaseUrl(item);
  const imageAlt = onlyShowVideoPlay ? null : imageAltForItem(item);
  const showImage = showArt && isImage && imageUrl;
  const duration = showVideo ? innerItem?.duration : null;
  const formattedDuration = showVideo ? formatDuration(duration) : null;

  if (!onlyShowVideoPlay && !showVideo && !showImage) {
    return null;
  }

  return (
    <div
      data-test={`${block}__visual-wrapper--video`}
      data-testid={`${block}__visual-wrapper--video`}
      className={classNames(styles.visualWrapper, {
        [styles.compact]: onlyShowVideoPlay,
        [styles.image]: showImage && !showImageOnlyInWide,
        [styles.showImageOnlyInWide]: showImage && showImageOnlyInWide,
        [styles.showVideoOnlyInWide]: isVideo && showImageOnlyInWide,
        [styles.hideImageInNarrowZone]: hideImageInNarrowZone,
      })}
    >
      <Link to={url} className={styles.visualLink}>
        {onlyShowVideoPlay && <VideoPlayButton />}
        {(showImage || showVideo) && (
          <TeasePicture
            alt={imageAlt}
            className={styles.visual}
            preview={
              type === 'video' && { url, redirect: false, radius: false }
            }
            responsiveFlavors={visualSizes}
            retina
            url={imageUrl}
            computedValues={computedValues}
          />
        )}
        {showVideo && (
          <div
            data-test={`${block}__video-controls`}
            data-testid={`${block}__video-controls`}
            className={styles.videoControls}
          >
            <VideoDuration formattedDuration={formattedDuration} />
            <VideoPlayButton />
          </div>
        )}
      </Link>
    </div>
  );
};

BaguetteVisual.propTypes = {
  item: ContentPropType.isRequired,
  showArt: PropTypes.bool,
  showImageOnlyInWide: PropTypes.bool,
  hideImageInNarrowZone: PropTypes.bool,
};

BaguetteVisual.defaultProps = {
  showArt: false,
  showImageOnlyInWide: false,
  hideImageInNarrowZone: false,
};

const VideoPlayButton = () => (
  <div data-test={`${block}__video-play`} data-testid={`${block}__video-play`} className={styles.videoPlay}>
    <span className="icon icon-video pointer-events-initial" />
  </div>
);

const VideoDuration = ({ formattedDuration }) => {
  if (!formattedDuration) {
    return null;
  }
  return (
    <div className={styles.videoDuration} data-testid={`${block}__video-duration`}>
      <span>{formattedDuration}</span>
    </div>
  );
};

VideoDuration.propTypes = {
  formattedDuration: PropTypes.string,
};

VideoDuration.defaultProps = {
  formattedDuration: null,
};
