import PropTypes from 'prop-types';
import React from 'react';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import PlaymakerTease from 'components/PlaymakerTease';

function FeaturedPlaymaker(props) {
  const {
    block,
    content: {
      id,
      metadata,
    },
  } = props;

  return (
    <div
      className={`${block}__playmaker`}
      data-contentid={id}
    >
      <div className={`${block}__player`}>
        <PlaymakerTease
          icid="tease-coverspread"
          metadata={metadata}
          showDek={false}
          showPlayerControls
          stickyEnabled
          styleOverride={{
            headline: 'cover-spread-featured__headline',
          }}
        />
      </div>
    </div>
  );
}

FeaturedPlaymaker.propTypes = {
  block: PropTypes.string,
  content: ContentPropType.isRequired,
};

FeaturedPlaymaker.defaultProps = {
  block: null,
};

export { FeaturedPlaymaker };
