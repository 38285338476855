import React from 'react';
import PropTypes from 'prop-types';

import getVideoAutoplayCapabilities from 'lib/getVideoAutoplayCapabilities';

// 👇 no-op wrapper component that just calls the children func
export const NoDetect = ({ children }) => children();

class AutoplayCapabilitiesDetector extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      canAutoplayMuted: false,
      canAutoplayUnmuted: false,
      isTestingAutoplayCapabilities: false,
    };
  }

  componentDidMount() {
    this.setState({ isTestingAutoplayCapabilities: true });
    getVideoAutoplayCapabilities()
      .then((autoplayCapabilities) => {
        this.setState({
          ...autoplayCapabilities,
          isTestingAutoplayCapabilities: false,
        });
      });
  }

  render() {
    const { children } = this.props;
    return children({ ...this.state });
  }
}

export default AutoplayCapabilitiesDetector;
