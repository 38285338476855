import React from 'react';
import PropTypes from 'prop-types';
import { PackageStaticColGrid } from './PackageStaticColGrid';
import { PackageFlexColGrid } from './PackageFlexColGrid';

const PackageGrid = (props) => {
  const { gridType } = props;

  const Grid = gridType === 'flex' ? PackageFlexColGrid : PackageStaticColGrid;

  return <Grid {...props} />;
};

PackageGrid.propTypes = {
  gridType: PropTypes.string,
};

PackageGrid.defaultProps = {
  gridType: '',
};

export { PackageGrid };
