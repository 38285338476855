import React from 'react';

import Logo from 'components/TVE/Logo';
import SignIn from 'components/TVE/SignIn';
import { block } from './helper';

function CTAAuthedFull() {
  return (
    <div className={`${block} ${block}__authed-full`} data-testid="call-to-action-authed-full">
      <Logo />
      <SignIn />
    </div>
  );
}

export default CTAAuthedFull;
