import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { stub as $t } from '@nbcnews/analytics-framework';

import {
  package as PackagePropType,
} from 'lib/CustomPropTypes';
import { getTeaseUrl } from 'lib/teaseUtils';
import { imageAltForItem } from 'lib/imageAlt';
import { FeatureFlagContext } from 'lib/ContextTypes';

import { BaconSlider, useBaconSlider } from 'components/BaconSlider';
import { PageIndicators } from 'components/BaconSlider/PageIndicators';
import { PageArrowButton } from 'components/BaconSlider/PageArrowButton';
import { NotchedHorizontalRule } from 'components/NotchedHorizontalRule';

import { EventCard } from './EventCard/index';

import styles from './styles.module.scss';

const TRACKING_EVENT_NAME = 'mbt_timeline_module_inview';

$t('register', TRACKING_EVENT_NAME);

export function Timeline({ content, getTrackingPlacement, isEmbed }) {
  const {
    baconSliderRef,
    currentPage,
    hasMultiplePages,
    pageControls,
  } = useBaconSlider();

  const { ref: timelineRef, inView: timeineLineInView } = useInView();

  const { 'timeline-module': isTimelineModuleEnabled } = useContext(FeatureFlagContext);

  useEffect(() => {
    if (timeineLineInView) {
      $t('track', TRACKING_EVENT_NAME, {
        placement: getTrackingPlacement?.(),
      });
    }
  }, [timeineLineInView]);

  if (!isTimelineModuleEnabled) return null;

  const {
    id,
    items = [],
    metadata: packageMetadata,
  } = content || {};

  return (
    <div
      className={classnames('pkg timeline', styles.timelinePkg, { [styles.isEmbed]: isEmbed })}
      data-packageid={id}
      data-testid="timeline"
      ref={timelineRef}
    >
      <NotchedHorizontalRule className={{ [styles.notchEmbedded]: isEmbed }} />
      <div className={styles.header}>
        {packageMetadata?.title && (
          <h2 className={styles.title}>{packageMetadata?.title}</h2>
        )}
        {hasMultiplePages && (
          <PageIndicators
            pageControls={pageControls}
            currentPage={currentPage}
            onPageClick={null}
          />
        )}
      </div>
      <div className={styles.timeline}>
        <BaconSlider
          ref={baconSliderRef}
          className={styles.timelineSlider}
          columnGap={4}
          offsetRight={4}
          nextButtonSlot={(goToNextPage) => (hasMultiplePages && (
            <PageArrowButton
              onClick={goToNextPage}
              direction="right"
              label="Next page"
              hasLeftBorder
              className={styles.nextButton}
            />
          ))}
          prevButtonSlot={(goToPrevPage) => (hasMultiplePages && (
            <PageArrowButton
              onClick={goToPrevPage}
              direction="left"
              label="Previous page"
              hasRightBorder
              className={styles.prevButton}
            />
          ))}
          isPrevButtonVisible={currentPage > 1}
        >
          {items.map((item) => item && (
            <EventCard
              key={item.id}
              id={item.id}
              headline={item.computedValues?.headline}
              text={item.computedValues?.dek}
              ctaText={item.metadata?.ctaText}
              ctaURL={item.computedValues?.url}
              showCTA={item.metadata?.showCTA}
              openInNewTab={item.metadata?.openInNewTab}
              imageURL={getTeaseUrl({ computedValues: item.computedValues })}
              imageAlt={imageAltForItem({ computedValues: item.computedValues })}
              showArt={item.metadata?.showArt}
              showPlayIcon={item.type === 'video'}
              isEmbed={isEmbed}
            />
          ))}
        </BaconSlider>
      </div>
    </div>
  );
}

Timeline.propTypes = {
  content: PackagePropType,
  getTrackingPlacement: PropTypes.func,
  isEmbed: PropTypes.bool,
};

Timeline.defaultProps = {
  content: null,
  getTrackingPlacement: null,
  isEmbed: false,
};
