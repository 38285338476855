import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  package as packagePropType,
  layoutContext as LayoutContextPropType,
} from 'lib/CustomPropTypes';
import {
  filterItems,
  getInitialFilters,
  getPackageItemsTaxonomy,
  getUpdatedCategoryValues,
  hydrateFilteredItemsWithAds,
  getNewActiveFilters,
} from 'lib/waffleUtils';

import { Header } from 'components/packages/Waffle/Header';
import { List } from 'components/packages/Waffle/List';

import styles from '../styles.module.scss';

const subTypesWithMultiselectOn = ['recipeWaffle', 'productWaffle'];

const mapStateToProps = ({ shared, front }) => ({
  vertical: shared.vertical,
  draftId: front.draftId,
});

@connect(mapStateToProps)
class Waffle extends React.Component {
  height = null;

  static propTypes = {
    content: packagePropType.isRequired,
    vertical: PropTypes.string,
    pkgClassName: PropTypes.string,
    adsDisabled: PropTypes.bool,
    draftId: PropTypes.number,
  };

  static defaultProps = {
    vertical: 'today',
    pkgClassName: 'pkg waffle',
    adsDisabled: false,
    draftId: undefined,
  };

  static contextTypes = {
    ...LayoutContextPropType,
  };

  constructor(props) {
    super(props);
    const {
      content: {
        metadata: {
          customFilters,
        } = {},
      } = {},
    } = this.props;
    const {
      categories, displayNameByKey, filterValues, categoryToDisplay,
    } = getInitialFilters(customFilters);

    this.state = {
      activeFilters: new Map(),
      categories,
      displayNameByKey,
      filterValues,
      categoryToDisplay,
      loadingTaxonomy: true,
      idToTaxonomy: {},
    };
  }

  componentDidMount() {
    const { pageRoute, vertical } = this.context;
    const { draftId } = this.props;

    getPackageItemsTaxonomy(vertical, pageRoute, draftId)
      .then((idToTaxonomy) => this.setState((prevState) => ({ ...prevState, idToTaxonomy, loadingTaxonomy: false })))
      .catch(() => this.setState((prevState) => ({ ...prevState, loadingTaxonomy: false })));
  }

  componentDidUpdate(prevProps) {
    const {
      content: {
        metadata: {
          customFilters,
        } = {},
      } = {},
    } = this.props;
    const {
      content: {
        metadata: {
          customFilters: prevFilters,
        } = {},
      } = {},
    } = prevProps;
    if (customFilters === prevFilters) {
      return;
    }
    this.clearAllFilters();
  }

  handleFilterChange = (category) => (key, value) => {
    const {
      activeFilters, categories, displayNameByKey, filterValues,
    } = this.state;
    const {
      content: {
        subType,
        metadata: { multiSelect: enableMultiselectFilters = subTypesWithMultiselectOn.includes(subType) } = {},
      },
    } = this.props;

    const newCategoryValues = getUpdatedCategoryValues(key, value, filterValues, category, enableMultiselectFilters);

    // update filter values
    const newFilterValues = {
      ...filterValues,
      [category]: newCategoryValues,
    };

    const newActiveFilters = getNewActiveFilters({
      activeFilters,
      hasMultiSelect: enableMultiselectFilters,
      key,
      value,
      category,
      displayNameByKey,
      newCategoryValues,
    });

    this.setState({
      activeFilters: newActiveFilters,
      categories: { ...categories },
      filterValues: newFilterValues,
    });
  };

  clearAllFilters = () => {
    const {
      content: {
        metadata: {
          customFilters,
        },
      },
    } = this.props;
    const {
      categories, displayNameByKey, filterValues, categoryToDisplay,
    } = getInitialFilters(customFilters);

    this.setState({
      activeFilters: new Map(),
      filterValues,
      categories,
      displayNameByKey,
      categoryToDisplay,
    });
  };

  render() {
    const {
      adsDisabled,
      content = {},
      pkgClassName,
      vertical,
    } = this.props;

    const {
      categories,
      filterValues,
      categoryToDisplay,
      activeFilters,
      displayNameByKey,
      idToTaxonomy,
    } = this.state;

    const {
      id,
      items,
      metadata,
      subType,
    } = content || {};

    const {
      mobileDisplay,
      showCommerceDisclaimer = false,
      multiSelect: enableMultiselectFilters = subTypesWithMultiselectOn.includes(subType),
    } = metadata || {};

    const filterStates = Object.values(filterValues).map((filterValue) => ({
      type: 'taxonomy',
      values: filterValue,
    }));

    const waffleCards = (items || []).filter((item) => item);

    const cardsWithoutAds = waffleCards.filter(({ type }) => type !== 'ad');

    const filteredCards = filterItems(cardsWithoutAds, filterStates, idToTaxonomy); // cards with filters applied

    const cards = adsDisabled ? filteredCards : hydrateFilteredItemsWithAds(filteredCards, waffleCards);

    const cardCount = cards.filter(({ type }) => type !== 'ad').length;

    const shouldDisplayMobileColumns = parseInt(mobileDisplay, 10) === 2;

    return (
      <div
        className={classNames(pkgClassName, styles.waffle)}
        data-packageid={id}
        ref={(e) => { this.container = e; }}
      >
        <Header
          animateBackground
          activeFilters={Array.from(activeFilters.values())}
          cardCount={cardCount}
          categoryToDisplay={categoryToDisplay}
          categories={categories}
          clearFilter={this.clearAllFilters}
          content={content}
          enableMultiselectFilters={enableMultiselectFilters}
          filterDisplayNameByKey={displayNameByKey}
          filterOnChange={this.handleFilterChange}
          filterValues={filterValues}
          isFilterActive={activeFilters.size > 0}
          showDisclaimer={showCommerceDisclaimer}
        />
        <List
          adsDisabled={adsDisabled}
          cards={cards}
          metadata={metadata}
          mobileDisplayColumns={shouldDisplayMobileColumns}
          vertical={vertical}
        />
      </div>
    );
  }
}

export { Waffle };
