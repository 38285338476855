/* eslint-disable jsdoc/require-jsdoc */
/* About containerFunction.js
    These are for MSP Container and are ways of accessing
    the lead item for using it as package headline when selected
*/

import { BADGES } from 'components/BadgeLabel/constants';


/**
 *
 * @param {string} leadHeadlineSize
 * @param {boolean} contentAsPackageHeadline
 * @param {string} leadColumn
 * @param {Array} items
 * @param {object} metadata
 * @returns {object}
 */

// find lead item info for when contentAsPackageHeadline is selected
export const findLeadItemInfo = (leadHeadlineSize, contentAsPackageHeadline, leadColumn, items, metadata) => {
  const hasColumn1Lead = leadColumn === 'Column1Lead';
  const hasColumn2Lead = leadColumn === 'Column2Lead';
  const hasColumn3Lead = leadColumn === 'Column3Lead';
  let edittedItems = items;
  const leadItemValues = {
    headlineSize: leadHeadlineSize,
  };
  if (contentAsPackageHeadline) {
    if (hasColumn1Lead) {
      [leadItemValues.item] = edittedItems;
      leadItemValues.columnLeadIndex = 0;
      if ((!items[0].computedValues.teaseImage || items[0].metadata.mediaSize === 'none' || items[0]?.metadata?.mediaSize === 'multiSmall') && items[0].type !== 'liveBlog' && items[0].type !== 'custom') {
        edittedItems = items.slice(1);
        leadItemValues.valueRemoved = 0;
        leadItemValues.removed = true;
      }
    }
    if (hasColumn2Lead) {
      leadItemValues.item = edittedItems[metadata.numberOfItemsInColumn1];
      leadItemValues.columnLeadIndex = 1;
      if ((!items[metadata.numberOfItemsInColumn1]?.computedValues?.teaseImage || items[metadata.numberOfItemsInColumn1]?.metadata?.mediaSize === 'none' || items[metadata.numberOfItemsInColumn1]?.metadata?.mediaSize === 'multiSmall') && items[metadata.numberOfItemsInColumn1]?.type !== 'liveBlog' && items[metadata.numberOfItemsInColumn1]?.type !== 'custom') {
        edittedItems = items.slice(0, metadata.numberOfItemsInColumn1).concat(items.slice(metadata.numberOfItemsInColumn1 + 1));
        leadItemValues.valueRemoved = metadata?.numberOfItemsInColumn1;
        leadItemValues.removed = true;
      }
    }
    if (hasColumn3Lead) {
      const previousItemsNumber = metadata.numberOfItemsInColumn1 + metadata.numberOfItemsInColumn2;
      leadItemValues.item = edittedItems[previousItemsNumber];
      leadItemValues.columnLeadIndex = 2;
      if ((!items[previousItemsNumber]?.computedValues?.teaseImage || items[previousItemsNumber]?.metadata?.mediaSize === 'none' || items[previousItemsNumber]?.metadata?.mediaSize === 'multiSmall') && items[previousItemsNumber]?.type !== 'liveBlog' && items[metadata.numberOfItemsInColumn1]?.type !== 'custom') {
        edittedItems = items.slice(0, previousItemsNumber).concat(items.slice(previousItemsNumber + 1));
        leadItemValues.valueRemoved = previousItemsNumber;
        leadItemValues.removed = true;
      }
    }
  }
  leadItemValues.edittedItems = edittedItems;
  return leadItemValues;
};


/**
 *
 * @param {string} badge
 * @param {boolean} hasBadge
 * @returns {string}
 */

// find badge type for MSPContainer
export const getBadgeType = (badge, hasBadge) => {
  if (hasBadge && !badge) {
    return 'BREAKING';
  }
  return (hasBadge && badge) || BADGES.EYEBROW;
};
