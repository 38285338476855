import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash.get';

import loadScript from 'lib/loadScript';
import Breakpoints from 'lib/Breakpoints';
import placements from 'lib/taboolaConfig';
import MobileDetection from 'lib/MobileDetection';
import MobileApi from 'lib/MobileApi';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { TABOOLA_CUSTOM_PLACEMENT } from 'lib/brandFeatures';
import { setTaboolaLoaded } from 'redux/modules/shared';
import { isBlogArticle } from 'lib/article';
import { VIEW } from 'lib/view';

import styles from './styles.module.scss';

const verticalWithTaboola = {
  entretenimiento: true,
  msnbc: true,
  news: true,
  noticias: true,
  deportes: true,
  select: true,
  shows: true,
  telemundo: true,
  today: true,
};

const loaderConfig = {
  default: 'nbcnews',
  entretenimiento: 'telemundocom',
  msnbc: 'nbcuniversal-msnbc',
  news: 'nbcnews',
  noticias: 'telemundocom',
  deportes: 'telemundocom',
  select: 'nbcuniversal-network',
  shows: 'telemundocom',
  telemundo: 'telemundocom',
  today: 'nbc-today',
};

const publisherChromelessConfig = {
  today: {
    android: 'nbc-todayappandroid',
    ios: 'nbctodayappios',
  },
  news: {
    android: 'nbcnewsappandroid',
    ios: 'nbcnewsappios',
  },
  mach: {
    android: 'nbcnewsappandroid',
    ios: 'nbcnewsappios',
  },
  better: {
    android: 'nbcnewsappandroid',
    ios: 'nbcnewsappios',
  },
  think: {
    android: 'nbcnewsappandroid',
    ios: 'nbcnewsappios',
  },
  noticias: {
    android: 'telemundocom-app-android',
    ios: 'telemundocom-app-ios',
  },
  deportes: {
    android: 'telemundocom-app-android',
    ios: 'telemundocom-app-ios',
  },
  entretenimiento: {
    android: 'telemundocom-app-android',
    ios: 'telemundocom-app-ios',
  },
  shows: {
    android: 'telemundocom-app-android',
    ios: 'telemundocom-app-ios',
  },
  telemundo: {
    android: 'telemundocom-app-android',
    ios: 'telemundocom-app-ios',
  },
  msnbc: {
    android: 'msnbcappandroid',
    ios: 'msnbcappios',
  },
  default: 'nbcnews-app',
};

// exported for testing
export const mapStateToProps = ({
  front, shared, article,
}) => ({
  isLiveBlog: isBlogArticle(article?.content?.[0]),
  vertical: shared.vertical,
  pageView: shared.pageView === 'card' ? 'article' : shared.pageView,
  section: shared.section,
  isChromeless: shared.isChromeless,
  loaderLoaded: shared.taboolaLoaderLoaded,
  adsDisabled: front.curation.adsDisabled,
  taboolaFeed: shared.taboolaFeed,
  ecommerceEnabled: article?.content?.[0]?.ecommerceEnabled,
  view: shared.view,
});

class Taboola extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    vertical: PropTypes.string.isRequired,
    id: PropTypes.string,
    pageView: PropTypes.string.isRequired,
    section: PropTypes.string,
    canonicalUrl: PropTypes.string,
    isRail: PropTypes.bool,
    isMidFront: PropTypes.bool,
    isChromeless: PropTypes.bool.isRequired,
    isLiveBlog: PropTypes.bool,
    loaderLoaded: PropTypes.bool.isRequired,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    adsDisabled: PropTypes.bool,
    pkgClassName: PropTypes.string,
    ecommerceEnabled: PropTypes.bool,
    view: PropTypes.string,
  };

  static contextTypes = {
    isLiveBlog: PropTypes.bool,
    store: PropTypes.shape({}),
  };

  static defaultProps = {
    id: '1',
    section: null,
    canonicalUrl: null,
    isRail: false,
    isMidFront: false,
    className: null,
    adsDisabled: false,
    pkgClassName: 'pkg taboola',
    ecommerceEnabled: false,
    isLiveBlog: false,
    view: null,
  };

  async componentDidMount() {
    const { id, isRail } = this.props;
    const placement = this.getPlacement();

    if (placement) {
      const placementConfig = { target_type: 'mix', ...placement };
      placementConfig.container = placement.container.replace('%id', id);

      if (this.taboolaContainer) {
        this.taboolaContainer.id = placementConfig.container;
      }
      // Delay the article rail configuration until the article placement is added.
      if (isRail) {
        return;
      }
      /* eslint-disable no-underscore-dangle */
      window._taboola = window._taboola || [];
      // Push placement config
      window._taboola.push(placementConfig);

      this.addConfig();

      await this.getLoader();

      if (window.performance && typeof window.performance.mark === 'function') {
        window.performance.mark('tbl_ic');
      }
      /* eslint-enable no-underscore-dangle */
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  getChromelessLoaderPublisherID = () => {
    const {
      vertical,
    } = this.props;

    const device = MobileDetection.Android() ? 'android' : 'ios';

    return publisherChromelessConfig[vertical]
      ? publisherChromelessConfig[vertical][device]
      : publisherChromelessConfig.default;
  };

  getLoader = () => {
    const {
      vertical, isChromeless, loaderLoaded, dispatch,
    } = this.props;

    if (!loaderLoaded) {
      // Publisher name
      let publisher = isChromeless
        ? this.getChromelessLoaderPublisherID()
        : loaderConfig[vertical] || loaderConfig.default;

      // The Today native app does not load in chromeless mode. This is using
      // the MobileApi class to detect if the logic is running inside the
      // native app and assigning the correct loader.

      const isTodayAndroidApp = vertical === 'today'
        && MobileDetection.Android()
        && MobileApi.getAndroidBridge() !== null;

      if (isTodayAndroidApp) {
        publisher = publisherChromelessConfig.today.android;
      }

      // Loader name
      // taboola feed only chromeless only works for news articles and if enabled as query
      // TODO: change this when feed is ready on chromeless today
      let loader = 'loader';
      if (this.shouldShowTaboolaFeedOnChromeless()) {
        loader = 'mobile-loader';
      }

      dispatch(setTaboolaLoaded());

      return loadScript(`//cdn.taboola.com/libtrc/${publisher}/${loader}.js`);
    }
    return null;
  };

  getPlacement = () => {
    const type = this.getTypeFromPageSection();
    const {
      ecommerceEnabled,
      isChromeless,
      isRail,
      isMidFront,
      isLiveBlog,
      vertical,
    } = this.props;

    const isTaboolaCustomPlacement = getFeatureConfigForBrand(
      TABOOLA_CUSTOM_PLACEMENT,
      vertical,
    );

    let breakpoint = this.getBreakpoint();

    if (isRail) {
      breakpoint = Breakpoints.isLorXL() && !isChromeless ? 'rail' : null;
    }

    if (isTaboolaCustomPlacement) {
      breakpoint = isMidFront ? 'midFront' : null;
    }

    // ecommerce placement
    if (vertical === 'today' && ecommerceEnabled && !isChromeless && !isRail) {
      if (breakpoint === 'mobile') {
        return placements.today.article['commerce-mobile'];
      }
      return placements.today.article.commerce;
    }

    const isArticle = type === 'article';
    if (isArticle && isLiveBlog) {
      return placements.news.liveBlog[breakpoint];
    }

    const specificPlacement = this.getSpecificPlacement(vertical, type, breakpoint);
    return specificPlacement;
  };

  getSpecificPlacement = (
    vertical,
    type,
    breakpoint,
  ) => get(placements, [vertical, type, breakpoint], null);

  getTypeFromPageSection = () => {
    const { vertical, pageView, section } = this.props;

    const isTodayCoverABTestPage = (section === 'today-cover-redesign' || section === 'cover');
    if (pageView === 'front' && verticalWithTaboola[vertical]) {
      if (section === '' || isTodayCoverABTestPage) {
        return 'home';
      }

      return 'category';
    }
    if (pageView === 'card') {
      return 'article';
    }
    // ads on showblogs should mimic article
    if (pageView === 'showBlog') {
      return 'article';
    }
    return pageView;
  };

  getBreakpoint = () => {
    const { isChromeless, vertical } = this.props;

    if (this.shouldShowTaboolaFeedOnChromeless()) {
      /*
       * When updating hasiOSConfig, make sure the chromeless_feed_ios object
       * exists in src/lib/taboolaConfig.js for the selected brand's page type
       */
      const hasiOSConfig = {
        msnbc: false,
        news: false,
        telemundo: false,
        today: MobileDetection.iOS(),
      };

      return `chromeless_feed${hasiOSConfig[vertical] ? '_ios' : ''}`;
    }

    if (isChromeless) {
      return Breakpoints.isS() ? 'chromeless_mobile' : 'chromeless_tablet';
    }

    if (Breakpoints.isS()) {
      return 'mobile';
    }

    return 'desktop';
  };

  shouldShowTaboolaFeedOnChromeless = () => {
    const { ecommerceEnabled, isChromeless, vertical } = this.props;

    const config = {
      msnbc: isChromeless && !ecommerceEnabled,
      news: isChromeless && !ecommerceEnabled,
      telemundo: false,
      today: isChromeless && !ecommerceEnabled,
    };

    return config[vertical];
  };

  addConfig = () => {
    const {
      isChromeless, id, canonicalUrl, vertical, isLiveBlog,
    } = this.props;
    const type = this.getTypeFromPageSection();

    const pageConfig = type === 'recipe'
      ? { category: 'auto' }
      : { [type]: 'auto' };
    const isArticle = type === 'article';

    /* eslint-disable no-underscore-dangle */

    if (isArticle) {
      if (!isChromeless) {
        let railPlacement = this.getSpecificPlacement(vertical, type, 'rail');
        if (isLiveBlog) {
          railPlacement = this.getSpecificPlacement(vertical, 'liveBlog', 'rail');
        }

        if (railPlacement) {
          const railPlacementConfig = { target_type: 'mix', ...railPlacement };
          railPlacementConfig.container = railPlacementConfig.container.replace('%id', id);
          window._taboola.push(railPlacementConfig);
        }
      }

      if (canonicalUrl) {
        pageConfig.url = canonicalUrl;
      }
    }

    if (this.shouldShowTaboolaFeedOnChromeless()) {
      window._taboola.mobile = [];
      window._taboola.mobile.push({
        // run sdkless when testing the js on a browser (no sdk) (optional)
        allow_sdkless_load: true,
        taboola_view_id: new Date().getTime(),
        // override publisher, this value is injected by mobile
        publisher: this.getChromelessLoaderPublisherID(),
      });
    }

    window._taboola.push(pageConfig);

    if (type !== 'article') {
      window._taboola.push({ flush: true });
    }
  };

  render() {
    const {
      adsDisabled,
      id,
      isRail,
      pkgClassName,
      className: propsClassName,
      view,
    } = this.props;

    // Hide taboola feed on today page for START_TODAY_APP
    if (view === VIEW.START_TODAY_APP) {
      return null;
    }

    // Do NOT show Taboola ads if adsDisabled is true.
    if (adsDisabled) return null;

    // the same markup should be rendered on the server as well as on the initial
    // render cycle on the client
    return (
      <div
        className={classNames(pkgClassName, styles.taboola, propsClassName, 'dn-print')}
        data-packageid={id}
        data-test="taboola"
        data-testid="taboola"
        data-activity-map={`taboola-${isRail ? 'article-sidebar' : 'article-bottom'}`}
      >
        <div
          data-test="placeholder"
          data-testid="taboola-content"
          ref={(taboola) => { this.taboolaContainer = taboola; }}
        />
      </div>
    );
  }
}

// unconnected component used for testing
export { Taboola as UnconnectedTaboola };

export default connect(mapStateToProps)(Taboola);
