import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LinkIfHref } from 'components/Link';

import './styles.themed.scss';

export function Headline({
  text,
  headlineSize,
  storyType,
  url,
  showHeadlineOnly,
  className,
}) {
  if (!text) return null;

  return (
    <h2 className={classNames(
      'storyline__headline founders-cond fw6',
      {
        important: storyType === 'important',
        large: headlineSize === 'large',
        lead: headlineSize === 'lead',
        headlineOnly: showHeadlineOnly,
      },
      className,
    )}
    >
      <LinkIfHref href={url}>
        {text}
      </LinkIfHref>
    </h2>
  );
}

Headline.propTypes = {
  text: PropTypes.string,
  headlineSize: PropTypes.string,
  storyType: PropTypes.string,
  url: PropTypes.string,
  showHeadlineOnly: PropTypes.bool,
  className: PropTypes.string,
};

Headline.defaultProps = {
  text: null,
  headlineSize: 'standard',
  storyType: 'standard',
  url: null,
  showHeadlineOnly: false,
  className: '',
};
