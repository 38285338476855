import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { content as contentPropType } from 'lib/CustomPropTypes';
import { formatDuration } from 'lib/DateTime';
import { isVideoLive } from 'lib/videoUtils';

import Link from 'components/Link';
import TypeIcon from 'components/TypeIcon';

import styles from './styles.module.scss';

function Duration(props) {
  const { t } = useTranslation();

  const {
    item: {
      computedValues: {
        url,
      } = {},
      item,
      item: {
        duration,
      },
      type,
    },
  } = props;

  const isLive = isVideoLive(item);
  const durationLabel = isLive ? t('LIVE') : formatDuration(duration);

  return (
    <Link
      className={classNames(styles.duration, { [styles.isLive]: isLive })}
      to={url}
    >
      {durationLabel && (
        <span className={styles.label}>
          {durationLabel}
        </span>
      )}
      <TypeIcon
        className={styles.typeIcon}
        type={type}
      />
    </Link>
  );
}

Duration.propTypes = {
  item: contentPropType.isRequired,
};

export { Duration };
