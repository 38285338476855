import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { content as ContentPropType } from 'lib/CustomPropTypes';

import get from 'lodash.get';
import { Count } from '../Count';
import { Featured } from './Featured';
import { Related } from './Related';

import styles from './styles.module.scss';

function MsnbcDailyArticles(props) {
  const {
    packageId,
    showFeature,
    showFeatureNumbered,
    showFeaturePublishedDate,
  } = props;

  let { articles } = props;
  let offset = 1;
  let featured;

  articles = articles.map((article) => {
    // Allow Eyebrow URLs to be overridden
    const isEyebrowUrlOverridden = get(article, ['metadata', 'overrideEyebrowUrl'], false);
    const eyebrowUrlOverride = get(article, ['metadata', 'eyebrowUrlOverride'], '');
    const shouldOverrideUnibrowUrl = isEyebrowUrlOverridden && eyebrowUrlOverride.length;
    let computedValuesFragment = { computedValues: { ...article.computedValues } };

    if (shouldOverrideUnibrowUrl) {
      const unibrow = get(article, ['computedValues', 'unibrow']);

      unibrow.url.override = eyebrowUrlOverride;

      computedValuesFragment = {
        computedValues: {
          ...article.computedValues,
          unibrow,
        },
      };
    }

    // Allow Bylines to be overridden
    const isBylineOverridden = get(article, ['metadata', 'overrideByline'], false);
    const bylineTextOverride = get(article, ['metadata', 'bylineTextOverride'], '');
    const bylineUrlOverride = get(article, ['metadata', 'bylineUrlOverride'], '');
    const shouldOverrideByline = isBylineOverridden && bylineTextOverride.length;
    let itemFragment = { item: { ...article.item } };

    if (shouldOverrideByline) {
      let authorInfo = get(article, ['item', 'authors[0]'], undefined);

      if (!authorInfo) {
        authorInfo = {
          authorType: 'author',
          featuredAuthor: {
            isFeaturedAuthor: false,
          },
        };
      }

      itemFragment = {
        item: {
          ...article.item,
          authors: [
            {
              ...authorInfo,
              person: {
                name: bylineTextOverride,
                url: {
                  primary: bylineUrlOverride,
                },
              },
            },
          ],
        },
      };
    }

    return {
      ...article,
      ...computedValuesFragment,
      ...itemFragment,
    };
  });

  if (showFeature) {
    // Extract first item as feature
    ([featured, ...articles] = articles);
    // Offset count if feature is numbered
    if (showFeatureNumbered) {
      offset = 2;
    }
  }

  return (
    <>
      {showFeature && (
        // Featured, special treatment for main item
        <div
          className={classNames(
            styles.featured,
            'layout-grid-container',
            { [styles.numbered]: showFeatureNumbered },
          )}
          data-testid="msnbc-daily-articles-featured"
        >
          <div className={classNames(styles.featuredItem, 'layout-grid-item grid-col-12-m')}>
            {showFeatureNumbered && (
              <Count className={styles.count}>
                1
              </Count>
            )}
            <Featured
              item={featured}
              showFeaturePublishedDate={showFeaturePublishedDate}
            />
          </div>
        </div>
      )}

      <div
        className={classNames(styles.grid, 'layout-grid-container')}
        // This count attribute corresponds to rules in the site styling
        // that force the numbered items into columns by manipulating 'order'
        data-count={2 * Math.round(articles.length / 2)}
        data-testid="msnbc-daily-articles-grid"
      >
        {Array.isArray(articles) && articles.map((item, i) => (
          <div
            className={classNames(styles.gridItem, 'layout-grid-item grid-col-6-m')}
            key={`${item.id}-${packageId}`}
          >
            <Count className={styles.count}>
              {i + offset}
            </Count>
            <Related item={item} />
          </div>
        ))}
      </div>
    </>
  );
}

MsnbcDailyArticles.propTypes = {
  articles: PropTypes.arrayOf(ContentPropType).isRequired,
  packageId: PropTypes.string.isRequired,
  showFeature: PropTypes.bool.isRequired,
  showFeatureNumbered: PropTypes.bool.isRequired,
  showFeaturePublishedDate: PropTypes.bool.isRequired,
};

export { MsnbcDailyArticles };
