import React from 'react';
import get from 'lodash.get';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import { getTeaseUrl } from 'lib/teaseUtils';

import { BylineList } from 'components/Byline';
import Link from 'components/Link';
import { Picture } from 'components/Picture';
import TypeIcon from 'components/TypeIcon';
import { Unibrow } from 'components/Unibrow';

import { passTrackingMSNBCDaily } from '../../utility';
import styles from './styles.module.scss';

function Related(props) {
  const { item } = props;

  const type = get(item, 'type', 'article');
  const isCustomLink = type === 'custom';
  const target = isCustomLink ? '_blank' : '_top';

  const authors = get(item, ['item', 'authors'], []);
  const imageURL = getTeaseUrl(item, true);

  // Computed
  const altText = get(item, ['computedValues', 'teaseImage', 'altText']);
  const teaseHeight = get(item, ['computedValues', 'teaseImage', 'height']);
  const teaseWidth = get(item, ['computedValues', 'teaseImage', 'width']);
  const headline = get(item, ['computedValues', 'headline']);
  const unibrow = get(item, ['computedValues', 'unibrow']);
  let url = get(item, ['computedValues', 'url']);

  // Append tracking to content and unibrow links
  const trackingType = 'pkgArticle';
  url = passTrackingMSNBCDaily(url, trackingType);

  // Meta
  const publicationName = get(item, ['metadata', 'publicationName']);

  const preview = type === 'video' ? { url, redirect: true } : false;

  return (
    <div className={styles.relatedArticle} data-contentid={item.id} data-testid="related">
      {imageURL && (
        <div className={styles.picture}>
          <Link target={target} to={url.url} icid={url.icid}>
            <Picture
              alt={altText}
              lazyloaded
              originalHeight={teaseHeight}
              originalWidth={teaseWidth}
              preview={preview}
              responsiveFlavors={{
                s: AIMS_FLAVORS.FOCAL_80x80,
              }}
              retina
              url={imageURL}
            />
            <TypeIcon type={type} />
          </Link>
        </div>
      )}
      <div className={styles.info}>
        {unibrow && (
          <Unibrow
            className={styles.unibrow}
            size="h4"
            target={target}
            unibrow={unibrow}
            icid={trackingType}
          />
        )}
        <h3 className={styles.headline}>
          <Link target={target} to={url.url} icid={url.icid}>
            {headline}
          </Link>
        </h3>
        {Array.isArray(authors) && authors.length > 0 && (
          <div className={styles.author} data-testid="author">
            <BylineList isMsnbcDaily authors={authors} />
          </div>
        )}
        {isCustomLink && publicationName && (
          <Link className={styles.externalLink} target={target} to={url.url} icid={url.icid}>
            {publicationName}
            <span className="icon icon-external-link-arrow" />
          </Link>
        )}
      </div>
    </div>
  );
}

Related.propTypes = {
  item: ContentPropType.isRequired,
};

export { Related };
