import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import trimText from 'lib/trimText';
import Breakpoints from 'lib/Breakpoints';
import styles from './styles.module.scss';

// can either take two text inputs or it can split one based on character lengths
// can either build text with HTML input or pure string

const textType = {
  MORE_INFO: 'more-info',
  DEK: 'dek',
};

class TextSummary extends React.Component {
  static propTypes = {
    allowHTMLText: PropTypes.bool,
    dekText: PropTypes.string,
    mobileExpand: PropTypes.bool,
    moreInfoClasses: PropTypes.string,
    moreInfoText: PropTypes.string,
    relatedLinkLabel: PropTypes.string,
    relatedLinkUrl: PropTypes.string,
    relatedLinkClasses: PropTypes.string,
    openRelatedInNewTab: PropTypes.bool,
    showDek: PropTypes.bool,
    showMoreInfo: PropTypes.bool,
    textClasses: PropTypes.string,
    trimDekText: PropTypes.bool,
  };

  static defaultProps = {
    allowHTMLText: false,
    dekText: '',
    mobileExpand: false,
    moreInfoClasses: '',
    moreInfoText: '',
    relatedLinkLabel: '',
    relatedLinkUrl: '',
    relatedLinkClasses: '',
    openRelatedInNewTab: false,
    showDek: false,
    showMoreInfo: false,
    textClasses: '',
    trimDekText: false,
  };

  componentDidMount() {
    this.contentHeight = this.moreInfoElem?.scrollHeight;
  }

  addBreaksToText = (text, type) => {
    if (type === textType.DEK) return text;

    const { allowHTMLText } = this.props;
    if (allowHTMLText) {
      return text.split('\n').map((paragraph) => (
        `<p>${paragraph}</p>`)).join('');
    }

    return text.split('\n').map((paragraph) => (
      <p>
        {paragraph}
      </p>
    ));
  }

  render() {
    let { dekText } = this.props;
    const {
      allowHTMLText,
      mobileExpand,
      moreInfoClasses,
      moreInfoText,
      relatedLinkLabel,
      relatedLinkUrl,
      relatedLinkClasses,
      openRelatedInNewTab,
      showDek,
      showMoreInfo,
      textClasses,
      trimDekText,
    } = this.props;

    const textClassnames = classNames(
      styles.textSummary,
      textClasses,
    );

    const relatedLinkClassnames = classNames(
      styles.relatedLink,
      relatedLinkClasses,
    );

    if (!showDek && !showMoreInfo) {
      return null;
    }

    // Trim dek if required
    if (showDek && dekText && trimDekText) {
      dekText = trimText(dekText, 60);
    }

    const buildPTag = (text, type) => {
      const pTagAttributes = {
        'data-test': `text-summary--${type}`,
        'data-component': `text-summary--${type}`,
        'data-testid': `text-summary--${type}`,
        className: textClassnames,
      };
      if (type === textType.MORE_INFO) {
        pTagAttributes.ref = (element) => { this.moreInfoElem = element; };
        pTagAttributes.className += ` ${moreInfoClasses}`;
      }

      const textWithBreaks = this.addBreaksToText(text, type);

      if (allowHTMLText) {
        pTagAttributes.dangerouslySetInnerHTML = { __html: textWithBreaks };
      } else {
        pTagAttributes.children = textWithBreaks;
      }

      return (
        <div {...pTagAttributes} />
      );
    };

    const dekTextParagraph = showDek && dekText !== ''
      ? buildPTag(dekText, textType.DEK) : null;

    const showMoreTextParagraph = showMoreInfo && moreInfoText !== ''
      ? buildPTag(moreInfoText, textType.MORE_INFO) : null;

    const showRelatedLink = relatedLinkLabel && relatedLinkUrl
      ? (
        <p className={relatedLinkClassnames}>
          <a href={relatedLinkUrl} target={openRelatedInNewTab ? '_blank' : ''} rel="noreferrer">
            {relatedLinkLabel}
            {openRelatedInNewTab && <span className="icon icon-external-link" />}
          </a>
        </p>
      )
      : null;

    return (
      <div>
        {dekTextParagraph}
        {showMoreTextParagraph}
        {showRelatedLink}
        {mobileExpand && showMoreInfo && moreInfoText !== '' && Breakpoints.isS() && (
          <div
            data-testid="text-summary--more-info--mobile"
            className={classNames(
              'overflow-hidden dn-m',
              styles.moreInfoWrapper,
              { 'o-0': !showMoreInfo, 'o-100': showMoreInfo },
            )}
            style={{ height: (showMoreInfo && this.contentHeight) || 0 }}
          >
            {showMoreTextParagraph}
          </div>
        )}
      </div>
    );
  }
}

export { TextSummary };
