export const GATE_BANNER_TITLES = {
  front: 'Join Us',
  default: 'Exclusive',
};

export const GATE_TITLES = {
  article: 'Sign up for your free TODAY account to read this article!',
  recipe: 'Sign up for your free TODAY account to get this recipe!',
  game: 'Sign up for your free account to play!',
  front: 'Create a free TODAY Account',
  default: 'Access with your free TODAY account!',
};

export const GATE_SUBTITLES = {
  front: 'Save your favorite meal plans, exercises and get exclusive fitness and nutrition tips.',
};
