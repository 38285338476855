import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getConfig from 'next/config';

import Anonymous from './Anonymous';
import AnonymousSecondary from './AnonymousSecondary';
import AuthedFull from './AuthedFull';
import AuthedTemporary from './AuthedTemporary';

import { ERROR_TYPES } from '../Slate';

import './styles.themed.scss';

const mapStateToProps = ({ tve, video }) => ({
  authenticated: tve.authenticated,
  hasTempPass: tve.hasTempPass,
  videoError: video.error,
});

function CallToAction(props) {
  const { publicRuntimeConfig: { DISABLE_AUTH_TVE_MSNBC } } = getConfig();

  const {
    authenticated,
    videoError,
    hasTempPass,
    showSecondary,
    showAuthedFull,
  } = props;

  if (DISABLE_AUTH_TVE_MSNBC || videoError === ERROR_TYPES.BROWSER) {
    return null;
  }

  if (authenticated && !hasTempPass && showAuthedFull) {
    return <AuthedFull />;
  }

  return (
    <>
      {authenticated === true && hasTempPass && (
        <AuthedTemporary />
      )}
      {authenticated === false && (
        <Anonymous />
      )}
      {showSecondary && (
        <AnonymousSecondary />
      )}
    </>
  );
}

CallToAction.propTypes = {
  authenticated: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  hasTempPass: PropTypes.bool,
  videoError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  showSecondary: PropTypes.bool,
  showAuthedFull: PropTypes.bool,
};

CallToAction.defaultProps = {
  authenticated: null,
  hasTempPass: false,
  videoError: false,
  showSecondary: true,
  showAuthedFull: true,
};

export default connect(mapStateToProps)(CallToAction);
