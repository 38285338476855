import React from 'react';

function nbcBrandStudio() {
  return (
    /* eslint-disable max-len */
    <svg
      width="97px"
      height="29px"
      viewBox="0 0 97 29"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <polygon
          id="path-1"
          points="0 28.09375 96.8538725 28.09375 96.8538725 0.12522835 0 0.12522835"
        />
      </defs>
      <g
        id="Paid-Content"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="1240-—-NEWS-Paid-Content"
          transform="translate(-80.000000, -213.000000)"
        >
          <g id="sponsored" transform="translate(79.000000, 210.000000)">
            <g id="NBC-Brand-Studio" transform="translate(1.000000, 3.000000)">
              <polygon
                id="Fill-1"
                fill="#FFFFFF"
                points="60.0820908 7 57.9514156 3.73908154 57.9514156 7 57 7 57 2 57.9782208 2 60.0485844 5.14095595 60.0485844 2 61 2 61 7"
              />
              <g id="Group-36">
                <path
                  d="M64.2781527,5.20799367 C64.2781527,4.89951328 64.0629956,4.63510151 63.6615482,4.63510151 L62.3492691,4.63510151 L62.3492691,5.77354105 L63.6615482,5.77354105 C64.0486518,5.77354105 64.2781527,5.56035907 64.2781527,5.20799367 Z M64.1849179,3.21058313 C64.1849179,2.90210274 63.9697608,2.67441483 63.6256887,2.67441483 L62.3492691,2.67441483 L62.3492691,3.73922304 L63.6256887,3.73922304 C63.9697608,3.73922304 64.1849179,3.5188799 64.1849179,3.21058313 Z M61.3310381,6.6767643 L61.3310381,1.77853636 L63.8480177,1.77853636 C64.7586702,1.77853636 65.2246647,2.37346283 65.2246647,3.02696385 C65.2246647,3.64374101 64.851905,4.0550482 64.400075,4.15053023 C64.9092802,4.23132271 65.3178994,4.73792831 65.3178994,5.35488909 C65.3178994,6.0891826 64.8375612,6.6767643 63.9269087,6.6767643 L61.3310381,6.6767643 Z"
                  id="Fill-2"
                  fill="#FFFFFF"
                />
                <path
                  d="M65.9999475,4.23126763 C65.9999475,2.72577314 67.1114133,1.69787241 68.5456148,1.69787241 C69.5997054,1.69787241 70.2091379,2.28527049 70.5460381,2.89470288 L69.6714244,3.33538915 C69.4704318,2.93877151 69.0402969,2.62312996 68.5456148,2.62312996 C67.6778144,2.62312996 67.0468662,3.30601007 67.0468662,4.23126763 C67.0468662,5.15652518 67.6778144,5.83958891 68.5456148,5.83958891 C69.0402969,5.83958891 69.4704318,5.53110852 69.6714244,5.1271461 L70.5460381,5.5604876 C70.201966,6.16992 69.5997054,6.76484647 68.5456148,6.76484647 C67.1114133,6.76484647 65.9999475,5.73676211 65.9999475,4.23126763"
                  id="Fill-4"
                  fill="#FFFFFF"
                />
                <polygon
                  id="Fill-6"
                  fill="#FFFFFF"
                  points="76.5778961 6.67667249 74.2975893 3.48224786 74.2975893 6.67667249 73.2793582 6.67667249 73.2793582 1.77862817 74.3262769 1.77862817 76.5420366 4.85553646 76.5420366 1.77862817 77.5602677 1.77862817 77.5602677 6.67667249"
                />
                <polygon
                  id="Fill-8"
                  fill="#FFFFFF"
                  points="78.6510246 6.67667249 78.6510246 1.77862817 82.0356253 1.77862817 82.0356253 2.69654095 79.6692556 2.69654095 79.6692556 3.73197008 81.985422 3.73197008 81.985422 4.64988286 79.6692556 4.64988286 79.6692556 5.7587597 82.0356253 5.7587597 82.0356253 6.67667249"
                />
                <polygon
                  id="Fill-10"
                  fill="#FFFFFF"
                  points="86.6326543 6.67667249 85.7937209 3.31331812 84.9619593 6.67667249 83.8791811 6.67667249 82.5095268 1.77862817 83.6496802 1.77862817 84.4886137 5.31807343 85.3992662 1.77862817 86.1953475 1.77862817 87.0988281 5.31807343 87.9305896 1.77862817 89.077915 1.77862817 87.7154325 6.67667249"
                />
                <path
                  d="M89.3800673,5.98639252 L89.9321246,5.19334085 C90.2692041,5.55305102 90.792753,5.85418664 91.4523889,5.85418664 C92.0117974,5.85418664 92.2841505,5.58977488 92.2841505,5.31067357 C92.2841505,4.44417418 89.5306773,5.03910065 89.5306773,3.19574669 C89.5306773,2.3806607 90.21918,1.70512537 91.3448104,1.70512537 C92.1048529,1.70512537 92.7359804,1.93997443 93.2091468,2.38800547 L92.6427457,3.15186168 C92.2554628,2.78462312 91.7392651,2.61569338 91.2515756,2.61569338 C90.8142688,2.61569338 90.570424,2.81400221 90.570424,3.10779306 C90.570424,3.88615519 93.3167253,3.36467643 93.3167253,5.19334085 C93.3167253,6.08921932 92.692949,6.76475466 91.4021856,6.76475466 C90.4843612,6.76475466 89.824546,6.44911311 89.3800673,5.98639252"
                  id="Fill-12"
                  fill="#FFFFFF"
                />
                <path
                  d="M58.7621159,26.8959647 L59.2189662,26.2946116 C59.6291991,26.7622899 60.3006686,27.2015072 61.1768959,27.2015072 C62.2865688,27.2015072 62.6408608,26.5904222 62.6408608,26.1036475 C62.6408608,24.461173 58.9579089,25.3682523 58.9579089,23.0957801 C58.9579089,22.0454778 59.8716094,21.3293626 61.0929847,21.3293626 C62.0441584,21.3293626 62.7807129,21.6538179 63.3028275,22.2173454 L62.8366538,22.7996022 C62.37048,22.2746346 61.7178368,22.035746 61.0370438,22.035746 C60.2911658,22.035746 59.7597277,22.4465023 59.7597277,23.0480391 C59.7597277,24.4802694 63.4426797,23.659124 63.4426797,26.0366265 C63.4426797,26.9628021 62.8273303,27.9082578 61.1491048,27.9082578 C60.0674024,27.9082578 59.274907,27.4881369 58.7621159,26.8959647"
                  id="Fill-14"
                  fill="#FFFFFF"
                />
                <polygon
                  id="Fill-16"
                  fill="#FFFFFF"
                  points="65.8945384 27.7936059 65.8945384 22.1313382 63.9272852 22.1313382 63.9272852 21.4245875 68.6451429 21.4245875 68.6451429 22.1313382 66.6683869 22.1313382 66.6683869 27.7936059"
                />
                <path
                  d="M69.4839867,25.3204929 L69.4839867,21.4246426 L70.2578351,21.4246426 L70.2578351,25.3013965 C70.2578351,26.4662772 70.8731845,27.2014888 72.0201512,27.2014888 C73.1669386,27.2014888 73.782288,26.4662772 73.782288,25.3013965 L73.782288,21.4246426 L74.5561364,21.4246426 L74.5561364,25.3109447 C74.5561364,26.8959463 73.7077002,27.9082394 72.0201512,27.9082394 C70.3324229,27.9082394 69.4839867,26.8863981 69.4839867,25.3204929"
                  id="Fill-18"
                  fill="#FFFFFF"
                />
                <path
                  d="M80.3366551,24.613981 C80.3366551,23.2390398 79.4882189,22.1312647 77.9496662,22.1312647 L76.5977623,22.1312647 L76.5977623,27.0871491 L77.9496662,27.0871491 C79.4602484,27.0871491 80.3366551,25.979374 80.3366551,24.613981 Z M75.8239139,27.7935325 L75.8239139,21.4246977 L77.9496662,21.4246977 C79.8798048,21.4246977 81.1384739,22.8093707 81.1384739,24.613981 C81.1384739,26.4281395 79.8798048,27.7935325 77.9496662,27.7935325 L75.8239139,27.7935325 Z"
                  id="Fill-20"
                  fill="#FFFFFF"
                />
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="Clip-23" />
                <polygon
                  id="Fill-22"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                  points="82.163841 27.7937161 82.9376895 27.7937161 82.9376895 21.4246977 82.163841 21.4246977"
                />
                <path
                  d="M89.3243954,24.613981 C89.3243954,23.1244614 88.4293418,22.0262345 87.040144,22.0262345 C85.6414434,22.0262345 84.7558926,23.1244614 84.7558926,24.613981 C84.7558926,26.094136 85.6414434,27.2015439 87.040144,27.2015439 C88.4293418,27.2015439 89.3243954,26.094136 89.3243954,24.613981 M83.9538945,24.613981 C83.9538945,22.7329851 85.1939166,21.3196675 87.040144,21.3196675 C88.8768686,21.3196675 90.1263935,22.7329851 90.1263935,24.613981 C90.1263935,26.4949769 88.8768686,27.9081109 87.040144,27.9081109 C85.1939166,27.9081109 83.9538945,26.4949769 83.9538945,24.613981"
                  id="Fill-24"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
                <path
                  d="M59.1065287,15.6514321 C59.1065287,15.0807434 58.708488,14.5915816 57.9654787,14.5915816 L55.5372514,14.5915816 L55.5372514,16.6976948 L57.9654787,16.6976948 C58.6819519,16.6976948 59.1065287,16.3036478 59.1065287,15.6514321 Z M58.9340444,11.955176 C58.9340444,11.3844872 58.5360037,10.9632646 57.8991386,10.9632646 L55.5372514,10.9632646 L55.5372514,12.9336831 L57.8991386,12.9336831 C58.5360037,12.9336831 58.9340444,12.5260483 58.9340444,11.955176 Z M53.6530129,18.3691811 L53.6530129,9.30536612 L58.3104473,9.30536612 C59.9954861,9.30536612 60.8580869,10.4061637 60.8580869,11.6154803 C60.8580869,12.7570414 60.1679704,13.5179597 59.332085,13.6946014 C60.2742939,13.8440675 61.0305712,14.7818112 61.0305712,15.9231886 C61.0305712,17.2821549 60.1414344,18.3691811 58.4563956,18.3691811 L53.6530129,18.3691811 Z"
                  id="Fill-25"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
                <path
                  d="M67.3724348,12.2270427 C67.3724348,11.4659407 66.8019098,11.0039546 66.0456325,11.0039546 L64.0685179,11.0039546 L64.0685179,13.4499471 L66.0456325,13.4499471 C66.8019098,13.4499471 67.3724348,12.987961 67.3724348,12.2270427 Z M67.1866824,18.3691076 L65.4483922,15.148609 L64.0685179,15.148609 L64.0685179,18.3691076 L62.1842795,18.3691076 L62.1842795,9.30547629 L66.324261,9.30547629 C68.1685161,9.30547629 69.2964773,10.5419685 69.2964773,12.2270427 C69.2964773,13.8304062 68.3144643,14.6866229 67.3591667,14.917616 L69.3495494,18.3691076 L67.1866824,18.3691076 Z"
                  id="Fill-26"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
                <path
                  d="M74.1122852,11.2350579 L72.7324109,15.1349478 L75.4923388,15.1349478 L74.1122852,11.2350579 Z M76.5537806,18.3692178 L76.0097917,16.8334261 L72.2147787,16.8334261 L71.6575218,18.3692178 L69.5079229,18.3692178 L72.9314312,9.30540284 L75.2931392,9.30540284 L78.7033795,18.3692178 L76.5537806,18.3692178 Z"
                  id="Fill-27"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
                <polygon
                  id="Fill-28"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                  points="85.3636397 18.3691443 81.1440501 12.4580724 81.1440501 18.3691443 79.2599909 18.3691443 79.2599909 9.30551301 81.1971222 9.30551301 85.2972996 14.9989961 85.2972996 9.30551301 87.1815381 9.30551301 87.1815381 18.3691443"
                />
                <path
                  d="M94.9297224,13.8441042 C94.9297224,12.240557 93.9744248,11.0040648 92.1565264,11.0040648 L90.5509165,11.0040648 L90.5509165,16.6707394 L92.1565264,16.6707394 C93.9080847,16.6707394 94.9297224,15.3795287 94.9297224,13.8441042 Z M88.6668573,18.3692178 L88.6668573,9.30540284 L92.1565264,9.30540284 C94.9297224,9.30540284 96.8537649,11.1127674 96.8537649,13.8441042 C96.8537649,16.575441 94.9297224,18.3692178 92.1565264,18.3692178 L88.6668573,18.3692178 Z"
                  id="Fill-29"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
                <path
                  d="M20.8424312,24.4097413 C20.4709266,23.5336937 20.1334885,22.7450489 19.8014294,21.9542006 C17.8476235,17.3011044 15.904934,12.6430505 13.9371429,7.99601375 C13.1450061,6.12511689 12.9709081,4.28929132 14.1436937,2.52434279 C15.5499248,0.408314195 18.0163425,-0.36417212 20.3371706,0.532624449 C22.5323111,1.38094553 23.8096272,3.59814835 23.4612519,6.21545758 C22.6637362,12.2064039 21.8032871,18.1883528 20.9663259,24.1736069 C20.9607677,24.2143704 20.927239,24.2509106 20.8424312,24.4097413"
                  id="Fill-30"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
                <path
                  d="M26.7128497,5.93799048 C25.9359532,5.87354011 25.2747037,5.81882157 24.5345632,5.75749273 C24.4424043,4.1087752 24.8761251,2.64330972 26.0949902,1.53002602 C27.9431898,-0.157986029 30.6010974,-0.328201103 32.4523451,1.03810997 C34.5908275,2.61631769 35.2891917,4.98812794 34.2625336,7.49838713 C31.9920882,13.0495652 29.6820179,18.5838504 27.3855743,24.1238277 C27.3540179,24.2000297 27.2882157,24.2613585 27.167907,24.4266159 C26.9403784,22.9062482 26.7246834,21.4953177 26.5184911,20.0832854 C26.0019348,16.5454927 25.492371,13.0069656 24.9743802,9.46954011 C24.709737,7.66254277 24.9324246,7.173381 26.469005,6.24867431 C26.5202841,6.21782627 26.5496889,6.14878542 26.7128497,5.93799048"
                  id="Fill-31"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
                <path
                  d="M20.705161,26.2758824 C18.606124,24.8188634 16.5070871,23.3618444 14.4078708,21.905009 C11.7146416,20.036132 9.01155102,18.1815772 6.33051405,16.2943383 C3.90228669,14.5850264 3.25573956,11.5127086 4.80648447,9.06506356 C5.86667116,7.39192467 7.45381349,6.60236176 9.35131996,6.67727843 C11.4926712,6.7617433 12.9568153,7.99658297 13.8176231,9.9692049 C15.1903255,13.1146032 16.5343403,16.2728548 17.8928782,19.4248634 C18.8628782,21.6753013 19.8364641,23.9240866 20.8084364,26.1737901 C20.7740113,26.2079433 20.7395861,26.2419128 20.705161,26.2758824"
                  id="Fill-32"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
                <path
                  d="M27.3586797,26.081503 C27.8227018,24.9805218 28.2847518,23.8786225 28.7516427,22.7789266 C30.5180826,18.6171956 32.2852397,14.4556482 34.0543691,10.295019 C34.7624153,8.63014293 35.8281603,7.35362169 37.6186261,6.86445993 C39.7671492,6.2776127 42.1638201,7.29963762 43.312042,9.29814988 C44.4704837,11.3148404 44.2490512,13.8878974 42.566164,15.4631672 C41.3921233,16.5623123 40.0131455,17.4389107 38.694053,18.3674734 C35.0419403,20.9385106 31.3731529,23.4847592 27.710103,26.0396378 C27.6385632,26.0897659 27.5573414,26.1252044 27.480602,26.1676205 C27.4400808,26.1387922 27.3993802,26.1101476 27.3586797,26.081503"
                  id="Fill-33"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
                <path
                  d="M21.344339,28.0914548 L20.8397956,28.0914548 C15.6719009,28.0914548 10.5040063,28.0980651 5.33593235,28.0888841 C1.98306728,28.0831919 -0.479943808,25.1713573 0.079464695,21.8992617 C0.704675416,18.242851 4.47763475,16.4037203 7.68078632,18.2994057 C9.46910055,19.3577873 11.1284998,20.6458765 12.8357715,21.8456449 C15.5718529,23.7681388 18.2996865,25.7027515 21.029851,27.6340591 C21.1535664,27.7214619 21.2667031,27.8250232 21.3848603,27.9208724 L21.344339,28.0914548 Z"
                  id="Fill-34"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
                <path
                  d="M26.6946331,27.9449633 C27.1599104,27.6113271 27.6232153,27.2745693 28.0908235,26.9440546 C31.9234889,24.2354866 35.7624298,21.5362831 39.5881026,18.8172488 C42.4717458,16.767874 45.8122394,17.4399206 47.3848586,20.4101461 C49.1998882,23.8386853 46.7890527,28.0762511 43.0047976,28.0839631 C37.591086,28.0951639 32.1775536,28.0867174 26.763842,28.0867174 C26.7407126,28.0395272 26.7175832,27.9923371 26.6946331,27.9449633"
                  id="Fill-35"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    /* eslint-enable max-len */
  );
}

export default nbcBrandStudio;
