import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BTE from 'lib/BTE';
import {
  package as packagePropType,
  layoutContext as LayoutContextPropType,
} from 'lib/CustomPropTypes';
import {
  buildQueryParams,
  filterItems,
  getInitialFilters,
  getPackageItemsTaxonomy,
  getUpdatedCategoryValues,
  applyUrlFilters,
  getNewActiveFilters,
} from 'lib/waffleUtils';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { Header } from 'components/packages/Waffle/Header';
import { List } from 'components/packages/Waffle/List';
import { Pagination } from 'components/packages/Waffle/Pagination';

import { ProductWaffleCard } from './Card';

import styles from './styles.module.scss';

const mapStateToProps = ({ shared, navbar, front }) => ({
  vertical: shared.vertical,
  navTheme: navbar.theme,
  draftId: front.draftId,
});

@connect(mapStateToProps)
class ProductWaffle extends React.Component {
  height = null;

  static propTypes = {
    content: packagePropType.isRequired,
    pkgClassName: PropTypes.string,
    draftId: PropTypes.number,
  };

  static defaultProps = {
    pkgClassName: 'pkg product-waffle',
    draftId: undefined,
  };

  static contextTypes = {
    ...LayoutContextPropType,
  };

  constructor(props) {
    super(props);
    const {
      content: {
        metadata: {
          customFilters = [],
          priceFilter,
        } = {},
        pagination,
      } = {},
    } = this.props;

    const taxonomyFilters = customFilters.map((customFilterConfig) => ({
      ...customFilterConfig,
      type: 'taxonomy',
    }));

    const pxFilter = priceFilter?.length ? { type: 'price', ...priceFilter[0] } : null;

    const {
      categories, displayNameByKey, filterValues, categoryToDisplay,
    } = getInitialFilters(taxonomyFilters, pxFilter);

    // if this is a waffle with paged results, we need to initialize
    // the filter state using the query params
    let finalFilters = filterValues;
    const activeFilters = new Map();
    if (pagination?.urlFilter) {
      finalFilters = applyUrlFilters(filterValues, pagination.urlFilter);
      Object.entries(finalFilters).forEach(([key, value]) => {
        Object.entries(value).forEach(([k, v]) => {
          if (v) {
            activeFilters.set(k, {
              key: k,
              category: key,
              displayName: displayNameByKey.get(k),
            });
          }
        });
      });
    }

    this.state = {
      activeFilters,
      categories,
      displayNameByKey,
      filterValues: finalFilters,
      categoryToDisplay,
      idToTaxonomy: {},
    };

    this.scrollPosition = 0;
  }

  componentDidMount() {
    const { pageRoute, vertical } = this.context;
    const { draftId } = this.props;
    BTE.on('resize', this.handleResize);
    this.setHeight();

    getPackageItemsTaxonomy(vertical, pageRoute, draftId)
      .then((idToTaxonomy) => this.setState((prevState) => ({
        ...prevState,
        idToTaxonomy,
      })))
      .catch(console.error);
  }

  componentDidUpdate(prevProps) {
    const {
      content: {
        metadata: {
          customFilters,
        } = {},
      } = {},
    } = this.props;
    const {
      content: {
        metadata: {
          customFilters: prevFilters,
        } = {},
      } = {},
    } = prevProps;
    if ((!prevFilters && !customFilters) || customFilters === prevFilters) {
      return;
    }
    this.clearAllFilters();
  }

  componentWillUnmount() {
    BTE.remove('resize', this.handleResize);
  }

  setHeight() {
    const box = this.container.getBoundingClientRect();
    this.height = box.top + window.pageYOffset;
  }

  handleResize = () => {
    this.setHeight();
  };

  isBottom = (el) => el.getBoundingClientRect().bottom <= window.innerHeight;

  handleFilterChange = (category) => (key, value) => {
    const {
      activeFilters, categories, displayNameByKey, filterValues,
    } = this.state;
    const {
      content: {
        metadata: { multiSelect: enableMultiselectFilters = true } = {},
        context,
        pagination,
      },
    } = this.props;

    const newCategoryValues = getUpdatedCategoryValues(
      key,
      value,
      filterValues,
      category,
      enableMultiselectFilters,
    );

    // update filter values
    const newFilterValues = {
      ...filterValues,
      [category]: newCategoryValues,
    };

    const newActiveFilters = getNewActiveFilters({
      activeFilters,
      hasMultiSelect: enableMultiselectFilters,
      key,
      value,
      category,
      displayNameByKey,
      newCategoryValues,
    });

    if (pagination) {
      // for paged waffles we go back to page one when changing filters
      // because we don't know how many results there will be
      const queryParams = buildQueryParams(1, newFilterValues);
      const url = `${context.vertical === 'select' ? '/select' : ''}${context.pageRoute}?${queryParams}`;
      window.location.href = url;
    } else {
    // get the updated waffles
      this.setState({
        activeFilters: newActiveFilters,
        categories: { ...categories },
        filterValues: newFilterValues,
      });
    }
  };

  clearAllFilters = () => {
    const {
      content: {
        metadata: {
          customFilters = [],
          priceFilter,
        },
        context,
        pagination,
      },
    } = this.props;

    const taxonomyFilters = customFilters.map((f) => ({
      ...f,
      type: 'taxonomy',
    }));

    const pxFilter = priceFilter?.length ? { type: 'price', ...priceFilter[0] } : null;

    const {
      categories, displayNameByKey, filterValues, categoryToDisplay,
    } = getInitialFilters(taxonomyFilters, pxFilter);

    if (pagination) {
      const queryParams = buildQueryParams(1);
      const url = `${context.vertical === 'select' ? '/select' : ''}${context.pageRoute}?${queryParams}`;
      window.location.href = url;
    } else {
      this.setState({
        activeFilters: new Map(),
        filterValues,
        categories,
        displayNameByKey,
        categoryToDisplay,
      });
    }
  };

  render() {
    const {
      categories,
      filterValues,
      categoryToDisplay,
      activeFilters,
      displayNameByKey,
      idToTaxonomy,
    } = this.state;
    const {
      content,
      pkgClassName,
    } = this.props;
    const {
      metadata = {},
      pagination,
      context,
    } = content || {};

    const {
      multiSelect: enableMultiselectFilters = true,
    } = metadata || {};

    const waffleCards = (content.items || []).filter((item) => item);
    const taxonomyFilterStates = Object.entries(filterValues).map(([key, filterValue]) => ({
      type: key === 'priceFilter' ? 'price' : 'taxonomy',
      values: filterValue,
    }));
    const filteredCards = filterItems(
      waffleCards,
      taxonomyFilterStates,
      idToTaxonomy,
    );

    const items = activeFilters.size > 0 ? filteredCards : waffleCards;
    const countWithoutAds = items.filter(
      ({ isCustomAd }) => !isCustomAd,
    ).length;

    const generatePageUrl = (page) => `${context.vertical === 'select' ? '/select' : ''}${
      context.pageRoute
    }?page=${page}${
      pagination.urlFilter
        ? `&filters=${encodeURIComponent(pagination.urlFilter)}`
        : ''
    }`;

    const navigatePageCallback = (page) => {
      const pageUrl = generatePageUrl(page);
      window.location.href = pageUrl;
    };

    return (
      <div
        className={classNames(pkgClassName, styles.productWaffle)}
        ref={(ref) => { this.container = ref; }}
        data-packageid={content.id}
        data-testid="product-waffle"
        data-ready={Object.keys(idToTaxonomy || {}).length > 0}
      >
        <Header
          activeFilters={Array.from(activeFilters.values())}
          cardCount={pagination?.totalCount || countWithoutAds}
          categories={categories}
          categoryToDisplay={categoryToDisplay}
          clearFilter={this.clearAllFilters}
          content={content}
          enableMultiselectFilters={enableMultiselectFilters}
          filterDisplayNameByKey={displayNameByKey}
          filterOnChange={this.handleFilterChange}
          filterValues={filterValues}
          isFilterActive={activeFilters.size > 0}
          loading={false}
          showDisclaimer
        />
        {items.length > 0 && (
          <List
            adsDisabled
            cards={items}
            cardRenderer={(card) => (card && (
              <ErrorBoundary key={card.id}>
                <ProductWaffleCard card={card} />
              </ErrorBoundary>
            ))}
            mobileDisplayColumns
            isProductWaffle
            listClasses={styles.productWaffleList}
          />
        )}
        {pagination ? (
          <Pagination
            pagination={pagination}
            navigatePageCallback={navigatePageCallback}
            generatePageUrl={generatePageUrl}
            usePageLink
          />
        ) : null}
      </div>
    );
  }
}

export { ProductWaffle };
