import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { children as childrenPropType } from 'lib/CustomPropTypes';

import './styles.themed.scss';
/**
 * Takes packages that were combined in the same zone and sets them in a grid pattern,
 * according to maximum columns per row setting, or however many columns available if less than the max.
 * Defaults to 3 column layout on full width. Uses CSS Grid for layout.
 *
 * Use PackageStaticColGrid for a layout collected in a more "boxed in" design with equal column widths (default: 3 columns)
 * Use PackageFlexColGrid for a configuration with custom column span settings (default: 4 columns)
 */
function PackageStaticColGrid(props) {
  const {
    children,
    maxPerRow,
    zoneType,
  } = props;

  const columns = Array.isArray(children) ? children : [children];

  const numberOfColumns = Math.min(columns.length, maxPerRow);

  return (
    <div
      className={classNames(
        'package-grid',
        `package-grid--columns-${numberOfColumns}`,
        `package-grid--zone-type-${zoneType}`,
      )}
      data-test="package-grid"
    >
      {
        /* eslint-disable react/no-array-index-key */
        // There is no unique identifier for columns, index must be used
        columns.map((col, i) => (
          <div
            className="package-grid__column"
            key={`column-${i}`}
            data-test="package-grid__column"
          >
            {col}
          </div>
        ))
        /* eslint-enable react/no-array-index-key */
      }
    </div>
  );
}

PackageStaticColGrid.propTypes = {
  children: childrenPropType,
  maxPerRow: PropTypes.number,
  zoneType: PropTypes.oneOf(['normal', 'rail', 'railAdjacent']),
};

PackageStaticColGrid.defaultProps = {
  children: null,
  maxPerRow: 3,
  zoneType: 'normal',
};

export { PackageStaticColGrid };
