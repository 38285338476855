import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { VerticalContext } from 'lib/ContextTypes';
import styles from './styles.module.scss';

function Background({ title }) {
  const vertical = useContext(VerticalContext);
  return (
    <>
      {vertical === 'today' && (
        <div className={styles.bigHeadline}>
          <div className={styles.bigHeadlineSolid}>
            {title}
          </div>
          <div className={styles.bigHeadlineDots}>
            {title}
          </div>
        </div>
      )}
      <div className={styles.background} />
    </>
  );
}

Background.propTypes = {
  title: PropTypes.string.isRequired,
};

export { Background };
