import React from 'react';
import PropTypes from 'prop-types';

import {
  content as ContentPropType,
  playmakerMetadata as PlaymakerMetadataPropType,
} from 'lib/CustomPropTypes';

import { LiveBlogMetaContent } from './LiveBlogMetaContent';
import { StandardMetaContent } from './StandardMetaContent';
import { MAIN_ART_TEASE_VALID_SIZES } from '../constants';

import './styles.themed.scss';

const block = 'smorgasbord-meta-content';

/**
 * The dek of a main story in smorgasbord component - reused across tease sizes
 * @param {object} props The React props passed to the component
 * @param {Tease} props.item The item being rendered, used to get the dek/byline/etc
 * @param {JSON} props.packageMetadata the metadata from the package. Used to render live blog.
 * @param {boolean} props.showByline Will turn on/off the byline in the tease
 * @param {boolean} props.showEyebrow Will turn on/off the eyebrow in the tease
 * @param {boolean} props.showDek Will turn on/off the dek in the tease
 * @param {boolean} props.isRelatedContent Determines whether to apply related content styles
 * @param {string} props.size Determines the styles applied to the component (using --<size> modifier in BEM)
 * @returns {JSX.Element} Component
 */
export const MetaContent = ({
  item,
  packageMetadata,
  showByline,
  showEyebrow,
  showHeadline,
  isRelatedContent,
  showDek,
  size,
}) => {
  if (!item) {
    return null;
  }

  const { type } = item || {};

  const isLiveBlog = type === 'liveBlog';

  return (
    <div className={`${block}__content-wrapper`}>
      {(isLiveBlog && !isRelatedContent)
        ? (
          <LiveBlogMetaContent
            item={item}
            packageMetadata={packageMetadata}
            size={size}
          />
        )
        : (
          <StandardMetaContent
            item={item}
            isRelatedContent={isRelatedContent}
            showByline={showByline}
            showEyebrow={showEyebrow}
            showDek={showDek}
            showHeadline={showHeadline}
            size={size}
          />
        )}
    </div>
  );
};

MetaContent.propTypes = {
  item: ContentPropType.isRequired,
  packageMetadata: PlaymakerMetadataPropType,
  showByline: PropTypes.bool,
  showEyebrow: PropTypes.bool,
  showDek: PropTypes.bool,
  showHeadline: PropTypes.bool,
  isRelatedContent: PropTypes.bool,
  size: PropTypes.oneOf([null, ...MAIN_ART_TEASE_VALID_SIZES]),
};

MetaContent.defaultProps = {
  packageMetadata: null,
  showByline: true,
  showEyebrow: true,
  showDek: false,
  showHeadline: true,
  isRelatedContent: false,
  size: null,
};
