import { TEASE_SIZES, SMORGASBORD_COMPONENT_TYPES } from './constants';

const { VISUAL, NO_VISUAL, PLAYMAKER } = SMORGASBORD_COMPONENT_TYPES;

/**
 * @typedef {object} SmorgasbordSlot
 * @property {number} maxRelatedItems maximum number of related items
 * @property {string} size the size of the slot (S/M/M1/M2/L/XL)
 * @property {string} componentType the variant of the slot (Visual / No Visual)
 * @property {number} column slot column
 * @property {number} [columnPosition = 0] position to be sorted on within column
 */

/**
 * @param {...SmorgasbordSlot}
 * @returns {SmorgasbordSlot}
 */
const getSlot = (maxRelatedItems, size, componentType, column, columnPosition = 0) => ({
  maxRelatedItems, size, componentType, column, columnPosition,
});

/**
 * @typedef {object} SmorgasbordPackageConfig
 * @property {string} [playmakerPosition = 'top'] position of playmaker in column (top/bottom)
 */

/**
 * @param {SmorgasbordLayoutConfig} layoutConfig
 * @param {SmorgasbordPackageConfig} packageConfig
 */
const applyPackageConfig = (layoutConfig, packageConfig = {}) => {
  const { playmakerPosition = 'top' } = packageConfig;

  // update playmaker column position if applicable (supported for 'threeKeyVisuals' only for now)
  if (playmakerPosition === 'bottom') {
    const playmakerSlot = layoutConfig.threeKeyVisuals.playmakerEnabledLayoutConfig[7];
    playmakerSlot.columnPosition = 4;
  }
};

/**
 * @typedef {object} SmorgasbordLayoutConfig
 * @property {object} threeKeyVisuals
 * @property {number} threeKeyVisuals.maxSlots
 * @property {boolean} threeKeyVisuals.playmakerToggleDefault
 * @property {SmorgasbordSlot[]} threeKeyVisuals.playmakerEnabledLayoutConfig
 * @property {SmorgasbordSlot[]} threeKeyVisuals.playmakerDisabledLayoutConfig
 * @property {object} threeKeyVisualsWithoutRail
 * @property {number} threeKeyVisualsWithoutRail.maxSlots
 * @property {boolean} threeKeyVisualsWithoutRail.playmakerToggleDefault
 * @property {SmorgasbordSlot[]} threeKeyVisualsWithoutRail.liveVideoLayoutConfig
 * @property {SmorgasbordSlot[]} threeKeyVisualsWithoutRail.liveVideoDisabledLayoutConfig
 */

/**
 * @param {SmorgasbordPackageConfig} packageConfig
 * @returns {SmorgasbordLayoutConfig}
 */
export const layouts = (packageConfig = {}) => {
  const threeKeyVisuals = {
    maxSlots: 8,
    playmakerToggleDefault: true,
    playmakerEnabledLayoutConfig: [
      getSlot(4, TEASE_SIZES.L, VISUAL, 1, 0),
      getSlot(4, TEASE_SIZES.M2, VISUAL, 0, 0),
      getSlot(4, TEASE_SIZES.M2, VISUAL, 0, 1),
      getSlot(0, TEASE_SIZES.M, NO_VISUAL, 2, 0),
      getSlot(0, TEASE_SIZES.S, NO_VISUAL, 2, 1),
      getSlot(0, TEASE_SIZES.S, NO_VISUAL, 2, 2),
      getSlot(0, TEASE_SIZES.S, NO_VISUAL, 2, 3),
      getSlot(0, TEASE_SIZES.M, PLAYMAKER, 2, -1),
    ],
    playmakerDisabledLayoutConfig: [
      getSlot(4, TEASE_SIZES.L, VISUAL, 1),
      getSlot(4, TEASE_SIZES.M2, VISUAL, 0),
      getSlot(4, TEASE_SIZES.M2, VISUAL, 0),
      getSlot(0, TEASE_SIZES.M, NO_VISUAL, 2),
      getSlot(0, TEASE_SIZES.S, NO_VISUAL, 2),
      getSlot(0, TEASE_SIZES.S, NO_VISUAL, 2),
      getSlot(0, TEASE_SIZES.S, NO_VISUAL, 2),
      getSlot(0, TEASE_SIZES.M, VISUAL, 2),
    ],
  };

  const threeKeyVisualsWithoutRail = {
    maxSlots: 7,
    playmakerToggleDefault: false,
    liveVideoLayoutConfig: [
      getSlot(4, TEASE_SIZES.L, PLAYMAKER, 1),
      getSlot(4, TEASE_SIZES.M2, VISUAL, 0),
      getSlot(4, TEASE_SIZES.M2, VISUAL, 0),
      getSlot(0, TEASE_SIZES.M, VISUAL, 2),
      getSlot(0, TEASE_SIZES.S, NO_VISUAL, 2),
      getSlot(0, TEASE_SIZES.S, NO_VISUAL, 2),
      getSlot(0, TEASE_SIZES.S, NO_VISUAL, 2),
    ],
    liveVideoDisabledLayoutConfig: [
      getSlot(4, TEASE_SIZES.L, VISUAL, 1),
      getSlot(4, TEASE_SIZES.M2, VISUAL, 0),
      getSlot(4, TEASE_SIZES.M2, VISUAL, 0),
      getSlot(0, TEASE_SIZES.M, VISUAL, 2),
      getSlot(0, TEASE_SIZES.S, NO_VISUAL, 2),
      getSlot(0, TEASE_SIZES.S, NO_VISUAL, 2),
      getSlot(0, TEASE_SIZES.S, NO_VISUAL, 2),
    ],
  };

  const layoutConfig = {
    threeKeyVisuals,
    threeKeyVisualsWithoutRail,
  };

  // apply package config modifications/overrides to default layouts
  applyPackageConfig(layoutConfig, packageConfig);

  return layoutConfig;
};
