import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.themed.scss';

import {
  railClipPackages,
  doesTypeArrayHavePkgTypes,
} from 'lib/packageHelper';

const Rail = ({ children, className, packageTypes }) => {
  // Wrap children in div with rail__content class.
  let wrappedChildren = (
    <div className="rail__content" data-testid="rail-content">
      {children}
    </div>
  );
  // Conditionally wrap children in div with rail__wrapper (clipping) class.
  const hasClipPackage = doesTypeArrayHavePkgTypes(packageTypes, railClipPackages);
  if (hasClipPackage) {
    wrappedChildren = (
      <div className="rail__wrapper" data-testid="rail-wrapper">
        {wrappedChildren}
      </div>
    );
  }
  // Return wrapped in rail__main class.
  return (
    <div className={classNames('rail rail__main', className)} data-testid="rail">
      {wrappedChildren}
    </div>
  );
};

Rail.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  packageTypes: PropTypes.arrayOf(
    PropTypes.string,
  ),
};

Rail.defaultProps = {
  className: null,
  packageTypes: [],
};

export { Rail };
