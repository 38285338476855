import React from 'react';
import PropTypes from 'prop-types';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import { isValidUrl } from 'lib/urlUtils';
import Link from 'components/Link';
import DataVizLoader from 'components/DataVizLoader';

// TODO: Move this component to a separate file. Only one component per file.
// eslint-disable-next-line react/prop-types
const ConditionalLink = ({ to, children }) => {
  if (typeof to !== 'undefined' && isValidUrl(to)) {
    return (
      <Link to={to}>
        {children}
        <span className="icon icon-arrow-link" />
      </Link>
    );
  }
  return children;
};

function FeaturedEmbed(props) {
  const {
    block,
    content: {
      computedValues: {
        url: embedUrl,
      } = {},
      id,
      metadata: {
        embedHeadline,
        embedHeadlineUrl,
        label,
        title: packageTitle,
      } = {},
    },
  } = props;

  const hasTitle = packageTitle && packageTitle.length > 0;
  const hasHeadline = embedHeadline && embedHeadline.length > 0;
  const hasLabel = label && label.length > 0;

  return (
    <div
      className={`${block}__embed ${
        hasTitle ? `${block}__embed--package-title` : ''
      }`}
      data-contentid={id}
    >
      <div className={`${block}__embed-element`}>
        <DataVizLoader data={{ id, computedValues: { url: embedUrl } }} />
      </div>
      {(hasHeadline || hasLabel) && (
        <h2 className={`${block}__headline`}>
          <ConditionalLink to={embedHeadlineUrl}>
            {/* TODO: BENTO-15783 extract this flag to sub component */}
            {label && (
              <span className={`${block}__flag`}>
                {label}
                {hasHeadline && (
                  <>
                    &nbsp;
                    {'/ '}
                  </>
                )}
              </span>
            )}
            {hasHeadline && (
              <span className={`${block}__headline-text`}>{embedHeadline}</span>
            )}
          </ConditionalLink>
        </h2>
      )}
    </div>
  );
}

FeaturedEmbed.propTypes = {
  block: PropTypes.string,
  content: ContentPropType.isRequired,
};

FeaturedEmbed.defaultProps = {
  block: null,
};

export { FeaturedEmbed };
