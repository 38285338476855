import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { content as ContentPropType } from 'lib/CustomPropTypes';
import { getFormattedDateString, getFormattedTimeString } from 'lib/DateTime';
import aimsFlavors from 'lib/aimsFlavors';

import TeasePicture from 'components/TeasePicture';
import Link from 'components/Link';

import styles from './styles.module.scss';

const aspectRatiosByType = {
  primary: {
    s: aimsFlavors.FOCAL_120x120,
    m: aimsFlavors.FOCAL_380x190,
    l: aimsFlavors.FOCAL_320x320,
    xl: aimsFlavors.FOCAL_440x440,
  },
  secondary: {
    s: aimsFlavors.FOCAL_120x120,
    m: aimsFlavors.FOCAL_200x100,
    l: aimsFlavors.FOCAL_200x100,
    xl: aimsFlavors.FOCAL_260x130,
  },
  latest: {
    s: aimsFlavors.FOCAL_60x60,
    m: aimsFlavors.FOCAL_60x60,
    l: aimsFlavors.FOCAL_60x60,
    xl: aimsFlavors.FOCAL_60x60,
  },
};

function ImageCard(props) {
  const {
    card: {
      computedValues = {},
      item = {},
    } = {},
    packageMetadata,
    type,
  } = props;

  if (!item || !Object.keys(item).length) {
    return null;
  }

  const aspectRatios = aspectRatiosByType[type];

  const isLatest = type === 'latest';

  const {
    showAuthor,
    showTimestamp,
  } = packageMetadata;

  // Get byline content options.
  const bylineAuthors = item?.authors?.[0]?.person?.name;

  const { dateModified } = item;

  const shouldShowByline = (showAuthor || showTimestamp) && !isLatest;
  const shouldShowAuthor = showAuthor && bylineAuthors;
  const shouldShowDate = showTimestamp && dateModified;

  // Get eyebrow content.
  const eyebrowText = computedValues?.unibrow?.text;
  const eyebrowURL = computedValues?.unibrow?.url?.primary;
  const shouldShowEyebrow = eyebrowText && eyebrowURL;

  // Get tease image content.
  const teaseImageAltText = computedValues?.teaseImage?.altText;
  const teaseImageURL = computedValues?.teaseImage?.url?.primary;
  const shouldShowTeaseImage = !!teaseImageURL;

  // Get headline content.
  const headline = computedValues?.headline;
  const url = computedValues?.url;
  const shouldShowHeadline = headline && url;

  return (
    <article
      data-testid="image-card"
      className={classNames(
        styles.imageCard,
        [styles[type]],
      )}
      data-contentid={item?.id}
    >
      <div className={styles.tease}>
        {shouldShowTeaseImage && (
          <Link to={url} className={styles.imageWrapper}>
            <TeasePicture
              alt={teaseImageAltText}
              className={styles.image}
              computedValues={computedValues}
              responsiveFlavors={aspectRatios}
              url={teaseImageURL}
            />
          </Link>
        )}
      </div>
      <div className={styles.info}>
        {isLatest && (
          <span data-testid="ellipse" className={styles.ellipse} />
        )}
        {shouldShowEyebrow && (
          <p className={styles.eyebrow}>
            <Link href={eyebrowURL}>{eyebrowText}</Link>
          </p>
        )}
        {shouldShowHeadline && (
          <p className={styles.headline}>
            <Link href={url}>{headline}</Link>
          </p>
        )}
        {shouldShowByline && (
          <p data-testid="imgcard-byline" className={styles.byline}>
            {shouldShowAuthor ? bylineAuthors : null}

            {(shouldShowAuthor && shouldShowDate) ? ' / ' : null}

            {shouldShowDate ? (
              <>
                {getFormattedDateString(dateModified)}
                {', '}
                {getFormattedTimeString(dateModified)}
              </>
            ) : null}
          </p>
        )}
      </div>
    </article>
  );
}

ImageCard.propTypes = {
  card: ContentPropType,
  packageMetadata: ContentPropType,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'latest',
  ]),
};

ImageCard.defaultProps = {
  card: undefined,
  packageMetadata: {},
  type: 'primary',
};

export { ImageCard };
