import React, { useContext } from 'react';

import { VerticalContext } from 'lib/ContextTypes';

import Ad from 'components/Ad';
import NBCBrandStudioIcon from 'components/logos/nbcBrandStudio';

import styles from './styles.module.scss';

function PaidContentHeader() {
  const vertical = useContext(VerticalContext);

  if (vertical !== 'sponsoredcontent') {
    return null;
  }

  return (
    <div className={styles.paidContentHeader}>
      <div className={styles.header}>
        <h3 className={styles.headline}>paid content</h3>
      </div>
      <div className={styles.icons}>
        <div>
          <NBCBrandStudioIcon />
        </div>
        <div className={styles.line} />
        <div>
          <Ad
            slot="sponsorlogo"
            refreshInterval={0}
          />
        </div>
      </div>
    </div>
  );
}

export { PaidContentHeader };
