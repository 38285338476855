import React from 'react';
import { content as ContentPropType } from 'lib/CustomPropTypes';

import CardList from 'components/packages/LiveBlog/CardList';

const ShowBlog = (props) => {
  const {
    content,
    content: {
      items,
      context: {
        isRailAdjacent,
      },
      id,
    },
  } = props;

  return (
    <div
      className="live-blog__content-wrapper"
      data-testid="cover-spread__show-blog"
    >
      <div className="live-blog__content">
        <CardList
          id={id}
          content={content}
          cards={items}
          isEmbedded={false}
          isWebEmbed={false}
          defaultBreakingStyle={false} // ShowBlog's are not breaking news
          defaultLabel="Blog"
          isRailAdjacent={isRailAdjacent}
        />
      </div>
    </div>
  );
};

ShowBlog.propTypes = {
  content: ContentPropType.isRequired,
};

export { ShowBlog };
