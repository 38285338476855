import { getPlaymakerMetadata } from 'lib/playmaker';

const isTeasePlaymakerOverride = (
  teaseIndex,
  layoutType,
  metadata,
  packageSize,
) => {
  if (teaseIndex === 0) {
    const {
      playmakerSlot,
      playmakerToggle,
    } = getPlaymakerMetadata(metadata);

    if (playmakerToggle) {
      return (
        (layoutType === 'feature' && playmakerSlot === 0)
        || (layoutType === 'default'
          && playmakerSlot === 1
          && (packageSize === 5 || packageSize === 6))
        || (layoutType === 'blog' && playmakerSlot === 2 && packageSize === 4)
      );
    }
  }
  return false;
};

export {
  isTeasePlaymakerOverride,
};
