import React from 'react';
import classNames from 'classnames';
import { smallWords } from './smallWords';

const seedrandom = require('seedrandom');
// The current date is used to seed the random values so the grid is
// consistent for all users (even when refreshing) but still changes the next day.
const currentDate = new Date();
const dateSeed = currentDate.toISOString().slice(0, 10);

export function getRandomLetter(seed) {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const rng = seedrandom(`${dateSeed + seed.toString()}`);
  return alphabet[Math.floor(rng() * alphabet.length)];
}

/**
 * Calculates all the selected cells based on the first and last selected grid cell.
 * @param {Object} start - the starting row and col grid cell that the user selects.
 * @param {Object} end - the ending row and col grid cell that the user selects.
 * @returns {Object} - an object of grid cells that the user has selected.
 */
export function getSelectedRange(start, end) {
  const selectedRange = [];
  const rowDiff = end.row - start.row;
  const colDiff = end.col - start.col;
  const currentRow = start.row;
  const currentCol = start.col;
  // horizontally
  if (rowDiff === 0) {
    for (let i = 0; i <= Math.abs(colDiff); i += 1) {
      selectedRange.push({ row: currentRow, col: currentCol + (colDiff >= 0 ? i : -i) });
    }
  // vertically
  } else if (colDiff === 0) {
    for (let i = 0; i <= Math.abs(rowDiff); i += 1) {
      selectedRange.push({ row: currentRow + (rowDiff >= 0 ? i : -i), col: currentCol });
    }
  }
  return selectedRange;
}

/**
 * Generates a word search grid of cells based on the number of row, columns and words provided.
 * The function also checks if a word can be placed without interfering with other words
 * and makes sure that it can fit in the grid. Words that are longer than the amount of columns
 * in the grid are automatically placed vertically.
 * @param {number} rows - number of rows in the grid.
 * @param {number} cols - number of columns in the grid.
 * @param {Array} words - filtered words from curator article headline.
 * @returns {Array} - An array of grid cells.
 */
export function generateGrid(rows, cols, words) {
  const grid = Array.from({ length: rows }, () => Array(cols).fill(''));

  const canPlaceWord = (word, startRow, startCol, direction) => {
    const wordLength = word.length;
    if (
      (direction === 'horizontal' && startCol + wordLength > cols)
      || (direction === 'vertical' && startRow + wordLength > rows)
    ) {
      return false;
    }
    for (let i = 0; i < wordLength; i += 1) {
      const cellValue = direction === 'horizontal' ? grid[startRow][startCol + i] : grid[startRow + i][startCol];
      if (cellValue !== '' && cellValue !== word[i]) {
        return false;
      }
    }
    return true;
  };

  const placeWord = (word, startRow, startCol, direction, wordIndex) => {
    const wordLength = word.length;
    for (let i = 0; i < wordLength; i += 1) {
      if (direction === 'horizontal') {
        grid[startRow][startCol + i] = (
          <span className={classNames(
            'horizontal', `color-${wordIndex}`,
            { first: i === 0, last: i === wordLength - 1 },
          )}
          >
            {word[i]}
          </span>
        );
      } else {
        grid[startRow + i][startCol] = (
          <span className={classNames(
            `vertical color-${wordIndex}`,
            { first: i === 0, last: i === wordLength - 1 },
          )}
          >
            {word[i]}
          </span>
        );
      }
    }
  };

  for (let index = words.length - 1; index >= 0; index -= 1) {
    const word = words[index];
    let placed = false;
    let direction;
    let attempts = 0;
    let rowSeed = 0;
    let colSeed = 1;
    const maxAttempts = 80;
    while (!placed && attempts < maxAttempts) {
      attempts += 1;
      rowSeed += 1;
      colSeed += 1;
      if (word.length > cols) {
        direction = 'vertical';
      } else {
        direction = index % 2 === 0 ? 'horizontal' : 'vertical';
      }
      const rowRng = seedrandom(`${dateSeed + rowSeed.toString()}`);
      const colRng = seedrandom(`${dateSeed + colSeed.toString()}`);
      const startRow = Math.floor(rowRng() * rows);
      const startCol = Math.floor(colRng() * cols);

      if (canPlaceWord(word, startRow, startCol, direction)) {
        placeWord(word, startRow, startCol, direction, index);
        placed = true;
      }
    }
    if (!placed) {
      words.splice(index, 1);
    }
  }

  // fill grid with random letters
  let seed = 0;
  for (let row = 0; row < rows; row += 1) {
    for (let col = 0; col < cols; col += 1) {
      if (grid[row][col] === '') {
        grid[row][col] = getRandomLetter(seed);
        seed += 1;
      }
    }
  }
  return grid;
}

/**
 * Creates a list of random words based on an article headline provided from curator.
 * Filtered items include: words that are less than 3 or larger than 10 characters,
 * words that are in the small words list, as well as special characters,
 * numbers and duplicate words. The amount of words is, at maximum, 6 words. If not
 * enough words are available, 3 filler words will be left and the rest will be used
 * in the wordsearch grid.
 * @param {string} articleTitle - a string article headline from curator.
 * @returns {Array} - An array of string words.
 */
export function getWords(articleTitle) {
  const article = articleTitle.replace(/([.,!?()-])/g, ' $1 ');
  const regex = /^[a-zA-Z\s]+$/;
  const title = article && article.split(/\s/)?.filter((word) => regex.test(word));
  const filterLengthyWords = title.filter((word) => (word.length >= 3 && word.length <= 10));
  const filterSmallWords = filterLengthyWords.filter(
    (word) => (!smallWords.includes(word.toLowerCase())),
  );
  const filterDupes = filterSmallWords.filter(
    (item, index, wordsArray) => wordsArray.indexOf(item) === index,
  );
  let seed = 0;
  const rng = seedrandom(`${dateSeed + seed.toString()}`);
  const shuffledTitle = [...filterDupes].sort(() => 0.5 - rng());
  const uppercaseTitle = shuffledTitle.map((word) => word.toUpperCase());
  const articleTitleCount = article.replace(/([.,!?''()-])/g, '').match(/\S+/g).length;
  const filteredWordCount = articleTitleCount - uppercaseTitle.length;
  if (filteredWordCount > 2) {
    return uppercaseTitle.slice(0, 6);
  }
  const fillerWords = 3 - filteredWordCount;
  seed += 1;
  return uppercaseTitle.slice(0, fillerWords - 3);
}

/**
 * Gives the width of a word to dynamically place blanks in
 * the article title.
 * @param {string} word - a string word from the article title.
 * @returns {number} - a number denoting the width of the word
 */
export function calculateTextSize(word) {
  const tempDiv = document.createElement('div');
  tempDiv.textContent = word;
  tempDiv.style.fontFamily = 'Arial';
  tempDiv.style.fontSize = 20;
  tempDiv.style.visibility = 'hidden';
  tempDiv.style.position = 'absolute';
  tempDiv.style.whiteSpace = 'nowrap';
  document.body.appendChild(tempDiv);
  const { width } = tempDiv.getBoundingClientRect();
  document.body.removeChild(tempDiv);
  return width + 30;
}
