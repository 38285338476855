export const icid = {
  // Daily Curation - Grid (top section)
  pkgArticle: 'msd_topgrid',
  // Daily Curation - Columnists (bottom section)
  pkgColumnist: 'msd_botgrid',
  // Standard Daily in-line recirc module
  recircArticleBody: 'msd_recirc',
  // Columnist in-line recirc module
  recircColumnist: 'msd_columnrecirc',
  // Bottom Daily recirc module
  recircArticleBottom: 'msd_botrecric',
  // Daily Curation - Link to newsletter signup (will add via curator)
  // icid=msd_nlsignup
};

export function passTrackingMSNBCDaily(url, type) {
  const { [type]: icidCode } = icid;
  return { url, icid: icidCode };
}
