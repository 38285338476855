import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  package as packagePropType,
} from 'lib/CustomPropTypes';
import {
  getInitialFilters,
  getUpdatedCategoryValues,
  getNewActiveFilters,
  QUERY_LIMIT,
} from 'lib/waffleUtils';
import { loadAutomatedWaffle } from 'redux/modules/waffle';

import { Header } from 'components/packages/Waffle/Header';
import { Pagination } from 'components/packages/Waffle/Pagination';
import { List } from 'components/packages/Waffle/List';
import { ProductWaffleCard } from 'components/packages/Waffle/ProductWaffle/Card';
import { ErrorBoundary } from 'components/ErrorBoundary';

import styles from '../styles.module.scss';
import productStyles from '../ProductWaffle/styles.module.scss';

const mapStateToProps = ({
  shared, waffle,
}) => ({
  vertical: shared.vertical,
  waffle,
});
/**
 * A Waffle type that generates content via a custom API call rather than getting items from Curator
 * @param {object} content data fetched from Curator response
 * @param {string} vertical the current vertical value, used with the API call
 * @param {string} pkgClassName class name to apply to the package
 * @param {boolean} adsDisabled whether ads are enabled or disabled
 * @param {object} waffle holds the redux state for the Waffle API query
 * @returns {ReactElement}
 */
const AutomatedWaffleUnwrapped = ({
  content, vertical, pkgClassName, adsDisabled, waffle,
}) => {
  const {
    id,
    metadata,
  } = content;

  // fields from package metadata
  const {
    productContentType,
    showCommerceDisclaimer,
    multiSelect = true,
    mobileDisplay,
    customFilters,
  } = metadata;

  const dispatch = useDispatch();

  // set up initial values for drop down filter lists
  const {
    categories, displayNameByKey, filterValues: initialFilterValues, categoryToDisplay,
  } = getInitialFilters(customFilters);

  // set initial states for filter functionality
  const [filters, setFilters] = useState({
    // creates a list of active filter values
    // set to null initially to avoid duplicate API call on initial render
    active: null,
    // list of all filters, flagged if active or inactive
    values: initialFilterValues,
    // array of active filters used for display below drop down items
    display: [],
    // array of active filters grouped by category, for use with API call
    search: null,
  });

  const waffleContainerRef = useRef();
  const loadingPaginationRef = useRef(false);

  // trigger actions to update the filter values when a user selects or deselects a filter
  const handleFilterChange = (category) => (key, value) => {
    // update the active/inactive flags for the category
    const newCategoryValues = getUpdatedCategoryValues(
      key, value, filters.values, category, multiSelect,
    );

    // update whole filter list with new category filter values
    const filterValues = {
      ...filters.values,
      [category]: newCategoryValues,
    };

    // update Map of active filters
    const newFilters = getNewActiveFilters({
      activeFilters: filters.active,
      hasMultiSelect: multiSelect,
      key,
      value,
      category,
      displayNameByKey,
      newCategoryValues,
    });

    // update array of filters to display in the Header
    const displayFilters = newFilters ? Array.from(newFilters.values()) : [];

    // update array of filters grouped by category, to use with the API query
    const searchFilters = [];
    newFilters.forEach((f) => {
      // get index number from category name, which is appended with an incremental number
      const filterCategory = parseInt(f.category.match(/\d+/)[0], 10) - 1;
      // add taxonomy path name to the list of search filters, by category
      if (!searchFilters[filterCategory]) searchFilters[filterCategory] = [];
      searchFilters[filterCategory].push(f.key);
    });

    setFilters({
      values: filterValues,
      active: newFilters || new Map(),
      display: displayFilters,
      search: searchFilters,
    });
  };

  // reset filters to default state
  const clearAllFilters = () => {
    setFilters({
      values: initialFilterValues,
      active: new Map(), // give this a non-null empty value to trigger a new API call to reset data
      displayList: [],
      search: null,
    });
  };

  // update data from API call when user changes page
  const handlePagination = (page) => {
    loadingPaginationRef.current = true;
    dispatch(loadAutomatedWaffle({
      initialFilters: waffle.initialFilters,
      searchFilters: filters.search,
      page,
    }));
  };

  useEffect(() => {
    if (filters.active) {
      // update the Waffle data when user updates filters
      dispatch(loadAutomatedWaffle({
        initialFilters: waffle.initialFilters,
        searchFilters: filters.search,
      }));
    }
  }, [filters.active]);

  useEffect(() => {
    // after new data loads from pagination call,
    // scroll to the top of the Waffle
    if (!waffle.loading && loadingPaginationRef.current) {
      waffleContainerRef.current.scrollIntoView?.({ behavior: 'smooth' });
      loadingPaginationRef.current = false;
    }
  }, [waffle.loading]);


  const totalItems = waffle.pagination?.totalItems;

  const cardRenderer = productContentType ? (card) => (card && (
    <ErrorBoundary key={card.id}>
      <ProductWaffleCard card={card} />
    </ErrorBoundary>
  )) : null;

  return (
    <div
      className={classNames(pkgClassName, styles.waffle, 'automated-waffle')}
      data-packageid={id}
      data-testid="automated-waffle"
      ref={waffleContainerRef}
    >
      <Header
        animateBackground
        activeFilters={filters.display}
        cardCount={totalItems}
        categoryToDisplay={categoryToDisplay}
        categories={categories}
        clearFilter={clearAllFilters}
        content={content}
        enableMultiselectFilters={multiSelect}
        filterDisplayNameByKey={displayNameByKey}
        filterOnChange={handleFilterChange}
        filterValues={filters.values}
        isFilterActive={filters.active?.size > 0}
        showDisclaimer={showCommerceDisclaimer}
      />
      <List
        adsDisabled={adsDisabled}
        cards={waffle.items}
        metadata={metadata}
        cardRenderer={cardRenderer}
        mobileDisplayColumns={parseInt(mobileDisplay, 10) !== 1}
        vertical={vertical}
        insertGridAds
        listClasses={productContentType ? productStyles.productWaffleList : null}
      />
      {totalItems > QUERY_LIMIT ? (
        <Pagination pagination={waffle.pagination} navigatePageCallback={handlePagination} />
      ) : null}
    </div>
  );
};

AutomatedWaffleUnwrapped.propTypes = {
  content: packagePropType.isRequired,
  vertical: PropTypes.string,
  pkgClassName: PropTypes.string,
  adsDisabled: PropTypes.bool,
  waffle: PropTypes.shape({
    items: PropTypes.array,
    pagination: PropTypes.shape({
      page: PropTypes.number,
      totalPages: PropTypes.number,
      totalItems: PropTypes.number,
    }),
    initialFilters: PropTypes.array,
    loading: PropTypes.bool,
  }),
};

AutomatedWaffleUnwrapped.defaultProps = {
  vertical: 'today',
  pkgClassName: 'pkg waffle',
  adsDisabled: false,
  waffle: {},
};

const AutomatedWaffle = connect(mapStateToProps)(AutomatedWaffleUnwrapped);

export { AutomatedWaffle };
