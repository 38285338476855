import AIMS_FLAVORS from 'lib/aimsFlavors';

export const fiveSlotLayout = {
  tease0: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_380x190,
        m: AIMS_FLAVORS.FOCAL_200x100,
        l: AIMS_FLAVORS.FOCAL_200x100,
        xl: AIMS_FLAVORS.FOCAL_260x130,
      },
    },
    headlineSizes: 'f7 f5-m f6-l',
    headlineTextPositioning: '',
    mediaLabel: {
      eyebrowDisplay: 'db',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn',
    },
    grid: 'layout-subgrid-item grid-col-12',
  },
  tease1: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_90x90,
      },
      display: 'db dn-m',
    },
    headlineSizes: 'f6 f5-m f6-l',
    headlineTextPositioning: 'pl4-m',
    mediaLabel: {
      eyebrowDisplay: 'dn',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn di-m',
    },
    grid: 'layout-subgrid-item grid-col-12',
  },
  tease2: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_380x190,
        m: AIMS_FLAVORS.FOCAL_147x147,
        l: AIMS_FLAVORS.FOCAL_200x100,
        xl: AIMS_FLAVORS.FOCAL_260x130,
      },
    },
    headlineSizes: 'f7 f7 f6-xl',
    headlineTextPositioning: '',
    mediaLabel: {
      eyebrowDisplay: 'db dn-m db-l',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn',
    },
    grid: 'layout-subgrid-item grid-col-12 grid-col-6-m grid-col-12-xl',
  },
  tease3: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_90x90,
        m: AIMS_FLAVORS.FOCAL_147x147,
        l: AIMS_FLAVORS.FOCAL_200x100,
      },
      display: 'db dn-xl',
    },
    headlineSizes: 'f6 f7-m f6-xl',
    headlineTextPositioning: 'pl4-xl',
    mediaLabel: {
      eyebrowDisplay: 'dn db-l',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn di-xl',
    },
    grid: 'layout-subgrid-item grid-col-12 grid-col-6-m grid-col-12-xl',
  },
};

export const fiveSlotRailLayout = {
  tease0: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_380x190,
        m: AIMS_FLAVORS.FOCAL_200x100,
        l: AIMS_FLAVORS.FOCAL_200x100,
        xl: AIMS_FLAVORS.FOCAL_260x130,
      },
    },
    headlineSizes: 'f6 f5-m f7-l f5-xl',
    headlineTextPositioning: '',
    mediaLabel: {
      eyebrowDisplay: 'db',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn',
    },
    grid: 'layout-subgrid-item grid-col-12',
  },
  tease1: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_90x90,
      },
      display: 'db dn-m',
    },
    headlineSizes: 'f6 f5-m f6-l f5-xl',
    headlineTextPositioning: 'pl4-m',
    mediaLabel: {
      eyebrowDisplay: 'dn',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn di-m',
    },
    grid: 'layout-subgrid-item grid-col-12',
  },
  tease2: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_380x190,
        m: AIMS_FLAVORS.FOCAL_147x147,
        l: AIMS_FLAVORS.FOCAL_200x100,
      },
    },
    headlineSizes: 'f6 f7-m f5-xl',
    headlineTextPositioning: '',
    mediaLabel: {
      eyebrowDisplay: 'db',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn',
    },
    grid: 'layout-subgrid-item grid-col-12 grid-col-6-m grid-col-12-l',
  },
  tease3: {
    image: {
      sizes: {
        s: AIMS_FLAVORS.FOCAL_90x90,
        m: AIMS_FLAVORS.FOCAL_147x147,
        l: AIMS_FLAVORS.FOCAL_200x100,
      },
      display: 'db dn-l',
    },
    headlineSizes: 'f6 f7-m f6-l f5-xl',
    headlineTextPositioning: 'pl4-xl',
    mediaLabel: {
      eyebrowDisplay: 'dn db-l',
      iconBlockDisplay: 'db',
      iconInlineDisplay: 'dn di-xl',
    },
    grid: 'layout-subgrid-item grid-col-12 grid-col-6-m grid-col-12-l',
  },
};
