import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';

import {
  ACCESS_ENABLER_OBJ,
  configTVE,
  configTempPass,
  getResourceID,
} from 'lib/tve';

const mapStateToProps = ({ router, tve }) => ({
  authenticated: tve.authenticated,
  domain: router.domain,
  hasTempPass: tve.hasTempPass,
  selectedProvider: tve.provider,
  tempPassProviderID: tve.tempPassProviderID,
});

class TempPass extends React.Component {
  // Interval reference for checking tempPass authorization
  tempPassInterval = null;

  static propTypes = {
    authenticated: PropTypes.oneOf([null, false, true]),
    checkTempPass: PropTypes.bool,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    domain: PropTypes.string.isRequired,
    hasTempPass: PropTypes.bool,
    selectedProvider: PropTypes.string,
    tempPassProviderID: PropTypes.string,
  };

  static defaultProps = {
    authenticated: null,
    checkTempPass: false,
    hasTempPass: false,
    selectedProvider: null,
    tempPassProviderID: null,
  };

  shouldComponentUpdate() {
    const { tempPassProviderID } = this.props;
    return tempPassProviderID !== null;
  }

  componentDidUpdate(prevProps) {
    const {
      hasTempPass: hadTempPass,
      checkTempPass: hadCheckProp,
    } = prevProps;

    const {
      authenticated,
      checkTempPass,
      domain,
      hasTempPass,
      selectedProvider,
      tempPassProviderID,
    } = this.props;

    // authenticated has three states - null, false, & true
    if (authenticated === true) {
      // User is now authenticated

      // User now has tempPass
      if (hadTempPass === false && hasTempPass === true) {
        // Set tempPass cookie
        this.tempPassSetCookie();

        // Check authorization regularly as a backup to the countdown
        if (!this.tempPassInterval) {
          const {
            [domain]: {
              channelTitle, itemTitle, guid,
            } = {},
          } = configTVE;

          const resourceID = getResourceID(channelTitle, itemTitle, guid);

          this.tempPassInterval = setInterval(() => {
            window[ACCESS_ENABLER_OBJ].checkAuthorization(resourceID);
          }, configTempPass.checkInterval * 1000);
        }
      }
    } else if (authenticated === false) {
      // User is now unauthenticated

      // Component instructed to check tempPass through prop change
      if (hadCheckProp === false && checkTempPass === true) {
        // TempPass not yet in use, check availability
        const { cookies } = this.props;
        if (typeof cookies.get(configTempPass.checkName) === 'undefined') {
          this.tempPassCheck();
        }
      }
    }

    // User provider changed to something other than tempPass, or
    // user went from having tempPass to not, clear status check:
    if ((selectedProvider && selectedProvider !== tempPassProviderID)
      || (hadTempPass === true && hasTempPass === false)) {
      clearInterval(this.tempPassInterval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.tempPassInterval);
  }

  /**
   * Store the tempPass expiration in a cookie, this is for display purposes only
   */
  tempPassSetCookie = () => {
    const { cookies } = this.props;
    const {
      expirationName,
      expirationTTL,
      reset: {
        utcHours: resetHours,
        utcMinutes: resetMinutes,
      },
    } = configTempPass;

    if (typeof cookies.get(expirationName) === 'undefined') {
      const { path } = configTVE;
      // Set tempPass expiration date
      const expires = new Date();
      expires.setUTCHours(resetHours);
      expires.setUTCMinutes(resetMinutes);
      if (new Date() > expires) {
        expires.setUTCDate(expires.getUTCDate() + 1);
      }
      // Set tempPass expiration value
      const expirationValue = (new Date()).getTime() + expirationTTL;
      // Set it
      cookies.set(expirationName, expirationValue, { path, expires });
    }
  }

  /**
   * If tempPass is enabled, test selecting as a provider
   */
  tempPassCheck = () => {
    const { cookies, tempPassProviderID } = this.props;

    if (tempPassProviderID) {
      const { path } = configTVE;
      const { checkName, checkTTL } = configTempPass;

      // Set check cookie expiration to prevent rechecking
      const expires = new Date();
      expires.setTime(expires.getTime() + checkTTL);
      cookies.set(checkName, 1, { path, expires });

      // Required before selecting a provider
      window[ACCESS_ENABLER_OBJ].getAuthentication();

      // Inform enabler
      window[ACCESS_ENABLER_OBJ].setSelectedProvider(tempPassProviderID);
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(
  withCookies(TempPass),
);
