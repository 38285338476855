/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';

import Breakpoints from 'lib/Breakpoints';
import { layoutContext as LayoutContextPropType, package as PackagePropType } from 'lib/CustomPropTypes';
import { formatDuration } from 'lib/DateTime';
import { isLeadPackageAdjacent } from 'lib/packageHelper';

import Link from 'components/Link';
import SocialShareInline from 'components/Social/ShareInline';
import VideoPlayer from 'components/VideoPlayer';
import VideoAutoplayCapabilitiesDetector from 'components/NdpVideoPlayer/AutoplayCapabilitiesDetector';
import TitleLogo from 'components/TitleLogo';
import PackageTitle from 'components/PackageTitle';

import { Button } from 'components/Button';
import { GammaFeatureFlagSwitch, withIsGamma } from 'components/Gamma/GammaSwitch';
import { ListItem } from './ListItem';

import styles from './styles.module.scss';

class VideoPackage extends Component {
  static propTypes = {
    content: PackagePropType.isRequired,
    packageIndex: PropTypes.number,
    isGamma: PropTypes.bool,
  };

  static contextTypes = {
    leadPackageType: PropTypes.string,
    vertical: PropTypes.string,
    ...LayoutContextPropType,
  };

  static defaultProps = {
    packageIndex: null,
    isGamma: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/destructuring-assignment
      currentVideo: this.props?.content?.items?.[0]?.item,
      mobile: false,
      videoPlaying: false,
      adPlaying: false,
      infoHidden: false,
      videoMuted: false,
      nowPlayingInitiator: 'continuous',
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ mobile: Breakpoints.isS() });
  }

  onAdPlay = () => {
    this.setState({
      infoHidden: true,
      adPlaying: true,
    });
  }

  onAdEnd = () => {
    this.setState({
      adPlaying: false,
    });
  }

  onVideoMute = () => {
    this.setState({
      videoMuted: true,
    });
  }

  onVideoUnmute = () => {
    this.setState({
      videoMuted: false,
    });
  }

  onVideoPlay = () => {
    this.setState({
      videoPlaying: true,
      infoHidden: true,
    });
  }

  onVideoPause = () => {
    this.setState({
      videoPlaying: false,
      infoHidden: false,
    });
  }

  onVideoEnd = (selected) => {
    this.setState({
      nowPlayingInitiator: 'continuous',
      currentVideo: selected.item,
    });
  }

  onMouseMove = () => {
    const { infoHidden, videoPlaying } = this.state;

    if (videoPlaying) {
      if (this.isCaptionsCardOpen() && !infoHidden) {
        this.setState({ infoHidden: true });
      } else if (!this.isCaptionsCardOpen() && infoHidden) {
        this.setState({ infoHidden: false });
      }
    }
  }

  onMouseLeave = () => {
    const { videoPlaying } = this.state;

    if (videoPlaying) {
      this.setState({ infoHidden: true });
    }
  }

  getListItem = (item) => {
    const { currentVideo, videoPlaying } = this.state;
    const { isFluidWidthPage } = this.context;
    return (
      <ListItem
        content={item}
        key={item.id}
        changeVideo={this.changeVideo}
        playing={videoPlaying}
        active={item.id === currentVideo.id}
        isRebrandFront={isFluidWidthPage}
      />
    );
  }

  isCaptionsCardOpen = () => {
    if (this.player?.wrapper) {
      const captionCard = document.querySelector('.ndpCaptionOptionsCard', this.player.wrapper);
      if (captionCard && (!captionCard.style.display || captionCard.style.display !== 'none')) {
        return true;
      }
    }
    return false;
  }

  changeVideo = (item) => {
    const { videoPlaying, currentVideo, mobile } = this.state;
    if (mobile) {
      window.location.href = item.url.primary;
    } else {
      // Trigger play
      if (!videoPlaying && item.id === currentVideo.id) {
        this.player.manualPlay();
      }
      this.setState({
        nowPlayingInitiator: 'manual',
        currentVideo: item,
        videoPlaying: true,
      });
    }
  }


  meta = () => {
    const { vertical } = this.context;
    const { currentVideo } = this.state;
    return (
      <GammaFeatureFlagSwitch ifGamma={null}>
        <div className={styles.meta} data-testid="video-package__meta">
          {this.title()}
          {this.duration()}
          <SocialShareInline
            content={currentVideo}
            placement="videoPackage"
            propStyles={{ share: styles.share }}
            vertical={vertical}
          />
        </div>
      </GammaFeatureFlagSwitch>
    );
  }

  title = () => {
    const { currentVideo } = this.state;
    const { headline: { tease }, url: { primary } } = currentVideo;
    return (
      <h3
        className={styles.title}
        data-testid="video-package__title"
      >
        <Link to={primary}>
          {tease}
        </Link>
      </h3>
    );
  }

  duration = () => {
    const { currentVideo: { duration } } = this.state;
    return (
      <span
        className={styles.duration}
        data-testid="video-package__duration"
      >
        {formatDuration(duration)}
      </span>
    );
  };

  renderVideoPlayer = ({ inView, ref }) => {
    const { currentVideo, nowPlayingInitiator } = this.state;

    const { content, isGamma } = this.props;

    // not using `get` since it doesn't guard against `null` values
    const { items: videos, metadata } = content || {};

    const { shouldAutoplay } = metadata || {};

    const isLiveVideo = currentVideo && currentVideo.videoType === i18next.t('Non Broadcast Live Video');

    if (isGamma) {
      return (
        <div className={styles.videoInner} ref={ref}>
          <VideoPlayer
            autoPlay={shouldAutoplay}
            continuousPlay
            isLive={isLiveVideo}
            nowPlayingInitiator={nowPlayingInitiator}
            onAdEnd={this.onAdEnd}
            onAdPlay={this.onAdPlay}
            onVideoEnd={this.onVideoEnd}
            onVideoMute={this.onVideoMute}
            onVideoPause={this.onVideoPause}
            onVideoPlay={this.onVideoPlay}
            onVideoUnmute={this.onVideoUnmute}
            playlist={[{ videos }]}
            video={currentVideo}
          />
        </div>
      );
    }

    return (
      <div className={styles.videoInner} ref={ref}>
        {shouldAutoplay && inView ? (
          <VideoAutoplayCapabilitiesDetector>
            {({ ...autoplayCapabilities }) => (
              <VideoPlayer
                adPlayButtonPosition="bottomLeft"
                autoPlay
                autoplayCapabilities={autoplayCapabilities}
                continuousPlay
                isLive={isLiveVideo}
                isTestingAutoplayCapabilities={
                  (autoplayCapabilities || {}).isTestingAutoplayCapabilities
                }
                nowPlayingInitiator={nowPlayingInitiator}
                onAdEnd={this.onAdEnd}
                onAdPlay={this.onAdPlay}
                onRef={(player) => {
                  this.player = player;
                }}
                onVideoEnd={this.onVideoEnd}
                onVideoMute={this.onVideoMute}
                onVideoPause={this.onVideoPause}
                onVideoPlay={this.onVideoPlay}
                onVideoUnmute={this.onVideoUnmute}
                playlist={[{ videos }]}
                video={currentVideo}
              />
            )}
          </VideoAutoplayCapabilitiesDetector>
        ) : (
          <VideoPlayer
            adPlayButtonPosition="bottomLeft"
            continuousPlay
            isLive={isLiveVideo}
            onAdEnd={this.onAdEnd}
            onAdPlay={this.onAdPlay}
            onRef={(player) => {
              this.player = player;
            }}
            nowPlayingInitiator={nowPlayingInitiator}
            onVideoEnd={this.onVideoEnd}
            onVideoMute={this.onVideoMute}
            onVideoPause={this.onVideoPause}
            onVideoPlay={this.onVideoPlay}
            onVideoUnmute={this.onVideoUnmute}
            playlist={[{ videos }]}
            video={currentVideo}
          />
        )}
      </div>
    );
  };

  render() {
    const {
      adPlaying,
      currentVideo,
      infoHidden,
      videoMuted,
    } = this.state;

    const { content, packageIndex, isGamma } = this.props;

    // not using `get` since it doesn't guard against `null` values
    const {
      items: videos,
      id,
      metadata,
    } = (content || {});

    const {
      description,
      logoUrl,
      seeAllText,
      seeAllUrl,
      title,
    } = (metadata || {});

    const { leadPackageType, isFluidWidthPage } = this.context;

    const isLeadPkgAdjacent = packageIndex === 1 && isLeadPackageAdjacent(leadPackageType);

    const containerClasses = classNames(
      'pkg video-package',
      styles.videoPkg,
      {
        [styles.adPlaying]: adPlaying,
        [styles.infoHidden]: infoHidden,
        [styles.isLeadPkgAdj]: isLeadPkgAdjacent,
        [styles.muted]: videoMuted,
      },
    );

    const TitleContainer = () => (
      <div
        className={styles.titleContainer}
        data-testid="video-package__header"
      >
        <h2 className={styles.titleText}>
          <TitleLogo logoUrl={logoUrl} title={title} />
        </h2>
        {description && (
          <p className={styles.titleDescription}>
            {description}
          </p>
        )}
      </div>
    );

    const seeAllTextProp = i18next.t(seeAllText) || i18next.t('See All');

    return currentVideo && (
      <section
        className={containerClasses}
        data-packageid={id}
        data-testid="video-package__section"
      >
        {isFluidWidthPage && (
          <div className="grid-col-push-1-m grid-col-10-m fluidWidth">
            <PackageTitle
              metadata={{ title, description, logoUrl }}
              className="video-pkg-title"
            />
          </div>
        )}
        <div className={styles.grid}>
          <div className={styles.columnVideo}>
            {!isFluidWidthPage && (
              <TitleContainer />
            )}
            <div
              className={styles.videoOuter}
              onMouseMove={this.onMouseMove}
              onMouseLeave={this.onMouseLeave}
            >
              <InView triggerOnce>{this.renderVideoPlayer}</InView>
              {isGamma ? null : this.meta()}
            </div>
          </div>
          <div className={classNames(styles.columnList, { [styles.noSeeAll]: !seeAllUrl })}>
            <div className={styles.scroll}>
              <div className={styles.list}>
                {videos.filter(Boolean).map(this.getListItem)}
              </div>
            </div>
            {seeAllUrl && (
              <div className={classNames({ [styles.seeAllWrapper]: !isFluidWidthPage }, styles.buttonWrapper)}>
                <Button
                  url={seeAllUrl}
                  title={seeAllTextProp}
                  additionalClasses={styles.seeAllBtn}
                  tiltClasses={styles.buttonTilt}
                  type="link"
                />
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const VideoPackageIsGamma = withIsGamma(VideoPackage);

export { VideoPackage as VideoPackageComponent, VideoPackageIsGamma as VideoPackage };
