import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { parsePersonNames } from 'lib/parsePersonNames';
import { content as ContentPropType } from 'lib/CustomPropTypes';

import ButtonHoverAnimation from 'components/ButtonHoverAnimation';
import { AuthorCard } from 'components/Card/Author';

import styles from './styles.module.scss';

function EntityHub({ content = {} }) {
  const {
    metadata: {
      collapseButtonText,
      expandButtonText,
      title,
    } = {},
  } = content;

  const FALLBACK_COLLAPSE_BUTTON_TEXT = 'SHOW FEWER COLUMNISTS';
  const FALLBACK_EXPAND_BUTTON_TEXT = 'SHOW MORE COLUMNISTS';
  const FALLBACK_TITLE_TEXT = 'Columnists';
  const MIN_AUTHOR_TRUNCATION_COUNT = 6;

  const { t } = useTranslation();

  const [displayAllRows, setDisplayAllRows] = useState(false);

  const {
    items: persons = [],
    error = null,
    loading = false,
  } = useSelector((state) => state.person || {});

  if (error) {
    return null;
  }

  if (loading) {
    return <section className={styles.entityHub} />;
  }

  const authors = persons.filter((person) => person?.url?.primary?.length > 1)
    .map((author) => parsePersonNames(author))
    .sort((a, b) => {
      if (a.name.last > b.name.last) {
        return 1;
      }
      if (a.name.last < b.name.last) {
        return -1;
      }
      return (a.name.first > b.name.first) ? 1 : -1;
    });

  let buttonText = collapseButtonText || t(FALLBACK_COLLAPSE_BUTTON_TEXT);
  if (!displayAllRows) {
    buttonText = expandButtonText || t(FALLBACK_EXPAND_BUTTON_TEXT);
  }

  return (
    <section className={styles.entityHub} data-testid="entityHub">
      <div className={styles.wrapper}>
        <h4 className={styles.title}>{title || t(FALLBACK_TITLE_TEXT)}</h4>
        <div className={styles.border} />
        <div className={classNames(
          styles.entities,
          { [styles.allRows]: displayAllRows },
        )}
        >
          {authors.map((author) => (
            <div className={styles.entity} key={author.id}>
              <AuthorCard author={author} />
            </div>
          ))}
        </div>
        {authors.length > MIN_AUTHOR_TRUNCATION_COUNT
          && (
            <ButtonHoverAnimation
              title={buttonText}
              onClick={() => setDisplayAllRows(!displayAllRows)}
              type="buttonWithArrow"
              additionalClasses={styles.actionButton}
              iconStyle={
                displayAllRows
                  ? 'button-hover-animation__button-icon icon icon-angle-down up'
                  : 'button-hover-animation__button-icon icon icon-angle-down'
              }
            />
          )}
      </div>
    </section>
  );
}

EntityHub.propTypes = {
  content: ContentPropType.isRequired,
};

EntityHub.defaultProps = {};

export { EntityHub };
