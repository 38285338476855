import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { content as ContentPropType } from 'lib/CustomPropTypes';
import { MetaContent } from '../MetaContent';
import { SmallTeaseVisual } from '../SmallTeaseVisual';

import './styles.themed.scss';

const block = 'smorgasbord-no-art-tease';

/**
 * A Smorgasbord tease without any visual on the left.
 * Video does get a "play" icon, though.
 * @param {object} props The React props passed to the component
 * @param {Tease} props.item The item to render in the tease
 * @param {boolean} props.showByline Will turn on/off the byline in the tease
 * @param {boolean} props.showEyebrow Will turn on/off the eyebrow in the tease
 * @returns {JSX.Element} Component
 */
export const NoArtTease = ({ item, showEyebrow, showByline }) => {
  if (!item) {
    return null;
  }

  const variant = item.type === 'video' ? 'Play' : 'None';

  return (
    <div className={classNames(block)} data-contentid={item.id}>
      <SmallTeaseVisual variant={variant} item={item} />
      <MetaContent
        showByline={showByline}
        showEyebrow={showEyebrow}
        item={item}
      />
    </div>
  );
};

NoArtTease.propTypes = {
  item: ContentPropType.isRequired,
  showByline: PropTypes.bool,
  showEyebrow: PropTypes.bool,
};

NoArtTease.defaultProps = {
  showByline: true,
  showEyebrow: true,
};
