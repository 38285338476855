import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import TeasePicture from 'components/TeasePicture';
import { Unibrow } from 'components/Unibrow';
import Link from 'components/Link';
import RelatedContent from 'components/RelatedContent';
import { TypeIconInline } from 'components/TypeIconInline';
import { Save } from 'components/SocialShareMenu/Save';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import styles from './styles.module.scss';

const sizes = {
  s: AIMS_FLAVORS.FOCAL_440x220,
  l: AIMS_FLAVORS.FOCAL_552x276,
  xl: AIMS_FLAVORS.FOCAL_696x348,
};

/**
 * Creates FeatureLead package. Full width package renders main story. Intended to be viewable on homepage only.
 * @param {array[ContentPropType]} content an array of package items
 * @param {string} pkgClassName Additional classnames to provide to the package
 */
const TwoLead = ({ content }) => {
  if (!content || !content.length) return null;

  return (
    <div className={classNames('feature-lead-twolead', styles.container)} data-test="feature-lead-twolead" data-testid="feature-lead-twolead">
      {content.map(({
        computedValues, type, item, related = [], id,
      }, index) => {
        const { headline, unibrow, url } = computedValues;
        return (
          <>
            <div className={classNames(styles.titleWrap, styles[`titleWrap${index + 1}`])}>
              <Unibrow
                unibrow={unibrow}
                hasDefaultTextStyle
              />
              <h2 className={styles.title}>
                <Link to={url}>
                  <TypeIconInline
                    size="medium"
                    type={type}
                    taxonomy={item?.taxonomy}
                  />
                  {headline}
                </Link>
              </h2>
            </div>

            <div className={classNames(styles.picture, styles[`picture${index + 1}`])}>
              <Link to={url}>
                <TeasePicture
                  responsiveFlavors={sizes}
                  computedValues={computedValues}
                />
              </Link>
              <Save
                contentId={id}
                contentType={type}
                options={{ isThumbnail: true, showCTA: true }}
              />
            </div>

            <div className={classNames(styles.related, styles[`related${index + 1}`])}>
              <RelatedContent
                related={related}
              />
            </div>
          </>
        );
      })}
    </div>
  );
};

TwoLead.propTypes = {
  content: PropTypes.arrayOf(ContentPropType).isRequired,
};

export { TwoLead };
