import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getDisplayNameForVertical } from 'lib/vertical';

import { CoverSpreadPeacockAvatar } from 'components/svgs/CoverSpreadPeacockAvatar';
import { BylineThumbnail } from 'components/Byline';

/**
 * <ChatByline />
 * This component displays the byline of the author(s) and first author headshot in chat live blog.
 * If no authors are provided, it displays a fallback avatar and the name of the brand vertical.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {string} props.liveBlogStyle - The style of the live blog (can be 'chatStyle', 'reportedStyle', or 'storylineChatStyle').
 * @param {boolean} props.isChatStyle - A flag indicating whether the chat style is applied.
 * @param {Array} props.authors - An array of author objects. Each author object should have a 'name' property.
 * @param {string} props.vertical - The vertical for the chat.
 *
 * @example
 * return (
 *   <ChatByline
 *     liveBlogStyle="chatStyle"
 *     isChatStyle={true}
 *     authors={[{ name: 'Author 1' }]}
 *     vertical="news"
 *   />
 * );
 */
export const ChatByline = ({
  liveBlogStyle,
  authors,
  vertical,
  isChatStyle,
}) => (
  <span
    data-testid="lbp-card__meta-line__bylines"
    className={classNames(
      'lbp-card__meta-line__bylines',
      {
        [`lbp-card__meta-line__bylines--${liveBlogStyle}`]: liveBlogStyle,
      },
    )}
  >
    {authors?.length > 0 && (
      <>
        <BylineThumbnail
          person={authors[0]}
          chatStyle={isChatStyle}
          position={1}
          additionalClasses="lbp-card__avatar--pkg"
          isLinked={false}
        />
        {authors[0].name}
        {(authors.length > 1 && ' & more')}
      </>
    )}
    {!authors?.length && (
      <>
        <span className="lbp-card__avatar--pkg">
          <CoverSpreadPeacockAvatar className="db lbp-card__peacock-avatar" />
        </span>
        {getDisplayNameForVertical(vertical)}
      </>
    )}
      &nbsp;
  </span>
);

ChatByline.propTypes = {
  liveBlogStyle: PropTypes.oneOf(['chatStyle', 'reportedStyle', 'storylineChatStyle']),
  isChatStyle: PropTypes.bool,
  vertical: PropTypes.string,
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
};


ChatByline.defaultProps = {
  liveBlogStyle: 'reportedStyle',
  authors: [],
  isChatStyle: false,
  vertical: '',
};
