/* eslint-disable max-len */
import React from 'react';

export function LiveVideoEmbedMSNBCTVELogo() {
  return (
    <svg width="151" height="26" viewBox="0 0 151 26" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="live-video-embed-msnbc-tve-logo">
      <rect width="150.147" height="26" fill="white" />
      <rect width="116.147" height="26" transform="translate(2)" fill="white" />
      <g clipPath="url(#clip0_1182_574)">
        <path d="M23.2117 8.82512L19.3175 17.7188L18.2036 9.9908C18.0654 8.93737 19.8528 8.28978 19.8528 8.28978C19.6887 7.92712 19.1275 7.93576 19.1275 7.93576H17.9186C17.7287 6.03613 19.4556 4.64596 21.3121 5.06905C23.1426 5.47488 23.8075 7.46948 23.2117 8.81649" fill="#6E55DC" />
        <path d="M15.9843 20.6028L8.39442 15.5429C6.46026 14.2563 4.552 15.5688 4.14617 16.9244C3.55038 18.9277 4.88875 20.6028 6.98697 20.6028H15.9843Z" fill="#FCCC12" />
        <path d="M16.9773 8.06531C17.2623 6.10525 15.5008 4.64599 13.5925 5.07772C11.762 5.48355 11.0971 7.47816 11.6929 8.82516L15.5871 17.7188L16.9773 8.05668V8.06531Z" fill="#EF1541" />
        <path d="M15.2768 19.1781L11.538 10.6643C10.6659 8.687 8.34319 8.44523 7.06526 9.60227C6.16726 10.4226 5.46785 12.6417 7.47973 13.9887L15.2768 19.1867V19.1781Z" fill="#FF7112" />
        <path d="M27.927 20.6028C30.0252 20.6028 31.3636 18.9363 30.7678 16.9244C30.362 15.5688 28.4537 14.2477 26.5195 15.5429L18.9297 20.6028H27.927Z" fill="#05AC3F" />
        <path d="M27.426 13.9887C29.4379 12.6417 28.7385 10.4226 27.8405 9.60227C26.5625 8.44523 24.2398 8.687 23.3677 10.6643L19.6289 19.1867L27.426 13.9887Z" fill="#069DE0" />
        <path d="M46.4744 5.40588L42.0535 13.6261L37.6239 5.40588H34.0664V20.6029H37.408V11.9596L41.0173 18.3751H43.0896L46.6989 11.9596V20.6029H50.0405V5.40588H46.4744Z" fill="black" />
        <path d="M114.688 15.6724L114.602 15.5688L114.489 15.4393C112.987 16.7172 112.029 17.4425 109.861 17.4425H109.766C106.787 17.4425 104.939 15.1284 104.939 12.987C104.939 10.5262 107.115 8.53157 109.783 8.53157C111.934 8.54884 113.073 9.70588 113.617 10.2585L113.704 10.3448L113.764 10.2844L113.773 10.2758L113.825 10.224L113.919 10.129L116.216 7.83216C115.94 7.55586 113.799 5 109.809 5C105.224 5 101.417 8.4884 101.391 12.9698C101.391 17.3216 105.052 20.9827 109.766 21C113.315 21 115.111 19.5494 116.778 18.116L114.679 15.6724H114.688Z" fill="black" />
        <path d="M99.9919 15.8192C99.9919 19.9552 96.6849 20.6028 94.6125 20.6028H85.7793V5.39722H94.6039C97.3066 5.39722 99.4738 6.93418 99.4738 9.81816C99.4738 11.2429 98.4722 12.0286 97.7037 12.4172C98.9558 13.0475 99.9919 13.9801 99.9919 15.8192ZM89 8.25528V11.1306H94.2067C95.0702 11.1306 96.0114 10.7766 96.0114 9.7059C96.0114 8.63521 95.2774 8.25528 94.0858 8.25528H89ZM96.5553 15.8192C96.5553 14.36 95.3378 13.9801 94.1549 13.9801H89V17.6066H94.2758C95.2429 17.6066 96.5553 17.2699 96.5553 15.8106V15.8192Z" fill="black" />
        <path d="M68.709 5.39722H72.2751L79.6145 15.2321V5.39722H82.9562V20.6028H79.39L72.0506 10.7507V20.6028H68.709V5.39722Z" fill="black" />
        <path d="M63.5277 12.0286C60.9718 10.9406 56.43 11.7696 56.4559 9.68861C56.4645 8.88559 57.6388 8.19482 59.5212 8.17755C61.6367 8.16028 64.3134 9.28278 64.3134 9.28278L66.0144 6.58014C66.0144 6.58014 62.5519 5 59.5816 5C55.0398 5 52.907 7.00324 52.907 9.49002C52.907 11.9768 54.3404 13.0648 55.1175 13.4447C57.6388 14.6708 60.471 14.3341 61.9907 15.1025C63.6572 15.9315 63.0009 17.9261 59.5816 17.6498C57.0689 17.4425 55.5838 16.9417 53.7791 15.871L51.9141 18.4787C55.4284 20.646 58.8218 20.9914 59.5816 21C62.2325 21.0173 66.5412 20.3092 66.4807 15.9401C66.4548 14.1614 65.5913 12.9007 63.5277 12.0286Z" fill="black" />
      </g>
      <path d="M126.21 21H124.146V7.608H118.65V5.88H131.802V7.608H126.21V21ZM141.159 21H138.903L132.831 5.88H134.967L140.079 18.6H140.175L145.143 5.88H147.255L141.159 21Z" fill="black" />
      <defs>
        <clipPath id="clip0_1182_574">
          <rect width="112.147" height="16" fill="white" transform="translate(4 5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
