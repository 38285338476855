import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { ET_TIMEZONE } from 'lib/scheduleUtils';

import { SHOW_DETAILS_MAP, DEFAULT_THUMBNAIL } from '../schedule.config';

import styles from './styles.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

function Entry(props) {
  const {
    description,
    minify,
    seriesId,
    startTime,
    title,
  } = props;

  // Convert to ET
  const startTimeInET = dayjs(startTime).tz(ET_TIMEZONE);
  // Format into HH:MM AM/PM ET
  const startTimeFormatted = startTimeInET.format('h:mm A [ET]');

  const {
    [seriesId]: additionalDetails = {},
  } = SHOW_DETAILS_MAP;

  const {
    frontURL,
    thumbnail = DEFAULT_THUMBNAIL,
  } = additionalDetails;

  return (
    <div
      className={classNames(styles.entry, { [styles.full]: !minify })}
      data-entry-id={startTime}
    >
      {!minify && (
        <div className={styles.entryImageWrapper}>
          <a className={styles.entryAnchor} href={frontURL}>
            <img className={styles.entryImage} src={thumbnail} alt={title} />
          </a>
        </div>
      )}
      <div>
        <p className={styles.entryStartTime}>{startTimeFormatted}</p>
        <a className={styles.entryTitle} href={frontURL}>{title}</a>
        {!minify && (
          <p className={styles.entryDescription}>{description}</p>
        )}
      </div>
    </div>
  );
}

Entry.propTypes = {
  description: PropTypes.string,
  minify: PropTypes.bool,
  seriesId: PropTypes.string.isRequired,
  startTime: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

Entry.defaultProps = {
  description: null,
  minify: false,
};

export { Entry };

