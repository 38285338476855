/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';

import { imageAltForItem } from 'lib/imageAlt';
import { getTeaseUrl } from 'lib/teaseUtils';
import { formatDuration } from 'lib/DateTime';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import {
  content as ContentPropType,
  playmakerMetadata as PlaymakerMetadataPropType,
} from 'lib/CustomPropTypes';
import { getPlaymakerMetadata } from 'lib/playmaker';

import { ERROR_TYPES } from 'components/TVE/Slate';
import WithTVEAuthProvider from 'components/TVE/AuthProvider';
import TempPass from 'components/TVE/TempPass';
import PlaymakerTease from 'components/PlaymakerTease/index';
import VideoPlayer from 'components/VideoPlayer';
import CallToAction from 'components/TVE/CallToAction';
import placeholderVideo from 'assets/videos/msnbc-placeholder.mp4';

import {
  hideProviderSelectAction,
  showProviderSelectAction,
} from 'redux/modules/tve';

import {
  MAIN_ART_TEASE_VALID_SIZES,
  SMORGASBORD_TEASE_VISUAL_VARIANTS,
  SMORGASBORD_PLAYMAKER_DEFAULTS,
} from '../constants';

import './styles.themed.scss';

const {
  ARTICLE,
  LIVEBLOG,
  VIDEO,
  PLAYMAKER,
} = SMORGASBORD_TEASE_VISUAL_VARIANTS;

const { PLAYMAKER_FLAG, PLAYMAKER_PLAY_BEHAVIOR } = SMORGASBORD_PLAYMAKER_DEFAULTS;

// these are the responsive flavors for each size
const visualSizes = {
  S: {
    s: AIMS_FLAVORS.FOCAL_80x80,
  },
  M: {
    s: AIMS_FLAVORS.FOCAL_380x190,
    m: AIMS_FLAVORS.FOCAL_220x110,
    l: AIMS_FLAVORS.FOCAL_280x140,
    xl: AIMS_FLAVORS.FOCAL_260x130,
  },
  M1: {
    s: AIMS_FLAVORS.FOCAL_380x190,
    m: AIMS_FLAVORS.FOCAL_280x140,
    l: AIMS_FLAVORS.FOCAL_440x220,
    xl: AIMS_FLAVORS.FOCAL_260x130,
  },
  M2: {
    s: AIMS_FLAVORS.FOCAL_380x190,
    m: AIMS_FLAVORS.FOCAL_160x160,
    l: AIMS_FLAVORS.FOCAL_280x140,
    xl: AIMS_FLAVORS.FOCAL_260x130,
  },
  L: {
    s: AIMS_FLAVORS.FOCAL_380x190,
    m: AIMS_FLAVORS.FOCAL_460x230,
    l: AIMS_FLAVORS.FOCAL_560x280,
    xl: AIMS_FLAVORS.FOCAL_560x280,
  },
  XL: {
    s: AIMS_FLAVORS.FOCAL_380x190,
    m: AIMS_FLAVORS.FOCAL_460x230,
    l: AIMS_FLAVORS.FOCAL_560x280,
    xl: AIMS_FLAVORS.FOCAL_860x430,
  },
};

const block = 'tease-visual';

/**
 * The visual (video, image, liveVideo) for the M->XL Smorgasbord teases
 * @param {object} props The React props passed to the component
 * @param {Tease} props.item The item to render in the tease
 * @param {string} props.variant Enum to determine the type of visual that gets rendered (playmaker/image/video)
 * @param {string} props.size the size of the tease to render so we can fetch right image dimensions
 * @param {JSON} props.packageMetadata the metadata from the package the tease will be rendered inside. Used to render the playmaker.
 * @returns {JSX.Element} Component
 */
const TeaseVisual = ({
  authenticated,
  hasTempPass,
  hideProviderSelect,
  item,
  layout,
  packageMetadata,
  providerSelectVisible,
  size,
  variant,
  videoError,
}) => {
  if (!item) {
    return null;
  }

  const {
    computedValues,
    type,
    item: innerItem,
    metadata: { useEyebrowAsTag },
    slotIndex,
  } = item || {};
  const { url, unibrow, teaseImage } = computedValues || {};

  const isLiveBlog = variant === LIVEBLOG && item?.type === 'liveBlog';
  const showVideo = variant === VIDEO && item?.type === 'video';
  // liveblogs only have visuals if in first slot
  const showImage = (variant === ARTICLE && item?.type === 'article')
  || (isLiveBlog && slotIndex === 0);

  const iframeRef = React.useRef(null);

  const [checkTempPass, setCheckTempPass] = React.useState(false);
  const [showVideoControls, setShowVideoControls] = React.useState(true);

  const showPlaymaker = variant === PLAYMAKER && packageMetadata;
  const playmakerMetadata = showPlaymaker ? getPlaymakerMetadata({
    ...packageMetadata,
    // for Smorgasbord package, playmakerFlag and playmakerPlayBehavior defaults set here
    playmakerFlag: ('playmakerFlag' in packageMetadata)
      ? packageMetadata.playmakerFlag
      : PLAYMAKER_FLAG,
    playmakerPlayBehavior: ('playmakerPlayBehavior' in packageMetadata)
      ? packageMetadata.playmakerPlayBehavior
      : PLAYMAKER_PLAY_BEHAVIOR,
  }, 'msnbc', '2007680') : {};

  // User not authenticated with mvpd
  React.useEffect(() => {
    // authenticated has three states - null, false, & true
    if (authenticated === false && !hasTempPass) {
      // render tempPass component
      setCheckTempPass(true);
    } else if (authenticated && !hasTempPass && providerSelectVisible) {
      // Close MVPD overlay
      hideProviderSelect();
    }
  }, [authenticated, hasTempPass, providerSelectVisible]);

  const showEyebrowAsTag = !showPlaymaker && !isLiveBlog && useEyebrowAsTag && unibrow.text;

  if (showVideo || showImage || showPlaymaker) {
    const imageUrl = getTeaseUrl(item);
    if (!imageUrl) {
      return null;
    }
    const duration = showVideo ? innerItem?.duration : null;
    const formattedDuration = showVideo ? formatDuration(duration) : null;
    const imageAlt = imageAltForItem(item);

    const withRailVideo = layout === 'threeKeyVisuals';
    const videoSlotIndex = withRailVideo ? 7 : 0;
    const showVideoPlayer = showVideo && slotIndex === videoSlotIndex;
    const isVideoInLastSlot = showVideoPlayer && videoSlotIndex === 7;
    const showTeasePicture = (showImage || showVideo) && !showVideoPlayer;

    const autoPlayCapabilities = {
      canAutoplayMuted: true,
      canAutoplayUnmuted: false,
      isTestingAutoplayCapabilities: false,
    };

    const slateOverride = {
      hasOverlay: true,
      thumb: placeholderVideo,
    };

    const styleOverride = {
      gridOuter: 'smorgasbord__playmaker-outer',
      gridCol1: 'smorgasbord__playmaker-col-1',
      gridCol2: 'smorgasbord__playmaker-col-2',
      headline: 'smorgasbord__playmaker-headline',
    };

    if (withRailVideo && showPlaymaker) {
      // eslint-disable-next-line max-len
      styleOverride.gridCol2 = 'smorgasbord__playmaker-col-2 smorgasbord__playmaker-col-2--rail-video';
    }

    return (
      <div
        data-test={`${block}__visual-wrapper`}
        className={classNames(`${block}__visual-wrapper`, {
          [`${block}__visual-wrapper--${size}`]: !!size,
          [`${block}__visual-wrapper--playmaker`]: showPlaymaker,
          [`${block}__visual-wrapper--video-player`]: showVideoPlayer,
          [`${block}__visual-wrapper--rail-video`]:
            withRailVideo && (showPlaymaker || showVideoPlayer),
        })}
      >
        {showVideoPlayer && (
          <VideoPlayer
            centerPlayButtonAtMobile
            replay
            video={{ ...innerItem, teaseImage }}
            onVideoPlay={() => {
              setShowVideoControls(false);
            }}
            onlyAutoplayMuted={isVideoInLastSlot}
            autoPlay={isVideoInLastSlot}
            autoplayCapabilities={autoPlayCapabilities}
            shouldPauseWhenMutedAndOutOfView
            disableStickyOnMute
          />
        )}
        {showTeasePicture && (
          <Link to={url} className={`${block}__visual-link`}>
            <TeasePicture
              alt={imageAlt}
              className={`${block}__visual`}
              preview={
                type === 'video' && { url, redirect: false, radius: false }
              }
              responsiveFlavors={visualSizes[size]}
              retina
              url={imageUrl}
              computedValues={computedValues}
            />
          </Link>
        )}
        {showPlaymaker && (
          <>
            <WithTVEAuthProvider iframeRef={iframeRef} />
            {videoError !== ERROR_TYPES.BROWSER && (
              <TempPass checkTempPass={checkTempPass} />
            )}
            <PlaymakerTease
              metadata={playmakerMetadata}
              showDek={false}
              showPlayerControls
              slateOverride={slateOverride}
              stickyEnabled
              styleOverride={styleOverride}
              showPlayerActionButton={false}
            />
            <CallToAction showSecondary={false} />
          </>
        )}
        {showVideo && showVideoControls && (
          <VideoControls {...{ formattedDuration, slotIndex, withRailVideo }} />
        )}
        {showEyebrowAsTag && (
          <div
            data-test={`${block}__storyline`}
            className={classNames(`${block}__storyline`)}
          >
            {unibrow.text}
          </div>
        )}
      </div>
    );
  }
  return null;
};

TeaseVisual.propTypes = {
  authenticated: PropTypes.bool,
  hasTempPass: PropTypes.bool,
  hideProviderSelect: PropTypes.func,
  item: ContentPropType.isRequired,
  layout: PropTypes.string.isRequired,
  packageMetadata: PlaymakerMetadataPropType,
  providerSelectVisible: PropTypes.bool,
  size: PropTypes.oneOf(MAIN_ART_TEASE_VALID_SIZES).isRequired,
  variant: PropTypes.oneOf([VIDEO, ARTICLE, LIVEBLOG, PLAYMAKER]).isRequired,
  videoError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

TeaseVisual.defaultProps = {
  authenticated: null,
  hasTempPass: false,
  hideProviderSelect: Function.prototype,
  packageMetadata: {},
  providerSelectVisible: false,
  videoError: false,
};

const mapStateToProps = ({ tve, video }) => ({
  authenticated: tve.authenticated,
  hasTempPass: tve.hasTempPass,
  providerSelectVisible: tve.providerSelectVisible,
  videoError: video.error,
});

const mapActionsToProps = (dispatch) => ({
  hideProviderSelect: () => dispatch(hideProviderSelectAction()),
  showProviderSelect: () => dispatch(showProviderSelectAction()),
});

const TeaseVisualWrapper = connect(mapStateToProps, mapActionsToProps)(TeaseVisual);
export { TeaseVisualWrapper as TeaseVisual };

const VideoControls = ({ formattedDuration, slotIndex, withRailVideo }) => (
  <div
    data-test={`${block}__video-controls`}
    // only hide video controls if video is in the first slot (index === 0) with no rail video
    className={slotIndex === 0 && !withRailVideo ? `${block}__no-video-controls` : `${block}__video-controls`}
  >
    <div className={`${block}__video-duration`}>
      <span>{formattedDuration}</span>
    </div>
    <div data-test={`${block}__video-play`} className={`${block}__video-play`}>
      <span className="icon icon-video pointer-events-initial" data-testid="icon-video" />
    </div>
  </div>
);

VideoControls.propTypes = {
  formattedDuration: PropTypes.string,
  withRailVideo: PropTypes.bool,
  slotIndex: PropTypes.number,
};

VideoControls.defaultProps = {
  formattedDuration: null,
  slotIndex: null,
  withRailVideo: null,
};
