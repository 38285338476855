import React from 'react';
import classNames from 'classnames';

import TeasePicture from 'components/TeasePicture';
import { Unibrow } from 'components/Unibrow';
import Link from 'components/Link';
import { TypeIconInline } from 'components/TypeIconInline';
import RelatedContent from 'components/RelatedContent';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import { Save } from 'components/SocialShareMenu/Save';
import RecipeDetails from 'components/Recipe/RecipeDetails';
import styles from './styles.module.scss';

const sizes = {
  s: AIMS_FLAVORS.FOCAL_440x220,
  m: AIMS_FLAVORS.FOCAL_552x276,
  l: AIMS_FLAVORS.FOCAL_696x348,
  xl: AIMS_FLAVORS.FOCAL_920x460,
};

/**
 * Creates FeatureLead package. Full width package renders main story. Intended to be viewable on homepage only.
 * @param {ContentPropType} content package items data
 */
const Standard = ({ content }) => {
  if (!content) return null;

  const {
    computedValues, type, item, related = [], id,
  } = content;
  const {
    dek, headline, unibrow, url,
  } = computedValues;

  const {
    cookTime, prepTime, servingSize, yield: yields,
  } = item;

  const isLiveBlog = type === 'liveBlog';
  const relatedContent = related.length > 3 ? related.slice(0, -1) : related;

  return (
    <div className={classNames('feature-lead-standard', styles.container)} data-test="feature-lead-standard" data-testid="feature-lead-standard">
      <div className={styles.infoContent}>
        <div className={styles.picture}>
          <Link to={url}>
            <TeasePicture
              responsiveFlavors={sizes}
              type={type}
              computedValues={computedValues}
            />
          </Link>
          <Save
            contentId={id}
            contentType={type}
            options={{ isThumbnail: true, showCTA: true }}
          />
        </div>
        <div className={styles.info}>
          <Unibrow
            unibrow={unibrow}
            hasDefaultTextStyle
          />
          <Link to={url}>
            <h2 className={styles.title}>{headline}</h2>
          </Link>
          <p className={styles.dek}>
            <TypeIconInline
              size="medium"
              type={type}
              taxonomy={item?.taxonomy}
            />
            {dek}
          </p>
          <RecipeDetails
            cookTime={cookTime}
            prepTime={prepTime}
            servingSize={servingSize}
            yields={yields}
            type={type}
          />
          <div className={styles.related}>
            <RelatedContent
              related={relatedContent}
              displayTimeAgo={isLiveBlog}
            />
          </div>
        </div>
      </div>
      <div className={classNames('feature-lead__related-links', styles.related2)}>
        <RelatedContent
          related={relatedContent}
          displayTimeAgo={isLiveBlog}
        />
      </div>
    </div>
  );
};

Standard.propTypes = {
  content: ContentPropType.isRequired,
};

export { Standard };
