import React, { useState, useEffect } from 'react';
import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import { MaskedHorizontalBar } from 'components/MaskedHorizontalBar';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import { TextSummary } from 'components/packages/Waffle/TextSummary';
import { hasTeaseImage } from 'lib/teaseUtils';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FallbackImage } from './FallbackImage';
import styles from './styles.module.scss';

/**
 * @summary Package that displays updates for each day of the week
 * @description Package works by taking in data entered in from curator
 * and displaying each day of the week in a tab. The active tab is defaulted
 * to the current day of the week for the user. The user is not able to see
 * content for future days of the week but all tabs are viewable in curator
 * preview links for testing. Content links will open in new tabs depending
 * on selection in curator.
 * @param {object} content Package data from curator to display items
 * @returns {JSX.Element} Component
 */

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const CuratedUpdates = ({ content }) => {
  const todaysDate = new Date();
  const currentDay = todaysDate.getDay();
  const { id, items, context } = content;
  const [selectedDay, setSelectedDay] = useState(daysOfWeek[currentDay]);
  const [dayContent, setDayContent] = useState(items[currentDay]);
  const [futureDays, setFutureDays] = useState([]);
  const [tabDates, setTabDates] = useState([]);
  const seeMoreText = dayContent.metadata.seeMoreText || 'See more';
  const openLinkInNewTab = !!dayContent.metadata.openInNewTab;
  const shouldShowAllTabs = context.pageRoute.startsWith('/__preview');
  // handle future days of the week calculation
  const getFutureDays = () => {
    const currentIndex = currentDay;
    const daysArray = [...Array(7)].map((_, index) => {
      const dayIndex = (currentIndex + index + 1);
      return daysOfWeek[dayIndex];
    });
    setFutureDays(daysArray);
  };

  // handle getting date for each tab based on current user date
  const getWeekdayTabs = () => {
    const tabDateArray = daysOfWeek.map((_, index) => {
      const dateForTab = new Date(todaysDate);
      dateForTab.setDate(dateForTab.getDate() - dateForTab.getDay() + index);
      return {
        month: monthNames[dateForTab.getMonth()],
        day: dateForTab.getDate(),
      };
    });
    setTabDates(tabDateArray);
  };

  useEffect(() => {
    if (selectedDay !== null) {
      setDayContent(items[daysOfWeek.indexOf(selectedDay)]);
    }
  }, [selectedDay]);

  useEffect(() => {
    getFutureDays();
    getWeekdayTabs();
  }, []);

  return (
    <div
      className={classNames(
        styles.wrapper,
        styles.updatesWrapper,
      )}
      data-activity-map="curated-updates-package"
      data-packageid={id}
    >
      <div role="tablist">
        <MaskedHorizontalBar data-testid="today-account-dashboard-curated-updates">
          {daysOfWeek.map((day) => (
            <button
              className={classNames(
                styles.tab,
                { [`${styles.active}`]: selectedDay === day },
                { [`${styles.inactive}`]: !shouldShowAllTabs && futureDays.includes(day) },
              )}
              role="tab"
              type="button"
              onClick={() => setSelectedDay(day)}
              disabled={!shouldShowAllTabs && futureDays.includes(day)}
              key={`tab-${day}`}
            >
              {day}
            </button>
          ))}
        </MaskedHorizontalBar>
      </div>
      <div className={`update ${styles.update}`}>
        <div className={`dateAndPicture ${styles.dateAndPicture}`}>
          <p className={styles.date}>
            <span className={styles.month}>{tabDates[daysOfWeek?.indexOf(selectedDay)]?.month}</span>
            <span className={styles.day}>{tabDates[daysOfWeek?.indexOf(selectedDay)]?.day}</span>
          </p>
          <div className={styles.lineBelowDate} />
          <Link
            className={styles.link}
            to={dayContent.computedValues.url}
            target={dayContent.metadata.openInNewTab ? '_blank' : ''}
          >
            {hasTeaseImage(dayContent.computedValues) ? (
              <TeasePicture
                className={styles.tease}
                url={dayContent.computedValues.teaseImage.url.primary}
                responsiveFlavors={{
                  s: AIMS_FLAVORS.FOCAL_360x180,
                  l: AIMS_FLAVORS.FOCAL_560x280,
                }}
                alt={dayContent.computedValues.headline}
                role="img"
              />
            ) : (
              <div className={styles.tease}>
                <FallbackImage />
              </div>
            )}
          </Link>
        </div>
        <div className={styles.textSummary}>
          <TextSummary
            dekText={`<a href=${dayContent.computedValues.url} target=${openLinkInNewTab ? '_blank' : ''} rel="noreferrer">${dayContent.computedValues.headline}</a>`}
            allowHTMLText
            moreInfoClasses={styles.moreInfo}
            moreInfoText={dayContent.computedValues.dek}
            showDek
            showMoreInfo
            textClasses={styles.text}
            relatedLinkLabel={seeMoreText}
            relatedLinkUrl={dayContent.computedValues.url}
            relatedLinkClasses={styles.relatedLink}
            openRelatedInNewTab={openLinkInNewTab}
          />
        </div>
      </div>
    </div>
  );
};

CuratedUpdates.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    context: PropTypes.shape({
      pageRoute: PropTypes.string,
    }),
  }).isRequired,
};

export { CuratedUpdates };
