/**
 * Fetcher intended for GraphQL data requests, but can be used to fetch any http `GET` request which
 * returns a response with a `.data` property. It uses `fetch` to initiate the request, and simply
 * contains a destructuring call to return `data` from the response JSON.
 * @param  {*[]} args Fetcher passes all args directly to `fetch`. So e.g. if a string is passed,
 * that will be passed to `fetch` as the URL to fetch.
 * @return {*} Any data contained in the `.data` property of the resulting response from the
 * `fetch`.
 */
export function fetcher(...args) {
  return fetch(...args)
    .then((res) => res.json())
    .then(({ data }) => data);
}
