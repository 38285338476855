import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { PackageTitleTopBorder } from 'components/PackageTitleTopBorder';
import { PaidContent } from 'components/PaidContent';
import Ad from 'components/Ad';
import ButtonHoverAnimation from 'components/ButtonHoverAnimation';
import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import TypeIcon from 'components/TypeIcon';
import { Unibrow } from 'components/Unibrow';

import {
  TEASE_LIST_ALTERNATE_ITEM_DISPLAY,
  UNIBROW_OVERRIDE,
} from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { RouteContext, VerticalContext } from 'lib/ContextTypes';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import { teaseList as paidContent } from 'lib/paidContentUtils';
import { timeFrom } from 'lib/DateTime';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import { getVerticalName } from 'lib/verticalType';

import styles from './styles.module.scss';

function TeaseList(props) {
  const { t } = useTranslation();

  const { path: route } = React.useContext(RouteContext);
  const vertical = React.useContext(VerticalContext);

  const [paidContentLoaded, setPaidContentLoaded] = React.useState(false);

  const {
    content: {
      adType,
      adAboveList,
      id,
      metadata: packageMetadata,
      metadata: {
        logoUrl,
        seeAllUrl,
        seeAllText,
        title,
      },
    },
    pkgClassName,
  } = props;

  let { content: { items } } = props;
  // Insert paid content in-between 3rd and 4th items
  if (items.length >= 3) {
    items = [...items.slice(0, 3), paidContent, ...items.slice(3)];
  }

  const renderTeaseListItem = (article) => {
    const {
      computedValues,
      id: itemId,
      item,
      metadata: contentMetadata,
      type,
    } = article;

    const alternateTeaseListDisplay = getFeatureConfigForBrand(
      TEASE_LIST_ALTERNATE_ITEM_DISPLAY,
      vertical,
    );

    if (type === paidContent.type) {
      return (
        <PaidContent
          adLoaded={setPaidContentLoaded}
          className={classNames(
            styles.item,
            styles.isItemAd,
            {
              [styles.alternate]: alternateTeaseListDisplay,
              [styles.hidden]: !paidContentLoaded,
            },
          )}
          key={type}
          type={type}
          vertical={vertical}
        />
      );
    }

    if (!computedValues) {
      return null;
    }

    const {
      headline,
      teaseImage,
      unibrow,
      url,
    } = computedValues;

    const isNativeAd = item?.nativeAd;
    if (isNativeAd) {
      unibrow.override = t('Paid Content');
    }

    const metadata = {
      ...packageMetadata,
      ...contentMetadata,
    };

    // Default for showTeaseImage on TeaseList is false
    const showTeaseImage = metadata.showTeaseImage && teaseImage;

    let showEyebrow = alternateTeaseListDisplay && (isNativeAd || unibrow.text || showTeaseImage);

    const contentVertical = getVerticalName(article);

    // Attach an override to the unibrow object where configured
    const hasUnibrowOverride = getFeatureConfigForBrand(UNIBROW_OVERRIDE, contentVertical);
    if (hasUnibrowOverride) {
      showEyebrow = true;

      const { link, text } = hasUnibrowOverride;
      unibrow.override = text;
      unibrow.url = { primary: link };
    }

    const itemClasses = [
      styles.item,
      [styles[contentVertical]],
      {
        [styles.isNativeAd]: isNativeAd,
        [styles.alternate]: alternateTeaseListDisplay,
      }];

    let responsiveFlavors = {
      s: AIMS_FLAVORS.FOCAL_760x380,
      m: AIMS_FLAVORS.FOCAL_1000x500,
      l: AIMS_FLAVORS.FOCAL_320x160,
    };

    if (alternateTeaseListDisplay) {
      responsiveFlavors = {
        s: AIMS_FLAVORS.FOCAL_80x80,
      };
    }

    return (
      <li
        className={classNames(itemClasses)}
        data-contentid={itemId}
        data-test="teaseList__item"
        key={itemId}
      >
        <div>
          {isNativeAd && <Ad slot="native" />}

          <div className={styles.teaseTimestamp}>
            {t('time_ago', { time: timeFrom(item?.dateCreated) })}
          </div>

          {showEyebrow && (
            <Unibrow
              className={styles.teaseEyebrow}
              enableLogos
              unibrow={unibrow}
            />
          )}

          <h2 className={styles.teaseTitle}>
            <Link to={url}>
              {headline}
            </Link>
          </h2>

          {isNativeAd && item?.source?.organization && (
            <p className={styles.adSource}>
              {item.source.organization.name.toUpperCase()}
            </p>
          )}
        </div>

        {showTeaseImage && (
          <Link to={url} className={styles.teasePicture}>
            <TeasePicture
              computedValues={computedValues}
              className={styles.teasePictureImage}
              responsiveFlavors={responsiveFlavors}
              retina={alternateTeaseListDisplay}
            />
            <TypeIcon
              className={styles.typeIcon}
              type={type}
            />
          </Link>
        )}
      </li>
    );
  };

  return (
    <section
      className={classNames(
        pkgClassName,
        styles.teaseList,
        {
          [styles.isCover]: route === '/',
          [styles.isNotCover]: route !== '/',
          [styles.hasAdAbove]: adAboveList,
          [styles.hasSeeAll]: !!seeAllUrl,
        },
      )}
      data-packageid={id}
      data-test="teaseList"
    >
      <div
        className={styles.wrapperOuter}
        data-test="teaseList__wrapper"
      >
        <PackageTitleTopBorder
          data-test="teaseList__title"
          logoUrl={logoUrl}
          title={title}
        />
        <div className={styles.wrapperInner}>
          <div className={styles.listOuter}>
            <ul className={styles.listInner}>
              {items.filter((item) => item).map(renderTeaseListItem)}
            </ul>
          </div>

          {seeAllUrl && (
            <div className={styles.seeAllButtonWrapper}>
              <ButtonHoverAnimation
                additionalClasses={styles.seeAllButton}
                size="normal"
                title={seeAllText || t('See All')}
                type="link"
                url={seeAllUrl}
              />
            </div>
          )}
        </div>

        {adType && (
          <div className={styles.ad} data-test="teaseList__ad">
            <Ad
              offsetViewport={['boxrail', 'boxflex'].includes(adType) ? 100 : 50}
              packageId={id}
              slot={adType}
            />
          </div>
        )}
      </div>
    </section>
  );
}

TeaseList.propTypes = {
  content: PackagePropType.isRequired,
  pkgClassName: PropTypes.string,
};

TeaseList.defaultProps = {
  pkgClassName: 'pkg teaseList',
};

export { TeaseList };
