import { AIMS_FLAVORS } from 'lib/aimsFlavors';

// ThreeUp
const threeUpFull = {
  s: AIMS_FLAVORS.FOCAL_758x379,
  m: AIMS_FLAVORS.FOCAL_400x200, // 2x1
  l: AIMS_FLAVORS.FOCAL_260x260, // 1x1
  xl: AIMS_FLAVORS.FOCAL_380x380, // 1x1
};

const threeUpFullVideoAdj = {
  s: AIMS_FLAVORS.FOCAL_758x379,
  m: AIMS_FLAVORS.FOCAL_400x200, // 2x1
  l: AIMS_FLAVORS.FOCAL_260x260, // 1x1
  xl: AIMS_FLAVORS.FOCAL_380x380, // 1x1
};

const threeUpRailAdj = {
  s: AIMS_FLAVORS.FOCAL_758x379,
  m: AIMS_FLAVORS.FOCAL_400x200, // 2x1
  l: AIMS_FLAVORS.FOCAL_320x160, // 2x1
  xl: AIMS_FLAVORS.FOCAL_240x240, // 1x1
};

const threeUpRailAdjVideoAdj = {
  s: AIMS_FLAVORS.FOCAL_758x379,
  m: AIMS_FLAVORS.FOCAL_400x200, // 2x1
  l: AIMS_FLAVORS.FOCAL_320x160, // 2x1
  xl: AIMS_FLAVORS.FOCAL_240x240, // 1x1
};

const threeUpSlideshow = {
  s: 360,
  m: 230,
  l: 130,
  xl: 190,
};

// TwoUp
const twoUpFull = {
  s: AIMS_FLAVORS.FOCAL_758x379,
  m: AIMS_FLAVORS.FOCAL_460x460, // 1x1
  l: AIMS_FLAVORS.FOCAL_320x160, // 2x1
  xl: AIMS_FLAVORS.FOCAL_440x220, // 2x1
};

const twoUpFullVideoAdj = {
  s: AIMS_FLAVORS.FOCAL_758x379,
  m: AIMS_FLAVORS.FOCAL_360x180, // 2x1
  l: AIMS_FLAVORS.FOCAL_320x160, // 2x1
  xl: AIMS_FLAVORS.FOCAL_440x220, // 2x1
};

const twoUpRailAdj = {
  s: AIMS_FLAVORS.FOCAL_758x379,
  m: AIMS_FLAVORS.FOCAL_460x460, // 1x1
  l: AIMS_FLAVORS.FOCAL_260x260, // 1x1
  xl: AIMS_FLAVORS.FOCAL_380x380, // 1x1
};

const twoUpRailAdjVideoAdj = {
  s: AIMS_FLAVORS.FOCAL_758x379,
  m: AIMS_FLAVORS.FOCAL_360x180, // 2x1
  l: AIMS_FLAVORS.FOCAL_320x160, // 2x1
  xl: AIMS_FLAVORS.FOCAL_440x220, // 2x1
};

const twoUpSlideshow = {
  s: 360,
  m: 230,
  l: 130,
  xl: 190,
};

// OneUp
const oneUpFullDefault = {
  s: AIMS_FLAVORS.FOCAL_758x379,
  m: AIMS_FLAVORS.FOCAL_1000x500,
  l: AIMS_FLAVORS.FOCAL_1240x620,
  xl: AIMS_FLAVORS.FOCAL_1240x620,
};

const oneUpSlideshow = {
  s: 360,
  m: 230,
  l: 130,
  xl: 190,
};

/**
 *
 * @param {*} config
 */
export const getUpImageSize = (config) => {
  const {
    count = 1,
    railAdj = false,
    videoAdj = false,
    type,
  } = config;

  // ThreeUp
  if (count === 3) {
    if (type === 'slideshow') {
      return threeUpSlideshow;
    }
    if (railAdj) {
      if (videoAdj) {
        return threeUpRailAdjVideoAdj;
      }
      return threeUpRailAdj;
    }
    if (videoAdj) {
      return threeUpFullVideoAdj;
    }
    return threeUpFull;
  }

  // TwoUp
  if (count === 2) {
    if (type === 'slideshow') {
      return twoUpSlideshow;
    }
    if (railAdj) {
      if (videoAdj) {
        return twoUpRailAdjVideoAdj;
      }
      return twoUpRailAdj;
    }
    if (videoAdj) {
      return twoUpFullVideoAdj;
    }
    return twoUpFull;
  }

  // OneUp default
  if (type === 'slideshow') {
    return oneUpSlideshow;
  }
  return oneUpFullDefault;
};
