import React from 'react';
import PropTypes from 'prop-types';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import './styles.themed.scss';
import classNames from 'classnames';
import { LiveBlogContent } from './ItemContent/LiveBlogContent';
import { StandardItemContent } from './ItemContent/StandardItemContent';
import { StandardItemRelatedContent } from './ItemContent/StandardItemRelatedContent';
import { getItemData } from '../../utils/packageDataManager';
import { getIs2024ScoreboardEmbed } from '../../utils/getIs2024ScoreboardEmbed';

export function StoryItem({
  className,
  content,
  itemIndex,
  itemData,
  columnIndex,
  showLive,
  hideHeadlineAndDek,
}) {
  if (!content || !itemData) return null;

  const {
    hasRelatedContent,
    multiMediaSize,
    isLiveBlog,
    itemType,
    colLead,
    leadColumn,
  } = getItemData(
    itemData,
    'multiStorylineItemData',
    { content, showLive, columnIndex },
  );

  const is2024ScoreboardEmbed = getIs2024ScoreboardEmbed(itemData);

  return (
    <div
      className={classNames(
        'story-item',
        `item-type-${itemType}`,
        {
          [className]: className,
          'image-lead': colLead,
          'not-image-lead': !colLead && leadColumn !== 'none',
          'lead-column': colLead,
          'not-lead-column': !colLead && leadColumn !== 'none',
          'is-flex-item': is2024ScoreboardEmbed,
          'is-2024-scoreboard-embed': is2024ScoreboardEmbed,
        },
      )}
      data-contentid={itemData?.id}
    >
      <div className={classNames(
        'multi-item-container', {
          'multi-small-container': multiMediaSize === 'multiSmall',
          [`contained-media-${multiMediaSize}`]: true,
        },
      )}
      >
        {isLiveBlog
          ? (
            <LiveBlogContent
              content={content}
              itemIndex={itemIndex}
              itemData={itemData}
              columnIndex={columnIndex}
              showLive={showLive}
              hideHeadlineAndDek={hideHeadlineAndDek}
            />
          )
          : (
            <StandardItemContent
              content={content}
              itemIndex={itemIndex}
              itemData={itemData}
              showLive={showLive}
              hideHeadlineAndDek={hideHeadlineAndDek}
              isFlexColumnItem={is2024ScoreboardEmbed}
            />
          )}
      </div>
      {!isLiveBlog && hasRelatedContent && (
        <>
          <div className="multistory-related-spacer" />
          <StandardItemRelatedContent
            content={content}
            itemIndex={itemIndex}
            itemData={itemData}
            showLive={showLive}
          />
        </>
      )}
    </div>
  );
}

StoryItem.propTypes = {
  content: PackagePropType.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */
  itemData: PropTypes.object.isRequired,
  className: PropTypes.string,
  columnIndex: PropTypes.number,
  showLive: PropTypes.bool,
  itemIndex: PropTypes.number,
  hideHeadlineAndDek: PropTypes.bool,
};

StoryItem.defaultProps = {
  columnIndex: null,
  showLive: false,
  itemIndex: null,
  className: '',
  hideHeadlineAndDek: false,
};
