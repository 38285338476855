import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import cardStyles from '../Card/styles.module.scss';

import { PromoCode } from '../PromoCode';

function Details(props) {
  const {
    close,
    name,
    description,
    shopButtons,
    metadata,
    isMobile,
  } = props;

  const minusIcon = (
    <svg width="9" height="2" viewBox="0 0 9 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.29171 1.54152H0.708374V0.458191H8.29171V1.54152Z" className={cardStyles.minusIcon} />
    </svg>
  );

  const {
    moreInfoText: moreInfo,
    promoCode,
    relatedLinkLabel: relatedText,
    relatedLinkUrl: relatedUrl,
  } = metadata;

  const shouldShowRelatedLink = !!relatedText && !!relatedUrl;

  return (
    <div className={isMobile ? `${styles.drawerContainer} ${styles.isMobile}` : styles.drawerContainer} data-testid="details-container">
      <div className={styles.drawerTitle}>
        {name}
      </div>
      {(description || moreInfo) && (
        <div className={styles.drawerContent}>
          <div className={styles.description}>{description}</div>
          <div className={styles.moreInfo}>{moreInfo}</div>
        </div>
      )}
      {shouldShowRelatedLink && <a className={styles.related} href={relatedUrl}>{relatedText}</a>}
      {promoCode && <PromoCode promoCode={promoCode} isLarge />}
      <div className={styles.sellers}>
        <ul className={styles.sellersList}>
          {shopButtons?.map((button) => <li>{button}</li>)}
        </ul>
      </div>
      <div className={isMobile ? `${styles.closeBtnContainer} ${styles.isMobile}` : styles.closeBtnContainer}>
        <button
          onClick={close}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              close(true);
            }
          }}
          className={styles.closeBtn}
          type="button"
        >
          Close
          {minusIcon}
        </button>
      </div>
    </div>
  );
}

Details.propTypes = {
  close: PropTypes.isRequired,
  name: PropTypes.isRequired,
  description: PropTypes.isRequired,
  shopButtons: PropTypes.string,
  isMobile: PropTypes.bool,
  metadata: PropTypes.object,
};

Details.defaultProps = {
  shopButtons: null,
  isMobile: false,
  metadata: null,
};

export { Details };
