import React from 'react';
import SignIn from 'components/TVE/SignIn';
import { block } from './helper';

function CTAAnonymous() {
  return (
    <div className={`${block} ${block}__anonymous`} data-testid="call-to-action-anonymous">
      <div className={`${block}__text-wrap`}>
        <span className={`${block}__text ${block}__text__anonymous`}>
          Sign in to keep watching
        </span>
      </div>
      <SignIn withLock />
    </div>
  );
}

CTAAnonymous.propTypes = {};

CTAAnonymous.defaultProps = {};

export default CTAAnonymous;
