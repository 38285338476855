import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as CustomPropTypes from 'lib/CustomPropTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { LAYOUT_7_LEFT_COLUMNS_AT_LARGE_BREAKPOINT } from 'lib/brandFeatures';
import {
  doesTypeArrayHavePkgTypes,
  getPkgTypesInZone,
} from 'lib/packageHelper';

import { Rail, RailContainer } from 'components/Rail';
import MidresponsiveAd from 'components/Ad/Midresponsive';

import getPackagesForZone from '../getPackagesForZone';

import styles from './styles.module.scss';

function LeftRail({ packages }, context) {
  const { isFirstRail, vertical, isFluidWidthPage } = context;

  const has7LeftColumnsAtLargeBreakpoint = getFeatureConfigForBrand(
    LAYOUT_7_LEFT_COLUMNS_AT_LARGE_BREAKPOINT,
    vertical,
  );

  const pkgTypesInRail = getPkgTypesInZone(packages, 1);
  const hasTitle = doesTypeArrayHavePkgTypes(pkgTypesInRail, 'collectionTitle');

  const containerClasses = classNames(
    'layout-leftRail',
    {
      hasTitle,
      'grid-col-push-1-m grid-col-10-m': isFluidWidthPage,
      'layout-grid-container': !isFluidWidthPage,
    },
  );
  const leftClasses = classNames(
    'layout-grid-item',
    {
      'grid-col-5-l grid-col-4-xl': has7LeftColumnsAtLargeBreakpoint,
      'grid-col-4-l': !has7LeftColumnsAtLargeBreakpoint,
    },
  );
  const rightClasses = classNames(
    styles.right,
    'layout-grid-item',
    {
      'grid-col-7-l grid-col-8-xl': has7LeftColumnsAtLargeBreakpoint,
      'grid-col-8-l': !has7LeftColumnsAtLargeBreakpoint,
    },
  );

  return (
    <>
      <RailContainer className={containerClasses}>
        <Rail className={leftClasses} packageTypes={pkgTypesInRail}>
          {getPackagesForZone(1, packages, { ...context, railContext: 'left' })}
        </Rail>
        <div className={rightClasses}>
          {getPackagesForZone(2, packages, { ...context, isRailAdjacent: true })}
        </div>
      </RailContainer>

      {isFirstRail && !isFluidWidthPage && <MidresponsiveAd />}
    </>
  );
}

LeftRail.propTypes = {
  packages: PropTypes.arrayOf(CustomPropTypes.package).isRequired,
};

LeftRail.contextTypes = {
  ...CustomPropTypes.layoutContext,
};

export default LeftRail;
