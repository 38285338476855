import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const block = 'tve__schedule__navigation';

function ScheduleNavigationButton({ direction, enabled, onClick }) {
  return (
    <div className={block}>
      <button
        className={classNames(
          `${block}__button`,
          `${block}__button__${direction}`,
          { [`${block}__button__${direction}--enabled`]: enabled },
        )}
        data-testid={`${block}__button__${direction}`}
        onClick={onClick}
        type="button"
      >
        <span className="icon icon-angle-down" />
      </button>
    </div>
  );
}

ScheduleNavigationButton.propTypes = {
  direction: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ScheduleNavigationButton.defaultProps = {
  enabled: true,
  onClick: Function.prototype,
};

export default ScheduleNavigationButton;
