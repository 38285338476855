export const querySettings = {
  page: 1,
  queryLimit: 4,
  shownOnFronts: true,
};

export const getLiveBlogArticleId = (packageData, itemIndex = 0) => {
  const item = (packageData?.items?.[itemIndex]) || {};
  const { id } = item;
  if (!id) return null;
  return String(id).replace('cna', '');
};

export default {
  querySettings,
  getLiveBlogArticleId,
};
