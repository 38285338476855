import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { content as ContentPropType } from 'lib/CustomPropTypes';
import { MetaContent } from '../MetaContent';
import { SmallTeaseVisual } from '../SmallTeaseVisual';
import { SMORGASBORD_TEASE_VISUAL_VARIANTS } from '../constants';

import './styles.themed.scss';

const block = 'smorgasbord-small-tease';

/**
 * A (S) small Smorgasbord tease, with visuals and one optional
 * related item
 * @param {object} props The React props passed to the component
 * @param {Tease} props.item The item to render in the tease
 * @param {boolean} props.showByline Will turn on/off the byline in the tease
 * @param {boolean} props.showEyebrow Will turn on/off the eyebrow in the tease
 * @returns {JSX.Element} Component
 */
export const SmallTease = ({ item, showByline, showEyebrow }) => {
  if (!item) {
    return null;
  }
  const { related, type } = item;

  const { VIDEO, IMAGE, DOT } = SMORGASBORD_TEASE_VISUAL_VARIANTS;
  const variant = type === 'video' ? VIDEO : IMAGE;

  const relatedItem = related?.length >= 1 ? related[0] : null;

  return (
    <div
      className={classNames(block, {
        [`${block}`]: true,
      })}
      data-contentid={item.id}
    >
      <div className={`${block}__main-content-wrapper`}>
        <SmallTeaseVisual variant={variant} item={item} />
        <MetaContent
          showByline={showByline}
          showEyebrow={showEyebrow}
          item={item}
        />
      </div>
      {relatedItem && (
        <div className={`${block}__related-content-wrapper`}>
          <SmallTeaseVisual variant={DOT} item={relatedItem} />
          <MetaContent item={relatedItem} isRelatedContent />
        </div>
      )}
    </div>
  );
};

SmallTease.propTypes = {
  item: ContentPropType.isRequired,
  showByline: PropTypes.bool,
  showEyebrow: PropTypes.bool,
};

SmallTease.defaultProps = {
  showByline: true,
  showEyebrow: true,
};
