import AIMS_FLAVORS from 'lib/aimsFlavors';

export const liveBlogLayouts = {
  2: {
    tease0: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_380x190,
          m: AIMS_FLAVORS.FOCAL_147x147,
          l: AIMS_FLAVORS.FOCAL_200x100,
          xl: AIMS_FLAVORS.FOCAL_260x130,
        },
      },
      headlineSizes: 'f7 f7-l f6-xl',
      headlineTextPositioning: '',
      mediaLabel: {
        eyebrowDisplay: 'db dn-m db-l',
        iconBlockDisplay: 'db',
        iconInlineDisplay: 'dn',
      },
      grid: 'layout-subgrid-item grid-col-12 grid-col-8-m grid-col-6-l grid-col-12-xl',
    },
    tease1: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_90x90,
          m: AIMS_FLAVORS.FOCAL_200x100,
        },
        display: 'dn-xl',
      },
      headlineSizes: 'f6 f5-m f7-l f6-xl',
      headlineTextPositioning: 'pl4-xl',
      mediaLabel: {
        eyebrowDisplay: 'dn db-l dn-xl',
        iconBlockDisplay: 'db dn-xl',
        iconInlineDisplay: 'dn di-xl',
      },
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-6-l grid-col-12-xl',
    },
  },
  3: {
    tease0: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_380x190,
          m: AIMS_FLAVORS.FOCAL_200x100,
          xl: AIMS_FLAVORS.FOCAL_90x90,
        },
      },
      headlineSizes: 'f7 f5-m f7-l f6-xl',
      headlineTextPositioning: '',
      mediaLabel: {
        eyebrowDisplay: 'db dn-m db-l dn-xl',
        iconBlockDisplay: 'db',
        iconInlineDisplay: 'dn',
      },
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-6-l grid-col-12-xl',
    },
    tease1: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_90x90,
          m: AIMS_FLAVORS.FOCAL_200x100,
          xl: AIMS_FLAVORS.FOCAL_90x90,
        },
        display: 'dn-l db-xl',
      },
      headlineSizes: 'f6 f5-m f6-l',
      headlineTextPositioning: 'pl4-l pl0-xl',
      mediaLabel: {
        eyebrowDisplay: 'dn',
        iconBlockDisplay: 'db',
        iconInlineDisplay: 'dn di-l dn-xl',
      },
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-3-l grid-col-12-xl',
    },
    tease2: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_90x90,
          m: AIMS_FLAVORS.FOCAL_200x100,
          xl: AIMS_FLAVORS.FOCAL_90x90,
        },
        display: 'dn-l db-xl',
      },
      headlineSizes: 'f6 f5-m f6-l',
      headlineTextPositioning: 'pl4-l pl0-xl',
      mediaLabel: {
        eyebrowDisplay: 'dn',
        iconBlockDisplay: 'db',
        iconInlineDisplay: 'dn di-l dn-xl',
      },
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-3-l grid-col-12-xl',
    },
  },
  4: {
    tease0: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_380x190,
          m: AIMS_FLAVORS.FOCAL_200x100,
        },
        display: 'dn-l',
      },
      headlineSizes: 'f7 f5-m f6-l f5-xl',
      headlineTextPositioning: 'pl4-l',
      mediaLabel: {
        eyebrowDisplay: 'db dn-m',
        iconBlockDisplay: 'db dn-l',
        iconInlineDisplay: 'dn di-l',
      },
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-3-l grid-col-12-xl',
    },
    tease1: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_90x90,
          m: AIMS_FLAVORS.FOCAL_200x100,
        },
        display: 'dn-l',
      },
      headlineSizes: 'f6 f5-m f6-l f5-xl',
      headlineTextPositioning: 'pl4-l',
      mediaLabel: {
        eyebrowDisplay: 'dn',
        iconBlockDisplay: 'db dn-l',
        iconInlineDisplay: 'dn di-l',
      },
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-3-l grid-col-12-xl',
    },
    tease2: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_90x90,
        },
        display: 'dn-m',
      },
      headlineSizes: 'f6 f4-m f6-l f5-xl',
      headlineTextPositioning: 'pl4-m',
      mediaLabel: {
        eyebrowDisplay: 'dn',
        iconBlockDisplay: 'db dn-m',
        iconInlineDisplay: 'dn di-m',
      },
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-3-l grid-col-12-xl',
    },
    tease3: {
      image: {
        sizes: {},
        display: 'dn',
      },
      headlineSizes: 'f6 f4-m f6-l f5-xl',
      headlineTextPositioning: 'pl4',
      mediaLabel: {
        eyebrowDisplay: 'dn',
        iconBlockDisplay: 'dn',
        iconInlineDisplay: 'di',
      },
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-3-l grid-col-12-xl',
    },
  },
};

export const liveBlogRailLayouts = {
  2: {
    tease0: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_380x190,
          m: AIMS_FLAVORS.FOCAL_147x147,
          l: AIMS_FLAVORS.FOCAL_260x130,
          xl: AIMS_FLAVORS.FOCAL_260x130,
        },
      },
      headlineSizes: 'f7 f7-l f5-xl',
      headlineTextPositioning: '',
      mediaLabel: {
        eyebrowDisplay: 'db dn-m db-l',
        iconBlockDisplay: 'db',
        iconInlineDisplay: 'dn',
      },
      grid: 'layout-subgrid-item grid-col-12 grid-col-8-m grid-col-12-l',
    },
    tease1: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_90x90,
          m: AIMS_FLAVORS.FOCAL_260x130,
        },
        display: 'dn-l',
      },
      headlineSizes: 'f6 f5-m f6-l f5-xl',
      headlineTextPositioning: 'pl4-xl',
      mediaLabel: {
        eyebrowDisplay: 'dn db-l dn-xl',
        iconBlockDisplay: 'db dn-xl',
        iconInlineDisplay: 'dn di-xl',
      },
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-12-l',
    },
  },
  3: {
    tease0: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_380x190,
          m: AIMS_FLAVORS.FOCAL_200x100,
          l: AIMS_FLAVORS.FOCAL_200x100,
        },
        display: 'db db-m dn-l',
      },
      headlineSizes: 'f7 f5-m f7-l f5-xl',
      headlineTextPositioning: '',
      mediaLabel: {
        eyebrowDisplay: 'db dn-m db-l dn-xl',
        iconBlockDisplay: 'db',
        iconInlineDisplay: 'dn',
      },
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-12-l',
    },
    tease1: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_90x90,
          m: AIMS_FLAVORS.FOCAL_200x100,
        },
        display: 'dn-l',
      },
      headlineSizes: 'f6 f5-m f7-l f5-xl',
      headlineTextPositioning: 'pl4-l pl0-xl',
      mediaLabel: {
        eyebrowDisplay: 'dn',
        iconBlockDisplay: 'db',
        iconInlineDisplay: 'dn di-l dn-xl',
      },
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-12-l',
    },
    tease2: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_90x90,
          m: AIMS_FLAVORS.FOCAL_200x100,
          l: AIMS_FLAVORS.FOCAL_200x100,
          xl: AIMS_FLAVORS.FOCAL_260x130,
        },
        display: 'dn-l',
      },
      headlineSizes: 'f6 f5-m f7-l f5-xl',
      headlineTextPositioning: 'pl4-l pl0-xl',
      mediaLabel: {
        eyebrowDisplay: 'dn',
        iconBlockDisplay: 'db',
        iconInlineDisplay: 'dn di-l dn-xl',
      },
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-12-l',
    },
  },
  4: {
    tease0: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_380x190,
          m: AIMS_FLAVORS.FOCAL_200x100,
        },
        display: 'dn-l',
      },
      headlineSizes: 'f7 f5-m f6-l f5-xl',
      headlineTextPositioning: 'pl4-l',
      mediaLabel: {
        eyebrowDisplay: 'dn',
        iconBlockDisplay: 'db dn-l',
        iconInlineDisplay: 'dn di-l',
      },
      unibrowDisplay: 'db-l dn-xl',
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-12-l',
    },
    tease1: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_90x90,
          m: AIMS_FLAVORS.FOCAL_200x100,
        },
        display: 'dn-l',
      },
      headlineSizes: 'f6 f5-m f6-l f5-xl',
      headlineTextPositioning: 'pl4-l',
      mediaLabel: {
        eyebrowDisplay: 'dn',
        iconBlockDisplay: 'db dn-l',
        iconInlineDisplay: 'dn di-l',
      },
      unibrowDisplay: 'db-l dn-xl',
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-12-l',
    },
    tease2: {
      image: {
        sizes: {
          s: AIMS_FLAVORS.FOCAL_90x90,
        },
        display: 'dn-m',
      },
      headlineSizes: 'f6 f4-m f6-l f5-xl',
      headlineTextPositioning: 'pl4-m',
      mediaLabel: {
        eyebrowDisplay: 'dn',
        iconBlockDisplay: 'db dn-m',
        iconInlineDisplay: 'dn di-m',
      },
      unibrowDisplay: 'db-l dn-xl',
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-12-l',
    },
    tease3: {
      image: {
        sizes: {},
        display: 'dn',
      },
      headlineSizes: 'f6 f4-m f6-l f5-xl',
      headlineTextPositioning: 'pl4',
      mediaLabel: {
        eyebrowDisplay: 'dn',
        iconBlockDisplay: 'dn',
        iconInlineDisplay: 'di',
      },
      unibrowDisplay: 'db-l dn-xl',
      grid: 'layout-subgrid-item grid-col-12 grid-col-4-m grid-col-12-l',
    },
  },
};
