import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { loadLocationByZip, loadLocalWeather } from 'redux/modules/localnews';
import { useTracking } from 'lib/Hooks';
import { useMyNewsStore } from 'store';

import Weather from 'components/MyNews/LocalNews/Weather';
import weatherSVGs from '../LocalNews/weather';

import styles from './styles.module.scss';

const LocalWeather = ({ placement }) => {
  const [showWeather, setShowWeather] = useState(true);
  const dispatch = useDispatch();
  const customer = useMyNewsStore((state) => state.customer);
  const saveCustomer = useMyNewsStore((state) => state.saveCustomer);
  const getCustomer = useMyNewsStore((state) => state.getCustomer);
  const { city, state, weather } = useSelector((store) => store.localnews || {});
  const trackSubmit = useTracking('mbt_mynews_local_zip_submit');

  const {
    zipCode: customerZip,
  } = customer;
  const [zip, setZip] = useState(customerZip);

  useEffect(() => {
    setShowWeather(!!customerZip);
    // watches for changes to zipCode and dispatches calls to update weather & location
    if (customerZip) {
      dispatch(loadLocationByZip(customerZip));
      dispatch(loadLocalWeather(customerZip));
    }
  }, [customerZip]);

  const showZipForm = () => {
    setShowWeather(false);
  };

  const submitZip = async () => {
    if (zip && customerZip !== zip) {
      await saveCustomer({ zipCode: zip });
      getCustomer();
      trackSubmit({
        zip,
        status: customerZip ? 'change' : 'new',
        placement,
      });
    }

    setShowWeather(true);
  };

  return (
    <div className={classNames('pkg LocalNews', styles.weatherPkg, {
      [`${styles.zipContainer} ${styles.container}`]: !showWeather,
    })}
    >
      {showWeather ? (
        <>
          {(weather?.loading) ? (
            <div className={styles.loading} data-testid="weather-loading">
              <div className={styles.loadingHeader} />
              <div className={styles.loadingWeather} />
            </div>
          ) : (
            <div
              className={classNames({
                [`${styles.errorContainer} ${styles.container}`]: !city || !state,
                [styles.weatherContainer]: city && state,
              })}
              data-testid="weather-display"
            >
              {(city && state) ? (
                <div className={styles.header} data-testid="weather-location-header">
                  <span className={styles.location}>{`${city}, ${state}`}</span>
                  <button type="button" onClick={showZipForm} className={styles.editZip}>Edit Zip Code</button>
                </div>
              ) : (
                <button type="button" onClick={showZipForm} className={styles.editZip} data-testid="weather-edit-zip-btn">Edit Zip Code</button>
              )}
              <Weather />
            </div>
          )}
        </>
      ) : (
        <>
          <img src={weatherSVGs.icon32} alt="sun icon" className={styles.icon} />
          <div className={styles.zipText}>
            Enter your zip code to see your local weather displayed here
          </div>
          <form
            className={styles.form}
            onSubmit={(e) => {
              e.preventDefault();
              submitZip();
            }}
            data-testid="weather-zip-form"
          >
            <input
              className={styles.input}
              onChange={(e) => setZip(e.target.value)}
              autoComplete="off"
              placeholder="Zip code here"
              maxLength="5"
              minLength="1"
              name="zip"
              pattern="^(?:\d{5})?$"
              defaultValue={customerZip}
              type="text"
              data-testid="weather-zip-input"
            />
            <button type="submit" className={styles.submitZip}>Enter</button>
          </form>
        </>
      )}
    </div>
  );
};

LocalWeather.propTypes = {
  placement: PropTypes.string.isRequired,
};

LocalWeather.defaultProps = {};

export { LocalWeather };
