import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  hideProviderSelectAction,
  showProviderSelectAction,
} from 'redux/modules/tve';

import {
  ACCESS_ENABLER_OBJ,
  configTVE,
  configTempPass,
} from 'lib/tve';

import './styles.themed.scss';

const block = 'tve__signin';

const mapStateToProps = ({ tve }) => ({
  authenticated: tve.authenticated,
  hasTempPass: tve.hasTempPass,
  provider: tve.provider,
  providerSelectVisible: tve.providerSelectVisible,
});

const mapActionsToProps = (dispatch) => ({
  hideProviderSelect: () => dispatch(hideProviderSelectAction()),
  showProviderSelect: () => dispatch(showProviderSelectAction()),
});

class SignIn extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    hasTempPass: PropTypes.bool.isRequired,
    hideProviderSelect: PropTypes.func,
    provider: PropTypes.string,
    providerSelectVisible: PropTypes.bool.isRequired,
    showProviderSelect: PropTypes.func,
    withLock: PropTypes.bool,
  };

  static defaultProps = {
    authenticated: false,
    hideProviderSelect: Function.prototype,
    provider: null,
    showProviderSelect: Function.prototype,
    withLock: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      lastProvider: null,
    };
  }

  componentDidUpdate() {
    const { lastProvider } = this.state;
    const {
      authenticated,
      hasTempPass,
      providerSelectVisible,
    } = this.props;

    // Authenticated with cable provider
    if (!hasTempPass && authenticated === true) {
      this.handleHideOverlay();
    }

    // Overlay closed but not authenticated with new provider,
    // If user had provider previuosly set (tempPass), restore it
    if (!providerSelectVisible && hasTempPass && lastProvider) {
      window[ACCESS_ENABLER_OBJ].setSelectedProvider(lastProvider);
    }
  }

  handleSignIn = (e) => {
    e.preventDefault();
    const {
      hasTempPass,
      provider,
      showProviderSelect,
    } = this.props;
    // Remember if user previously had a tempPass
    if (hasTempPass) {
      this.setState({ lastProvider: provider });
    }
    // Open MVPD overlay
    showProviderSelect();
  }

  handleSignOut = (e) => {
    e.preventDefault();
    // Remove tempPass check cookie
    const { path } = configTVE;
    const { cookies } = this.props;
    cookies.remove(configTempPass.checkName, { path });
    // Send logout to accessEnabler
    setTimeout(window[ACCESS_ENABLER_OBJ].logout, 0);
  }

  handleHideOverlay = () => {
    const { hideProviderSelect } = this.props;
    // Close MVPD overlay
    hideProviderSelect();
  }

  render() {
    const {
      authenticated,
      hasTempPass,
      withLock,
    } = this.props;

    const authWithProvider = authenticated && !hasTempPass;

    return (
      <div className={block}>
        <button
          type="button"
          onClick={this[authWithProvider ? 'handleSignOut' : 'handleSignIn']}
          className={classNames(
            `${block}__action`,
            {
              [`${block}__action--authed`]: authenticated && !hasTempPass,
              [`${block}__action--withLock`]: withLock,
            },
          )}
        >
          {withLock && (
            <span className={`${block}__lock icon icon-lock`} />
          )}
          <span className={`${block}__label`}>
            {authWithProvider ? 'sign out' : 'sign in'}
          </span>
        </button>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(
  withCookies(SignIn),
);
