import React from 'react';
import classNames from 'classnames';
import get from 'lodash.get';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import { Footer } from './Footer';
import { Header } from './Header';
import { MsnbcDailyArticles } from './MsnbcDailyArticles';
import styles from './MsnbcDailyArticles/styles.module.scss';


function MsnbcDaily({ content, content: { id } }) {
  const headerTitle = get(content, ['metadata', 'title']);
  const headerTitleUrl = get(content, ['metadata', 'titleUrl']);
  const headerDescription = get(content, ['metadata', 'description']);

  const articles = get(content, ['items'], []);
  const showFeature = get(content, ['metadata', 'showFeature'], true);
  const showFeatureNumbered = get(content, ['metadata', 'showFeatureNumbered'], false);
  const showFeaturePublishedDate = get(content, ['metadata', 'showFeaturePublishedDate'], false);

  const actionButtonText = get(
    content,
    ['metadata', 'actionButtonText'],
    'Subscribe to MSNBC Daily',
  );
  const actionButtonUrl = get(
    content,
    ['metadata', 'actionButtonUrl'],
    'https://www.msnbc.com/daily-subscribe',
  );
  const infoText = get(
    content,
    ['metadata', 'infoText'],
    'Like this content? Follow our rundown delivered daily right to your inbox',
  );

  if (articles.length === 0) return null;
  return (
    <div className={classNames('pkg', styles.msnbcDaily)} data-packageid={id} data-testid="msnbc-daily">
      <Header
        description={headerDescription}
        title={headerTitle}
        titleUrl={headerTitleUrl}
      />
      <MsnbcDailyArticles
        articles={articles}
        packageId={id}
        showFeature={showFeature}
        showFeatureNumbered={showFeatureNumbered}
        showFeaturePublishedDate={showFeaturePublishedDate}
      />
      <Footer
        actionButtonText={actionButtonText}
        actionButtonUrl={actionButtonUrl}
        infoText={infoText}
      />
    </div>
  );
}

MsnbcDaily.propTypes = {
  content: ContentPropType.isRequired,
};

export { MsnbcDaily };
