import React from 'react';
import PropTypes from 'prop-types';

import PinnedLabel from '../../PinnedLabel/index';
import TimeAgo from '../../TimeAgo/index';

/**
 * <Eyebrow />
 * This component displays a pinned label if the post is pinned, or a timestamp if the post is not pinned.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {boolean} props.isBreaking - A flag indicating whether the post is breaking news.
 * @param {boolean} props.isPinnedPost - A flag indicating whether the post is pinned.
 * @param {string} props.pinnedLabel - The label to display when the post is pinned.
 * @param {boolean} props.showTimestamp - A flag indicating whether to show the timestamp.
 * @param {string} props.publishedAt - The publication date of the post.
 * @param {boolean} props.isInline - A flag indicating whether the component is displayed inline.
 *
 * @example
 * return (
 *   <Eyebrow
 *     isBreaking={true}
 *     isPinnedPost={true}
 *     pinnedLabel="Pinned Post"
 *     showTimestamp={false}
 *     publishedAt="2022-01-01T00:00:00Z"
 *     isInline={true}
 *   />
 * );
 */
export const Eyebrow = ({
  isBreaking,
  isPinnedPost,
  pinnedLabel,
  showTimestamp,
  publishedAt,
  isInline,
}) => (
  <>
    {isPinnedPost && (
      <PinnedLabel
        label={pinnedLabel}
        isBreakingNews={isBreaking}
        isInline={isInline}
      />
    )}
    {!isPinnedPost && showTimestamp && (
      <TimeAgo
        displayDate={publishedAt}
        isInline={isInline}
      />
    )}
  </>
);

Eyebrow.propTypes = {
  isBreaking: PropTypes.bool,
  isPinnedPost: PropTypes.bool,
  pinnedLabel: PropTypes.string,
  showTimestamp: PropTypes.bool,
  publishedAt: PropTypes.string,
  isInline: PropTypes.bool,
};

Eyebrow.defaultProps = {
  isBreaking: false,
  isPinnedPost: false,
  pinnedLabel: '',
  showTimestamp: true,
  publishedAt: '',
  isInline: false,
};
