import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Background from 'components/Background';
import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import TitleLogo from 'components/TitleLogo';
import TypeIcon from 'components/TypeIcon';
import { Unibrow } from 'components/Unibrow';

import { getFeaturedAuthor, hasFeaturedAuthor } from 'lib/featuredAuthor';
import { getVerticalName, getVerticalModuleClassName } from 'lib/verticalType';
import { isThinkModule } from 'lib/teaseUtils';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import buildUrl from 'lib/buildUrl';

import './styles.themed.scss';

function Collection(props) {
  const {
    content: {
      autofill,
      id,
      items,
      metadata: {
        collectionDescription,
        collectionImage,
        collectionName,
        collectionUrl,
        logoUrl,
      },
    },
    pkgClassName,
  } = props;

  const { t } = useTranslation();

  // Returns article thumbnail sizes
  const getArticleThumbnailSizes = (index) => {
    if (pkgClassName.includes('Billboard') && index === 0) {
      return {
        s: AIMS_FLAVORS.FOCAL_80x80,
        m: AIMS_FLAVORS.FOCAL_320x160,
        l: AIMS_FLAVORS.FOCAL_160x160,
        xl: AIMS_FLAVORS.FOCAL_360x180,
      };
    }
    return {
      s: AIMS_FLAVORS.FOCAL_80x80,
    };
  };

  // Returns article image
  const getArticleImage = (article, index, articleModuleClassName) => {
    const sizes = getArticleThumbnailSizes(index);
    const {
      computedValues,
      item,
      type,
    } = article;

    if (hasFeaturedAuthor(item, type)) {
      return (
        <span className={articleModuleClassName}>
          <span
            className={classNames(
              isThinkModule(article),
              'icon icon-quote-mark collection__quote',
            )}
          />
        </span>
      );
    }

    return (
      <div className="collection__picture">
        <Link to={buildUrl(article)}>
          <TeasePicture
            computedValues={computedValues}
            placeholder
            placeholderColor="white"
            responsiveFlavors={sizes}
            type={article.type}
          />
          <TypeIcon
            className="collection__icon"
            type={article.type}
          />
        </Link>
      </div>
    );
  };

  // Returns article
  const getArticle = (article, index) => {
    if (!article) {
      return null;
    }

    const { computedValues } = article;
    if (!computedValues) {
      return null;
    }

    const { headline, unibrow } = computedValues;

    const articleVertical = getVerticalName(article);
    const articleModuleClassName = getVerticalModuleClassName(articleVertical);

    const { item, type } = article;
    const hasAuthor = hasFeaturedAuthor(item, type);

    const classes = classNames(
      'collection__article',
      { 'collection__article--hasFeaturedAuthor': hasAuthor },
    );

    return (
      <li
        className={classes}
        data-contentid={article.id}
        key={article.id}
      >
        {getArticleImage(article, index, articleModuleClassName)}
        <div className="collection__info">
          <Unibrow
            size="h4"
            className={classNames(
              isThinkModule(article),
              'collection__eyebrow',
              'f2 lh-none founders-mono fw1 ttu mt0 mb1',
            )}
            unibrow={unibrow}
          />
          <h3 className="collection__title">
            <Link to={buildUrl(article)}>
              {hasAuthor && (
                <span className={articleModuleClassName}>
                  <span className="collection__author">
                    {getFeaturedAuthor(item, type)}
                  </span>
                </span>
              )}
              {headline}
            </Link>
          </h3>
        </div>
      </li>
    );
  };

  // Returns collection heading
  const getHeading = () => {
    const headingModule = <TitleLogo logoUrl={logoUrl} title={collectionName} />;

    if (collectionUrl) {
      return <Link to={collectionUrl}>{headingModule}</Link>;
    }

    return headingModule;
  };


  let verticalModuleStyle;
  if (autofill?.values) {
    const firstValue = autofill.values[0];
    // pulls the vertical from the `autofill.value`
    // `value` could be something like:
    // - "nbcnews/vertical/mach"
    // - "web/mach/"
    // (see here: https://regexr.com/3rdhs)
    [, verticalModuleStyle] = /(?:\w+\/)?(?:vertical\/)?([\w-]+)(?:\/\w*)?/i.exec(firstValue) || [];
  }

  const classesPkg = classNames(
    'pkg collection__container',
    getVerticalModuleClassName(verticalModuleStyle),
    pkgClassName,
  );

  return (
    <section className={classesPkg} data-packageid={id}>
      <header className="collection__header">
        <Background
          imageUrl={collectionImage}
          styleOverride={{
            bgColor: 'collection__background-color',
            bgContent: 'collection__background-content',
            bgGradient: 'collection__background-gradient',
            bgPicture: 'collection__background-picture',
            bgWrapper: 'collection__background-wrapper',
          }}
          useBackground
        >
          <div className="collection__headings">
            <h2 className="collection__heading">
              {getHeading()}
            </h2>
            <h3 className="collection__subheading">
              {collectionDescription}
            </h3>
          </div>
        </Background>
      </header>
      <ul className="collection__articles">
        {items.map(getArticle)}
      </ul>
      {collectionUrl && (
        <div className="collection__more">
          <Link to={collectionUrl}>
            {t('More')}
          </Link>
        </div>
      )}
    </section>
  );
}

Collection.propTypes = {
  content: PackagePropType.isRequired,
  pkgClassName: PropTypes.string,
};

Collection.defaultProps = {
  pkgClassName: null,
};

export { Collection };
