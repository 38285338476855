import React from 'react';
import { Button } from 'components/Button';
import classNames from 'classnames';
import { SHARE_LINK_COPIED_TEXT } from 'lib/constants';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const ShareButton = ({ handleShare, isCopied }) => (
  <div className={`share-button-word-search ${styles.shareContainer}`}>
    <Button
      onClick={handleShare}
      title="SHARE"
      tiltClasses={styles.button}
      displayType="no-arrow"
    />
    <div
      data-testid="copy-tooltip"
      className={classNames(styles.copyTooltip, {
        [styles.success]: isCopied,
      })}
    >
      <span className="icon icon-check" />
      <span className={styles.copiedText} data-testid="share-link-copied">{SHARE_LINK_COPIED_TEXT}</span>
    </div>
  </div>
);

export default ShareButton;

ShareButton.propTypes = {
  isCopied: PropTypes.bool.isRequired,
  handleShare: PropTypes.func.isRequired,
};
