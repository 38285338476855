import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  content as ContentPropType,
  packageContext as packageContextType,
  layoutContext as layoutContextType,
} from 'lib/CustomPropTypes';
import { VerticalContext } from 'lib/ContextTypes';
import AIMS_FLAVORS from 'lib/aimsFlavors';

import TeasePicture from 'components/TeasePicture';

/**
 * "Lead One Up Picture" main image for the Lead One Up package
 * @param {object} props
 * @param {boolean} props.railAdjacent Whether or not there is a rail next to the package
 * @param {Tease} props.content Tease to render
 * @returns {JSX.Element}
 */
const LeadOneUpPicture = (props, context) => {
  const {
    content: {
      computedValues,
      type,
    },
    railAdjacent,
  } = props;

  const { layoutIndex, packageIndex } = context;
  const isLeadPackage = layoutIndex === 0 && packageIndex === 0;

  const vertical = useContext(VerticalContext);

  const flavors = {
    s: AIMS_FLAVORS.FOCAL_760x760,
    m: AIMS_FLAVORS.FOCAL_440x440,
    l: AIMS_FLAVORS.FOCAL_696x348,
    x: AIMS_FLAVORS.FOCAL_870x435,
  };

  if (railAdjacent) {
    // Square images in rail layouts
    flavors.l = AIMS_FLAVORS.FOCAL_320x320;
    flavors.x = AIMS_FLAVORS.FOCAL_460x460;
  }

  if (vertical === 'think' && isLeadPackage) {
    // Only tablet has a 1:1 image
    flavors.s = AIMS_FLAVORS.FOCAL_760x380;
    flavors.m = AIMS_FLAVORS.FOCAL_580x580;
  }

  return (
    <TeasePicture
      computedValues={computedValues}
      placeholder
      placeholderColor="gray-40"
      responsiveFlavors={flavors}
      type={type}
    />
  );
};

LeadOneUpPicture.propTypes = {
  content: ContentPropType.isRequired,
  railAdjacent: PropTypes.bool.isRequired,
};

LeadOneUpPicture.contextTypes = {
  packageIndex: packageContextType.packageIndex,
  layoutIndex: layoutContextType.layoutIndex,
};

export { LeadOneUpPicture };
