import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { UP_PKG_PAID_ENABLED } from 'lib/brandFeatures';

import MultiUp from 'components/packages/MultiUp';
import MultiUpRail from 'components/packages/MultiUpRail';
import ThreeUp from 'components/packages/ThreeUp';
import TwoUp from 'components/packages/TwoUp';

import { setPaidContentPosition as setPaidContentAction } from 'redux/modules/front';

import {
  teaseCard as paidContent,
} from 'lib/paidContentUtils';

import {
  layoutContext as layoutContextPropType,
  packageContext as packageContextPropType,
  package as packagePropType,
} from 'lib/CustomPropTypes';

export const PAID_CONTENT_ELIGIBLE_PKG_TYPES = ['twoUp', 'threeUp', 'fourUp'];
const PAID_CONTENT_PKG_TYPE_MAP = {
  twoUp: 'threeUp',
  threeUp: 'fourUp',
  fourUp: 'fourUp',
};
const PAID_CONTENT_INELIGIBLE_LEAD_PKG_TYPES = ['collectionsLead', 'showLead'];

export class UpController extends Component {
  static propTypes = {
    package: packagePropType.isRequired,
    setPaidContentPosition: PropTypes.func,
    paidContentPosition: PropTypes.shape({
      layoutIndex: PropTypes.number,
      packageIndex: PropTypes.number,
    }),
    hasNoPaidContent: PropTypes.bool,
    adsDisabled: PropTypes.bool,
    pageView: PropTypes.string,
  };

  static contextTypes = {
    ...layoutContextPropType,
    ...packageContextPropType,
  };

  static defaultProps = {
    paidContentPosition: null,
    hasNoPaidContent: false,
    setPaidContentPosition: Function.prototype,
    adsDisabled: false,
    pageView: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasInsertedPaidContent: false,
      shouldInsertPaidContent: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const shouldInsertPaidContent = this.shouldInsertPaidContent();
    this.setState({
      hasInsertedPaidContent: shouldInsertPaidContent,
      shouldInsertPaidContent,
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { hasNoPaidContent } = nextProps;
    const { pageRoute } = this.context;
    const { hasInsertedPaidContent, shouldInsertPaidContent } = this.state;

    // For non-covers, if has no paid content set should insert paid content to false
    // does not try to insert paid content
    if (hasNoPaidContent
      && shouldInsertPaidContent
      && hasInsertedPaidContent
      && pageRoute !== '/'
    ) {
      this.setState({ shouldInsertPaidContent: false });
    }
  }

  // function that evaluates based on context properties and two new ad-related redux properties,
  // whether or not this is the first eligible package for paid content
  shouldInsertPaidContent = () => {
    const {
      adsDisabled,
      pageView,
      paidContentPosition,
      setPaidContentPosition,
    } = this.props;
    const {
      layoutIndex,
      packageIndex,
      pageRoute,
      vertical,
      zonePackageTypes,
    } = this.context;

    const upPkgPaidContentEnabled = getFeatureConfigForBrand(
      UP_PKG_PAID_ENABLED,
      vertical,
    );

    // Exit insertion logic if not a curation view (i.e. video canonical)
    if (pageView !== 'front'
      // Exit, if ads are globally disabled on the page
      || adsDisabled
      // Exit, if paid content has been inserted in another package, return false
      || paidContentPosition !== null
      // Exit, if vertical doesn't support paid content insertion
      || !upPkgPaidContentEnabled
    ) {
      return false;
    }

    // use special placement logic for covers
    if (pageRoute === '/') {
      const shouldInsertOnCover = this.shouldInsertPaidContentOnCover();
      if (shouldInsertOnCover) {
        setPaidContentPosition({ layoutIndex, packageIndex });
      }
      return shouldInsertOnCover;
    }

    // if first pkg below an ineligible lead pkg, do not inseert
    if (
      layoutIndex === 0
      && packageIndex === 1
      && PAID_CONTENT_INELIGIBLE_LEAD_PKG_TYPES.includes(zonePackageTypes[0])
    ) return false;

    setPaidContentPosition({ layoutIndex, packageIndex });

    // TODO: considers manually placed paid content for eligibility of package.
    return true;
  };

  shouldInsertPaidContentOnCover = () => {
    const {
      layoutIndex,
      packageIndex,
      zone,
      zonePackageTypes = [],
    } = this.context;

    if (layoutIndex !== 1 || zone !== 1) return false; // only consider second layout first zone

    // CASE A: SIZE > 3
    // Start at the 3rd package and search to find suitable placement.
    if (zonePackageTypes.length > 3) return packageIndex >= 2;

    // CASE B: SIZE = 3
    // If there are only 3 packages and the 3rd package not a 2/3/4up, no paid content will appear.
    if (zonePackageTypes.length === 3) return packageIndex === 2;

    // CASE C: SIZE = 2
    // If there are fewer than 3 packages, paid content will appear in the last suitable package.
    if (zonePackageTypes.length === 2) {
      // If second of two, should insert. If first of two, check next package for eligibility.
      return packageIndex !== 0 || !PAID_CONTENT_ELIGIBLE_PKG_TYPES.includes(zonePackageTypes[1]);
    }

    // CASE D: SIZE = 1
    // Insert into only eligible package
    return true;
  };

  insertPaidContent = () => {
    const { package: { type, items } } = this.props;
    const newPackageType = PAID_CONTENT_PKG_TYPE_MAP[type];
    // if no package type change, replace final canonical item with paid content
    if (type === newPackageType) {
      const itemsWithoutLastItem = [...items];
      itemsWithoutLastItem.pop();
      return { type, items: [...itemsWithoutLastItem, paidContent] }; // preserve package type
    }
    // append paid content after canonical items
    return { type: newPackageType, items: [...items, paidContent] };
  };

  render() {
    const {
      isFirstRail,
      isRailLayout,
      layoutIndex,
      layoutType,
      pageRoute,
      vertical,
    } = this.context;

    const { package: pkg } = this.props;
    const { shouldInsertPaidContent } = this.state;

    let paidContentOverrides = {};
    if (shouldInsertPaidContent) {
      paidContentOverrides = this.insertPaidContent();
    }
    // Top stories module logic to determine tease sizes
    const isTopStoriesSection = (
      ['news', 'msnbc'].includes(vertical) // for news and msnbc verticals
      && pageRoute === '/' // on the homepages
      && isFirstRail // first rail layout only
    );

    // TODO: lots of bad props spreading here, needs refactor
    const sharedProps = {
      vertical,
      content: {
        ...pkg,
        ...paidContentOverrides,
      },
      key: pkg.id,
      isFirstLayout: !layoutIndex,
      ...this.props,
    };

    const { type } = sharedProps.content;

    // Top stories or four up that isn't in a full-width position
    if (isTopStoriesSection || (type === 'fourUp' && isRailLayout)) {
      return (
        <MultiUpRail
          {...sharedProps}
          pkgClassName={`pkg multiUpRail multiUpRail--${type}`}
        />
      );
    }
    // Note: Full-width are determined here
    if (type === 'fourUp' || layoutType === 'fullWidth') {
      return (
        <MultiUp
          inFront
          {...sharedProps}
          pkgClassName={`pkg multiUp multiUp--${type}`}
        />
      );
    }
    if (type === 'twoUp') { // this is with rail
      return <TwoUp standalone {...sharedProps} />;
    }
    if (type === 'threeUp') {
      return (
        <ThreeUp standalone showFirstImageSource={false} {...sharedProps} />
      );
    }
    return null;
  }
}

const mapStateToProps = ({ front, shared: { pageView } }) => ({
  paidContentPosition: front.paidContentPosition,
  hasNoPaidContent: front.hasNoPaidContent,
  pageView,
});

const mapActionsToProps = (dispatch) => ({
  setPaidContentPosition: (position) => dispatch(setPaidContentAction(position)),
});

export default connect(mapStateToProps, mapActionsToProps)(UpController);
