import React from 'react';
import ButtonHoverAnimation from 'components/ButtonHoverAnimation';

import styles from './styles.module.scss';

function LocalNewsError() {
  return (
    <div className={styles.localNewsError}>
      <p
        className={styles.text}
        data-testid="local-news-error-text"
      >
        We&apos;re sorry we were unable to find your local news. Please try another location.
      </p>
      <div className={styles.btn}>
        <ButtonHoverAnimation
          url="https://www.nbcnews.com/news/us-news/nbc-affiliates-n19981"
          title="See Local Affiliates"
          size="normal"
          type="link"
          target="_blank"
        />
      </div>
    </div>
  );
}

export { LocalNewsError };
