import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as CustomPropTypes from 'lib/CustomPropTypes';

import { package as packagePropType } from 'lib/CustomPropTypes';
import { VerticalContext, LayoutContext } from 'lib/ContextTypes';
import { ECOMMERCE_DISCLAIMER } from 'lib/commerceUtils';

import { PackageSponsor } from 'components/PackageSponsor';
import Background from 'components/Background';
import Navigation from 'components/packages/HeaderPackage/Navigation';
import PackageHeader from 'components/PackageHeader';
import RawImage from 'components/RawImage';
import Wrapper from 'components/packages/HeaderPackage/Wrapper';

import { Button } from 'components/Button';
import styles from './styles.module.scss';

function Divider({ imageOverlay, isSelect }) {
  // In CollectionLead don't show divider for Select when Image Overlay is set to none
  return (
    <div
      data-test-id="separator"
      data-testid="separator"
      className={
        classNames(styles.separator, {
          [styles.hidden]: isSelect && imageOverlay === 'none',
        })
      }
    />
  );
}

Divider.defaultProps = {
  imageOverlay: 'default',
};

Divider.propTypes = {
  imageOverlay: PropTypes.string,
  isSelect: PropTypes.bool.isRequired,
};

function CollectionLead(props) {
  const {
    content: {
      id,
      metadata = {},
      packageIndex,
    } = {},
    dekRenderer,
    pkgClassName,
    postContentRenderer,
  } = props;

  const { t } = useTranslation();

  const seoTitle = useSelector((state) => state.front?.curation?.seoTitle || '');

  const vertical = useContext(VerticalContext);
  const { isFluidWidthPage } = useContext(LayoutContext);
  const isSelect = vertical === 'select';

  const {
    description,
    disclaimerKey,
    disclaimerOverride,
    logoUrl,
    navigation,
    title,
    updatesButtonText,
    updatesButtonUrl,
    imageOverlay,
  } = metadata;

  const altDescription = title || seoTitle;
  const hasLogo = logoUrl?.length;
  const imageUrl = metadata.imageUrl || metadata.backgroundImageUrl;

  const shouldDisclaimerOverride = disclaimerKey === 'OVERRIDE'; // naming inspired by some of the react lifecycle methods like shouldComponentUpdate
  const disclaimer = shouldDisclaimerOverride
    ? disclaimerOverride
    : ECOMMERCE_DISCLAIMER[disclaimerKey];

  const hasHeader = !hasLogo && title;

  const updatesButton = () => {
    if (updatesButtonUrl) {
      return (
        isFluidWidthPage ? (
          <Button
            url={updatesButtonUrl}
            title={updatesButtonText || t('Sign Up For Updates')}
            tiltClasses={styles.tiltButton}
          />
        ) : (
          <div>
            <a href={updatesButtonUrl}>
              <button
                className={styles.updatesButton}
                data-test="collection-lead__updates-button"
                type="button"
              >
                {updatesButtonText || t('Sign Up For Updates')}
              </button>
            </a>
          </div>
        ));
    }
    return null;
  };

  const styleOverride = {};
  const applyStyleOverride = (key, value, condition) => {
    if (condition) {
      Object.assign(styleOverride, { [key]: value });
    }
  };

  applyStyleOverride('bgGradient', false, imageOverlay === 'none');

  return (
    <Wrapper
      id={id}
      className={classNames('pkg lead collection-lead', styles.collectionLead, pkgClassName, {
        [styles.hasBkgImg]: imageUrl,
      })}
      packageIndex={packageIndex}
    >
      <Background
        imageUrl={imageUrl}
        useBackground
        animate
        styleOverride={styleOverride}
      >
        <div className={classNames('layout-grid-container', styles.gridContainer)}>
          <div
            className={classNames(
              'layout-grid-item grid-col-12',
              styles.gridItem,
              {
                'grid-col-10-l grid-col-push-1-l': !isSelect,
              },
            )}
          >
            <div className={styles.spacingOuter}>
              <div className={styles.spacingInner}>
                {isSelect && updatesButton()}

                {hasHeader && (
                  <PackageHeader
                    className={styles.header}
                    packageIndex={packageIndex}
                    data-test="collection-lead__package-header"
                  >
                    {title}
                  </PackageHeader>
                )}

                {hasLogo && (
                  <PackageHeader
                    className={styles.logoWrapper}
                    packageIndex={packageIndex}
                  >
                    <RawImage
                      alt={altDescription}
                      className={styles.logo}
                      src={logoUrl}
                    />
                  </PackageHeader>
                )}

                <PackageSponsor metadata={metadata} />

                {typeof dekRenderer === 'function' && dekRenderer()}

                {!dekRenderer && description && (
                  <p className={styles.description}>
                    {description}
                  </p>
                )}

                <Divider imageOverlay={imageOverlay} isSelect={isSelect} />

                <Navigation
                  navigation={navigation}
                  styleOverride={{
                    linkListWrapper: styles.linkListWrapper,
                    linkList: styles.linkList,
                    linkListItem: styles.linkListItem,
                  }}
                />

                {!!disclaimer && (
                  <div
                    className={styles.disclaimer}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: disclaimer,
                    }}
                  />
                )}

                {!isSelect && updatesButton()}

                {typeof postContentRenderer === 'function'
                  && postContentRenderer()}
              </div>
            </div>
          </div>
        </div>
      </Background>
    </Wrapper>
  );
}

CollectionLead.propTypes = {
  content: packagePropType.isRequired,
  dekRenderer: PropTypes.func,
  pkgClassName: PropTypes.string,
  postContentRenderer: PropTypes.func,
};

CollectionLead.defaultProps = {
  dekRenderer: null,
  pkgClassName: null,
  postContentRenderer: null,
};

CollectionLead.contextTypes = {
  ...CustomPropTypes.layoutContext,
};

export { CollectionLead };
