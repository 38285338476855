import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LayoutContext } from 'lib/ContextTypes';
import Breakpoints from 'lib/Breakpoints';

import Ad from 'components/Ad';
import { Card } from 'components/packages/Waffle/Card';

import styles from './styles.module.scss';

// AD frequency for ads placed between items in grid layout
// Compatible with layouts with 4 items across at larger breakpoints
const BOXINLINE_AD_FREQUENCY = 4;
const MID_AD_FREQUENCY = 12;

const createSections = (items) => {
  const isMobile = Breakpoints.isS();
  // length of top layout is different on mobile
  const firstLayoutLen = isMobile ? 5 : 3;

  // slices elements of non ad type
  const sliceNonAds = (entries, start, end) => {
    const result = [];
    for (let i = start; i < end; i += 1) {
      const { type } = entries[i];
      if (type !== 'ad') result.push(entries[i]);
    }
    return result;
  };
  const subSections = [
    {
      type: 'article',
      items: sliceNonAds(items, 0, firstLayoutLen),
    },
  ];

  let currItems = [];
  const insertArticles = () => {
    if (currItems.length) {
      subSections.push({ type: 'article', items: currItems });
      currItems = [];
    }
  };

  // starts creating layouts after first layout
  // because first layout has different rules than rest
  for (let i = firstLayoutLen; i < items.length; i += 1) {
    const item = items[i];
    const { type, metadata } = item;
    const formattedAd = {
      ...item,
    };

    if (type === 'ad') {
      const { slot } = metadata;
      formattedAd.type = slot;

      // if we're on mobile we insert boxinlines
      // in between articles
      if (slot === 'boxinline' && isMobile) {
        insertArticles();
        subSections.push(formattedAd);

      // if we're not on mobile we insert
      // other types of ad between articles
      } else if (slot !== 'boxinline' && !isMobile) {
        insertArticles();
        subSections.push(formattedAd);
      }
    } else {
      currItems.push(item);
    }
  }
  // insert any leftover articles into layout list
  insertArticles();

  return subSections;
};

const mapSections = ({
  sections = [],
  cardRenderer,
  adRenderer,
  mobileDisplayColumns,
  isFluidWidthPage,
  listClasses,
  insertGridAds,
}) => {
  const markup = [];
  let currMarkup = [];
  const isMobile = Breakpoints.isS();
  // on desktop, ads switch from left to right every n articles
  let directionSwitch = true;

  let cardIndex = -1;
  const updateCardIndex = (card, ...args) => {
    cardIndex += 1;
    return cardRenderer(card, cardIndex, ...args);
  };

  for (let idx = 0; idx < sections.length; idx += 1) {
    const section = sections[idx];
    const nextSections = sections[idx + 1];
    const key = ((section.items.length && section.items[0].id) || section.id) + idx;

    const mobileAdCadence = mobileDisplayColumns
      ? BOXINLINE_AD_FREQUENCY * 2
      : BOXINLINE_AD_FREQUENCY;

    const firstMarkup = !idx && (
      <div className={classNames('layout-grid-container', styles.container)} key={key} data-testid="first-layout">
        <ul
          className={classNames(
            'layout-grid-item grid-col-12',
            styles.firstLayout,
            {
              'gutter-collapse-m': !isFluidWidthPage,
              'layout-grid-item--with-gutter-s-only': isFluidWidthPage && mobileDisplayColumns,
            },
            listClasses,
          )}
        >
          {section.items.map((article, i) => (
            <>
              {updateCardIndex(article, false)}
              {insertGridAds && i < section.items.length - 1
                ? (
                  <>
                    {/* insert ads between rows of content; should not be the last item in the list */}
                    {(i + 1) % mobileAdCadence === 0 ? (
                      adRenderer(
                        { packageId: section.packageId, slot: 'boxinline' },
                        `${styles.gridAd} dn-m`,
                      )
                    ) : null}
                    {(i + 1) % MID_AD_FREQUENCY === 0 ? (
                      adRenderer(
                        { packageId: section.packageId, slot: 'topbanner' },
                        `${styles.gridAd} dn db-m`,
                      )
                    ) : null}
                  </>
                )
                : null}
            </>
          ))}
        </ul>
      </div>
    );

    const articleLayout = section.type === 'article' && !firstMarkup && (
      <ul
        className={classNames(
          'layout-grid-item grid-col-12 grid-col-8-l',
          styles.articleLayout,
          {
            'gutter-collapse-m': !isFluidWidthPage,
            'layout-grid-item--with-gutter-s-only': isFluidWidthPage && mobileDisplayColumns,
          },
        )}
        key={key}
        data-testid="article-layout"
      >
        {section.items.map((s) => updateCardIndex(s, true))}
      </ul>
    );

    const boxinline = section.type === 'boxinline' && (
      <div
        className="layout-grid-item"
        key={key}
      >
        {adRenderer(section.metadata)}
      </div>
    );

    const midresponsive = section.type === 'midresponsive' && (
      <div
        className={classNames('layout-grid-item', styles.midAd)}
        key={key}
      >
        {adRenderer(section.metadata)}
      </div>
    );

    // boxflex Ads render as standard cards
    const boxflexAd = section.type === 'boxflex' && (
      <ul
        className="layout-grid-item grid-col-4-m boxflexAd"
        key={key}
      >
        {cardRenderer(section)}
      </ul>
    );

    const skippedAd = (
      <ul
        className="layout-grid-item grid-col-4-m boxflexAd skippedAd"
        key={key}
      />
    );

    if (firstMarkup) {
      markup.push(firstMarkup);
    } else if (articleLayout) {
      currMarkup.push(articleLayout);
    } else if (boxinline) {
      markup.push(boxinline);
    } else if (midresponsive) {
      const midresponsivePlusLayout = (
        <div className={styles.midResponsive} key={key}>
          {markup.pop()}
          {midresponsive}
        </div>
      );
      markup.push(midresponsivePlusLayout);
    } else if (boxflexAd) {
      if (nextSections?.items && nextSections.items.length <= 2) {
        currMarkup.push(skippedAd);
      } else {
        currMarkup.push(boxflexAd);
      }
    }

    if (isMobile && currMarkup.length) {
      markup.push(...currMarkup);
      currMarkup = [];
    } else if (!isMobile && currMarkup.length === 2) {
      const [ad, article] = currMarkup;
      const sectionMarkup = (
        <div
          className={classNames('layout-grid-container', styles.container)}
          key={key}
        >
          {!directionSwitch ? (
            <>
              {article}
              {ad}
            </>
          ) : (
            <>
              {ad}
              {article}
            </>
          )}
        </div>
      );
      directionSwitch = !directionSwitch;
      markup.push(sectionMarkup);

      currMarkup = [];
    }
  }

  return markup;
};

/**
 * Renders a list of Waffle content items with optional grid ads and custom card (content item) rendering.
 * This component can adapt to different display columns based on mobile settings and can disable ads according to page settings.
 *
 * @param {Object} props - The properties passed to the List component.
 * @param {boolean} props.adsDisabled - flag if ads are disabled on the page
 * @param {Array} props.cards - Array of content items to be rendered within the list.
 * @param {Function} [props.cardRenderer] - Optional custom renderer for cards. Defaults to a standard Card component if not provided.
 * @param {boolean} props.mobileDisplayColumns - Whether to show 2 columns in mobile layout
 * @param {string} props.listClasses - Custom CSS classes to be applied to the list container.
 * @param {boolean} props.isProductWaffle - Flag indicating if the list is in 'product waffle' mode, affecting layout.
 * @param {Function} props.insertGridAds - Flag to insert grid ads within the list; this is logic separate from ads inserted via FrontAdController
 * @param {Object} props.metadata - Metadata object containing additional information for content items
 * @param {Object} rest - Additional props passed to each card renderer.
 * @returns {JSX.Element} The rendered List component.
 */
function List(props) {
  const {
    adsDisabled,
    cards,
    cardRenderer,
    mobileDisplayColumns,
    listClasses,
    isProductWaffle,
    insertGridAds,
    metadata: packageMetadata = {},
    ...rest
  } = props;

  const { isFluidWidthPage } = useContext(LayoutContext);
  const numberOfArticles = cards.filter((card) => card && card.type !== 'ad').length;

  const defaultCardRenderer = cardRenderer
    || ((card, index) => (
      <Card
        card={card}
        cardsLength={numberOfArticles}
        index={index}
        key={card.id}
        useModal
        metadata={packageMetadata}
        {...rest}
      />
    ));

  const adRenderer = (metadata, adClass = styles.ad) => (
    <Ad
      adClass={adClass}
      offsetViewport={metadata.slot === 'boxinline' ? 50 : null}
      packageId={metadata.packageId}
      slot={metadata.slot}
    />
  );

  const adsIncluded = cards.some((card) => card && card.type === 'ad');

  const sections = !adsDisabled && adsIncluded
    ? createSections(cards, 'ad')
    : [{ type: 'article', items: cards, packageId: packageMetadata.packageId }];

  return (
    <div
      className={classNames(
        styles.list,
        {
          [styles.mobileColumns]: mobileDisplayColumns,
          'waffle-mobile-single-col': !mobileDisplayColumns,
        },
      )}
      data-testid="list"
    >
      {mapSections({
        sections,
        cardRenderer: defaultCardRenderer,
        adRenderer,
        isFluidWidthPage: isFluidWidthPage || isProductWaffle,
        mobileDisplayColumns,
        listClasses,
        insertGridAds: insertGridAds && !adsDisabled,
      })}
    </div>
  );
}

List.propTypes = {
  adsDisabled: PropTypes.bool,
  cardRenderer: PropTypes.func,
  cards: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  metadata: PropTypes.objectOf(PropTypes.any),
  mobileDisplayColumns: PropTypes.bool,
  listClasses: PropTypes.string,
  isProductWaffle: PropTypes.bool,
  insertGridAds: PropTypes.bool,
};

List.defaultProps = {
  adsDisabled: false,
  cardRenderer: null,
  cards: [],
  metadata: {},
  mobileDisplayColumns: false,
  listClasses: '',
  isProductWaffle: false,
  insertGridAds: false,
};

export { List };
