/* eslint-disable max-len */
import React from 'react';

export function LiveVideoEmbedTodayAllDayLogo() {
  return (
    <svg className="live-video-embed__logo-svg" viewBox="0 0 139 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M25.3987 12.4929C25.1266 5.79672 19.5478 0.450623 12.7053 0.450623C5.91027 0.450623 0.359748 5.72452 0.0173482 12.3566C0.00547839 12.5732 0 12.7898 0 13.0073H3.29434C3.29434 12.797 3.30165 12.5858 3.31625 12.3783C3.64496 7.54114 7.73001 3.70665 12.7053 3.70665C17.6989 3.70665 21.7967 7.57093 22.098 12.4333C22.1099 12.6237 22.1163 12.8141 22.1163 13.0073H25.4097C25.4097 12.8358 25.407 12.6625 25.3987 12.4929Z" fill="#FF503C" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7054 4.63611C8.21498 4.63611 4.54172 8.08977 4.2541 12.4558C4.24041 12.6372 4.23584 12.8213 4.23584 13.0072H7.52927C7.52927 12.8078 7.54114 12.6092 7.56396 12.4152C7.86162 9.87391 10.0548 7.89123 12.7054 7.89123C15.3707 7.89123 17.5721 9.89286 17.8515 12.454C17.8716 12.6363 17.8816 12.8195 17.8816 13.0072H21.175C21.175 12.8276 21.1696 12.6525 21.1586 12.4757C20.881 8.0997 17.2023 4.63611 12.7054 4.63611Z" fill="#FF503C" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7055 8.82233C10.6401 8.82233 8.91993 10.2825 8.54649 12.2155C8.49718 12.4718 8.4707 12.7371 8.4707 13.0088H16.9403C16.9403 12.8048 16.9257 12.6063 16.8974 12.4132C16.6052 10.3827 14.8402 8.82233 12.7055 8.82233Z" fill="#FF503C" />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.9172 12.8539V3.38788H39.1977V0.683304H27.3921V3.38788H31.614V12.8539H34.9172ZM52.7337 6.7686C52.7337 10.5164 49.9191 13.0857 46.3422 13.0857C42.7653 13.0857 39.9508 10.5164 39.9508 6.7686C39.9508 3.02083 42.7653 0.451477 46.3422 0.451477C49.9191 0.451477 52.7337 3.02083 52.7337 6.7686ZM49.3133 6.76895C49.3133 4.70187 48.1601 3.34958 46.3424 3.34958C44.5246 3.34958 43.3714 4.70187 43.3714 6.76895C43.3714 8.83602 44.5246 10.1883 46.3424 10.1883C48.1601 10.1883 49.3133 8.83602 49.3133 6.76895ZM57.8456 10.2656H59.1552C61.3052 10.2656 62.5757 8.99056 62.5757 6.74962C62.5757 4.54732 61.3052 3.2723 59.1552 3.2723H57.8456V10.2656ZM54.5228 12.8539V0.683304H59.3897C63.4747 0.683304 65.9961 2.96288 65.9961 6.76861C65.9961 10.5743 63.4747 12.8539 59.3897 12.8539H54.5228ZM71.1665 0.683304H74.8801L79.7861 12.8539H76.2483L75.3297 10.4971H70.5214L69.6028 12.8539H66.2605L71.1665 0.683304ZM72.8865 4.45086L71.4792 8.04409H74.372L72.9647 4.45086H72.8865ZM84.5001 5.06859L87.1388 0.683304H90.7156L85.9856 8.23681V12.8539H82.7605V8.23681L78.0305 0.683304H81.7246L84.5001 5.06859Z" fill="black" />
      <path d="M93.5422 12.8548C94.6629 12.8548 95.5686 12.0246 96.2747 11.0986H96.3361C96.2901 11.2902 96.2594 11.5935 96.2594 11.7851C96.2594 12.1842 96.3515 12.8388 97.2111 12.8388C98.3471 12.8388 99.5598 11.849 100.22 11.1625L99.9129 10.7953L99.5598 11.0188C99.0686 11.3381 98.8537 11.2902 98.8537 10.907C98.8537 10.7793 98.8844 10.6516 98.9151 10.4919L99.6519 6.69213C99.9896 4.9998 100.297 4.20152 100.297 4.20152L100.02 4.0738L98.9918 4.79225H98.9304C98.3471 4.36118 97.7484 4.10573 96.7813 4.10573C94.233 4.10573 91.6694 7.10723 91.6694 10.5079C91.6694 11.9607 92.3295 12.8548 93.5422 12.8548ZM94.8778 11.2902C94.4633 11.2902 94.2791 11.0028 94.2791 10.3003C94.2791 7.63409 95.5532 4.77628 96.6738 4.77628C97.1037 4.77628 97.2725 5.03173 97.38 5.35104L96.4129 10.1407C95.983 10.7154 95.3997 11.2902 94.8778 11.2902Z" fill="#FF503C" />
      <path d="M101.94 12.8388C103.137 12.8388 104.258 11.8809 104.995 11.1305L104.703 10.7633L104.335 10.9868C103.828 11.3221 103.567 11.3061 103.567 10.9709C103.567 10.7953 103.598 10.6516 103.629 10.4919L105.133 2.57305C105.333 1.51933 105.578 0.705091 105.578 0.705091L105.425 0.561401L101.787 0.896675L101.725 1.29581L102.262 1.53529C102.677 1.74284 102.692 1.88653 102.539 2.70077L101.096 10.3323C101.004 10.8272 100.896 11.3381 100.896 11.7532C100.896 12.2481 101.157 12.8388 101.94 12.8388Z" fill="#FF503C" />
      <path d="M106.632 12.8388C107.83 12.8388 108.95 11.8809 109.687 11.1305L109.395 10.7633L109.027 10.9868C108.52 11.3221 108.259 11.3061 108.259 10.9709C108.259 10.7953 108.29 10.6516 108.321 10.4919L109.825 2.57305C110.025 1.51933 110.27 0.705091 110.27 0.705091L110.117 0.561401L106.479 0.896675L106.417 1.29581L106.955 1.53529C107.369 1.74284 107.384 1.88653 107.231 2.70077L105.788 10.3323C105.696 10.8272 105.588 11.3381 105.588 11.7532C105.588 12.2481 105.849 12.8388 106.632 12.8388Z" fill="#FF503C" />
      <path d="M115.176 12.8548C116.327 12.8548 117.309 11.9927 118.015 11.0667H118.077C118.046 11.2583 118 11.5935 118 11.7851C118 12.1842 118.123 12.8388 118.983 12.8388C120.134 12.8388 121.362 11.849 122.022 11.1625L121.715 10.7953L121.362 11.0188C120.871 11.3381 120.656 11.2902 120.656 10.907C120.656 10.7793 120.687 10.6516 120.717 10.4919L122.268 2.57305C122.467 1.53529 122.728 0.705091 122.728 0.705091L122.575 0.561401L118.937 0.896675L118.875 1.29581L119.382 1.53529C119.796 1.74284 119.827 1.8546 119.658 2.70077L119.566 3.17973C119.458 3.77046 119.382 4.29732 119.382 4.29732H119.32C119.167 4.21749 118.875 4.10573 118.384 4.10573C115.82 4.10573 113.272 7.0753 113.272 10.444C113.272 11.9607 113.963 12.8548 115.176 12.8548ZM116.511 11.3381C116.097 11.3381 115.851 11.0667 115.851 10.2844C115.851 7.65006 117.14 4.77628 118.338 4.77628C118.783 4.77628 119.044 5.04769 119.136 5.367L118.154 10.1247C117.724 10.6995 117.033 11.3381 116.511 11.3381Z" fill="#FF503C" />
      <path d="M124.229 12.8548C125.35 12.8548 126.255 12.0246 126.962 11.0986H127.023C126.977 11.2902 126.946 11.5935 126.946 11.7851C126.946 12.1842 127.038 12.8388 127.898 12.8388C129.034 12.8388 130.247 11.849 130.907 11.1625L130.6 10.7953L130.247 11.0188C129.755 11.3381 129.541 11.2902 129.541 10.907C129.541 10.7793 129.571 10.6516 129.602 10.4919L130.339 6.69213C130.677 4.9998 130.984 4.20152 130.984 4.20152L130.707 4.0738L129.679 4.79225H129.617C129.034 4.36118 128.435 4.10573 127.468 4.10573C124.92 4.10573 122.356 7.10723 122.356 10.5079C122.356 11.9607 123.016 12.8548 124.229 12.8548ZM125.565 11.2902C125.15 11.2902 124.966 11.0028 124.966 10.3003C124.966 7.63409 126.24 4.77628 127.361 4.77628C127.791 4.77628 127.959 5.03173 128.067 5.35104L127.1 10.1407C126.67 10.7154 126.087 11.2902 125.565 11.2902Z" fill="#FF503C" />
      <path d="M131.018 16C132.537 16 133.98 14.3875 135.669 12.0246C137.45 9.53398 139 6.77196 139 5.3191C139 4.4889 138.494 4.10573 137.941 4.10573C137.45 4.10573 136.958 4.40907 136.958 4.98383C136.958 5.65438 137.619 5.8779 137.619 6.42072C137.619 7.52234 136.697 9.27854 135.73 10.7474H135.669C135.561 8.79957 135.331 6.88372 135.024 5.367C134.84 4.42504 134.533 4.10573 133.965 4.10573C133.105 4.10573 131.908 5.03173 131.11 5.94176L131.417 6.34089L131.616 6.1972C132.261 5.71824 132.43 5.71824 132.629 6.51651C132.921 7.71392 133.274 10.3003 133.551 13.6211C132.86 14.4034 132.43 14.7228 132.046 14.7228C131.463 14.7228 131.355 13.8447 130.542 13.8447C130.035 13.8447 129.636 14.2119 129.636 14.7866C129.636 15.537 130.158 16 131.018 16Z" fill="#FF503C" />
    </svg>
  );
}
