import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Head from 'next/head';

import { package as packagePropType } from 'lib/CustomPropTypes';


import PackageHeader from 'components/PackageHeader';
import Navigation from 'components/packages/HeaderPackage/Navigation';
import Wrapper from 'components/packages/HeaderPackage/Wrapper';

import TitleLogo from 'components/TitleLogo';
import ShowLeadBackground from './Background';

import './styles.themed.scss';

const mapStateToProps = ({ front: { curation } }) => ({
  seoTitle: curation.seoTitle,
});

class ShowLead extends React.Component {
  static propTypes = {
    content: packagePropType.isRequired,
    pkgClassName: PropTypes.string,
    seoTitle: PropTypes.string,
  };

  static defaultProps = {
    pkgClassName: 'pkg lead showLead',
    seoTitle: '',
  }

  logo = () => {
    const { seoTitle, content = {} } = this.props;
    const { packageIndex, metadata = {} } = content;
    const { logoUrl, title } = metadata || {};

    if (!logoUrl) {
      return null;
    }

    return (
      <PackageHeader
        packageIndex={packageIndex}
        className="show-lead__show--name"
      >
        <TitleLogo logoUrl={logoUrl} alt={title || seoTitle} />
      </PackageHeader>
    );
  }

  title = () => {
    const {
      content: {
        metadata: {
          title,
        },
        packageIndex,
      } = {},
    } = this.props;

    if (title) {
      return (
        <PackageHeader
          className="show-lead__show--name"
          packageIndex={packageIndex}
        >
          {title}
        </PackageHeader>
      );
    }
    return null;
  }

  render() {
    const {
      content: {
        id,
        metadata: {
          description,
          imageUrl,
          imageUrlMobile,
          logoUrl,
          navigation,
        },
        context: {
          isFluidWidthPage,
        },
      },
      pkgClassName,
    } = this.props;

    // Filter to remove empty links
    let filteredNavigation = [];
    if (Array.isArray(navigation)) {
      filteredNavigation = navigation.filter((item) => typeof item.linkUrl !== 'undefined');
    }

    return (
      <>
        <Head>
          {imageUrl && <link rel="preload" as="image" href={imageUrl} />}
          {imageUrlMobile && <link rel="preload" as="image" href={imageUrlMobile} />}
          {logoUrl && <link rel="preload" as="image" href={logoUrl} />}
        </Head>
        <Wrapper
          id={id}
          className={classNames(pkgClassName, 'show-lead')}
        >
          <div className="show-lead__wrapper relative">
            { !isFluidWidthPage
              ? (
                <ShowLeadBackground
                  imageUrl={imageUrl}
                  imageUrlMobile={imageUrlMobile}
                />
              )
              : null }
            <div className={classNames('show-lead__content', 'layout-grid-container')}>
              <div className={classNames('show-lead__meta--wrap', 'z-1')}>
                <div className={classNames('show-lead__meta-data', 'layout-grid-item')}>
                  {logoUrl && this.logo()}
                  {!logoUrl && this.title()}
                  {description && (
                    <h3 className="show-lead__air-time">
                      {description}
                    </h3>
                  )}
                  <Navigation
                    navigation={filteredNavigation}
                    styleOverride={{
                      linkListWrapper: 'show-lead__link-list--wrapper',
                      linkList: 'show-lead__link-list',
                      linkListItem: 'show-lead__link-list--item',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </>
    );
  }
}

export default connect(mapStateToProps)(ShowLead);
