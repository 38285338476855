import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Ad from 'components/Ad';
import Link from 'components/Link';
import TitleLogo from 'components/TitleLogo';

import styles from './styles.module.scss';

function StickyAdTrack(props) {
  const { t } = useTranslation();

  const {
    adSlot,
    children,
    dataTest,
    id,
    metadata: {
      logoUrl,
      seeAllText = t('See All'),
      seeAllUrl,
      title,
    },
    mirror,
    pkgClassName,
  } = props;

  const firstPkgIndex = children[0]?.props?.content?.packageIndex || 0;

  const hasTitle = !!title;

  return (
    <div
      className={classNames(
        pkgClassName,
        styles.stickyAdTrack,
        `pkg-index-${firstPkgIndex}`,
        {
          [styles.mirror]: mirror,
          [styles.hasTitle]: hasTitle,
        },
      )}
      data-packageid={id}
      data-test={dataTest}
    >
      <section className="layout-grid-container gutter-collapse">
        <div
          className={classNames(
            'layout-grid-item grid-col-5-l grid-col-4-xl',
            styles.adColumn,
          )}
        >
          <Ad
            adClass={styles.ad}
            manuallyCuratedAd
            offsetViewport={adSlot === 'boxinline' ? 50 : 100}
            slot={adSlot}
            sticky
          />
        </div>
        <div
          className={classNames(
            'layout-grid-item grid-col-7-l grid-col-8-xl',
            styles.contentColumn,
          )}
        >
          {hasTitle && (
            <h2 className={styles.head}>
              <span className={styles.title}>
                <TitleLogo logoUrl={logoUrl} title={title} />
              </span>
              {seeAllUrl && (
                <Link href={seeAllUrl} className={styles.link}>
                  {seeAllText}
                </Link>
              )}
            </h2>
          )}
          <div className={styles.contentItems}>
            {children}
          </div>
        </div>
      </section>
    </div>
  );
}

StickyAdTrack.propTypes = {
  adSlot: PropTypes.string,
  children: PropTypes.node.isRequired,
  dataTest: PropTypes.string,
  id: PropTypes.string,
  metadata: PropTypes.shape({
    logoUrl: PropTypes.string,
    seeAllText: PropTypes.string,
    seeAllUrl: PropTypes.string,
    title: PropTypes.string,
  }),
  mirror: PropTypes.bool,
  pkgClassName: PropTypes.string,
};

StickyAdTrack.defaultProps = {
  adSlot: 'boxflex',
  dataTest: '',
  id: null,
  metadata: {
    logoUrl: null,
    seeAllText: null,
    seeAllUrl: null,
    title: null,
  },
  mirror: false,
  pkgClassName: 'pkg stickyAdTrack',
};

export { StickyAdTrack };
