import React from 'react';

export function FallbackImage() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1120 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="fallback-image"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <path
        d="M1108.23 11.7609H11.7704V547.787H1108.23V11.7609Z"
        fill="#FFF2EB"
        stroke="white"
        strokeWidth="26"
      />
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M736.858 357.483C733.096 266.859 655.965 194.507 561.364 194.507C467.419 194.507 390.681 265.882 385.947 355.639C385.783 358.57 385.707 361.501 385.707 364.444H431.253C431.253 361.599 431.354 358.741 431.556 355.932C436.101 290.468 492.578 238.574 561.364 238.574C630.402 238.574 687.057 290.871 691.223 356.677C691.388 359.254 691.476 361.831 691.476 364.444H737.009C737.009 362.124 736.971 359.779 736.858 357.483Z"
        fill="#FF503C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M561.358 308.727C532.803 308.727 509.019 328.16 503.856 353.887C503.174 357.298 502.808 360.829 502.808 364.444H619.909C619.909 361.73 619.707 359.088 619.316 356.517C615.276 329.494 590.873 308.727 561.358 308.727Z"
        fill="#FF503C"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M562.752 250.224C499.928 250.224 448.537 297.348 444.513 356.921C444.321 359.396 444.258 361.908 444.258 364.444H490.335C490.335 361.723 490.5 359.014 490.82 356.367C494.984 321.692 525.668 294.64 562.752 294.64C600.04 294.64 630.839 321.95 634.748 356.896C635.029 359.383 635.169 361.883 635.169 364.444H681.247C681.247 361.994 681.17 359.605 681.017 357.192C677.134 297.484 625.665 250.224 562.752 250.224Z"
        fill="#FF503C"
      />
    </svg>
  );
}
