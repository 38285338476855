import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { withCookies, Cookies } from 'react-cookie';
import { ACCESS_ENABLER_OBJ, configTempPass } from 'lib/tve';
import TVECountdown from 'components/TVE/Countdown';

class Countdown extends Component {
  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired,
  };

  getExpiration = () => {
    const { expirationName } = configTempPass;
    const { cookies } = this.props;
    return parseInt(cookies.get(expirationName), 10);
  };

  render() {
    return (
      <span>
        <Trans>Daily preview expires in</Trans>
        &nbsp;
        <TVECountdown
          end={this.getExpiration()}
          onEndCallback={() => {
            if (
              typeof window !== 'undefined'
              && typeof window[ACCESS_ENABLER_OBJ] !== 'undefined'
              && typeof window[ACCESS_ENABLER_OBJ].logout === 'function'
            ) {
              window[ACCESS_ENABLER_OBJ].logout();
            }
          }}
        />
      </span>
    );
  }
}

export default withCookies(Countdown);
