export function parseLayoutTemplate(args) {
  if (!args) return null;
  const { layoutTemplate } = args;
  if (!layoutTemplate) return null;

  const parts = layoutTemplate.split('-');
  const numberParts = parts.map(Number);
  const totalItems = numberParts.reduce((ac, num) => (ac + num), 0);
  return {
    name: layoutTemplate,
    itemsPerColumn: numberParts,
    totalItems,
    layoutColumns: parts.length,
  };
}
