/* eslint-disable max-len */

/**
 * Hard-coded map that connects show IDs to their thumbnails stored in the news CDN.
 */
export const SHOW_DETAILS_MAP = {
  SH000028660000: {
    thumbnail: 'https://media2.s-nbcnews.com/i/newscms/2020_40/3415586/New Image - Meet The Press Daily_55dc9105a08f4b55844eaf37052a21d9.png',
    frontURL: 'https://www.nbc.com/meet-the-press',
  },
  SH000213900000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/1-MJCompositeSILO-302e25.png',
    frontURL: 'https://www.msnbc.com/morning-joe',
  },
  SH010835610000: {
    thumbnail: 'https://res.cloudinary.com/nbcnewsdigital-uat/image/upload/t_focal-200x200/rockcms/2023-04/msnbcschedule-rachelmaddow-e9d462.png',
    frontURL: 'https://www.msnbc.com/rachel-maddow-show',
  },
  SH011659250000: {
    thumbnail: 'https://media2.s-nbcnews.com/i/newscms/2020_40/3415575/New Image - Andrea Mitchell Reports_55dc9105a08f4b55844eaf37052a21d9.png',
    frontURL: 'https://www.msnbc.com/andrea-mitchell',
  },
  SH013300400000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/1LawrenceSILO-024e22.png',
    frontURL: 'https://www.msnbc.com/the-last-word',
  },
  SH014695780000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/2SharptonSILO-5bc648.png',
    frontURL: 'https://www.msnbc.com/politicsnation',
  },
  SH038130990000: {
    thumbnail: 'https://media1.s-nbcnews.com/i/newscms/2020_40/3415596/New Image - Weekends with Alex Witt_55dc9105a08f4b55844eaf37052a21d9.png',
    frontURL: 'https://www.msnbc.com/weekends-with-alex',
  },
  SH017174310000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/HayesSilo042723-1df05f.png',
    frontURL: 'https://www.msnbc.com/all',
  },
  SH022745780000: {
    thumbnail: 'https://media2.s-nbcnews.com/i/newscms/2020_40/3415586/New Image - Meet The Press Daily_55dc9105a08f4b55844eaf37052a21d9.png',
    frontURL: 'https://www.msnbc.com/mtp-daily',
  },
  SH024311730000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/6JoydesksmileheadonSILOcrop-24a8b4.png',
    frontURL: 'https://www.nbc.com/am-joy',
  },
  SH025041300000: {
    thumbnail: 'https://media4.s-nbcnews.com/i/newscms/2020_40/3415589/New Image - The 11th Hour with Brian Williams_55dc9105a08f4b55844eaf37052a21d9.png',
    frontURL: 'https://www.msnbc.com/11th-hour-with-Brian-Williams',
  },
  SH038130410000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/2Ruhle-headshot-silo-941a37.png',
    frontURL: 'https://www.msnbc.com/stephanie-ruhle',
  },
  SH038130770000: {
    thumbnail: 'https://media1.s-nbcnews.com/i/newscms/2020_40/3415581/New Image - MSNBC Live with Craig Melvin_55dc9105a08f4b55844eaf37052a21d9.png',
    frontURL: 'https://www.msnbc.com/craig-melvin',
  },
  SH038130630000: {
    thumbnail: 'https://media1.s-nbcnews.com/i/newscms/2020_40/3415582/New Image - MSNBC Live with Hallie Jackson_55dc9105a08f4b55844eaf37052a21d9.png',
    frontURL: 'https://www.msnbc.com/msnbc-live-hallie-jackson',
  },
  SH026894040000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/2NicolleBTSsoftsmileSILO-2822c9.png',
    frontURL: 'https://www.msnbc.com/deadline-white-house',
  },
  SH038130830000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/6KatyheadshotSILO-1463c0.png',
    frontURL: 'https://www.msnbc.com/msnbc-live-katy-tur',
  },
  SH027437400000: {
    thumbnail: 'https://media1.s-nbcnews.com/i/newscms/2020_40/3415590/New Image - The Beat with Ari Melber_55dc9105a08f4b55844eaf37052a21d9.png',
    frontURL: 'https://www.msnbc.com/the-beat-with-ari-melber',
  },
  SH038130510000: {
    thumbnail: 'https://media3.s-nbcnews.com/i/newscms/2020_51/3435620/168x168_yasmin_vossoughian_4c59c3b96e7521464bf2b6baecbb35b7.png',
    frontURL: 'https://www.msnbc.com/yasmin-vossoughian',
  },
  SH034467190000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/1VelshiSILO-bbfce0.png',
    frontURL: 'https://www.msnbc.com/velshi',
  },
  SH035600410000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/6JoydesksmileheadonSILOcrop-24a8b4.png',
    frontURL: 'https://www.msnbc.com/reidout',
  },
  SH036147300000: {
    thumbnail: 'https://media4.s-nbcnews.com/i/newscms/2020_40/3415579/New Image - Kasie DC_55dc9105a08f4b55844eaf37052a21d9.png',
    frontURL: 'https://www.msnbc.com/way-too-early-kasie-hunt',
  },
  SH036137750000: {
    thumbnail: 'https://media2.s-nbcnews.com/i/newscms/2020_51/3435614/168x168_joshua_johnson_4c59c3b96e7521464bf2b6baecbb35b7.png',
    frontURL: 'https://www.msnbc.com/the-week-joshua-johnson',
  },
  SH037118540000: {
    thumbnail: 'https://media3.s-nbcnews.com/i/newscms/2020_51/3435615/168x168_tiffany_cross_4c59c3b96e7521464bf2b6baecbb35b7.png',
    frontURL: 'https://www.msnbc.com/cross-connection',
  },
  SH037118500000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/1Capehartheadshotsmilesilocrop-14e85b.png',
    frontURL: 'https://www.msnbc.com/sunday-show',
  },
  SH036137810000: {
    thumbnail: 'https://media4.s-nbcnews.com/i/newscms/2020_51/3435612/168x168_alicia_menendez_4c59c3b96e7521464bf2b6baecbb35b7.png',
    frontURL: 'https://www.msnbc.com/american-voices-alicia-menendez',
  },
  SH040702640000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/7LemireSilo-2f6d50.png',
    frontURL: 'https://www.msnbc.com/way-too-early',
  },
  SH039989950000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/2JDBheadshotseriousSILO-1954c6.png',
    frontURL: 'https://www.msnbc.com/jose-diaz-balart',
  },
  SH043112210000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-05/JansingSilo050123-6f56fd.png',
    frontURL: 'https://www.msnbc.com/chris-jansing-reports',
  },
  SH044078750000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/1AlexheadshotseriousSILO-f6e155.png',
    frontURL: 'https://www.msnbc.com/alex-wagner-tonight',
  },
  SH041987080000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/2Ruhle-headshot-silo-941a37.png',
    frontURL: 'https://www.msnbc.com/11th-hour',
  },
  SH046450140000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/1-MJCompositeSILO-302e25.png',
    frontURL: 'https://www.msnbc.com/morning-joe',
  },
  SH042553820000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/3KatieheadshotsmileSILO-a9a58e.png',
    frontURL: 'https://www.msnbc.com/katie-phang',
  },
  SH047078540000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/3ACSoftSmileStandingSILOcrop-e30dc2.png',
    frontURL: 'https://www.msnbc.com/ana-cabrera-reports',
  },
  SH046409500000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/1Capehartheadshotsmilesilocrop-14e85b.png',
    frontURL: 'https://www.msnbc.com/capehart',
  },
  SH042728650000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/1Symone19763801220-e88b9d.png',
    frontURL: 'https://www.msnbc.com/symone',
  },
  SH039993570000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/1AymanBTS-2111f5.png',
    frontURL: 'https://www.msnbc.com/ayman-mohyeldin',
  },
  SH046694410000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/1PsakiSittingBlueSILO-d43b51.png',
    frontURL: 'https://www.msnbc.com/jen-psaki',
  },
  SH036365900000: {
    thumbnail: 'https://media-cldnry.s-nbcnews.com/image/upload/t_focal-200x200/rockcms/2023-04/1MedhiDeskSeriousSilo-da7e2c.png',
    frontURL: 'https://www.msnbc.com/mehdi-hasan',
  },
};

export const DEFAULT_THUMBNAIL = 'https://media-cldnry.s-nbcnews.com/image/upload/newsgroup-logos/msnbc/schedule/default-168x168.png';
