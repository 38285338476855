import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.themed.scss';

const RailContainer = ({ children, className }) => (
  <div className={classNames('rail__container', className)} data-testid="rail-container">
    {children}
  </div>
);

RailContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

RailContainer.defaultProps = {
  className: null,
};

export { RailContainer };
