import React from 'react';
import PropTypes from 'prop-types';

import VideoPlayer from 'components/VideoPlayer';
import { ErrorBoundary } from 'components/ErrorBoundary';

import { content as ContentPropType } from 'lib/CustomPropTypes';


/**
 * Function to render a regular video player
 * @param  {object} item - the video item to render
 * @param  {boolean} isRail - a boolean indicating whether or not the item is in a rail
 * @returns {React.ReactElement|null}
 */
const NDPVideo = ({ item, isRail }) => {
  if (!item || item.type !== 'video') {
    return null;
  }

  const autoPlayCapabilities = {
    canAutoplayMuted: true,
    canAutoplayUnmuted: false,
    isTestingAutoplayCapabilities: false,
  };

  return (
    <ErrorBoundary>
      <VideoPlayer
        video={item.item}
        onlyAutoplayMuted
        isRailLayout={isRail}
        isLiveVideoEmbed
        autoplayCapabilities={autoPlayCapabilities}
        autoPlay
        playButtonStyle="live-video-embed__ndp-play-button"
        disableStickyOnMute
        shouldPauseWhenMutedAndOutOfView
      />
    </ErrorBoundary>
  );
};

NDPVideo.propTypes = {
  item: ContentPropType.isRequired,
  isRail: PropTypes.bool.isRequired,
};

export default NDPVideo;
