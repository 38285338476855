import React from 'react';

export function Circles() {
  return (
    <svg fill="none" viewBox="0 0 366 176" xmlns="http://www.w3.org/2000/svg">
      <g fill="#ffb186">
        <path d="m.157373 7.47363c3.908597 93.71037 84.048127 168.52637 182.340627 168.52637 97.61 0 177.343-73.806 182.262-166.6193.17-3.03105.249-6.0621.249-9.105777l-47.323-.000004c0 2.942641-.105 5.897911-.315 8.802671-4.722 67.69341-63.403 121.35541-134.873 121.35541-71.732 0-130.5975-54.0787-134.9258-122.12583-.1705-2.66479-.2623-5.32959-.2623-8.032275l-47.30993316-.000004c-.00000021 2.399579.03936156 4.824419.15740616 7.198739" />
        <path d="m181.776 58.1818c29.866.0001 54.742-20.2927 60.142-47.1575.713-3.56186 1.096-7.24918 1.096-11.02429106l-122.476-.00001071c0 2.83447177.211 5.59368177.62 8.27765177 4.225 28.21925 29.748 49.90415 60.618 49.90415" />
        <path d="m181.046 117.819c64.549 0 117.351-48.6086 121.485-110.05804.197-2.55298.263-5.14407.263-7.76055506l-47.342-.00000414c0 2.8070092-.171 5.6013192-.499 8.3321192-4.279 35.76718-35.805 63.67218-73.907 63.67218-38.311 0-69.956-28.1718-73.972-64.21835-.289-2.56569-.433-5.14407-.433-7.78596221l-47.3419-.00000414c0 2.52757635.0787 4.99164635.2362 7.48112635 3.99 61.58909 56.8707 110.33749 121.5107 110.33749" />
      </g>
    </svg>
  );
}
