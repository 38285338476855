
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useGeoLocation, useTracking } from 'lib/Hooks';

import {
  loadNews,
  loadLocationByZip,
  loadLocalWeather,
  RESET_LOCAL_NEWS,
} from 'redux/modules/localnews';

import { useMyNewsStore } from 'store';

import ButtonHoverAnimation from 'components/ButtonHoverAnimation';
import Link from 'components/Link';
import { LocalNewsStories } from 'components/MyNews/LocalNews/LocalNewsStories';
import { LocalNewsMap } from 'components/MyNews/LocalNews/LocalNewsMap';
import { Loading } from 'components/MyNews/Loading';
import {
  AUTHENTICATED,
  UNINITIALIZED,
  UNAUTHENTICATED,
} from 'lib/myNewsConstants';

import userAuthenticatedIcon from 'assets/images/logged-in-user.svg';
import userUnauthenticatedIcon from 'assets/images/logged-out-user.svg';

import styles from './styles.module.scss';

const AUTH_STATES = {
  uninitialized: UNINITIALIZED,
  unauthenticated: UNAUTHENTICATED,
  authenticated: AUTHENTICATED,
};

const LocalNews = ({ placement }) => {
  const authenticationState = useMyNewsStore((state) => state.authenticationState);
  const authenticate = useMyNewsStore((state) => state.authenticate);
  const customer = useMyNewsStore((state) => state.customer);
  const saveCustomer = useMyNewsStore((state) => state.saveCustomer);

  const trackSubmit = useTracking('mbt_mynews_local_zip_submit');
  const trackLogin = useTracking('mbt_mynews_local_login_click');

  const handleLogin = () => {
    trackLogin();
    authenticate();
  };

  const dispatch = useDispatch();

  const {
    city,
    isLoading,
    news,
    state,
  } = useSelector((store) => store.localnews || {});

  const { isUsa } = useGeoLocation();

  const {
    customerId,
    zipCode: customerZip,
  } = customer;

  const handleSubmit = async (submittedZip) => {
    if (customerZip !== submittedZip) {
      saveCustomer({
        zipCode: submittedZip,
      });
      await dispatch(loadLocationByZip(submittedZip));
      trackSubmit({
        zip: submittedZip,
        status: customerZip ? 'change' : 'new',
        placement,
      });
      dispatch(loadNews(submittedZip));
    }
  };

  useEffect(() => {
    // watches for changes to zipCode and dispatches calls to update local news
    if (customerZip) {
      dispatch(loadLocationByZip(customerZip));
      dispatch(loadNews(customerZip));
      dispatch(loadLocalWeather(customerZip));
    }
  }, [customerZip]);

  useEffect(() => {
    if (authenticationState === AUTH_STATES.unauthenticated) {
      dispatch({ type: RESET_LOCAL_NEWS });
    }
  }, [authenticationState]);

  const isThereLocalNews = news.length || news.error;
  const unauthenticated = authenticationState === AUTH_STATES.authenticated ? null : 'unauthenticated';
  const isInitializing = authenticationState === AUTH_STATES.uninitialized;
  const isAuthenticated = authenticationState === AUTH_STATES.authenticated;
  const LocalNewsComponent = isThereLocalNews ? (
    <LocalNewsStories
      news={news}
      state={state}
      city={city}
      currentZip={customerZip}
      handleSubmit={handleSubmit}
    />
  ) : (
    <LocalNewsMap
      showHeader
      handleSubmit={handleSubmit}
      style={{ height: '570px' }}
    />
  );

  if (!isUsa) return null;

  return (
    <div className={`local-news-pkg ${styles.localNewsPackage}`} data-testid="local-news">
      <div className={styles.header}>
        Local
        {customerId && (
          <Link
            className={styles.myNewsLink}
            href="/my-news"
            icid="local_news_package"
            data-testid="my-news-link"
          >
            <img src={userAuthenticatedIcon} alt="user-authenticated icon" />
            <span className={styles.myNewsLabel}>
              my news
            </span>
          </Link>
        )}
      </div>

      {(isInitializing || isLoading) ? (
        <Loading amountOfLoadingDivs={4} />
      ) : (
        <>
          <div className={classNames(
            styles.overlay,
            { [styles.unauthenticated]: unauthenticated },
          )}
          >
            <h2 className={styles.overlayHed}>
              Your personalized news, including saved and local stories
            </h2>
            <p className={styles.overlayDek}>
              Access these features with your free account.
            </p>
            <img
              alt="user-unauthenticated icon"
              className={styles.overlayImg}
              src={userUnauthenticatedIcon}
            />
            <ButtonHoverAnimation
              additionalClasses={styles.overlayButton}
              onClick={handleLogin}
              title="Create Profile or log in"
              type="button"
            />
          </div>
          <div style={{ display: `${!isAuthenticated ? 'none' : 'block'}` }}>
            {LocalNewsComponent}
          </div>
        </>
      )}
    </div>
  );
};

LocalNews.propTypes = {
  placement: PropTypes.string.isRequired,
};

LocalNews.defaultProps = {};

export { LocalNews };
