
import classNames from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import { layoutContext as LayoutContextPropType, package as PackagePropType } from 'lib/CustomPropTypes';
import { Button } from 'components/Button';
import { PackageTitleTopBorder } from 'components/PackageTitleTopBorder';
import PackageTitle from 'components/PackageTitle';
import WideTease from 'components/WideTease';

import Ad from 'components/Ad';
import styles from './styles.module.scss';

const POSSIBLE_METADATA = {
  ALLTEXT: 'seeAllText',
  ALLURL: 'seeAllUrl',
  BRAND: 'showBrand',
  DEK: 'showDek',
  EYEBROW: 'showEyebrow',
  LOADMORE: 'showLoadMore',
  LOGO: 'logoUrl',
  TEASE: 'showTeaseImage',
  TIMESTAMP: 'showTimestamp',
  TITLE: 'title',
};


const ITEMS_PER_PAGE = 20;
const INITIAL_NUMBER_ITEMS = 11;

export class Feeds extends Component {
  static propTypes = {
    additionalClasses: PropTypes.string,
    content: PackagePropType.isRequired,
    isRail: PropTypes.bool,
    pkgClassName: PropTypes.string,
    vertical: PropTypes.string.isRequired,
    isFullWidth: PropTypes.bool,
  };

  static defaultProps = {
    additionalClasses: '',
    isRail: false,
    pkgClassName: 'pkg feeds',
    isFullWidth: false,
  };

  static contextTypes = {
    ...LayoutContextPropType,
  };

  constructor(props) {
    super(props);
    const {
      content: {
        items,
      },
    } = props;
    const loadMore = this.getMetadata([POSSIBLE_METADATA.LOADMORE]);
    let numberOfItems = INITIAL_NUMBER_ITEMS;
    if (!loadMore || numberOfItems >= items.length) {
      numberOfItems = items.length;
    }

    this.state = {
      numberOfItems,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      content: {
        items,
      },
    } = this.props;

    const {
      content: {
        items: prevItems,
      },
    } = prevProps;

    if (prevItems.length !== items.length) {
      this.updateNumberOfItems();
    }
  }

  updateNumberOfItems = () => {
    const {
      content: {
        items,
      },
    } = this.props;

    const loadMore = this.getMetadata([POSSIBLE_METADATA.LOADMORE]);

    let numberOfItems = INITIAL_NUMBER_ITEMS;
    if (!loadMore || numberOfItems >= items.length) {
      numberOfItems = items.length;
    }

    this.setState({
      numberOfItems,
    });
  }

  getMetadata = (path, defaultValue) => {
    const {
      content: {
        metadata,
      },
    } = this.props;

    return metadata?.[path] ?? (typeof defaultValue !== 'undefined' ? defaultValue : true);
  };

  isLastPage = () => {
    const {
      content: {
        items,
      },
    } = this.props;
    const {
      numberOfItems,
    } = this.state;


    return numberOfItems >= items.length;
  }

  onClickLoadMore = () => {
    const {
      content: {
        items,
      },
    } = this.props;
    const {
      numberOfItems,
    } = this.state;

    let numberToShow = numberOfItems + ITEMS_PER_PAGE;

    if (numberToShow >= items.length) {
      numberToShow = items.length;
    }

    this.setState({
      numberOfItems: numberToShow,
    });
  }

  renderInlineAd = () => {
    const {
      content: {
        id,
      },
    } = this.props;

    return (
      <Fragment key={id}>
        <div className="dn db-m">
          <Ad
            key={id}
            packageId={id}
            {...this.props}
            slot="topbanner"
            offsetViewport={100}
            pkgClassName="pkg-ad ad ad--centered"
            refreshInterval={0}
          />
        </div>
        <div className="dn-m">
          <Ad
            key={id}
            packageId={id}
            {...this.props}
            slot="boxinline"
            offsetViewport={50}
            pkgClassName="pkg-ad ad ad--centered"
            refreshInterval={0}
          />
        </div>
      </Fragment>
    );
  };

  renderArticleFeedsListItemContainer = (article, index, isLastArticle) => {
    if (!article) {
      return null;
    }
    const {
      id,
      computedValues,
      metadata: contentMetadata,
    } = article;
    const {
      content: {
        metadata: packageMetadata,
      },
      vertical,
      isRail,
    } = this.props;

    const title = packageMetadata?.title;
    const hasPackageTitle = !!title;
    if (!computedValues) {
      return null;
    }
    const metadata = {
      ...packageMetadata,
      ...contentMetadata,
    };
    return (
      <WideTease
        key={id}
        hideBorder={!hasPackageTitle && index === 0}
        content={
          {
            ...article,
            metadata,
          }
        }
        isLastArticle={isLastArticle}
        vertical={vertical}
        isRail={isRail}
      />
    );
  };

  // Insert ads into a feed list at position 5, 15, 25, 35, etc.
  insertInlineAdsIntoFeedsList = (items) => items
    .map((item, index) => {
      const idx = index + 1;
      return (idx / 5) % 2 === 1 && idx % 5 === 0 ? [item, { type: 'Ad', id: `Ad-${idx}` }] : item;
    })
    .flat();

  // Return the article list markup
  renderFeedsListItems = () => {
    const {
      content: {
        items,
      },
      isFullWidth,
    } = this.props;
    const { numberOfItems } = this.state;
    const { isFluidWidthPage } = this.context;
    const itemsSliced = items.slice(0, numberOfItems);
    const itemsWithAds = this.insertInlineAdsIntoFeedsList(itemsSliced);
    return (
      <div className={styles.itemsContainer}>
        {itemsWithAds.map((item, index) => {
          if (isFluidWidthPage && isFullWidth && item.type === 'Ad') return this.renderInlineAd();
          return this.renderArticleFeedsListItemContainer(
            item,
            index,
            this.isLastPage() && (numberOfItems - 1) === index,
          );
        })}
      </div>
    );
  };

  renderPackageTitle = () => {
    const { isFluidWidthPage } = this.context;
    const title = this.getMetadata(POSSIBLE_METADATA.TITLE, null);
    const loadMore = this.getMetadata(POSSIBLE_METADATA.LOADMORE, true);
    // hide see all if load more
    const seeAllText = loadMore ? null : this.getMetadata(POSSIBLE_METADATA.ALLTEXT, null);
    const seeAllUrl = loadMore ? null : this.getMetadata(POSSIBLE_METADATA.ALLURL, null);
    const logoUrl = this.getMetadata(POSSIBLE_METADATA.LOGO, null);

    return (
      isFluidWidthPage ? (
        <PackageTitle
          metadata={{ title }}
        />
      ) : (
        <PackageTitleTopBorder
          title={title}
          seeAllText={seeAllText}
          seeAllUrl={seeAllUrl}
          logoUrl={logoUrl}
        />
      )
    );
  };

  render() {
    const {
      pkgClassName,
      additionalClasses,
      content: {
        id,
      },
    } = this.props;

    const loadMore = this.getMetadata([POSSIBLE_METADATA.LOADMORE]);
    const showLoadButton = !this.isLastPage() && loadMore;

    return (
      <section
        data-packageid={id}
        className={classNames('feeds', pkgClassName, additionalClasses)}
      >
        <div>
          {this.renderPackageTitle()}
          {this.renderFeedsListItems()}

          {showLoadButton && (
            <div className={classNames(
              styles.loadMoreWrapper,
              {
                [styles.noTimestamp]: !this.getMetadata([POSSIBLE_METADATA.TIMESTAMP]),
              },
            )}
            >
              <Button
                type="button-tilt"
                displayType="no-arrow"
                additionalClasses={styles.button}
                onClick={this.onClickLoadMore}
                title={i18next.t('Load More')}
                size="normal"
                isFullWidth
              />
            </div>
          )}
        </div>
      </section>
    );
  }
}
