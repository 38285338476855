import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NAVBAR_THEME } from 'lib/navbar';
import BTE from 'lib/BTE';
import { setTheme as setThemeAction } from 'redux/modules/navbar';

const transitionTheme = NAVBAR_THEME.LIGHT;


const mapActionsToProps = (dispatch) => ({
  setTheme: (theme) => dispatch(setThemeAction(theme)),
});

const mapStateToProps = ({
  layout,
  navbar,
}) => ({
  triggerTop: layout.triggerTop,
  navTheme: navbar.theme,
});

@connect(mapStateToProps, mapActionsToProps)
class Wrapper extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    setTheme: PropTypes.func.isRequired,
    navTheme: PropTypes.string.isRequired,
    packageIndex: PropTypes.number,
    triggerTop: PropTypes.number,
  }

  static contextTypes = {
    layoutIndex: PropTypes.number,
  }

  static defaultProps = {
    packageIndex: 0,
    triggerTop: 0,
  }

  constructor(props) {
    super(props);
    const { navTheme } = this.props;
    this.state = {
      originalTheme: navTheme, // Store original theme value for transition
    };
  }

  componentDidMount() {
    const { originalTheme } = this.state;
    const { layoutIndex } = this.context;

    const {
      navTheme,
      packageIndex,
      setTheme,
      triggerTop,
    } = this.props;

    // Only monitor scrolling on first package of layout and a theme transition has been configured
    if (layoutIndex === 0 && packageIndex === 0 && originalTheme !== transitionTheme) {
      BTE.on('scroll', this.handleScroll, 'throttle');

      if (window.pageYOffset > triggerTop && navTheme !== transitionTheme) {
        setTheme(transitionTheme);
      }
    }
  }

  componentWillUnmount() {
    const { layoutIndex } = this.context;
    const { packageIndex } = this.props;

    if (layoutIndex === 0 && packageIndex === 0) {
      BTE.remove('scroll', this.handleScroll);
    }
  }

  handleScroll = (depth) => {
    const { originalTheme } = this.state;

    const {
      navTheme,
      setTheme,
      triggerTop,
    } = this.props;

    if (depth > triggerTop && navTheme !== transitionTheme) {
      setTheme(transitionTheme);
      return;
    }

    if (depth <= triggerTop && navTheme !== originalTheme) {
      setTheme(originalTheme);
    }
  }

  render() {
    const {
      id,
      className,
      children,
    } = this.props;

    return (
      <div
        data-packageid={id}
        className={className}
        ref={(e) => { this.container = e; }}
      >
        {children}
      </div>
    );
  }
}

export default Wrapper;
