import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18next from 'i18next';

import { content as ContentPropType } from 'lib/CustomPropTypes';

import Link from 'components/Link';
import { BylineList } from 'components/Byline';
import { Unibrow } from 'components/Unibrow';
import TimeAgo from 'components/packages/LiveBlog/TimeAgo';
import { MainStoryDek } from '../MainStoryDek/index';

import { MAIN_ART_TEASE_VALID_SIZES } from '../constants';

const block = 'smorgasbord-meta-content';

export const StandardMetaContent = (
  {
    item,
    isRelatedContent,
    showByline,
    showEyebrow,
    showDek,
    showHeadline,
    size,
  },
) => {
  const {
    computedValues,
    type,
    item: innerItem,
    metadata: { useEyebrowAsTag },
  } = item || {};

  const {
    headline,
    url,
    unibrow,
    dek,
  } = computedValues || {};

  const isLiveBlog = type === 'liveBlog';
  const isLiveBlogCard = type === 'card';
  const isVideo = type === 'video';
  const authors = innerItem?.authors ?? [];
  const shouldShowEyebrow = !isLiveBlog && !isLiveBlogCard
  && showEyebrow && unibrow && !useEyebrowAsTag;
  const shouldShowByline = !isLiveBlog && !isLiveBlogCard
  && showByline && !isVideo && authors.length >= 1;
  const shouldShowCardTimestamp = isRelatedContent && isLiveBlogCard;
  const cardTimestamp = innerItem?.date?.publishedAt;

  if (isLiveBlogCard) {
    return null;
  }

  return (
    <>
      <h2
        className={classNames(`${block}__headline`, {
          [`${block}__headline--related`]: isRelatedContent,
          [`${block}__headline--related-live-blog`]: isRelatedContent && isLiveBlog,
          [`${block}__headline--${size}`]: !!size,
          [`${block}__headline--hidden`]: !showHeadline,
        })}
        data-test={`${block}__headline`}
      >
        {isLiveBlog && showHeadline && (
          <span className={`${block}__live-blog-header`}>
            {i18next.t('Live Updates')}
            &nbsp;/&nbsp;
          </span>
        )}
        <Link to={url}>{headline}</Link>
      </h2>
      <span className={classNames(`${block}__span`, {
        [`${block}__span--headline-hidden`]: !showHeadline,
      })}
      >
        {showDek && <MainStoryDek dek={dek} />}
        {shouldShowByline && (
          <span
            className={classNames(`${block}__author`, {
              [`${block}__author--link`]: !!authors.length,
            })}
          >
            <BylineList
              additionalClasses={classNames(`${block}__author`, {
                [`${block}__author--link`]: !!authors.length,
              })}
              authors={authors}
            />
          </span>
        )}
        {shouldShowByline && shouldShowEyebrow && (
          <span className={`${block}__slash`} data-test={`${block}__slash`}>
            {' '}
            /
            {' '}
          </span>
        )}
        {shouldShowEyebrow && (
          <Unibrow
            className={classNames(`${block}__eyebrow`, {
              [`${block}__eyebrow--related`]: isRelatedContent,
            })}
            unibrow={unibrow}
          />
        )}
        {shouldShowCardTimestamp && cardTimestamp && (
          <TimeAgo displayDate={cardTimestamp} />
        )}
      </span>
    </>
  );
};

StandardMetaContent.propTypes = {
  item: ContentPropType.isRequired,
  showByline: PropTypes.bool,
  showEyebrow: PropTypes.bool,
  showDek: PropTypes.bool,
  showHeadline: PropTypes.bool,
  isRelatedContent: PropTypes.bool,
  size: PropTypes.oneOf([null, ...MAIN_ART_TEASE_VALID_SIZES]),
};

StandardMetaContent.defaultProps = {
  showByline: true,
  showEyebrow: true,
  showDek: false,
  showHeadline: true,
  isRelatedContent: false,
  size: null,
};
