import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  package as PackagePropType,
  layoutContext as LayoutContextPropType,
} from 'lib/CustomPropTypes';
import { teaseCard as paidContent } from 'lib/paidContentUtils';

import PackageTitle from 'components/PackageTitle';
import TeaseCard from 'components/TeaseCard';
import TeasePicture from 'components/TeasePicture';
import AIMS_FLAVORS from 'lib/aimsFlavors';

import './styles.themed.scss';

function getSizes(packageType, index) {
  if (index === 0 && packageType === 'threeUp') {
    return {
      s: AIMS_FLAVORS.FOCAL_760x380, // 320 x 160
      m: AIMS_FLAVORS.FOCAL_1000x500, // 718 x 359
      l: AIMS_FLAVORS.FOCAL_600x300,
    };
  }
  const altSizes = {
    s: AIMS_FLAVORS.FOCAL_760x380, // 320 x 160
    m: AIMS_FLAVORS.FOCAL_460x460,
    l: AIMS_FLAVORS.FOCAL_300x300,
  };
  if (packageType === 'twoUp') {
    return {
      ...altSizes,
      xl: AIMS_FLAVORS.FOCAL_460x230,
    };
  }
  return {
    ...altSizes,
    xl: AIMS_FLAVORS.FOCAL_380x380,
  };
}

function getTease(item, packageType, packageMetadata, index, vertical, isMobileOnlyComponent) {
  let teaseStyles;
  let size;

  if (packageType === 'threeUp') {
    teaseStyles = 'multi-up-rail__tease-card--three-up';
  } else if (packageType === 'fourUp') {
    teaseStyles = 'multi-up-rail__tease-card--four-up';
  } else {
    teaseStyles = 'multi-up-rail__tease-card--two-up';
    size = 'large';
  }

  let key = item.id || index;
  if (item.type && item.type === paidContent.type) {
    key = paidContent.type;
  }

  const articleClasses = classNames(
    'multi-up-rail__article',
    {
      'tease-card__main-tease': !index && packageType === 'threeUp',
      boxinlineAd: item.type === 'ad',
    },
    teaseStyles,
  );

  return (
    <div key={key} className={articleClasses}>
      <TeaseCard
        content={item}
        {...packageMetadata}
        {...(item.metadata || {})}
        size={size}
        vertical={vertical}
        isMobileOnlyComponent={isMobileOnlyComponent}
      >
        <TeasePicture
          responsiveFlavors={getSizes(packageType, index)}
          type={item.type}
          computedValues={item.computedValues}
        />
      </TeaseCard>
    </div>
  );
}

function MultiUpRail({
  alternateTitleFormat, content, className, pkgClassName, vertical, isMobileOnlyComponent,
}) {
  if (!content || !(content.items?.[0])) return null;

  const {
    id,
    items,
    metadata = {},
    type,
  } = content;

  const classes = classNames(
    pkgClassName,
    'multi-up-rail',
    className,
    'bg-knockout-primary',
    {
      'multi-up-rail__two-up': type === 'twoUp',
      'multi-up-rail__three-up': type === 'threeUp',
      'multi-up-rail__four-up': type === 'fourUp',
    },
  );

  const { showBottomBorder = false } = metadata;

  return (
    <section data-packageid={id} className={classes} data-test={`${type}-multirail__section`}>
      {metadata?.title && (
        <PackageTitle
          alternateDisplayFormat={alternateTitleFormat}
          metadata={metadata}
        />
      )}
      <div className="multi-up-rail__articles">
        {items && items.filter((item) => item).map((item, i) => getTease(item,
          type, metadata, i, vertical, isMobileOnlyComponent))}
      </div>
      {showBottomBorder && (<hr className="pkg-bottom-border" />)}
    </section>
  );
}

MultiUpRail.propTypes = {
  alternateTitleFormat: PropTypes.bool,
  content: PackagePropType.isRequired,
  className: PropTypes.string,
  pkgClassName: PropTypes.string,
  vertical: PropTypes.string,
  isMobileOnlyComponent: PropTypes.bool,
};

MultiUpRail.defaultProps = {
  alternateTitleFormat: false,
  className: null,
  pkgClassName: 'pkg multiUpRail',
  vertical: PropTypes.string,
  isMobileOnlyComponent: false,
};

MultiUpRail.contextTypes = LayoutContextPropType;

export default MultiUpRail;
