import React from 'react';
import classNames from 'classnames';

import Ad from 'components/Ad';
import './styles.themed.scss';

import { layoutContext as LayoutContextPropType } from 'lib/CustomPropTypes';

class Midresponsive extends React.Component {
  static contextTypes = {
    ...LayoutContextPropType,
  };

  constructor(props) {
    super(props);
    this.state = {
      adRendered: false,
    };
  }

  adHandler = (mpsAd) => {
    mpsAd.onRender((event) => {
      if (!event || event.empty) {
        return;
      }
      this.setState({
        adRendered: true,
      });
    });
  }

  render() {
    const { adRendered } = this.state;

    const classes = classNames(
      'ad--midresponsive',
      { 'ad--loaded': adRendered },
    );

    return (
      <Ad
        key="midresponsiveAd"
        slot="midresponsive"
        adClass={classes}
        refreshInterval={0}
        offset={300}
        handler={this.adHandler}
      />
    );
  }
}


export default Midresponsive;
