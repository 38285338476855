import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Loading } from 'components/MyNews/Loading';

import styles from './styles.module.scss';

function LocalNewsMap(props) {
  const [zip, setZip] = useState('');

  const {
    isLoading: isLocalNewsLoading,
    zip: localNewsZip,
  } = useSelector((state) => state.localnews || {});

  const {
    handleSubmit,
    showHeader,
  } = props;

  if (isLocalNewsLoading) {
    return <Loading amountOfLoadingDivs={4} />;
  }

  return (
    <>
      {showHeader && (
        <h2 className={styles.header} data-testid="local-news-map-header">
          Your Local News
        </h2>
      )}
      <div className={styles.map} data-testid="local-news-map-map">
        <form
          className={styles.form}
          data-testid="local-news-map-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(zip);
          }}
        >
          <input
            autoComplete="off"
            className={styles.zipcodeInput}
            data-testid="local-news-map-zip-input"
            defaultValue={localNewsZip || ''}
            maxLength="5"
            minLength="1"
            name="zip"
            onChange={(e) => {
              e.preventDefault();
              setZip(e.target.value);
            }}
            pattern="^(?:\d{5})?$"
            placeholder="Zip code here"
            type="text"
          />
          <div className={styles.errorMsg}>
            <p>* Not a valid ZIP Code</p>
          </div>
          <button
            className={styles.submitBtn}
            data-testid="local-news-map-submit-btn"
            type="submit"
          >
            submit
          </button>
        </form>
      </div>
    </>
  );
}

LocalNewsMap.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  showHeader: PropTypes.bool,
};

LocalNewsMap.defaultProps = {
  showHeader: false,
};

export { LocalNewsMap };
