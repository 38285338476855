import { useEffect, useRef, useState } from 'react';
import getConfig from 'next/config';
import dayjs from 'dayjs';

import { stripTrailingSlash } from 'lib/urlUtils';

const MVPD_ACCOUNT_CONCURRENCY_ENABLED = 1;

/**
 * Get a `fetch` function with pseudo-curried args. Returns the headers (mapped to a plain object)
 * of the `fetch` response. (exported for testing)
 * @param  {string}  url url of the fetch call
 * @param  {boolean} [isDelete=false] Should the request be of method DELETE. Request is POST by
 * default
 * @param  {object} [accountMetadata={}]
 * @param  {string|boolean} [accountMetadata.hba_status=true] passed through to the request body.
 * defaults to true
 * @param  {string}  appId App ID to use as auth for the Adobe calls
 * @return {Promise<object<string>>} Promise wrapping a plain object of the headers from the fetch response
 */
export const getTveConcurrencyFetch = ({
  url,
  isDelete = false,
  accountMetadata = {},
  appId,
}) => {
  if (typeof window === 'undefined') {
    return () => {};
  }

  const hbaStatus = accountMetadata?.hba_status ?? true;
  const body = `hba_status=${hbaStatus}`;

  const auth = window.btoa(`${appId}:`);
  const requestOptions = {
    method: !isDelete ? 'POST' : 'DELETE',
    headers: {
      Authorization: `Basic ${auth}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body,
  };

  return () => fetch(url, requestOptions)
    .then((res) => Object.fromEntries(res.headers.entries()));
};

/**
 * Returns `shouldInitFetch` based on the presence/truthiness of various args (exported for testing)
 * @param  {boolean} providerConcurrencyEnabled
 * @param  {string|null|undefined} sessionId
 * @param  {boolean} shouldMonitor
 * @param  {string|null|undefined} concurrencyUrl
 * @return {boolean}
 */
export function getShouldInitFetch({
  providerConcurrencyEnabled,
  sessionId,
  shouldMonitor,
  concurrencyUrl,
}) {
  return Boolean(
    providerConcurrencyEnabled
    && !sessionId
    && shouldMonitor
    && concurrencyUrl,
  );
}

/**
 * Returns `shouldHeartbeat` based on the presence/truthiness of various args (exported for testing)
 * @param  {boolean} providerConcurrencyEnabled
 * @param  {string|null|undefined} sessionId
 * @param  {boolean} shouldMonitor
 * @param  {string|null} expires
 * @param  {string|null|undefined} concurrencyUrl
 * @return {boolean}
 */
export function getShouldHeartbeat({
  providerConcurrencyEnabled,
  sessionId,
  shouldMonitor,
  expires,
  concurrencyUrl,
}) {
  return Boolean(
    providerConcurrencyEnabled
    && sessionId
    && shouldMonitor
    && expires
    && concurrencyUrl,
  );
}

/**
 * Get timeout ms amount for next heartbeat call (exported for testing)
 * @param  {string|null} [expires] Time when concurrency session will expire
 * @param  {DayJS} [referenceTime=now] Day.js instance to use as reference time. Defaults to
 * "now" Day.js instance ("now" when the function is called)
 * @return {number|null} If timeout can't be calculated (e.g. if expires is null), will return null.
 * Callers should use this to determine whether or not to actually set the heartbeat timeout.
 */
export function getHeartbeatMs(expires, referenceTime = dayjs()) {
  const parsedExpires = dayjs(expires);

  if (!parsedExpires.isValid() || parsedExpires.isBefore(referenceTime)) {
    return null;
  }

  // 5 seconds before expires in hopes of making sure the call can go through before expiration
  const fiveSecondsBeforeExpires = parsedExpires.subtract(5, 's').diff(referenceTime);

  const msFromReferenceTime = fiveSecondsBeforeExpires > 0
    ? fiveSecondsBeforeExpires
    // if 5 seconds before expires is before referenceTime (negative ms), choose the latest of 1 s
    // before expires or immediately (0 ms)
    : Math.max(0, parsedExpires.subtract(1, 's').diff(referenceTime));

  return msFromReferenceTime;
}

/**
 * Hook for concurrency monitoring
 * @param  {object} [mvpdAccountMetadata]
 * @param  {string} [mvpdAccountMetadata.userID] ID of the MVPD account user - this is the actual
 * user account, whereas `mvpdProviderId` is the company (like "Cablevision")
 * @param  {number} [mvpdProviderConcurrencyFlow] 1 for on, 0 for off
 * @param  {string|null} [mvpdProviderId] ID of the MVPD provider - e.g. "Cablevision"
 * @param  {boolean} [shouldMonitor=false] Should a session be considered "active" or not. Typically
 * this is governed by `isPlaying` for a video
 * @return {object}
 */
export function useConcurrencyMonitoring({
  mvpdAccountMetadata,
  mvpdProviderConcurrencyFlow,
  mvpdProviderId,
  shouldMonitor = false,
}) {
  const {
    publicRuntimeConfig: {
      TVE_CONCURRENCY_API,
      TVE_CONCURRENCY_APPID,
    },
  } = getConfig();

  const heartbeatTimeoutId = useRef(null);

  const [error, setError] = useState(null);
  const [expires, setExpires] = useState(null);
  const [sessionId, setSessionId] = useState(null);

  const providerConcurrencyEnabled = (
    mvpdProviderConcurrencyFlow === MVPD_ACCOUNT_CONCURRENCY_ENABLED
  );

  const concurrencyUrl = TVE_CONCURRENCY_API
    && mvpdProviderId
    && mvpdAccountMetadata?.userID
    && (
      `${stripTrailingSlash(TVE_CONCURRENCY_API)}/v2/sessions/`
      + `${mvpdProviderId}/${mvpdAccountMetadata.userID}/${sessionId || ''}`
    );

  const shouldInitFetch = getShouldInitFetch({
    providerConcurrencyEnabled,
    sessionId,
    shouldMonitor,
    concurrencyUrl,
  });

  const shouldHeartbeat = getShouldHeartbeat({
    providerConcurrencyEnabled,
    sessionId,
    shouldMonitor,
    concurrencyUrl,
  });

  // we're not looking at `providerConcurrencyEnabled` nor `concurrencyUrl` here because we want to
  // clear out any `sessionId` state in the useEffect below regardless of if we can fire the session
  // delete fetch
  const shouldDeleteConcurrencySession = (
    sessionId
    && !shouldMonitor
  );

  const postSessionFetch = shouldInitFetch || shouldHeartbeat
    ? getTveConcurrencyFetch({
      accountMetadata: mvpdAccountMetadata,
      appId: TVE_CONCURRENCY_APPID,
      url: concurrencyUrl,
    })
    : null;

  const deleteSessionFetch = shouldDeleteConcurrencySession && concurrencyUrl
    ? getTveConcurrencyFetch({
      accountMetadata: mvpdAccountMetadata,
      appId: TVE_CONCURRENCY_APPID,
      url: concurrencyUrl,
      isDelete: true,
    })
    : null;

  useEffect(() => {
    if (shouldInitFetch) {
      setError(null);
      postSessionFetch()
        .then((headers) => {
          setSessionId(headers.location);
          setExpires(headers.expires);
        })
        .catch((e) => setError(e));
    }
  }, [shouldInitFetch]);

  useEffect(() => {
    if (shouldDeleteConcurrencySession) {
      // clear any heartbeat currently scheduled to fire
      clearTimeout(heartbeatTimeoutId.current);
      heartbeatTimeoutId.current = null;
      setSessionId(null);
      deleteSessionFetch?.().catch((e) => setError(e));
    }
  }, [shouldDeleteConcurrencySession]);

  useEffect(() => {
    if (!shouldHeartbeat) {
      return;
    }

    const msFromNow = getHeartbeatMs(expires);

    if (msFromNow === null) {
      return;
    }

    heartbeatTimeoutId.current = setTimeout(() => {
      postSessionFetch()
        .then((headers) => {
          setExpires(headers.expires);
        })
        .catch((e) => setError(e));
    }, msFromNow);

    // disabling linter here because we only want to return a clean up function if a timeout was set
    // on the last effect run
    return () => { // eslint-disable-line consistent-return
      // if for some reason this fires before the new `expires` has been set by the previous
      // heartbeat/init, clear any timeout so we don't get a double-heartbeat
      clearTimeout(heartbeatTimeoutId.current);
      heartbeatTimeoutId.current = null;
    };
  }, [expires]); // we only want to fire this effect when `expires` changes, as it is only set when
  // the init call is fired or the previous heartbeat succeeded. we will use whatever the value of
  // `shouldHeartbeat` is when `expires` changed.

  return {
    heartbeatTimeoutIdRef: heartbeatTimeoutId,
    error,
  };
}
