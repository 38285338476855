import React from 'react';
import PropTypes from 'prop-types';

class Countdown extends React.Component {
  interval = null;

  static propTypes = {
    end: PropTypes.number.isRequired,
    onEndCallback: PropTypes.func,
  };

  static defaultProps = {
    onEndCallback: Function.prototype,
  };

  constructor(props) {
    super(props);
    this.state = {
      remaining: 0,
    };
  }

  componentDidMount() {
    this.update();
    this.interval = setInterval(this.update, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  update = () => {
    const { end, onEndCallback } = this.props;
    const remaining = Math.round((end - (new Date()).getTime()) / 1000);

    if (remaining <= 0) {
      clearInterval(this.interval);
      onEndCallback.call();
    } else {
      this.setState({ remaining });
    }
  }

  render() {
    const { remaining } = this.state;

    if (remaining > 0) {
      const seconds = remaining % 60;
      const minutes = (remaining - seconds) / 60;

      return (
        <>
          {minutes}
          :
          {`0${seconds}`.slice(-2)}
        </>
      );
    }

    return null;
  }
}

export default Countdown;
