/**
 * getContainerMeasurements
 * @date 8/10/2023 - 2:19:37 PM
 *
 * @export
 * @param {Element} scrollContainer
 * @param {Element} prevButtonContainer
 * @param {number} columnGap
 * @param {number} numCards
 * @returns {{
 *  containerWidth?: number,
 *  currentPage?: number,
 *  hasMultiplePages?: boolean,
 *  scrollLeft?: number,
 *  widthOfFirstPage?: number,
 *  widthOfNthPage?: number,
 *  getScrollAmtFromPageNum?: (number) => number,
 * }}
 */
export function getContainerMeasurements(
  scrollContainer,
  prevButtonContainer,
  columnGap,
  numCards,
) {
  if (!scrollContainer) return {};

  // Get width from container if value not set in state on mount
  const prevButtonWidth = prevButtonContainer.offsetWidth;

  const itemWidth = scrollContainer.firstElementChild?.offsetWidth;

  const {
    offsetWidth: containerWidth,
    scrollLeft,
    scrollWidth: totalContentWidth,
  } = scrollContainer;

  // this MAY account for the width of the "next" page button width, if it's rendered
  const firstPageFrameWidth = containerWidth;
  // this should always be the width of the scroll container WITHOUT any page button width,
  // basically 100% of the width of the container this component is rendered in
  const firstPageFrameWidthSansButtons = scrollContainer.parentElement.offsetWidth;

  const hasMultiplePages = totalContentWidth > firstPageFrameWidthSansButtons;

  // account for width of next & prev buttons
  const nthPageFrameWidth = containerWidth - prevButtonWidth;

  const numCardsFirstPage = !hasMultiplePages
    ? numCards
    : Math.floor(
      (firstPageFrameWidth - itemWidth) / (itemWidth + columnGap) + 1,
    ) || 1;

  const numCardsNthPage = Math.floor(
    (nthPageFrameWidth - itemWidth) / (itemWidth + columnGap) + 1,
  ) || 1;

  const widthOfFirstPage = (numCardsFirstPage * (itemWidth + columnGap)); // 2 cards with gap = start of card 3
  const widthOfNthPage = (numCardsNthPage * (itemWidth + columnGap));

  // amount scrolled beyond the right most edge of PAGE 1 (which is cards 1 & 2)
  const amountScrolledPastFirstPage = scrollLeft - (widthOfFirstPage - prevButtonWidth);

  const totalPages = hasMultiplePages
    ? Math.ceil(
      // figure out how many "nth" pages there are, including the last sliver of a card
      (totalContentWidth - widthOfFirstPage) / widthOfNthPage,
    ) + 1 // add one for the first page
    : 1;

  let currentPage = 1;

  if (scrollLeft + containerWidth === totalContentWidth) {
    currentPage = totalPages;
  } else if (scrollLeft > 0) {
    // we're on page 2, have not yet scrolled past the leftmost edge of card 3
    currentPage = scrollLeft < (widthOfFirstPage - prevButtonWidth)
      ? 2
      // we've scrolled past the leftmost edge of card 3, we're now on page 3 or further so we add "2":
      // 1 for the first page; 1 for making it 1-based instead of 0-based
      : Math.ceil(amountScrolledPastFirstPage / widthOfNthPage) + 2;
  }

  return {
    containerWidth,
    currentPage,
    hasMultiplePages,
    scrollLeft,
    totalContentWidth,
    totalPages,
    widthOfFirstPage,
    widthOfNthPage,
    getScrollAmtFromPageNum: (page) => {
      if (page === 1) {
        return 0;
      }
      if (page >= totalPages) {
        return totalContentWidth - containerWidth;
      }

      if (page === 2) {
        return widthOfFirstPage - prevButtonWidth;
      }

      // subtract 2 from the page num to account for the first page and the 1-based index
      return ((page - 2) * widthOfNthPage) + widthOfFirstPage - prevButtonWidth;
    },
  };
}
