import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useCopyToClipboard } from 'lib/Hooks';

import './styles.themed.scss';

const CopyToClipboardButton = (props) => {
  const { t } = useTranslation();

  const {
    buttonText,
    buttonClasses,
    copiedText,
    tooltipText,
    tooltipClasses,
  } = props;

  const [isCopied, handleCopyToClipboard] = useCopyToClipboard();

  const buttonClassString = classNames(
    { copied: isCopied },
    'copy-to-clipboard__button',
    buttonClasses,
  );

  const tooltipClassString = classNames(
    'copy-to-clipboard__tooltip',
    'arrow-top',
    tooltipClasses,
  );

  return (
    <div className="copy-to-clipboard">
      <button
        type="button"
        className={buttonClassString}
        onClick={() => handleCopyToClipboard(copiedText)}
      >
        {buttonText}
      </button>
      <span className={tooltipClassString}>
        {tooltipText || t('Copied')}
      </span>
    </div>
  );
};

CopyToClipboardButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonClasses: PropTypes.string,
  copiedText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  tooltipClasses: PropTypes.string,
};

CopyToClipboardButton.defaultProps = {
  buttonClasses: '',
  tooltipText: '',
  tooltipClasses: '',
};

export { CopyToClipboardButton };
