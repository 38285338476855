import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as CustomPropTypes from 'lib/CustomPropTypes';
import { formatDuration } from 'lib/DateTime';
import AIMS_FLAVORS from 'lib/aimsFlavors';

import { Picture } from 'components/Picture';

import styles from './styles.module.scss';

function ListItem({
  content,
  changeVideo,
  playing,
  active,
  isRebrandFront,
}) {
  const { t } = useTranslation();

  const {
    headline,
  } = content.computedValues || {};

  const { nativeAd } = content.item;

  const itemPlaying = playing && active;

  const videoPlayable = content.item.playable;

  const classes = classNames(
    styles.listItem,
    {
      [styles.playing]: itemPlaying,
      [styles.active]: active,
    },
  );

  /* eslint-disable jsx-a11y/no-static-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <div
      className={classes}
      data-testid="video-package__list-item"
      onClick={() => videoPlayable && changeVideo(content.item)}
    >
      <div
        className={styles.image}
        data-active-text={t('Now Playing')}
      >
        {!videoPlayable && (
          <div
            className={styles.expired}
            data-test="video-list-item__expired"
            data-testid="video-list-item__expired"
          >
            <div className={styles.expiredText}>
              {t('We apologize, this video has expired.')}
            </div>
          </div>
        )}
        <Picture
          lazyloaded
          responsiveFlavors={{
            s: AIMS_FLAVORS.FOCAL_760x380,
            m: AIMS_FLAVORS.FOCAL_320x160,
            l: AIMS_FLAVORS.FOCAL_200x100,
            xl: AIMS_FLAVORS.FOCAL_320x160,
          }}
          computedValues={content.computedValues}
        />
        {isRebrandFront ? (
          <div className={styles.durationInThumbnail}>
            <span data-test="video-list-item__timestamp">
              {nativeAd ? '' : formatDuration(content.item.duration)}
            </span>
          </div>
        ) : null}
      </div>
      <div
        className={styles.content}
        data-test="video-list-item__container"
        data-testid="video-list-item__container"
      >
        <h3
          className={styles.title}
          data-test="video-list-item__title"
          data-testid="video-list-item__title"
        >
          <span
            className={classNames({ db: nativeAd, dn: !nativeAd })}
            data-test="video-list-item__paid-title"
            data-testid="video-list-item__paid-title"
          >
            {t('PAID CONTENT')}
          </span>
          <div className={styles.clamp}>
            {headline}
          </div>
        </h3>
        {!isRebrandFront ? (
          <div className={styles.duration}>
            <span data-test="video-list-item__timestamp" data-testid="video-list-item__timestamp">
              {nativeAd ? '' : formatDuration(content.item.duration)}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
  /* eslint-enable jsx-a11y/click-events-have-key-events */
  /* eslint-enable jsx-a11y/no-static-element-interactions */
}

ListItem.propTypes = {
  content: CustomPropTypes.video.isRequired,
  changeVideo: PropTypes.func.isRequired,
  playing: PropTypes.bool,
  active: PropTypes.bool,
  isRebrandFront: PropTypes.bool,
};

ListItem.defaultProps = {
  playing: false,
  active: false,
  isRebrandFront: false,
};

export { ListItem };
