import React from 'react';
import PropTypes from 'prop-types';
import { Picture } from 'components/Picture';
import * as CustomPropTypes from 'lib/CustomPropTypes';
import { imageAltForImage } from 'lib/imageAlt';
import AIMS_FLAVORS from '../../lib/aimsFlavors';

import styles from './styles.module.scss';

function getSizes(index, sizes, rectangle) {
  if (rectangle && index > 0) {
    if (index === 1) {
      // Small square
      return {
        s: AIMS_FLAVORS.FOCAL_360x360,
        m: AIMS_FLAVORS.FOCAL_230x230,
        l: AIMS_FLAVORS.FOCAL_130x130,
        xl: AIMS_FLAVORS.FOCAL_190x190,
      };
    }
    // Small rectangle
    return {
      s: AIMS_FLAVORS.FOCAL_360x360,
      m: AIMS_FLAVORS.FOCAL_460x230,
      l: AIMS_FLAVORS.FOCAL_260x130,
      xl: AIMS_FLAVORS.FOCAL_380x190,
    };
  }
  // First square
  return {
    s: AIMS_FLAVORS.FOCAL_360x360,
    m: AIMS_FLAVORS.FOCAL_460x460,
    l: AIMS_FLAVORS.FOCAL_260x260,
    xl: AIMS_FLAVORS.FOCAL_380x380,
  };
}

function getPicture(slide, index, sizes, rectangle) {
  if (!slide) {
    return null;
  }
  return (
    <Picture
      className={styles.item}
      url={slide.image.url.primary || slide.image.url}
      responsiveFlavors={getSizes(index, sizes, rectangle)}
      alt={imageAltForImage(slide.image)}
    />
  );
}

function SlideshowTeasePicture({ slides, sizes, rectangle }) {
  return (
    <div
      className={`${styles.container} ${styles[rectangle ? 'rectangle' : '']}`}
      data-count={`${slides.length + 1}+`}
    >
      {getPicture(slides[0], 0, sizes, rectangle)}
      {getPicture(slides[1], 1, sizes, rectangle)}
      {getPicture(slides[2], 2, sizes, rectangle)}
    </div>
  );
}

SlideshowTeasePicture.propTypes = {
  slides: PropTypes.arrayOf(CustomPropTypes.legacySlide),
  sizes: PropTypes.shape({
    s: PropTypes.number,
    m: PropTypes.number,
    l: PropTypes.number,
    xl: PropTypes.number,
  }),
  rectangle: PropTypes.bool,
};

SlideshowTeasePicture.defaultProps = {
  slides: [],
  sizes: {
    s: 0,
    m: 0,
    l: 0,
    xl: 0,
  },
  rectangle: false,
};

export default SlideshowTeasePicture;
