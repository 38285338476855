import React from 'react';
import PropTypes from 'prop-types';
import { StripePattern } from 'components/StripePattern';

function StripedEyebrow({ children }) {
  return (
    <div className="striped-eyebrow" data-testid="striped-eyebrow">
      <span className="striped-eyebrow__text" data-testid="striped-eyebrow-text">{children}</span>
      <StripePattern />
    </div>
  );
}

StripedEyebrow.propTypes = {
  children: PropTypes.string.isRequired,
};

export default StripedEyebrow;
