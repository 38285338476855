import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { setErrorAction } from 'redux/modules/video/error';

import BrowserDetection from 'lib/BrowserDetection';
import { configTVE } from 'lib/tve';
import { ConcurrencyMonitor } from 'components/TVE/ConcurrencyMonitor';
import Loader from 'components/IconLoader';
import Slate, { ERROR_TYPES } from 'components/TVE/Slate';
import { OmegaVideoPlayer } from 'components/OmegaVideoPlayer';
import { CoreVideoPlayer } from 'components/CoreVideoPlayer';
import { VideoContainer } from 'components/VideoContainer';
import { VideoControlRack } from 'components/VideoContainer/VideoControlRack';
import { OmegaFeatureFlagSwitch } from 'components/OmegaVideoPlayer/OmegaSwitch';
import { TapToUnmuteButton } from 'components/VideoContainer/TapToUnmuteButton';

import placeholderVideo from 'assets/videos/msnbc-placeholder.mp4';

import {
  playmakerMetadata as PlaymakerMetadataPropType,
} from 'lib/CustomPropTypes';


import './styles.themed.scss';
import { GeoRestriction } from 'components/TVE/GeoRestriction';

const block = 'tve__player';

const mapStateToProps = ({
  router, shared, tve, video,
}) => ({
  authenticated: tve.authenticated,
  domain: router.domain,
  path: router?.path,
  hasTempPass: tve.hasTempPass,
  mvpdAccountMetadata: tve.metadata,
  selectedProvider: tve.selected,
  tempPassProviderID: tve.tempPassProviderID,
  isLoadingMvpd: !!tve?.loadingMVPD,
  videoError: video.error,
  vertical: shared.vertical,
});

const mapActionToProps = (dispatch) => ({
  setError: (payload) => dispatch(setErrorAction(payload)),
});

export class PlayerWrapper extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool,
    autoplayMuted: PropTypes.bool,
    bypassAuth: PropTypes.bool,
    clickToPlay: PropTypes.bool,
    domain: PropTypes.string,
    hasTempPass: PropTypes.bool,
    isRail: PropTypes.bool,
    playmakerMetadata: PlaymakerMetadataPropType,
    mvpdAccountMetadata: PropTypes.shape({
      // Adobe code returns a string of "true" or "false" rather than a boolean
      hba_status: PropTypes.oneOf(['true', 'false']),
      userID: PropTypes.string,
    }),
    selectedProvider: PropTypes.shape({
      freewheelHash: PropTypes.string,
      id: PropTypes.string,
      concurrencyFlow: PropTypes.number,
    }),
    setError: PropTypes.func.isRequired,
    stickyEnabled: PropTypes.bool,
    onStuckChange: PropTypes.func,
    tempPassProviderID: PropTypes.string,
    isLoadingMvpd: PropTypes.bool,
    videoError: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
  };

  static defaultProps = {
    authenticated: false,
    autoplayMuted: false,
    bypassAuth: false,
    clickToPlay: false,
    domain: null,
    hasTempPass: null,
    isRail: false,
    playmakerMetadata: null,
    mvpdAccountMetadata: null,
    selectedProvider: {
      freewheelHash: null,
      id: null,
    },
    isLoadingMvpd: false,
    stickyEnabled: false,
    onStuckChange: Function.prototype,
    tempPassProviderID: null,
    videoError: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      placeholder: false,
      isVideoPlaying: false,
    };
  }

  componentDidMount() {
    // Does not work in IE, throw browser error
    if (BrowserDetection.isIE() && BrowserDetection.version() <= 11) {
      const { setError } = this.props;
      setError(ERROR_TYPES.BROWSER);
    }

    // Prevent giant placeholder video from displaying unstyled until mounted
    this.setState({ placeholder: true });
  }

  onPlayListener = () => {
    this.setState({ isVideoPlaying: true });
  }

  onPauseListener = () => {
    this.setState({ isVideoPlaying: false });
  }

  updateIsVideoPlaying = (val) => {
    this.setState({ isVideoPlaying: val });
    if (val === true) {
      this.setState({ placeholder: false });
    }
  }

  renderPlaceholder = () => (
    <div className={`${block}__slate absolute absolute-fill`}>
      <div className={`${block}__slate__background absolute absolute-fill`} />
      <video
        width="0px"
        height="0px"
        autoPlay
        loop
        muted
        className={`${block}__slate__video`}
      >
        <source src={placeholderVideo} type="video/mp4" />
      </video>
    </div>
  );

  renderError = () => (
    <div className={`${block}__error absolute absolute-fill`}>
      <Slate />
    </div>
  );

  renderLoader = () => (
    <div className={`${block}__loader absolute absolute-fill`}>
      <Loader
        className="relative"
        brand="news"
        animateIn
        animateOut
      />
    </div>
  );

  renderPlayer = () => {
    const {
      autoplayMuted,
      clickToPlay,
      domain,
      hasTempPass,
      selectedProvider,
      tempPassProviderID,
      isRail,
      playmakerMetadata,
      stickyEnabled,
      onStuckChange,
    } = this.props;

    let providerId;
    if (hasTempPass) {
      providerId = tempPassProviderID;
    } else {
      providerId = get(selectedProvider, 'freewheelHash', null);
    }

    const {
      [domain]: {
        stream,
      } = {},
    } = configTVE;

    return (
      <div className={`${block}__player absolute absolute-fill`}>
        <OmegaFeatureFlagSwitch
          ifOmega={(
            <OmegaVideoPlayer
              stream={stream}
              autoplay={!clickToPlay}
              mutedAutoplay={autoplayMuted}
              mvpdHash={providerId}
              stickyEnabled={stickyEnabled}
              updateIsVideoPlaying={this.updateIsVideoPlaying}
              onStuckChange={onStuckChange}
            />
          )}
        >
          <VideoContainer stickyEnabled={stickyEnabled}>
            <TapToUnmuteButton />
            <CoreVideoPlayer
              stream={stream}
              mvpdHash={providerId}
              playmakerArt={playmakerMetadata?.playmakerArt}
              autoplay={!clickToPlay}
              mutedAutoplay={autoplayMuted}
            />
            <VideoControlRack isRail={isRail} />
          </VideoContainer>
        </OmegaFeatureFlagSwitch>
      </div>
    );
  }

  render() {
    const {
      authenticated,
      bypassAuth,
      mvpdAccountMetadata,
      videoError,
      selectedProvider,
      isLoadingMvpd,
    } = this.props;
    const {
      placeholder,
      isVideoPlaying,
    } = this.state;

    return (
      <div className={`${block} absolute absolute-fill`}>
        <GeoRestriction>
          <ConcurrencyMonitor
            mvpdProviderId={selectedProvider?.id}
            shouldMonitor={isVideoPlaying}
            mvpdProviderConcurrencyFlow={selectedProvider?.concurrencyFlow}
            mvpdAccountMetadata={mvpdAccountMetadata}
          />

          {(() => {
            if (videoError !== false) {
              return this.renderError();
            }
            return null;
          })()}

          {bypassAuth ? (
            this.renderPlayer()
          ) : (
            <>
              {authenticated && isLoadingMvpd && (
                this.renderLoader()
              )}
              {authenticated && !isLoadingMvpd && (
                this.renderPlayer()
              )}
            </>
          )}
          {placeholder && !isVideoPlaying && (
            this.renderPlaceholder())}
        </GeoRestriction>
      </div>
    );
  }
}

const Player = React.forwardRef((props, ref) => <PlayerWrapper {...props} forwardedRef={ref} />);

export default connect(mapStateToProps, mapActionToProps, null, { forwardRef: true })(
  Player,
);
