import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import LiveBlog from 'components/packages/LiveBlog';

import {
  content as ContentPropType,
  playmakerMetadata as PlaymakerMetadataPropType,
} from 'lib/CustomPropTypes';

import { MAIN_ART_TEASE_VALID_SIZES } from '../constants';

const block = 'smorgasbord-meta-content';

const shapeLiveBlogItem = (item = {}, packageMetadata = {}) => ({
  ...item,
  metadata: { // merge content and package metadata
    ...packageMetadata,
    ...(item || {}).metadata,
  },
});

export const LiveBlogMetaContent = ({ item, packageMetadata, size }) => {
  const { type } = item || {};
  const isLiveBlog = type === 'liveBlog';
  const liveBlog = isLiveBlog ? shapeLiveBlogItem(item, packageMetadata) : null;

  if (!liveBlog) {
    return null;
  }

  const { metadata } = liveBlog;

  return (
    <div
      className={classNames(`${block}__live-blog`, { [`${block}__live-blog--${size}`]: !!size })}
    >
      <LiveBlog
        // must pass package object as 'content',
        // so subcomponent knows it's not the root package
        content={{
          ...item,
          items: [{
            ...liveBlog,
            metadata: {
              ...metadata,
              showDek: false, // dek not supported, override
            },
          }],
        }}
        showLiveBadge={false}
      />
    </div>
  );
};

LiveBlogMetaContent.propTypes = {
  item: ContentPropType.isRequired,
  packageMetadata: PlaymakerMetadataPropType,
  size: PropTypes.oneOf([null, ...MAIN_ART_TEASE_VALID_SIZES]),
};

LiveBlogMetaContent.defaultProps = {
  packageMetadata: null,
  size: null,
};
