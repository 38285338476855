import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { PaidContent } from 'components/PaidContent';
import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import TypeIcon from 'components/TypeIcon';
import { Unibrow } from 'components/Unibrow';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import {
  content as ContentPropType,
  packageContext as packageContextTypes,
} from 'lib/CustomPropTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { getTeaseUrl } from 'lib/teaseUtils';
import { getVerticalName } from 'lib/verticalType';
import { imageAltForItem } from 'lib/imageAlt';
import { TEASE_LIST_ROUND_IMAGES } from 'lib/brandFeatures';
import { VerticalContext } from 'lib/ContextTypes';
import { isPaidContentType } from 'lib/paidContentUtils';

import styles from './styles.module.scss';

function Item(props, context) {
  const vertical = useContext(VerticalContext);
  const { railContext = '' } = context;

  const [adLoaded, setAdLoaded] = useState(false);

  const {
    isFeatured,
    inRail,
    item,
  } = props;

  const {
    computedValues,
    id,
    metadata,
    type,
  } = item;

  const {
    headline,
    url,
    unibrow,
  } = computedValues || {};

  // this is to check whether or not the native ad
  // should be a nativetopvideolist
  const isItemInMSNBCRightRail = vertical === 'msnbc' && railContext === 'right';
  if (isPaidContentType(type)) {
    return (
      <PaidContent
        adLoaded={() => { setAdLoaded(true); }}
        className={classNames({
          [styles.placeholder]: !adLoaded,
          [styles.item]: adLoaded,
          [styles.inRail]: adLoaded,
        })}
        key={type}
        type="alacarte"
        vertical={vertical}
        isMSNBCRightRail={isItemInMSNBCRightRail}
      />
    );
  }

  const isInRail = railContext !== null;
  const isOpinion = getVerticalName(item) === 'think';
  const isSquareImage = !isFeatured;
  const isRoundedImage = isInRail
    && !isFeatured
    && getFeatureConfigForBrand(
      TEASE_LIST_ROUND_IMAGES,
      vertical,
    );

  const imageUrl = getTeaseUrl(item, isSquareImage);
  const shouldDisplayImage = !!imageUrl && metadata.showTeaseImage !== false;
  const imageAlt = imageAltForItem(item);

  let imageSizes = { s: AIMS_FLAVORS.FOCAL_80x80 };

  if (isInRail && isFeatured) {
    imageSizes = {
      s: AIMS_FLAVORS.FOCAL_720x360,
      m: AIMS_FLAVORS.FOCAL_200x100,
      l: AIMS_FLAVORS.FOCAL_280x140,
      xl: AIMS_FLAVORS.FOCAL_260x130,
    };
  }
  return (
    <li
      className={classNames(
        styles.item,
        {
          [styles.inRail]: inRail,
          [styles.isFeatured]: isFeatured,
          [styles.isOpinion]: isOpinion,
        },
      )}
      data-contentid={id}
      key={id}
    >
      {shouldDisplayImage && (
        <div className={styles.imageWrapper}>
          <Link to={url}>
            <TypeIcon
              className={styles.typeIcon}
              type={type}
            />
            <TeasePicture
              alt={imageAlt}
              className={classNames(styles.image, { [styles.radius]: isRoundedImage })}
              preview={type === 'video' && { url, redirect: false, radius: isRoundedImage }}
              responsiveFlavors={imageSizes}
              retina
              url={imageUrl}
              computedValues={computedValues}
            />
          </Link>
        </div>
      )}
      <div
        className={
          shouldDisplayImage && !isFeatured
            ? styles.headlineContainerWithImage
            : styles.headlineContainer
        }
      >
        {shouldDisplayImage && (
          <Unibrow
            className={styles.unibrow}
            size="h3"
            unibrow={unibrow}
          />
        )}
        <h2 className={styles.headline}>
          <Link to={url}>
            {headline}
          </Link>
        </h2>
      </div>
    </li>
  );
}

Item.propTypes = {
  inRail: PropTypes.bool,
  isFeatured: PropTypes.bool,
  item: ContentPropType.isRequired,
};

Item.defaultProps = {
  inRail: false,
  isFeatured: false,
};

Item.contextTypes = {
  ...packageContextTypes,
};

export { Item };
