import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { IconClose } from 'components/Icon/Close';
import { LocalNewsMap } from 'components/MyNews/LocalNews/LocalNewsMap';
import { LocalNewsError } from 'components/MyNews/LocalNews/LocalNewsError';
import Link from 'components/Link';
import peacockSVG from 'assets/images/peacockmark.svg';
import Weather from '../Weather';

import styles from './styles.module.scss';

dayjs.extend(relativeTime);

function LocalNewsStories(props) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const {
    city,
    currentZip,
    handleSubmit,
    news,
    state,
  } = props;

  const handleOnClick = (e) => {
    e.preventDefault();
    setIsMenuOpened(!isMenuOpened);
  };

  const localNewsHandleSubmit = (zip) => {
    setIsMenuOpened(false);
    return handleSubmit(zip);
  };

  return (
    <div className={classNames(
      styles.localNewsStories,
      { [styles.isMenuOpened]: isMenuOpened },
    )}
    >
      <div className={styles.head}>
        <h2
          className={styles.heading}
          data-testid="local-news-stories-heading"
        >
          {(city && state) ? `${city}, ${state}` : 'Not Found'}
        </h2>
        <button
          className={styles.headerBtn}
          onClick={handleOnClick}
          type="button"
        >
          <span className={styles.menu} />
          <IconClose />
        </button>
      </div>
      <Weather />
      {(news.error || !news.length) ? (
        <LocalNewsError />
      ) : (
        (() => {
          const [{ items, header }] = news;
          const localNewsItems = items?.slice(0, 8) ?? [];
          return (
            <>
              <div className={styles.storiesHead}>
                <img
                  alt="nbc news logo"
                  className={styles.storiesLogo}
                  src={peacockSVG}
                />
                <p
                  className={styles.storiesDek}
                  /* eslint-disable-next-line react/no-danger */
                  dangerouslySetInnerHTML={{ __html: header }}
                />
                <Link
                  className={styles.affiliateLink}
                  to="/news/us-news/nbc-affiliates-n19981"
                >
                  <span className={styles.affiliateLabel}>
                    See Local Affiliates
                  </span>
                </Link>
              </div>
              <div
                className={styles.storiesGrid}
                data-activity-map="local-news-stories"
              >
                {localNewsItems.map((item) => {
                  const { headline, url, published } = item;
                  const relativeTimestamp = dayjs(published).fromNow();
                  return (
                    <div
                      className={styles.story}
                      data-testid="local-news-stories-story"
                      key={headline}
                    >
                      <Link href={url} target="_blank" rel="noreferrer">
                        <p className={styles.storyTitle}>{headline}</p>
                      </Link>
                      <p className={styles.storyTimestamp}>
                        {relativeTimestamp}
                      </p>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })()
      )}

      <div className={styles.map}>
        <LocalNewsMap
          currentZip={currentZip}
          handleSubmit={localNewsHandleSubmit}
        />
      </div>
    </div>
  );
}

LocalNewsStories.propTypes = {
  city: PropTypes.string.isRequired,
  currentZip: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  news: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    pubDate: PropTypes.string,
  })).isRequired,
  state: PropTypes.string.isRequired,
};

export { LocalNewsStories };
