import React from 'react';
import PropTypes from 'prop-types';

const block = 'tve__schedule__program';

const programPropType = {
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

// First program has special treatment
const First = ({ time, title }) => (
  <>
    <span className={`${block}__time`} data-testid={`${block}__time`}>
      Up Next
    </span>
    <span className={`${block}__title`} data-testid={`${block}__title`}>
      {title}
    </span>
    <span className={`${block}__sub`} data-testid={`${block}__sub`}>
      {time}
    </span>
  </>
);

First.propTypes = programPropType;

// Rest of the programs show time and title
const Rest = ({ time, title }) => (
  <>
    <span className={`${block}__time`} data-testid={`${block}__time`}>
      {time}
    </span>
    <span className={`${block}__title`} data-testid={`${block}__title`}>
      {title}
    </span>
  </>
);

Rest.propTypes = programPropType;


function ScheduleProgram(props) {
  const { index } = props;
  if (!index) {
    return (
      <div className={block} data-testid={block}>
        <First {...props} />
      </div>
    );
  }
  return (
    <div className={block} data-testid={block}>
      <Rest {...props} />
    </div>
  );
}

ScheduleProgram.propTypes = {
  index: PropTypes.number.isRequired,
  ...programPropType,
};

// ScheduleProgram.defaultProps = {};

export default ScheduleProgram;
