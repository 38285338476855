/**
 * Add the card id as the post query param
 * @param {string} url
 * @return {string}
 * @example
 * Returns 'https://www.example.com?post=rcrd2284#rcrd2284'
 * transformCardUrlForSocialShare('https://www.example.com#rcrd2284')
 * @see https://nbcnewsdigital.atlassian.net/browse/BENTO-23920
 */
export const transformCardUrlForSocialShare = (url) => {
  const urlObj = new URL(url);
  const post = urlObj.hash.substring(1);
  if (!post) {
    return url;
  }
  const params = new URLSearchParams(urlObj.searchParams);
  params.set('post', post);
  urlObj.search = params;
  return urlObj.toString();
};
