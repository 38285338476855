import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { package as packagePropType } from 'lib/CustomPropTypes';
import { isLeadPackageAdjacent } from 'lib/packageHelper';
import RelatedContent from 'components/RelatedContent';
import { PymFrame } from 'components/Pym/Frame';
import { EmbedInfo } from './EmbedInfo';

import styles from './styles.module.scss';

export class Embed extends React.Component {
  static propTypes = {
    content: packagePropType.isRequired,
  };

  static contextTypes = {
    layoutIndex: PropTypes.number,
    leadPackageType: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.id = this.getId(props);
  }

  // eslint-disable-next-line react/destructuring-assignment
  getEmbedUrl = () => this.props?.content?.items?.[0]?.computedValues?.url ?? false;

  // eslint-disable-next-line react/destructuring-assignment
  getContentType = () => this.props?.content?.items?.[0]?.type ?? false;

  getId = ({ content }) => {
    const { id } = content;

    return `Embed-${id}`;
  };

  getToolkitClassesFromEmbedURL = (url) => {
    if (typeof url !== 'string') {
      return '';
    }
    let ramenClasses;
    let styleClasses;
    if (url.includes('/ramen-classes_')) {
      const params = url.split('/');
      ramenClasses = params.filter((param) => param.includes('ramen-classes'));
      const [first = ''] = ramenClasses;
      [, styleClasses = ''] = first.split('_');
      if (styleClasses.includes('~')) {
        styleClasses = styleClasses.replace(/~/g, ' ');
      }
      return `${styleClasses} no-margin`;
    }
    return '';
  };

  render() {
    const embedUrl = this.getEmbedUrl();
    const contentType = this.getContentType();

    if (contentType !== 'custom') {
      return null;
    }

    if (!embedUrl) {
      return null;
    }

    const {
      content: {
        id,
        name,
        packageIndex,
        items: [
          embedItem,
        ],
        context: {
          railContext,
        } = {},
        subType,
        metadata: {
          headline,
          headlineUrl,
          dek,
          showBreakingHeader = false,
          label,
          showLabel,
          showBottomBorder = false,
        } = {},
      } = {},
    } = this.props;

    const { layoutIndex, leadPackageType, isRailAdjacent } = this.context;
    const { related = [] } = embedItem;

    const toolKitClasses = this.getToolkitClassesFromEmbedURL(embedUrl);
    const hasCustomClasses = !!toolKitClasses;
    const leadPkgAdj = layoutIndex === 0
      && packageIndex === 1
      && isLeadPackageAdjacent(leadPackageType);
    const isLeadPkg = layoutIndex === 0 && packageIndex === 0;
    const isStacked = subType === 'stacked';

    const showTopBorder = !isLeadPkg && !leadPkgAdj && headline && !isStacked;
    const embedClass = classNames(
      'pkg',
      styles.embedPackage,
      {
        [styles.topBorder]: showTopBorder,
        [styles.leadPackageAdjacent]: leadPkgAdj,
        [styles.customClasses]: hasCustomClasses,
        [styles.stacked]: isStacked,
        [toolKitClasses]: hasCustomClasses,
      },
    );

    return (
      <>
        <section
          className={embedClass}
          data-packageid={id}
          data-test="embed-pkg"
          data-testid="embed-pkg"
        >
          <EmbedInfo
            headline={headline}
            headlineUrl={headlineUrl}
            dek={dek}
            isBreaking={showBreakingHeader}
            label={label}
            showLabel={showLabel}
            isNotRail={!railContext}
            isStacked={isStacked}
          />
          <div>
            <PymFrame url={embedUrl} title={name} />
          </div>
          {!!related.length && (
            <div
              className={styles.related}
              data-test="embed-pkg__related-content"
              data-testid="embed-pkg__related-content"
            >
              <RelatedContent isRailAdjacent={isRailAdjacent} related={related} />
            </div>
          )}
        </section>
        {showBottomBorder && <div className={styles.embedBottomBorder}><hr /></div>}
      </>
    );
  }
}
