import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import Link from 'components/Link/Link';

import { stripLeadingSlash } from 'lib/urlUtils';
import { attribution } from 'lib/social';
import { useTracking, useVertical, useCopyToClipboard } from 'lib/Hooks';
import { transformCardUrlForSocialShare } from 'lib/transformCardUrlForSocialShare';
import { getDataActivityMapID } from 'lib/articleUtils';
import { EMBED_COPIED_TEXT, LINK_COPIED_TEXT, SHARE_LINK_COPIED_TEXT } from 'lib/constants';
import { useTranslation } from 'react-i18next';
import { useMyNewsStore } from 'store';

import { Save } from './Save';
import styles from './styles.module.scss';

const URL_HANDLERS = {
  pinterest: ({ url, cidEventNamePrefix }) => `http://pinterest.com/pin/create/link/?url=${decodeURI(url)}&cid=${cidEventNamePrefix}_pinterest`,
  facebook: ({ url, cidEventNamePrefix }) => `https://www.facebook.com/sharer/sharer.php?u=${decodeURI(url)}&cid=${cidEventNamePrefix}_facebook`,
  twitter: ({
    url,
    headline,
    via,
    cidEventNamePrefix,
  }) => `https://www.twitter.com/intent/tweet?text=${encodeURIComponent(
    headline,
  )}&via=${via}&url=${decodeURI(url)}&original_referer=${decodeURI(url)}&cid=${cidEventNamePrefix}_twitter`,
  email: ({ url, headline }) => `mailto:?subject=${headline}&body=${decodeURI(url)}`,
  sms: ({ url, headline, publisher }) => `sms:?&body=${headline} via ${publisher} - ${url}`,
};

const DEFAULT_ACTIONS = {
  facebook: true,
  twitter: true,
  pinterest: false,
  email: true,
  copy: true,
  embed: false,
  sms: true,
  print: false,
  save: true,
  shareThis: false,
  remove: false,
};

export const SocialShareMenu = ({
  url,
  headline,
  pageRegion,
  trackingEventName,
  cidEventNamePrefix,
  actions: partialActions,
  contentId,
  tooltipAlign,
}) => {
  const { query: { page } } = useRouter();
  const { isToday } = useVertical();
  const { t } = useTranslation();

  const publisher = useSelector(({ article, video }) => {
    if (page === 'article') return article?.content?.[0]?.publisher?.name ?? '';
    if (page === 'video') return video?.current?.publisher?.name ?? '';
    // Recipe has no publisher
    return '';
  });
  const socialMediaProfiles = useSelector(({ shared }) => shared.socialMediaProfiles);
  const via = attribution(socialMediaProfiles);
  const deleteBookmark = useMyNewsStore((state) => state.deleteBookmark);

  const [isUrlCopied, handleCopyUrl] = useCopyToClipboard(1500);
  const [isEmbedCopied, handleCopyEmbed] = useCopyToClipboard(1500);
  const [isShareLinkCopied, handleCopyShareLink] = useCopyToClipboard(1500);

  const computedCidEventNamePrefix = cidEventNamePrefix || trackingEventName;

  const track = useTracking(trackingEventName);
  const dataActivityMapID = getDataActivityMapID({
    pageRegion,
    componentName: 'social-share-links',
  });

  const actions = { ...DEFAULT_ACTIONS, ...partialActions };

  const handlePrint = () => {
    if (window.print) {
      track({ platform: 'print' });
      window.print();
    }
  };


  /**
   * This function deletes a bookmark identified by `contentId` and tracks the removal event.
   * @function handleRemove
   * @returns {Promise<void>} A promise that resolves when the bookmark is removed and the event is tracked.
   */
  const handleRemove = async () => {
    deleteBookmark(contentId);
    track({
      platform: 'remove',
    });
  };

  const handleCopyClick = (event) => {
    track({ platform: 'copy' });

    if (event.altKey && event.shiftKey) {
      // Use the specially formatted link
      // for sharing the individual blog post
      return handleCopyShareLink(transformCardUrlForSocialShare(url));
    }
    return handleCopyUrl(url);
  };

  return (
    <ul
      className={classNames(styles.container, 'social-share-menu__container')}
      data-activity-map={dataActivityMapID}
      data-testid="social-share-menu"
    >
      {actions.shareThis && (

        <li
          className={styles.shareThis}
          data-testid="social-share-share-this"
        >
          {`${t('Share this')} -`}
        </li>
      )}
      {actions.facebook ? (
        <li data-testid="social-share-facebook" className={classNames(styles.item, styles.facebook)}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={URL_HANDLERS.facebook({ url, cidEventNamePrefix: computedCidEventNamePrefix })}
            className={styles.link}
            onClick={() => track({ platform: 'facebook' })}
          >
            <span className={classNames('icon', 'icon-share-facebook')} />
          </Link>
        </li>
      ) : null}
      {actions.twitter ? (
        <li data-testid="social-share-twitter" className={classNames(styles.item, styles.twitter)}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={URL_HANDLERS.twitter({
              url,
              headline,
              via,
              cidEventNamePrefix: computedCidEventNamePrefix,
            })}
            className={styles.link}
            onClick={() => track({ platform: 'twitter' })}
          >
            <span className={classNames('icon', 'icon-twitter')} />
          </Link>
        </li>
      ) : null}
      {actions.pinterest ? (
        <li data-testid="social-share-pinterest" className={classNames(styles.item, styles.pinterest)}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={URL_HANDLERS.pinterest({
              url,
              headline,
              cidEventNamePrefix: computedCidEventNamePrefix,
            })}
            className={styles.link}
            onClick={() => track({ platform: 'pinterest' })}
          >
            <span className={classNames('icon', 'icon-share-pinterest')} />
          </Link>
        </li>
      ) : null}
      {actions.email ? (
        <li data-testid="social-share-email" className={classNames(styles.item, styles.email)}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={URL_HANDLERS.email({ url, headline })}
            className={styles.link}
            onClick={() => track({ platform: 'email' })}
          >
            <span className={classNames('icon', 'icon-share-email')} />
          </Link>
        </li>
      ) : null}
      {actions.copy ? (
        <li data-testid="social-share-copy" className={classNames('dn db-m', styles.item, styles.copy)}>
          <button
            type="button"
            className={styles.button}
            onClick={handleCopyClick}
          >
            <span className={classNames('icon', 'icon-link')} />
          </button>
          <div
            className={classNames(styles.copyTooltip, {
              [styles.success]: isUrlCopied || isShareLinkCopied,
              [styles.alignRight]: tooltipAlign === 'right',
            })}
          >
            {isToday ? <span className="icon icon-check" /> : <span className="icon icon-alt-check" />}
            {isUrlCopied ? <span className={styles.text} data-testid="link-copied">{`${t(LINK_COPIED_TEXT)}`}</span> : null}
            {isShareLinkCopied ? <span className={styles.text} data-testid="share-link-copied">{`${t(SHARE_LINK_COPIED_TEXT)}`}</span> : null}
          </div>
        </li>
      ) : null}
      {actions.embed?.path ? (
        <li data-testid="social-share-embed " className={classNames('dn', 'db-m', styles.item, styles.embed)}>
          <button
            type="button"
            className={styles.button}
            onClick={() => {
              track({ platform: 'embed' });
              const { protocol, host } = window.location;
              const sanitizedHref = stripLeadingSlash(actions.embed.path);
              const embedMarkup = `<iframe loading='lazy' width='560' height='315' src='${protocol}//${host}/${sanitizedHref}' scrolling='no' frameborder='0' allowfullscreen></iframe>`;
              handleCopyEmbed(embedMarkup);
            }}
          >
            <span className={classNames('icon', 'icon-embed')} />
          </button>
          <div
            className={classNames(styles.embedTooltip, {
              [styles.success]: isEmbedCopied,
            })}
          >
            {isToday ? <span className="icon icon-check" /> : <span className="icon icon-alt-check" />}
            <span className={styles.text}>{`${t(EMBED_COPIED_TEXT)}`}</span>
          </div>
        </li>
      ) : null}
      {actions.sms ? (
        <li data-testid="social-share-sms" className={classNames(styles.item, styles.sms)}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={URL_HANDLERS.sms({ url, headline, publisher })}
            className={styles.link}
            onClick={() => track({ platform: 'sms' })}
          >
            <span className="icon icon-sms" />
          </Link>
        </li>
      ) : null}
      {actions.print ? (
        <li data-testid="social-share-print" className={classNames(styles.print)}>
          <button
            type="button"
            className={styles.button}
            onClick={handlePrint}
          >
            <span className={classNames('icon', 'icon-share-print', styles.icon)} />
            <span className={styles.text}>Print</span>
          </button>
        </li>
      ) : null}
      {actions.save ? (
        <li data-testid="social-share-save" className={styles.save}>
          <Save contentId={contentId} contentType={page} options={pageRegion} />
        </li>
      ) : null}
      {actions.remove ? (
        <li data-testid="social-share-remove" className={classNames(styles.remove, 'share__remove', styles.item)}>
          <button
            type="button"
            className={styles.button}
            onClick={handleRemove}
          >
            <span className={classNames('icon', 'icon-remove', styles.icon)} />
          </button>
        </li>
      ) : null}
    </ul>
  );
};

SocialShareMenu.propTypes = {
  url: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  tooltipAlign: PropTypes.oneOf(['right', 'center']),
  pageRegion: PropTypes.string,
  trackingEventName: PropTypes.string.isRequired,
  cidEventNamePrefix: PropTypes.string,
  actions: PropTypes.shape({
    facebook: PropTypes.bool,
    twitter: PropTypes.bool,
    pinterest: PropTypes.bool,
    email: PropTypes.bool,
    copy: PropTypes.bool,
    embed: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }),
    sms: PropTypes.bool,
    print: PropTypes.bool,
    save: PropTypes.bool,
  }),
};

SocialShareMenu.defaultProps = {
  actions: {},
  pageRegion: 'article-top',
  cidEventNamePrefix: null,
  tooltipAlign: 'center',
};
