import { useState, useEffect, useRef } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { getBroadcastSchedulesDataUrl } from 'lib/scheduleUtils';
import { fetcher } from 'lib/graphqlDataFetcher';

dayjs.extend(isBetween);

/**
 * Given `data` from the API endpoint, get the current show for that brand using the current time
 * when this function is executed.
 * @param  {object} data Data from the API endpoint.
 * @param  {STREAM_KEYS} brand Brand of the current stream type (e.g. NEWS_NOW)
 * @return {object} The entire object of data for a show. Properties are defined by what's in
 * the graphql spec.
 */
function getCurrentShowForBrand(data, brand) {
  if (
    !(
      data?.[brand]
      && Array.isArray(data[brand].scheduleItems)
    )
  ) {
    return null; // early return if `data` is not in the expected format
  }

  const now = dayjs();

  const currentShowFromData = data[brand].scheduleItems.find((scheduleItem) => (
    // `'[)'` is not a typo. see dayjs's docs: https://day.js.org/docs/en/query/is-between
    // In the schedule data, the `endDateTime` for one show is the `startDateTime` for the next
    // show. Because of this, we want the start of the range to be inclusive but the end of the
    // range to be exclusive.
    // e.g.:
    // if current time is 3pm and startDateTime is 3pm, we want now.isBetween() to return true
    // if current time is 3pm and endDateTime is 3pm, we want now.isBetween() to return false
    now.isBetween(scheduleItem.startDateTime, scheduleItem.endDateTime, null, '[)')
  ));

  return currentShowFromData;
}

export function useBroadcastScheduleData(brand) {
  const timeoutId = useRef(null);

  const url = getBroadcastSchedulesDataUrl();

  const { mutate } = useSWRConfig();

  // fallback data (fetched on server side) should be set at Page component level
  const { data, error } = useSWR(url, fetcher);

  // set up the state after the initial `useSWR` call, in order to leverage cached data for the
  // initial state of this hook on both server and client side
  const [currentShow, setCurrentShow] = useState(() => getCurrentShowForBrand(data, brand));

  // Sets the current show immediately when data updates
  useEffect(() => {
    setCurrentShow(getCurrentShowForBrand(data, brand));
  }, [data, brand]);

  // Sets up a timeout to revalidate data and update new current show when the previous show ends
  useEffect(() => {
    if (currentShow?.endDateTime) {
      const timeUntilEndOfCurrentShow = dayjs(currentShow.endDateTime).diff(dayjs());
      timeoutId.current = setTimeout(
        () => {
          // calling `mutate` with the endpoint to revalidate data
          mutate(url);

          setCurrentShow(getCurrentShowForBrand(data, brand));
        },
        timeUntilEndOfCurrentShow,
      );
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [currentShow, data, mutate, brand]);

  return {
    error,
    currentShow,
    scheduleData: data?.[brand]?.scheduleItems,
  };
}
