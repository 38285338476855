import React from 'react';
import PropTypes from 'prop-types';

import { packageContext as packageContextPropType } from 'lib/CustomPropTypes';

class PackageContext extends React.Component {
  static propTypes = {
    ...packageContextPropType,
    children: PropTypes.node.isRequired,
  };

  static childContextTypes = {
    ...packageContextPropType,
  };

  /* eslint-disable react/default-props-match-prop-types */
  static defaultProps = {
    packageIndex: null,
    zone: null,
    zonePackageTypes: [],
    isRailAdjacent: null,
    railContext: null,
  };
  /* eslint-disable react/default-props-match-prop-types */

  getChildContext() {
    const {
      packageIndex,
      zone,
      zonePackageTypes,
      isRailAdjacent,
      railContext,
      topOfPagePackages,
      pkgTitleColorIndex,
    } = this.props;

    return {
      packageIndex,
      zone,
      zonePackageTypes,
      isRailAdjacent,
      railContext,
      topOfPagePackages,
      pkgTitleColorIndex,
    };
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default PackageContext;
