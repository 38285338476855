import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import classNames from 'classnames';
import styles from './styles.module.scss';

function Timer({ isRunning, shouldStop }) {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [hasNewTime, setNewTime] = useState(false);
  const [prevTime, setPrevTime] = useState(0);
  const [cookies, setCookies] = useCookies(['wordsearchtime']);

  useEffect(() => {
    let intervalId;

    if (isRunning && !shouldStop) {
      intervalId = setInterval(() => {
        if (seconds === 59) {
          setMinutes((prevMinutes) => prevMinutes + 1);
          setSeconds(0);
        } else {
          setSeconds((prevSeconds) => prevSeconds + 1);
        }
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, seconds, shouldStop]);

  useEffect(() => {
    const savedTime = cookies.wordsearchtime;
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    if (shouldStop) {
      if (savedTime) {
        const { minutes: savedMinutes, seconds: savedSeconds } = savedTime;
        const savedTotalSeconds = savedMinutes * 60 + savedSeconds;
        const currentTotalSeconds = minutes * 60 + seconds;
        if (currentTotalSeconds < savedTotalSeconds) {
          setNewTime(true);
          setPrevTime(savedTime);
          setCookies('wordsearchtime', { minutes, seconds }, { path: '/', expires: expirationDate });
        }
      } else {
        setCookies('wordsearchtime', { minutes, seconds }, { path: '/', expires: expirationDate });
      }
    }
  }, [shouldStop, minutes, seconds]);

  return (
    <div className={classNames('word-search-timer', styles.timerContainer)} role="timer">
      <div className={styles.timer}>
        <div className={hasNewTime ? styles.newTime : null}>
          {`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
        </div>
        {hasNewTime && (
          <span className={styles.newRecordTime}>
            New Record Time!
          </span>
        )}
      </div>
      {hasNewTime && (
        <span className={styles.previousRecord}>
          {(prevTime.minutes).toString().padStart(2, '0')}
          :
          {(prevTime.seconds).toString().padStart(2, '0')}
          {' '}
          Previous Record
        </span>
      )}
    </div>
  );
}

Timer.propTypes = {
  isRunning: PropTypes.bool.isRequired,
};

Timer.propTypes = {
  shouldStop: PropTypes.bool.isRequired,
};

export { Timer };
