import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

export function PageArrowButton({
  className,
  onClick,
  children,
  direction,
  label,
  hasLeftBorder,
  hasRightBorder,
}) {
  return (
    <button
      aria-label={label}
      type="button"
      onClick={onClick}
      className={classnames(
        styles.pageArrowButton,
        {
          [styles.leftBorder]: hasLeftBorder,
          [styles.rightBorder]: hasRightBorder,
        },
        className,
      )}
    >
      {children || <span className={`icon icon-chevron-${direction}`} />}
    </button>
  );
}

PageArrowButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  direction: PropTypes.oneOf(['left', 'right', 'up', 'down']).isRequired,
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  hasLeftBorder: PropTypes.bool,
  hasRightBorder: PropTypes.bool,
};

PageArrowButton.defaultProps = {
  className: null,
  onClick: null,
  children: null,
  hasLeftBorder: false,
  hasRightBorder: false,
};
