import React from 'react';
import PropTypes from 'prop-types';
import { ECOMMERCE_DISCLAIMER } from 'lib/commerceUtils';

import styles from './styles.module.scss';

const Disclaimer = ({ disclaimerKey, disclaimerOverride }) => {
  // When the user chooses to override the disclaimer, remove the existing disclaimer text.
  let disclaimer = ECOMMERCE_DISCLAIMER.SELECT_CUSTOMER;
  if (disclaimerKey === 'OVERRIDE') {
    disclaimer = disclaimerOverride || '';
  } else if (typeof ECOMMERCE_DISCLAIMER[disclaimerKey] !== 'undefined') {
    disclaimer = ECOMMERCE_DISCLAIMER[disclaimerKey];
  }

  return (
    <div
      className={styles.disclaimer}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: disclaimer }}
    />
  );
};

Disclaimer.propTypes = {
  disclaimerKey: PropTypes.string,
  disclaimerOverride: PropTypes.string,
};

Disclaimer.defaultProps = {
  disclaimerKey: null,
  disclaimerOverride: null,
};

export { Disclaimer };
