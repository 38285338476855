import PropTypes from 'prop-types';
import React, {
  useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export function MaskedHorizontalBar({ children, className, ...rest }) {
  const containerRef = useRef(null);
  const [scrollDirection, setScrollDirection] = useState('left');
  const prevScrollLeftRef = useRef(0);

  useEffect(() => {
    // handle scrolling fade
    const handleScroll = () => {
      const currentScrollLeft = containerRef.current.scrollLeft;
      if (currentScrollLeft > prevScrollLeftRef.current) {
        setScrollDirection('right');
      } else {
        setScrollDirection('left');
      }
      prevScrollLeftRef.current = currentScrollLeft;
    };

    containerRef.current.addEventListener('scroll', handleScroll);

    // auto scroll to current day tab for mobile screens
    // unfortunately this has to be done with a timeout.
    const timer = setTimeout(() => {
      const selectedTab = containerRef.current.querySelector('.active');
      selectedTab?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }, 1000);

    return () => {
      containerRef.current?.removeEventListener('scroll', handleScroll);
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      className={classNames(
        className,
        'masked-horizontal-bar',
        styles.container,
        scrollDirection === 'right' ? styles.rightFade : styles.leftFade,
      )}
      ref={containerRef}
      {...rest}
    >
      {children}
    </div>
  );
}

MaskedHorizontalBar.defaultProps = {
  className: '',
};

MaskedHorizontalBar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
