import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { package as PackagePropType } from 'lib/CustomPropTypes';

import TitleLogo from 'components/TitleLogo';
import { LinkIfHref } from 'components/Link/LinkIfHref';

import { BaguetteItem } from './BaguetteItem/index';

import styles from './styles.module.scss';

const block = 'baguette';

export const Baguette = (props) => {
  const {
    content: { id: packageId, items, metadata: packageMetadata },
    zonePackageTypes = [],
    packageIndex = -1,
    layoutType,
  } = props;

  const prevPackageType = zonePackageTypes
    .slice(0, packageIndex)
    .reverse()
    .find((t) => t !== 'ad');

  const nextPackageType = zonePackageTypes
    .slice(packageIndex + 1)
    .find((t) => t !== 'ad');
  const isLast = nextPackageType !== 'Baguette';
  const isStacked = prevPackageType === 'Baguette';

  const {
    logoUrl,
    packageDescription,
    packageHeadline,
    packageHeadlineUrl,
    showArt,
    showByline,
    showEyebrow,
  } = packageMetadata;
  const hasHeader = !!(packageHeadline || logoUrl);

  const renderPackageHeader = () => {
    if (!hasHeader) {
      return null;
    }
    const headlineOrLogo = (
      <TitleLogo title={packageHeadline} logoUrl={logoUrl} />
    );
    const headline = <LinkIfHref href={packageHeadlineUrl}>{headlineOrLogo}</LinkIfHref>;

    return (
      <div
        data-test={`${block}__header`}
        className={styles.header}
      >
        <h2
          className={classNames(styles.headline, {
            [styles.description]: !!packageDescription,
            [styles.logo]: !!logoUrl,
          })}
          data-testid="header"
        >
          {headline}
        </h2>
        {packageDescription && (
          <span className={styles.description}>
            {packageDescription}
          </span>
        )}
      </div>
    );
  };

  const { showBottomBorder = false } = packageMetadata;
  const hideImageInNarrowZone = layoutType !== 'fullWidth' && items.length > 3;

  return (
    <div
      className={classNames(styles.baguette, {
        [styles.header]: hasHeader,
        [styles.last]: isLast,
        [styles.stacked]: isStacked,
      })}
      data-packageid={packageId}
    >
      {renderPackageHeader()}
      <ul className={styles.itemsWrapper} data-testid="items">
        {items.map((item, index) => (
          <BaguetteItem
            key={item?.id}
            showByline={showByline}
            showEyebrow={showEyebrow}
            showBorder={!hasHeader}
            showArt={showArt}
            item={item}
            hideImageInNarrowZone={hideImageInNarrowZone}
            showImageOnlyInWide={items.length === 4}
            lastItem={items.length - 1 === index}
          />
        ))}
      </ul>
      {showBottomBorder && (<hr className="pkg-bottom-border" data-testid="bottom-border" />)}
    </div>
  );
};

Baguette.propTypes = {
  content: PackagePropType.isRequired,
  zonePackageTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  layoutType: PropTypes.string.isRequired,
  packageIndex: PropTypes.number.isRequired,
};
