module.exports = {
  news: {
    article: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-article-feed',
        placement: 'Below Article - Taboola Feed',
      },
      rail: {
        mode: 'thumbnails-rr',
        container: 'taboola-right-rail-thumbnails-%id',
        placement: 'Right Rail Thumbnails - Bento',
      },
      mobile: {
        mode: 'thumbnails-p',
        container: 'taboola-below-article-mobile-feed',
        placement: 'Mobile Below Article - Taboola Feed',
      },
      chromeless_feed: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App Below Article Thumbnails',
      },
      chromeless_mobile: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'Mobile App Below Article Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App tablet Below Article Thumbnails',
      },
    },

    liveBlog: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-article-feed',
        placement: 'Below Article - Taboola Feed',
      },
      rail: {
        mode: 'thumbnails-rr',
        container: 'taboola-right-rail-thumbnails-%id',
        placement: 'Right Rail Thumbnails - Live Blog Bento',
      },
      mobile: {
        mode: 'thumbnails-p',
        container: 'taboola-below-article-mobile-feed',
        placement: 'Mobile Below Article - Taboola Feed',
      },
      chromeless_feed: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App Below Article Thumbnails',
      },
      chromeless_mobile: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'Mobile App Below Article Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App tablet Below Article Thumbnails',
      },
    },

    home: {
      desktop: {
        mode: 'thumbnails-q',
        container: 'taboola-below-homepage-thumbnails',
        placement: 'Below Homepage Thumbnails',
      },
      mobile: {
        mode: 'thumbnails-q',
        container: 'taboola-below-homepage-thumbnails',
        placement: 'Mobile Below Homepage Thumbnails',
      },
    },

    category: {
      desktop: {
        mode: 'thumbnails-q',
        container: 'taboola-below-category-thumbnails',
        placement: 'Below Category Thumbnails',
      },
      mobile: {
        mode: 'thumbnails-q',
        container: 'taboola-below-category-thumbnails',
        placement: 'Below Category Thumbnails',
      },
    },

    video: {
      desktop: {
        mode: 'thumbnails-feed',
        container: 'taboola-below-video-thumbnails',
        placement: 'Below Video Taboola Feed',
      },
      mobile: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-mobile-below-video-thumbnails',
        placement: 'Mobile Below Video Taboola Feed',
      },
    },
  },

  noticias: {
    article: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-article-feed',
        placement: 'Below Article - Taboola Feed',
      },
      rail: {
        mode: 'thumbnails-rr',
        container: 'taboola-right-rail-thumbnails-%id',
        placement: 'Right Rail Thumbnails - Bento',
      },
      mobile: {
        mode: 'thumbnails-p',
        container: 'taboola-below-article-mobile-feed',
        placement: 'Mobile Below Article - Taboola Feed',
      },
      chromeless_feed: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App Below Article Thumbnails',
      },
      chromeless_mobile: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'Mobile App Below Article Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App tablet Below Article Thumbnails',
      },
    },

    home: {
      desktop: {
        mode: 'thumbnails-q',
        container: 'taboola-below-homepage-thumbnails',
        placement: 'Below Homepage Thumbnails',
      },
      mobile: {
        mode: 'thumbnails-q',
        container: 'taboola-below-homepage-thumbnails',
        placement: 'Mobile Below Homepage Thumbnails',
      },
    },

    category: {
      desktop: {
        mode: 'thumbnails-q',
        container: 'taboola-below-category-thumbnails',
        placement: 'Below Category Thumbnails',
      },
      mobile: {
        mode: 'thumbnails-q',
        container: 'taboola-below-category-thumbnails',
        placement: 'Below Category Thumbnails',
      },
    },

    video: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-video-thumbnails',
        placement: 'Below Video Taboola Feed',
      },
      mobile: {
        mode: 'thumbnails-o',
        container: 'taboola-mobile-below-video-thumbnails',
        placement: 'Mobile Below Video Taboola Feed',
      },
    },
  },

  deportes: {
    article: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-article-feed',
        placement: 'Below Article - Taboola Feed',
      },
      rail: {
        mode: 'thumbnails-rr',
        container: 'taboola-right-rail-thumbnails-%id',
        placement: 'Right Rail Thumbnails - Bento',
      },
      mobile: {
        mode: 'thumbnails-p',
        container: 'taboola-below-article-mobile-feed',
        placement: 'Mobile Below Article - Taboola Feed',
      },
      chromeless_feed: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App Below Article Thumbnails',
      },
      chromeless_mobile: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'Mobile App Below Article Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App tablet Below Article Thumbnails',
      },
    },

    home: {
      desktop: {
        mode: 'thumbnails-q',
        container: 'taboola-below-homepage-thumbnails',
        placement: 'Below Homepage Thumbnails',
      },
      mobile: {
        mode: 'thumbnails-q',
        container: 'taboola-below-homepage-thumbnails',
        placement: 'Mobile Below Homepage Thumbnails',
      },
    },

    category: {
      desktop: {
        mode: 'thumbnails-q',
        container: 'taboola-below-category-thumbnails',
        placement: 'Below Category Thumbnails',
      },
      mobile: {
        mode: 'thumbnails-q',
        container: 'taboola-below-category-thumbnails',
        placement: 'Below Category Thumbnails',
      },
    },

    video: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-video-thumbnails',
        placement: 'Below Video Taboola Feed',
      },
      mobile: {
        mode: 'thumbnails-o',
        container: 'taboola-mobile-below-video-thumbnails',
        placement: 'Mobile Below Video Taboola Feed',
      },
    },
  },

  entretenimiento: {
    article: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-article-feed',
        placement: 'Below Article - Taboola Feed',
      },
      rail: {
        mode: 'thumbnails-rr',
        container: 'taboola-right-rail-thumbnails-%id',
        placement: 'Right Rail Thumbnails - Bento',
      },
      mobile: {
        mode: 'thumbnails-p',
        container: 'taboola-below-article-mobile-feed',
        placement: 'Mobile Below Article - Taboola Feed',
      },
      chromeless_feed: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App Below Article Thumbnails',
      },
      chromeless_mobile: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'Mobile App Below Article Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App tablet Below Article Thumbnails',
      },
    },

    home: {
      desktop: {
        mode: 'thumbnails-q',
        container: 'taboola-below-homepage-thumbnails',
        placement: 'Below Homepage Thumbnails',
      },
      mobile: {
        mode: 'thumbnails-q',
        container: 'taboola-below-homepage-thumbnails',
        placement: 'Mobile Below Homepage Thumbnails',
      },
      midFront: {
        mode: 'thumbnails-feed-3x1',
        container: 'taboola-mid-article-thumbnails---entertainment',
        placement: 'Mid Article Thumbnails - Entertainment',
      },
    },

    category: {
      desktop: {
        mode: 'thumbnails-q',
        container: 'taboola-below-category-thumbnails',
        placement: 'Below Category Thumbnails',
      },
      mobile: {
        mode: 'thumbnails-q',
        container: 'taboola-below-category-thumbnails',
        placement: 'Below Category Thumbnails',
      },
    },

    video: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-video-thumbnails',
        placement: 'Below Video Taboola Feed',
      },
      mobile: {
        mode: 'thumbnails-o',
        container: 'taboola-mobile-below-video-thumbnails',
        placement: 'Mobile Below Video Taboola Feed',
      },
    },
  },

  shows: {
    article: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-article-feed',
        placement: 'Below Article - Taboola Feed',
      },
      rail: {
        mode: 'thumbnails-rr',
        container: 'taboola-right-rail-thumbnails-%id',
        placement: 'Right Rail Thumbnails - Bento',
      },
      mobile: {
        mode: 'thumbnails-p',
        container: 'taboola-below-article-mobile-feed',
        placement: 'Mobile Below Article - Taboola Feed',
      },
      chromeless_feed: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App Below Article Thumbnails',
      },
      chromeless_mobile: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'Mobile App Below Article Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App tablet Below Article Thumbnails',
      },
    },
    home: {
      desktop: {
        mode: 'thumbnails-q',
        container: 'taboola-below-homepage-thumbnails',
        placement: 'Below Homepage Thumbnails',
      },
      mobile: {
        mode: 'thumbnails-q',
        container: 'taboola-below-homepage-thumbnails',
        placement: 'Mobile Below Homepage Thumbnails',
      },
    },
    category: {
      desktop: {
        mode: 'thumbnails-q',
        container: 'taboola-below-category-thumbnails',
        placement: 'Below Category Thumbnails',
      },
      mobile: {
        mode: 'thumbnails-q',
        container: 'taboola-below-category-thumbnails',
        placement: 'Below Category Thumbnails',
      },
    },
    video: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-video-thumbnails',
        placement: 'Below Video Taboola Feed',
      },
      mobile: {
        mode: 'thumbnails-o',
        container: 'taboola-mobile-below-video-thumbnails',
        placement: 'Mobile Below Video Taboola Feed',
      },
    },
  },

  telemundo: {
    article: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-article-feed',
        placement: 'Below Article - Taboola Feed',
      },
      rail: {
        mode: 'thumbnails-rr',
        container: 'taboola-right-rail-thumbnails-%id',
        placement: 'Right Rail Thumbnails - Bento',
      },
      mobile: {
        mode: 'thumbnails-p',
        container: 'taboola-below-article-mobile-feed',
        placement: 'Mobile Below Article - Taboola Feed',
      },
      chromeless_feed: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App Below Article Thumbnails',
      },
      chromeless_mobile: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'Mobile App Below Article Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App tablet Below Article Thumbnails',
      },
    },
    home: {
      desktop: {
        mode: 'thumbnails-q',
        container: 'taboola-below-homepage-thumbnails',
        placement: 'Below Homepage Thumbnails',
      },
      mobile: {
        mode: 'thumbnails-q',
        container: 'taboola-below-homepage-thumbnails',
        placement: 'Mobile Below Homepage Thumbnails',
      },
    },
    category: {
      desktop: {
        mode: 'thumbnails-q',
        container: 'taboola-below-category-thumbnails',
        placement: 'Below Category Thumbnails',
      },
      mobile: {
        mode: 'thumbnails-q',
        container: 'taboola-below-category-thumbnails',
        placement: 'Below Category Thumbnails',
      },
    },
    video: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-video-thumbnails',
        placement: 'Below Video Taboola Feed',
      },
      mobile: {
        mode: 'thumbnails-o',
        container: 'taboola-mobile-below-video-thumbnails',
        placement: 'Mobile Below Video Taboola Feed',
      },
    },
  },

  mach: {
    article: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-article-feed',
        placement: 'Below Article - Taboola Feed - MACH',
      },
      rail: {
        mode: 'thumbnails-rr',
        container: 'taboola-right-rail-thumbnails-%id',
        placement: 'Right Rail Thumbnails - Bento',
      },
      mobile: {
        mode: 'thumbnails-p',
        container: 'taboola-below-article-feed',
        placement: 'Mobile Below Article - Taboola Feed - MACH',
      },
      chromeless_mobile: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App Below Article Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App tablet Below Article Thumbnails',
      },
    },
    video: {
      desktop: {
        mode: 'thumbnails-feed',
        container: 'taboola-below-video-thumbnails',
        placement: 'Below Video Taboola Feed',
      },
      mobile: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-mobile-below-video-thumbnails',
        placement: 'Mobile Below Video Taboola Feed',
      },
    },
  },

  better: {
    article: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-article-feed',
        placement: 'Below Article - Taboola Feed - Better',
      },
      rail: {
        mode: 'thumbnails-rr',
        container: 'taboola-right-rail-thumbnails-%id',
        placement: 'Right Rail Thumbnails - Bento',
      },
      mobile: {
        mode: 'thumbnails-p',
        container: 'taboola-below-article-feed',
        placement: 'Mobile Below Article - Taboola Feed - Better',
      },
      chromeless_mobile: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'Mobile App Below Article Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App tablet Below Article Thumbnails',
      },
    },
    video: {
      desktop: {
        mode: 'thumbnails-feed',
        container: 'taboola-below-video-thumbnails',
        placement: 'Below Video Taboola Feed',
      },
      mobile: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-mobile-below-video-thumbnails',
        placement: 'Mobile Below Video Taboola Feed',
      },
    },
  },

  think: {
    article: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-article-feed',
        placement: 'Below Article - Taboola Feed - Think',
      },
      rail: {
        mode: 'thumbnails-rr',
        container: 'taboola-right-rail-thumbnails-%id',
        placement: 'Right Rail Thumbnails - Bento',
      },
      mobile: {
        mode: 'thumbnails-p',
        container: 'taboola-below-article-feed-mobile',
        placement: 'Mobile Below Article - Taboola Feed - Think',
      },
      chromeless_mobile: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'Mobile App Below Article Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App tablet Below Article Thumbnails',
      },
    },
    video: {
      desktop: {
        mode: 'thumbnails-feed',
        container: 'taboola-below-video-thumbnails',
        placement: 'Below Video Taboola Feed',
      },
      mobile: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-mobile-below-video-thumbnails',
        placement: 'Mobile Below Video Taboola Feed',
      },
    },
  },

  today: {
    article: {
      desktop: {
        mode: 'thumbnails-h',
        container: 'taboola-below-article-feed',
        placement: 'Below Article - Taboola Feed',
      },
      rail: {
        mode: 'thumbnails-rr',
        container: 'taboola-right-rail-thumbnails-%id',
        placement: 'Right Rail Thumbnails - Bento',
      },
      mobile: {
        mode: 'thumbnails-i',
        container: 'taboola-below-article-mobile-feed',
        placement: 'Mobile Below Article - Taboola Feed',
      },
      chromeless_feed: {
        mode: 'thumbnails-feed-1x1_redesign',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'Mobile Below Article - Taboola Feed',
      },
      chromeless_feed_ios: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App Below Article Thumbnails',
      },
      chromeless_mobile: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'Mobile App Below Article Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App tablet Below Article Thumbnails',
      },
      'commerce-mobile': {
        mode: 'thumbnails-i',
        container: 'taboola-below-article-mobile-feed',
        placement: 'Mobile Below Article - Taboola Feed - Commerce',
      },
      commerce: {
        mode: 'thumbnails-h',
        container: 'taboola-below-article-feed',
        placement: 'Below Article - Taboola Feed - Commerce',
      },
    },
    liveBlog: {
      desktop: {
        mode: 'thumbnails-o',
        container: 'taboola-below-article-feed',
        placement: 'Below Article - Taboola Feed',
      },
      rail: {
        mode: 'thumbnails-rr',
        container: 'taboola-right-rail-thumbnails-%id',
        placement: 'Right Rail Thumbnails - Live Blog Bento',
      },
      mobile: {
        mode: 'thumbnails-p',
        container: 'taboola-below-article-mobile-feed',
        placement: 'Mobile Below Article - Taboola Feed',
      },
      chromeless_feed: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App Below Article Thumbnails',
      },
      chromeless_mobile: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'Mobile App Below Article Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App tablet Below Article Thumbnails',
      },
    },
    home: {
      desktop: {
        mode: 'thumbnails-h',
        container: 'taboola-below-homepage-thumbnails---bento',
        placement: 'Below Homepage Thumbnails - Bento',
      },
      mobile: {
        mode: 'thumbnails-i',
        container: 'taboola-mobile-below-homepage-thumbnails---bento',
        placement: 'Mobile Below Homepage Thumbnails - Bento',
      },
    },
    recipe: {
      desktop: {
        mode: 'thumbnails-h',
        container: 'taboola-below-recipe---taboola-feed',
        placement: 'Below Recipe - Taboola Feed',
      },
      mobile: {
        mode: 'thumbnails-i',
        container: 'taboola-mobile-below-recipe---taboola-feed',
        placement: 'Mobile Below Recipe - Taboola Feed',
      },
      chromeless_feed: {
        mode: 'thumbnails-feed-1x1_redesign',
        container: 'taboola-app-below-recipe-thumbnails',
        placement: 'Mobile Below Recipe - Taboola Feed',
      },
      chromeless_feed_ios: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-app-below-recipe-thumbnails',
        placement: 'App Below Recipe Thumbnails',
      },
      chromeless_mobile: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-recipe-thumbnails',
        placement: 'Mobile App Below Recipe Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-recipe-thumbnails',
        placement: 'App tablet Below Recipe Thumbnails',
      },
    },
    video: {
      desktop: {
        mode: 'thumbnails-feed-4x1',
        container: 'taboola-below-video-thumbnails',
        placement: 'Below Video Taboola Feed',
      },
      mobile: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-mobile-below-video-thumbnails',
        placement: 'Mobile Below Video Taboola Feed',
      },
    },
  },

  msnbc: {
    article: {
      desktop: {
        mode: 'thumbnails-feed-2x1',
        container: 'taboola-below-article---taboola-feed',
        placement: 'Below Article - Taboola Feed',
      },
      rail: {
        mode: 'thumbnails-rr',
        container: 'taboola-right-rail-thumbnails-%id',
        placement: 'Right Rail Thumbnails - Bento',
      },
      mobile: {
        mode: 'thumbnails-feed-2x1',
        container: 'taboola-mobile-below-article---taboola-feed',
        placement: 'Mobile Below Article - Taboola Feed',
      },
      chromeless_feed: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App Below Article Thumbnails',
      },
      chromeless_mobile: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'Mobile App Below Article Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App tablet Below Article Thumbnails',
      },
    },
    home: {
      desktop: {
        mode: 'thumbnails-q',
        container: 'taboola-below-homepage-thumbnails',
        placement: 'Below Homepage Thumbnails',
      },
      mobile: {
        mode: 'thumbnails-q',
        container: 'taboola-below-homepage-thumbnails',
        placement: 'Below Homepage Thumbnails',
      },
    },
    video: {
      desktop: {
        mode: 'thumbnails-feed',
        container: 'taboola-below-video-thumbnails',
        placement: 'Below Video Taboola Feed',
      },
      mobile: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-mobile-below-video-thumbnails',
        placement: 'Mobile Below Video Taboola Feed',
      },
    },
  },

  select: {
    article: {
      desktop: {
        mode: 'thumbnails-a',
        container: 'taboola-below-article---taboola-feed---select',
        placement: 'Below Article - Taboola Feed - Select',
      },
      mobile: {
        mode: 'thumbnails-p',
        container: 'taboola-below-article-mobile-feed',
        placement: 'Mobile Below Article - Taboola Feed',
      },
      chromeless_feed: {
        mode: 'thumbnails-feed-mobile',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App Below Article Thumbnails',
      },
      chromeless_mobile: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'Mobile App Below Article Thumbnails',
      },
      chromeless_tablet: {
        mode: 'thumbs-app',
        container: 'taboola-app-below-article-thumbnails',
        placement: 'App tablet Below Article Thumbnails',
      },
    },
  },
};
