export const BADGES = {
  FIRST_ON_NBC: 'FIRST ON NBC',
  BREAKING: 'BREAKING',
  LIVE: 'LIVE',
  DEVELOPING: 'DEVELOPING',
  EXCLUSIVE: 'EXCLUSIVE',
  SPECIAL_REPORT: 'SPECIAL REPORT',
  WATCH: 'WATCH',
  DATA_GRAPHIC: 'DATA GRAPHIC',
  EYEBROW: 'EYEBROW',
};
