import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.themed.scss';

/**
 *
 * @param {object} props
 * @returns {React.ReactElement} Component
 */
const CuratedList = (props) => {
  const {
    content,
    headline,
    text,
    isDropdownOpen,
    setIsDropdownOpen,
  } = props;

  const gridItems = content.items.map(({
    text: itemText,
    url,
  }) => (
    <a data-icid="package-nav" href={url?.primary}>
      <div key={itemText} className="grid-item">{itemText}</div>
    </a>
  ));

  /**
   *
   * @param {object} e
   */
  const filterKeyTriggerButton = (e) => {
    if (e.key === 'ArrowUp') {
      setIsDropdownOpen(false);
    } else if (e.key === 'ArrowDown') {
      setIsDropdownOpen(true);
    } else if (e.key === 'Enter') {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };
  return (
    <div className="dropdown-container">
      <div
        className="dropdown-trigger"
        onClick={() => { setIsDropdownOpen(!isDropdownOpen); }}
        onKeyDown={filterKeyTriggerButton}
        role="button"
        tabIndex={0}
      >
        <div className={classNames('dropdown-title', { 'dropdown-open': isDropdownOpen })}>
          {headline?.tease || text}
        </div>
        <div className={classNames('chevron', 'icon', {
          'icon-chevron-light-down': !isDropdownOpen,
          'icon-chevron-light-up': isDropdownOpen,
        })}
        />
        <div className={classNames('dropdown-content', { 'dropdown-open': isDropdownOpen })}>
          <div className="dropdown-inner">
            <div className="grid-layout">
              {gridItems}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CuratedList.propTypes = {
  content: PropTypes.object.isRequired,
  headline: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isDropdownOpen: PropTypes.bool,
  setIsDropdownOpen: PropTypes.func,
};

CuratedList.defaultProps = {
  isDropdownOpen: false,
  setIsDropdownOpen: () => {},
};

export { CuratedList };
