import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.themed.scss';

/**
 * Blinking dot to indicate something is "live"
 * @param {object} props React props
 * @param {string} props.className className to augment and pass on to the
 * underlying `span` element
 */
export function InlineBlinkingLiveDot(props) {
  const { className, ...restProps } = props;
  return ( // eslint-disable-next-line react/jsx-props-no-spreading
    <span className={classNames('inline-blinking-live-dot', className)} {...restProps} />
  );
}

InlineBlinkingLiveDot.propTypes = {
  className: PropTypes.string,
};

InlineBlinkingLiveDot.defaultProps = {
  className: '',
};
