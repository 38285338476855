import React from 'react';
import PropTypes from 'prop-types';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import { PymFrame } from 'components/Pym/Frame';
import './styles.themed.scss';

/**
 * @param {object} props
 * @param {object} props.data
 * @param {boolean} [props.useResponsiveSizing=true]
 * @returns {React.ReactElement}
 */
const DataVizLoader = ({ data, useResponsiveSizing = true }) => {
  const {
    computedValues: { url },
  } = data;

  const cleanedEmbedUrl = url?.replace(/&(childId|parentUrl)=[^&]+/g, '');

  return (
    <div className="data-viz-div">
      <PymFrame
        url={cleanedEmbedUrl}
        title="Data Visualization"
        useResponsiveSizing={useResponsiveSizing}
        className={!useResponsiveSizing ? 'w-100 h-100 b-none' : undefined}
        scrolling={!useResponsiveSizing ? 'no' : undefined}
      />
    </div>
  );
};

DataVizLoader.propTypes = {
  data: ContentPropType.isRequired,
  useResponsiveSizing: PropTypes.bool,
};

DataVizLoader.defaultProps = {
  useResponsiveSizing: true,
};

export default DataVizLoader;
