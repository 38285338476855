import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Background from 'components/Background';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { getOptimizedImageUrl } from 'lib/imageUtils';

import './styles.themed.scss';

function ShowLeadBackground({ imageUrl, imageUrlMobile }) {
  const bgImageUrlMobile = getOptimizedImageUrl(imageUrlMobile, AIMS_FLAVORS.FIT_760w);
  const bgImageUrlDesktop = getOptimizedImageUrl(imageUrl, AIMS_FLAVORS.FIT_1240w);

  return (
    <>
      {bgImageUrlMobile && (
        <Background
          imageUrl={bgImageUrlMobile}
          styleOverride={{
            bgWrapper: `show-lead-background__wrapper ${
              imageUrl ? 'show-lead-background__hide--on-large' : null
            }`,
            bgBackground: 'show-lead-background__container',
            bgPicture: 'show-lead-background__image',
            bgGradient: 'show-lead-background__hide',
          }}
          useBackground
        />
      )}
      {bgImageUrlDesktop && (
        <Background
          imageUrl={bgImageUrlDesktop}
          styleOverride={{
            bgWrapper: `show-lead-background__wrapper ${
              imageUrlMobile ? 'show-lead-background__hide--on-mobile' : null
            }`,
            bgBackground: 'show-lead-background__container',
            bgPicture: 'show-lead-background__image',
            bgGradient: 'show-lead-background__hide',
          }}
          useBackground
        />
      )}
      <div
        className={classNames(
          'show-lead-background__gradient',
          'absolute absolute-fill',
          {
            hasImage:
              typeof imageUrl !== 'undefined'
              || typeof imageUrlMobile !== 'undefined',
          },
        )}
        data-testid="show-lead-background"
      />
    </>
  );
}

ShowLeadBackground.propTypes = {
  imageUrl: PropTypes.string,
  imageUrlMobile: PropTypes.string,
};

ShowLeadBackground.defaultProps = {
  imageUrl: null,
  imageUrlMobile: null,
};

export default ShowLeadBackground;
