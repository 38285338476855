import React from 'react';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import { PackageSubNav } from 'components/packages/PackageSubNav';
import { StandardLayout } from './StandardLayout';

import './styles.themed.scss';
import { SmallLayout } from './SmallLayout';
import { applyPackageDefaults } from '../applyPackageDefaults';

/**
 * @param {object} content
 * @param {object} content.content - The content object.
 * @returns {React.ReactElement} Component
*/
export function SingleStoryline({ content: rawContent }) {
  if (!rawContent) return null;

  const content = applyPackageDefaults(rawContent);
  const {
    items,
    subnav,
    metadata: {
      packageTitle, mediaSize = 'medium', pkgHeaderStyle,
    },
  } = content;
  const data = { subnav, pkgHeaderStyle, packageTitle };
  const hasRelateds = items[0]?.related?.length;
  const showSmallLayout = mediaSize === 'small' || (mediaSize === 'none' && hasRelateds);
  return (
    <div className="single-storyline" data-testid="single-storyline">
      <PackageSubNav content={{ content, data }} />
      {showSmallLayout ? <SmallLayout content={content} /> : <StandardLayout content={content} />}
    </div>
  );
}

SingleStoryline.propTypes = {
  content: PackagePropType.isRequired,
};
