import AIMS_FLAVORS from 'lib/aimsFlavors';

/* eslint-disable camelcase */
const {
  FOCAL_100x100,
  FOCAL_120x120,
  FOCAL_147x147,
  FOCAL_160x160,
  FOCAL_200x100,
  FOCAL_220x110,
  FOCAL_260x130,
  FOCAL_280x140,
  FOCAL_360x180,
  FOCAL_460x230,
  FOCAL_760x380,
  FOCAL_90x90,
} = AIMS_FLAVORS;

export default {
  images: {
    2: {
      full: [
        [FOCAL_760x380, FOCAL_220x110, FOCAL_360x180, FOCAL_360x180],
        [FOCAL_90x90, FOCAL_220x110, null, FOCAL_260x130],
      ],
      rail: [
        [FOCAL_760x380, FOCAL_220x110, FOCAL_120x120, FOCAL_200x100],
        [FOCAL_90x90, FOCAL_220x110, FOCAL_120x120, FOCAL_200x100],
      ],
    },
    3: {
      full: [
        [FOCAL_760x380, FOCAL_147x147, FOCAL_120x120, FOCAL_360x180],
        [FOCAL_90x90, null, FOCAL_120x120, FOCAL_100x100],
        [FOCAL_90x90, null, FOCAL_200x100, FOCAL_100x100],
      ],
      rail: [
        [FOCAL_760x380, FOCAL_147x147, FOCAL_120x120, FOCAL_160x160],
        [FOCAL_90x90],
        [FOCAL_90x90],
      ],
    },
    4: {
      full: [
        [FOCAL_760x380, FOCAL_220x110, FOCAL_120x120, FOCAL_260x130],
        [FOCAL_90x90, FOCAL_220x110, FOCAL_120x120, FOCAL_260x130],
        [FOCAL_90x90, null, FOCAL_120x120, FOCAL_100x100],
        [FOCAL_90x90, null, null, FOCAL_100x100],
      ],
      rail: [
        [FOCAL_760x380, FOCAL_220x110, FOCAL_120x120, FOCAL_200x100],
        [FOCAL_90x90, FOCAL_220x110],
        [FOCAL_90x90],
        [FOCAL_90x90],
      ],
    },
    5: {
      full: [
        [FOCAL_760x380, FOCAL_460x230, FOCAL_460x230, FOCAL_360x180],
        [FOCAL_90x90, FOCAL_220x110],
        [FOCAL_90x90, FOCAL_220x110],
      ],
      rail: [
        [FOCAL_760x380, FOCAL_460x230, FOCAL_280x140, FOCAL_460x230],
        [FOCAL_90x90, FOCAL_220x110, FOCAL_120x120, FOCAL_200x100],
        [FOCAL_90x90, FOCAL_220x110, FOCAL_120x120, FOCAL_200x100],
      ],
    },
    6: {
      full: [
        [FOCAL_760x380, FOCAL_147x147, FOCAL_200x100, FOCAL_360x180],
        [FOCAL_90x90, FOCAL_147x147, FOCAL_200x100, FOCAL_200x100],
        [FOCAL_90x90],
      ],
      rail: [
        [FOCAL_760x380, FOCAL_147x147, FOCAL_120x120, FOCAL_160x160],
        [FOCAL_90x90, FOCAL_147x147, FOCAL_120x120, FOCAL_160x160],
        [FOCAL_90x90],
      ],
    },
    7: {
      full: [
        [FOCAL_760x380, FOCAL_460x230, FOCAL_460x230, FOCAL_160x160],
        [FOCAL_90x90, FOCAL_147x147, FOCAL_200x100, FOCAL_200x100],
        [FOCAL_90x90, FOCAL_147x147, FOCAL_200x100, FOCAL_200x100],
        [FOCAL_90x90],
      ],
      rail: [
        [FOCAL_760x380, FOCAL_460x230, FOCAL_280x140, FOCAL_460x230],
        [FOCAL_90x90, FOCAL_147x147, FOCAL_120x120, FOCAL_160x160],
        [FOCAL_90x90, FOCAL_147x147, FOCAL_120x120, FOCAL_160x160],
        [FOCAL_90x90],
      ],
    },
    8: {
      full: [
        [FOCAL_760x380, FOCAL_147x147, FOCAL_200x100, FOCAL_160x160],
        [FOCAL_90x90, FOCAL_147x147, FOCAL_200x100],
        [FOCAL_90x90],
        [FOCAL_90x90],
        [FOCAL_90x90],
      ],
      rail: [
        [FOCAL_760x380, FOCAL_147x147, FOCAL_120x120, FOCAL_160x160],
        [FOCAL_90x90, FOCAL_147x147, FOCAL_120x120, FOCAL_160x160],
        [FOCAL_90x90, null, FOCAL_120x120],
        [FOCAL_90x90, null, FOCAL_120x120],
        [FOCAL_90x90],
      ],
    },
  },

  headlineFonts: {
    2: {
      full: [
        [7, 6, 7, 8],
        [5, 6, null, 7],
      ],
      rail: [
        [7, 6],
        [5, 6],
      ],
      fullNoArt: [
        [8, 6, 9],
        [7, 6, 8],
      ],
      railNoArt: [
        [8, 6, 8, 6],
        [7, 6, 7, 6],
      ],
    },
    3: {
      full: [
        [7, 6, 7, 8],
        [5, null, 6, 5],
        [5, null, 6, 5],
      ],
      rail: [
        [7, 6, null, 7],
        [5, null],
        [5, null],
      ],
      fullNoArt: [
        [8, 7, 8, 9],
        [7, 5, 8, 6],
        [7, 5, 8, 6],
      ],
      railNoArt: [
        [8, 7, 8],
        [7, 5],
        [7, 5],
      ],
    },
    4: {
      full: [
        [7, 6, 7],
        [5, 6, null, 7],
        [5, null, 7, 6],
        [6, 5, 6],
      ],
      rail: [
        [7, 6],
        [5, 6, 5, 6],
        [5],
        [6, 5],
      ],
      fullNoArt: [
        [8, 6, 8],
        [7, 5, 8],
        [7, 5, 6],
        [7, 5, 6],
      ],
      railNoArt: [
        [8, 6, 8, 5],
        [7, 5],
        [7, 5],
        [7, 5],
      ],
    },
    5: {
      full: [
        [7, 8],
        [5, 6],
        [5, 6],
        [6, 5],
        [6, 5],
      ],
      rail: [
        [7, 8],
        [5, 6],
        [5, 6],
        [6, 5],
        [6, 5],
      ],
      fullNoArt: [
        [8, null, 9],
        [7, 5, 7],
        [7, 5, 7],
      ],
      railNoArt: [
        [8],
        [7, 5, 7],
        [7, 5, 7],
      ],
    },
    6: {
      full: [
        [7, 6, 7, 8],
        [5, 6, 7, 6],
        [5],
        [6, 5],
        [6, 5],
        [6, 5],
      ],
      rail: [
        [7, 6, null, 7],
        [5, 6, null, 7],
        [5],
        [6, 5],
        [6, 5],
        [6, 5],
      ],
      fullNoArt: [
        [8, 7, 8, 9],
        [7, null, 8, 7],
        [7, 5],
      ],
      railNoArt: [
        [8, 7],
        [7],
        [7, 5],
      ],
    },
    7: {
      full: [
        [7, 8, null, 7],
        [5, 6, 7, 6],
        [5, 6, 7, 6],
        [5],
        [6, 5],
        [6, 5],
        [6, 5],
      ],
      rail: [
        [7, 8],
        [5, 6, null, 7],
        [5, 6, null, 7],
        [5],
        [6, 5],
        [6, 5],
        [6, 5],
      ],
      fullNoArt: [
        [8, null, 9, 8],
        [7, null, 8, 7],
        [7, null, 8, 7],
        [7, 5],
      ],
      railNoArt: [
        [8, null, 8],
        [7, null, 5, 7],
        [7, null, 5, 7],
        [7, 5],
      ],
    },
    8: {
      full: [
        [7, 6, 7],
        [5, 6, 7, 6],
        [5],
        [5],
        [5],
        [6, 5],
        [6, 5],
        [6, 5],
      ],
      rail: [
        [7, 6, null, 7],
        [5, 6, null, 7],
        [5, 6, null, 5],
        [5, null, 6, 5],
        [5],
        [6, 5],
        [6, 5],
        [6, 5],
      ],
      fullNoArt: [
        [8, null, 9, 8],
        [7, null, 9, 7],
        [7, null, 5],
        [7, 5],
        [7, 5],
      ],
      railNoArt: [
        [8, null, 7, 8],
        [7, null, 7, 8],
        [7, null, null, 5],
        [7, 5, 7, 5],
        [7, 5],
      ],
    },
  },

  float: {
    2: {
      full: [
        [null, null, null, 1],
        [1, 0],
      ],
      rail: [
        [null, null, 1, 0],
        [1, 0, 1, 0],
      ],
    },
    3: {
      full: [
        [null, 1],
        [1, 0, 1],
        [1, 0, null, 1],
      ],
      rail: [
        [null, 1],
        [1, 0],
        [1, 0],
      ],
    },
    4: {
      full: [
        [null, null, 1, 0],
        [1, 0],
        [1, 0, 1],
        [null, null, null, 1],
      ],
      rail: [
        [null, null, 1, 0],
        [1, 0],
        [1, 0],
        [1, 0],
      ],
    },
    5: {
      full: [
        [null, null, null, 1],
        [1, 0],
        [1, 0],
      ],
      rail: [
        null,
        [1, 0, 1, 0],
        [1, 0, 1, 0],
      ],
    },
    6: {
      full: [
        [0, 1],
        [1, null, null, 0],
        [1, 0],
      ],
      rail: [
        [0, 1],
        [1],
        [1, 0],
      ],
    },
    7: {
      full: [
        [null, null, null, 1],
        [1, null, null, 0],
        [1, null, null, 0],
        [1, 0],
      ],
      rail: [
        null,
        [1],
        [1],
        [1, 0],
      ],
    },
    8: {
      full: [
        [0, 1],
        [1, null, null, 0],
        [1, null, null, 0],
        [1, 0],
        [1, 0],
      ],
      rail: [
        [0, 1],
        [1],
        [1, 0, 1, 0],
        [1, 0, 1, 0],
        [1, 0],
      ],
    },
  },

  defaultTitle: {
    default: 'Key Updates',
    today: 'Top Moments',
  },
};
