import { LIVEBLOG_DEEPLINK } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { transformCardLinkToHashLink } from './transformCardLinkToHashLink';

/**
 * Prepare Deep Link by Vertical (news, noticias, msnbc, etc.)
 * Uses BrandFeatures
 *
 * @param contentId
 * @param vertical
 * @returns {string}
 */
export function getDeepLinkByVertical(contentId, vertical) {
  const deviceUrlVertical = getFeatureConfigForBrand(LIVEBLOG_DEEPLINK, vertical);
  return `${deviceUrlVertical}://item/${contentId}`;
}

export function getLinkAttribs(href, contentId, isEmbedded, isWebEmbed, vertical) {
  if (isEmbedded && contentId && !isWebEmbed) {
    return {
      deviceUrl: getDeepLinkByVertical(contentId, vertical),
    };
  }

  if (isWebEmbed) {
    return {
      target: '_parent',
      href: transformCardLinkToHashLink(href),
    };
  }

  if (href) {
    return {
      href: transformCardLinkToHashLink(href),
    };
  }

  return {
    href: null,
  };
}
