import React from 'react';

export function LiveVideoEmbedNoticiasAhoraLogo() {
  return (
    <svg data-testid="NoticiasAhoraLogo" className="live-video-embed__logo-svg" width="735" height="158" viewBox="0 0 734.33 157.06" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <path fill="#e71402" d="M148.11.9l-23.68.02c-18.7.02-35.87,6.5-49.43,17.32C61.42,7.45,44.22,1,25.53,1.02l-23.68.02C.83,1.05,0,1.88,0,2.9l.06,54.73c0,1.02.83,1.85,1.86,1.85l43.14-.04.1,95.63c0,1.24,1.19,2.12,2.37,1.78,10.19-2.92,19.54-7.82,27.6-14.25,8.08,6.42,17.44,11.3,27.63,14.2,1.19.34,2.37-.55,2.37-1.79l-.1-95.63,43.14-.04c1.02,0,1.85-.83,1.85-1.86l-.06-54.73c0-1.02-.83-1.85-1.86-1.85Z" />
          <path fill="#8c110c" d="M45.07,80.45l-.02-21.01-43.14.04c-1.02,0-1.85-.83-1.86-1.85L0,2.9c0-1.02.83-1.85,1.85-1.86l23.68-.02c18.7-.02,35.89,6.43,49.47,17.22-18.26,14.57-29.95,37.03-29.93,62.21ZM45.07,80.45l.08,74.62c0,1.24,1.19,2.12,2.37,1.78,10.19-2.92,19.54-7.82,27.61-14.25-18.29-14.54-30.03-36.96-30.06-62.14Z" />
          <path fill="#be1307" d="M75.12,142.59c-18.29-14.54-30.03-36.96-30.06-62.14-.03-25.18,11.67-47.63,29.93-62.21,18.29,14.54,30.03,36.96,30.06,62.14.03,25.18-11.67,47.63-29.93,62.21Z" />
        </g>
        <g>
          <g>
            <path fill="black" d="M210.15,37.25h-6.28s-15.46-23.64-15.46-23.64l.02,23.66h-7.1s-.04-35.92-.04-35.92c.94-.22,2.04-.32,3.3-.33,1.05,0,1.9.17,2.57.51.67.34,1.29.98,1.87,1.91l14,22.62-.03-24.79c1.08-.18,1.89-.27,2.44-.27,3.11,0,4.66,1.39,4.66,4.19l.03,32.05Z" />
            <path fill="black" d="M250.15,19.09c0,5.95-1.42,10.61-4.29,13.99-2.87,3.37-6.95,5.06-12.26,5.07-3.5,0-6.46-.77-8.88-2.3-2.42-1.54-4.25-3.74-5.5-6.61-1.25-2.87-1.87-6.22-1.88-10.05s.68-7.13,2.05-9.98c1.37-2.85,3.3-5.01,5.79-6.49,2.49-1.47,5.43-2.21,8.82-2.21,5.16,0,9.14,1.62,11.95,4.88,2.8,3.26,4.2,7.83,4.21,13.71ZM242.52,19.15c0-4.09-.74-7.23-2.2-9.44-1.47-2.2-3.59-3.3-6.37-3.3-2.92,0-5.14,1.11-6.66,3.31-1.51,2.21-2.27,5.35-2.26,9.44,0,4.12.77,7.34,2.28,9.65s3.68,3.47,6.5,3.46c2.81,0,4.97-1.15,6.47-3.45,1.5-2.29,2.24-5.52,2.24-9.68Z" />
            <path fill="black" d="M263.52,7.19h-11.32c-.11-.63-.17-1.35-.17-2.14,0-1.18.41-2.12,1.24-2.82.83-.7,1.95-1.05,3.36-1.05l25.45-.03c.11.75.16,1.52.16,2.31,0,1.15-.39,2.05-1.16,2.72-.77.66-1.87,1-3.27,1h-6.82s.03,30.01.03,30.01h-7.47s-.03-30-.03-30Z" />
            <path fill="black" d="M287.12,1.2c1.16-.18,2.02-.27,2.6-.27,3.21,0,4.82,1.39,4.83,4.19l.03,32.05h-7.42s-.04-35.97-.04-35.97Z" />
            <path fill="black" d="M328.74,35.09c-.54.54-1.34,1.04-2.41,1.51-1.07.47-2.24.83-3.52,1.08-1.28.25-2.66.38-4.14.38-5.41,0-9.59-1.62-12.51-4.88-2.93-3.26-4.39-7.86-4.4-13.82,0-3.91.68-7.3,2.05-10.17,1.37-2.87,3.32-5.05,5.84-6.54s5.54-2.24,9.04-2.24c2.09,0,4.04.3,5.85.91,1.8.61,3.25,1.39,4.33,2.36.79.72,1.19,1.49,1.2,2.31,0,1.11-.86,2.49-2.6,4.14-1.34-1.32-2.67-2.27-4.01-2.85-1.34-.57-2.8-.86-4.39-.85-3.14,0-5.55,1.13-7.23,3.39-1.68,2.26-2.51,5.36-2.51,9.31,0,4.12.87,7.29,2.61,9.51,1.74,2.22,4.23,3.33,7.48,3.33,1.37-.04,2.57-.25,3.6-.65,1.03-.39,1.9-.86,2.63-1.4.72-.54,1.3-1,1.73-1.4,1.66,1.97,2.49,3.4,2.5,4.3,0,.43-.08.81-.24,1.13s-.46.7-.89,1.13Z" />
            <path fill="black" d="M336.5,1.14c1.15-.18,2.02-.27,2.6-.27,3.21,0,4.82,1.39,4.82,4.19l.03,32.05h-7.42s-.04-35.97-.04-35.97Z" />
            <path fill="black" d="M374.77,37.08l-3.09-8.82h-12.51s-2.97,8.84-2.97,8.84h-7.37s12.53-35.5,12.53-35.5c1.15-.5,2.6-.76,4.33-.76,2.67,0,4.37,1.11,5.09,3.33l11.68,32.9h-7.69ZM365.48,7.57l-4.91,15.33h9.69s-4.78-15.33-4.78-15.33Z" />
            <path fill="black" d="M387.49,28.79c1.63,1.25,3.14,2.15,4.55,2.68,1.41.54,2.87.8,4.39.8,1.84,0,3.31-.42,4.41-1.27,1.1-.84,1.65-2.04,1.65-3.58-.04-1.25-.38-2.25-1.03-2.98-.65-.73-1.51-1.34-2.57-1.83-1.07-.48-2.23-.92-3.49-1.31-1.16-.36-2.31-.78-3.47-1.26-1.16-.48-2.21-1.1-3.17-1.85-.96-.75-1.74-1.72-2.33-2.9-.59-1.18-.9-2.65-.9-4.41,0-2.19.56-4.07,1.67-5.65,1.11-1.58,2.66-2.79,4.62-3.64,1.97-.84,4.16-1.27,6.58-1.27,1.88,0,3.7.24,5.47.72,1.77.48,3.09,1.04,3.95,1.69,1.01.57,1.52,1.38,1.52,2.42,0,.47-.2,1.07-.59,1.8-.4.74-.92,1.44-1.57,2.13-1.12-.89-2.4-1.62-3.85-2.17-1.44-.55-2.85-.83-4.22-.83-1.77,0-3.18.35-4.22,1.05-1.05.7-1.57,1.71-1.57,3.04,0,.72.17,1.36.52,1.94.34.57.91,1.11,1.71,1.61.79.5,2.28,1.15,4.44,1.93,1.16.43,2.33.89,3.52,1.4,1.19.5,2.28,1.14,3.25,1.9.98.77,1.77,1.74,2.39,2.9.62,1.16.93,2.62.93,4.38,0,3.66-1.21,6.53-3.65,8.61-2.44,2.08-5.75,3.12-9.94,3.13-1.95,0-3.77-.19-5.47-.59-1.7-.39-3.14-.93-4.34-1.61-1.01-.68-1.52-1.47-1.52-2.36,0-1.04.77-2.58,2.32-4.63Z" />
            <path fill="black" d="M436.69,7.01h-11.32c-.11-.63-.17-1.35-.17-2.14,0-1.18.41-2.12,1.24-2.82.83-.7,1.95-1.05,3.36-1.05l25.45-.03c.11.75.16,1.52.16,2.31,0,1.15-.39,2.05-1.16,2.72-.77.66-1.87,1-3.27,1h-6.82s.03,30.01.03,30.01h-7.47s-.03-30-.03-30Z" />
            <path fill="black" d="M479.65,31.27c1.44,0,2.55.33,3.33.99.78.66,1.17,1.6,1.17,2.82,0,.39,0,.73-.03.99-.02.27-.05.56-.08.89l-23.45.02-.04-36.03,22.58-.02c.11.54.16,1.2.16,1.99,0,1.36-.35,2.33-1.05,2.91-.7.57-1.89.86-3.55.86h-10.67s0,8.88,0,8.88h11.48s0,5.74,0,5.74h-11.48s.01,9.96.01,9.96h11.59Z" />
            <path fill="black" d="M497.31,30.99h10.56c2.93-.01,4.39,1.26,4.39,3.8,0,1.29-.03,2.01-.11,2.15l-22.31.02-.04-35.98c1.16-.18,2.02-.27,2.6-.27,1.59,0,2.8.36,3.63,1.07.83.72,1.25,1.76,1.25,3.12l.03,26.08Z" />
            <path fill="black" d="M537.27,31.21c1.44,0,2.55.33,3.33.99.78.66,1.17,1.6,1.17,2.82,0,.39,0,.73-.03.99-.02.27-.04.56-.08.89l-23.45.02-.04-36.03,22.58-.02c.11.54.16,1.2.16,1.99,0,1.36-.35,2.33-1.05,2.91-.7.57-1.89.86-3.55.86h-10.67s0,8.88,0,8.88h11.48s0,5.74,0,5.74h-11.48s.01,9.96.01,9.96h11.59Z" />
            <path fill="black" d="M622.21,22.31c0,1.58-.07,3.03-.21,4.36-.21,2.33-.91,4.32-2.08,5.97-1.17,1.65-2.76,2.9-4.76,3.74-2,.84-4.38,1.27-7.12,1.27-4.26,0-7.49-.88-9.69-2.65-2.21-1.77-3.54-4.61-4.02-8.52-.15-1.15-.22-2.53-.22-4.14l-.02-21.46c1.15-.18,2.02-.27,2.6-.27,3.21,0,4.82,1.39,4.82,4.19l.02,18.01c0,2.15.22,3.82.65,5,.44,1.18,1.14,2.06,2.12,2.63.98.57,2.27.86,3.9.86,2.27,0,3.94-.65,5.01-1.94,1.06-1.29,1.59-3.34,1.59-6.13l-.02-22.37c1.15-.18,2.02-.27,2.6-.27,3.21,0,4.82,1.39,4.82,4.19l.02,17.53Z" />
            <path fill="black" d="M657.78,36.79h-6.28s-15.46-23.64-15.46-23.64l.02,23.66h-7.1s-.04-35.92-.04-35.92c.94-.22,2.04-.32,3.3-.33,1.05,0,1.9.17,2.57.51.67.34,1.29.98,1.87,1.91l14,22.62-.03-24.79c1.08-.18,1.89-.27,2.44-.27,3.11,0,4.66,1.39,4.66,4.19l.03,32.05Z" />
            <path fill="black" d="M694.85,18.15c0,3.8-.69,7.12-2.1,9.95-1.41,2.83-3.4,4.99-5.98,6.46-2.58,1.47-5.64,2.21-9.18,2.21h-11.53s-.04-35.7-.04-35.7c3.35-.43,6.75-.65,10.18-.66,6.17,0,10.82,1.48,13.95,4.45,3.13,2.97,4.69,7.4,4.7,13.28ZM687.27,18.26c0-4.09-.87-7.13-2.61-9.11-1.74-1.99-4.37-2.98-7.91-2.98-1.01,0-2.09.06-3.25.17l.03,24.79h2.98c3.72,0,6.44-1.03,8.17-3.08,1.73-2.05,2.59-5.31,2.59-9.79Z" />
            <path fill="black" d="M734.06,18.59c0,5.95-1.43,10.61-4.29,13.99-2.87,3.37-6.95,5.06-12.26,5.07-3.5,0-6.46-.77-8.88-2.3-2.42-1.54-4.25-3.74-5.5-6.61-1.25-2.87-1.87-6.22-1.88-10.05,0-3.8.68-7.13,2.05-9.98,1.37-2.85,3.3-5.01,5.79-6.49C711.57.74,714.51,0,717.9,0c5.16,0,9.14,1.62,11.94,4.88,2.8,3.26,4.21,7.83,4.21,13.71ZM726.42,18.65c0-4.09-.74-7.23-2.2-9.44-1.46-2.2-3.58-3.3-6.36-3.3-2.93,0-5.14,1.11-6.66,3.31-1.51,2.21-2.27,5.35-2.26,9.44,0,4.12.77,7.34,2.28,9.65,1.52,2.31,3.69,3.47,6.5,3.46,2.82,0,4.97-1.15,6.47-3.45,1.5-2.29,2.24-5.52,2.24-9.68Z" />
          </g>
          <path fill="black" d="M584.98.89c-.85-.11-1.73-.16-2.65-.16-1.44,0-2.55.28-3.33.84-.78.56-1.41,1.3-1.89,2.2l-9.91,19.44-9.95-19.42c-.48-.9-1.11-1.64-1.9-2.2-.79-.56-1.9-.84-3.33-.84-.92,0-1.8.06-2.65.17-.61.07-1.44.28-2.1.48l.04,35.96h7.41s0-.01,0-.01h.01s-.02-23.92-.02-23.92l8.64,16.31h7.7s8.61-16.34,8.61-16.34l.03,23.92h.01s0,0,0,0h7.41s-.04-35.97-.04-35.97c-.67-.19-1.49-.41-2.1-.47Z" />
        </g>
      </g>
      <g>
        <circle fill="#e71402" cx="457.75" cy="104.93" r="40.8" />
        <path fill="black" d="M457.75,52.98c-28.69,0-51.96,23.26-51.96,51.96s23.26,51.96,51.96,51.96,51.96-23.26,51.96-51.96-23.26-51.96-51.96-51.96ZM457.75,151.09c-25.49,0-46.16-20.67-46.16-46.16s20.67-46.16,46.16-46.16,46.16,20.67,46.16,46.16-20.67,46.16-46.16,46.16Z" />
        <g>
          <path fill="black" d="M264.45,139.09h-51.4l-7.72,17.97h-23.92l47.39-104.13h20.05l47.24,104.13h-23.92l-7.72-17.97ZM256.43,120.22l-15.3-35.95-2.38-6.24-2.38,6.24-15.3,35.95h35.35Z" />
          <path fill="black" d="M398.82,52.87v103.98h-21.99v-42.34h-52.29v42.34h-21.98V52.87h21.98v42.19h52.29v-42.19h21.99Z" />
          <path fill="black" d="M563.83,124.17h-25.25v32.68h-21.98V52.87h53.18c23.17,0,38.18,13.07,38.18,34.76,0,15.15-7.58,27.48-21.39,33.13l26.89,36.1h-26.59l-23.02-32.68ZM538.58,104.71h27.33c14.26,0,19.46-7.13,19.46-16.79,0-11.29-6.68-15.6-19.61-15.6h-27.19v32.38Z" />
          <path fill="black" d="M702.69,139.09h-51.4l-7.72,17.97h-23.92l47.39-104.13h20.05l47.24,104.13h-23.92l-7.72-17.97ZM694.67,120.22l-15.3-35.95-2.38-6.24-2.38,6.24-15.3,35.95h35.35Z" />
        </g>
      </g>
    </svg>
  );
}
