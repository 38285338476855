import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash.get';
import Link from 'components/Link';

import styles from './styles.module.scss';

const AuthorCard = ({ author }) => {
  // eslint-disable-next-line max-len
  const defaultAuthorPhotoUrl = 'https://media-cldnry.s-nbcnews.com/image/upload/newsgroup-logos/msnbc/columnists/default-square-120x120.png';
  const id = get(author, 'id', '');
  const displayName = get(author, ['name', 'display'], '');
  const firstName = get(author, ['name', 'first'], '');
  const lastName = get(author, ['name', 'last'], '');
  const middleName = get(author, ['name', 'middle'], '');
  const title = get(author, ['name', 'title'], '');
  const suffix = get(author, ['name', 'suffix'], '');
  const pageUrl = get(author, ['url', 'primary'], '');
  const picUrl = get(author, ['primaryImage', 'url', 'primary'], '');
  const shortBio = get(author, 'shortBio', '');

  return (
    <Link
      to={pageUrl}
      data-test="author-card__link-wrapper"
      data-testid="author-card__link-wrapper"
    >
      <article
        className={styles.authorCard}
        data-test="author-card"
        data-testid="author-card"
        data-contentid={id}
      >
        <img
          src={picUrl.length ? picUrl : defaultAuthorPhotoUrl}
          alt={displayName}
          className={classNames(styles.pic, { [styles.missing]: !picUrl })}
          data-test="author-card__pic"
          data-testid="author-card__pic"
        />
        <div
          className={styles.textGroup}
          data-test="author-card__text-group"
        >
          <div data-test="author-card__name" data-testid="author-card__name">
            <span
              className={styles.firstName}
              data-test="author-card__first-name"
              data-testid="author-card__first-name"
            >
              {
                title ? `${title} ${firstName} ${middleName}` : `${firstName} ${middleName}`
              }
            </span>
            <span
              className={styles.lastName}
              data-test="author-card__last-name"
              data-testid="author-card__last-name"
            >
              {
                suffix ? `${lastName} ${suffix}` : lastName
              }
            </span>
          </div>
          <div
            className={styles.shortBio}
            data-test="author-card__short-bio"
            data-testid="author-card__short-bio"
          >
            {shortBio}
          </div>
        </div>
      </article>
    </Link>
  );
};

AuthorCard.contextTypes = {
  vertical: PropTypes.string,
};

AuthorCard.propTypes = {
  author: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.shape({
      display: PropTypes.string.isRequired,
      title: PropTypes.string,
      first: PropTypes.string.isRequired,
      middle: PropTypes.string,
      last: PropTypes.string.isRequired,
      suffix: PropTypes.string,
    }),
    pageUrl: PropTypes.string,
    picUrl: PropTypes.string,
    shortBio: PropTypes.string,
  }).isRequired,
};

export { AuthorCard };
