import React from 'react';
import PropTypes from 'prop-types';

import { package as PackagePropType } from 'lib/CustomPropTypes';

import { PackageTitleTopBorder } from 'components/PackageTitleTopBorder';
import { Author as AuthorCard } from 'components/packages/Bacon/Cards/Author';

import styles from './styles.module.scss';

const icid = 'msd_botgrid';
function MsnbcDailyAuthors(props) {
  const {
    content: {
      id,
      items,
      metadata,
    },
    pkgClassName,
  } = props;

  const cardsMapped = items.map((item) => {
    const author = item?.item?.authors?.[0] ?? undefined;
    const authorName = author?.person?.name ?? undefined;
    const authorPhotoUrl = author?.person?.primaryImage?.url?.primary ?? undefined;
    if (!authorName && !authorPhotoUrl) return null;
    return (
      <AuthorCard card={item} key={item.id} icid={icid} />
    );
  });

  const title = metadata?.title ?? 'Columnist Must Read';

  return (
    <section
      className={pkgClassName}
      data-packageid={id}
    >
      <PackageTitleTopBorder title={title} icid={icid} />
      <div className={styles.wrapper}>
        {cardsMapped}
      </div>
    </section>
  );
}

MsnbcDailyAuthors.propTypes = {
  content: PackagePropType.isRequired,
  pkgClassName: PropTypes.string,
};

MsnbcDailyAuthors.defaultProps = {
  pkgClassName: 'pkg',
};

export { MsnbcDailyAuthors };
