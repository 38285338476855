import React from 'react';
import classNames from 'classnames';
import useSWR from 'swr';

import getConfig from 'next/config';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import { fetcher } from 'lib/graphqlDataFetcher';

import LiveBlog from 'components/packages/LiveBlog';
import { Featured } from 'components/packages/CoverSpread/Featured';
import { Tease } from 'components/packages/CoverSpread/Tease';
import { TeaseLayout } from 'components/packages/CoverSpread/TeaseLayouts';
import { Title } from 'components/packages/CoverSpread/Title';
import { ShowBlog } from 'components/packages/CoverSpread/ShowBlog';

import './styles.themed.scss';

const { publicRuntimeConfig: { API_URL_BENTO_PUBLIC } } = getConfig();
const FEATURED_ITEM_INDEX = 0;
const BLOG_INDEX = 1;

const shapeItem = (item = {}, packageMetadata = {}) => ({
  ...item,
  metadata: { // merge content and package metadata
    ...packageMetadata,
    ...(item || {}).metadata,
  },
});

const getFeaturedItem = (items, packageMetadata) => {
  const itemAtIndex = items?.[FEATURED_ITEM_INDEX];
  return shapeItem(itemAtIndex, packageMetadata);
};

const getLiveBlog = (items, packageMetadata) => {
  const itemAtIndex = items?.[BLOG_INDEX] ?? null;
  if (!itemAtIndex) {
    return null;
  }
  return shapeItem(itemAtIndex, packageMetadata);
};

const shapeShowBlogContent = (content, showBlogData, packageMetadata) => {
  if (!showBlogData?.taxonomy?.content?.items?.length) {
    return null;
  }

  const {
    taxonomy: {
      id,
      content: {
        items,
      },
    },
  } = showBlogData;

  const {
    blogSource: {
      taxonomyName,
    },
    blogUrl,
  } = packageMetadata;

  const { context } = content;

  return {
    id,
    computedValues: {
      headline: taxonomyName,
      url: blogUrl,
    },
    context,
    items,
  };
};

const CoverSpread = (props) => {
  const {
    content,
    content: {
      id: packageId,
      context: {
        isRailAdjacent,
      } = {},
      items = [],
      metadata: packageMetadata = {},
      subType,
    } = {},
  } = props;

  if (!items || !items.length) return null;

  const packageSize = items.length;

  const {
    showBorderHeader = true,
    showBreakingStyle,
    title,
    titleUrl,
    titleFontSize,
    showBottomBorder = false,
  } = packageMetadata;
  const isLiveBlog = subType === 'liveBlog';
  const isShowBlog = subType === 'showBlog';
  const isChatLiveBlog = subType === 'chatLiveBlog';
  const isBlog = isLiveBlog || isShowBlog || isChatLiveBlog;
  const layoutType = isBlog ? 'blog' : 'default';

  const featuredItem = getFeaturedItem(items, packageMetadata);
  let firstTeases = [];
  let secondTeases = [];
  let indexOffset = 0;
  let totalTeases = items.length;
  let liveBlog = null;
  let isLiveBlogBreaking = false;
  const showBlogEndpoint = (isShowBlog && packageMetadata.blogSource)
    ? `${API_URL_BENTO_PUBLIC}/latestShowBlogPosts/${packageMetadata.blogSource.taxonomyPath}`
    : null;

  const { data: showBlogData, error: showBlogError } = useSWR(showBlogEndpoint, fetcher);
  const showBlogContent = (showBlogData && !showBlogError)
    ? shapeShowBlogContent(content, showBlogData, packageMetadata)
    : null;

  if (isBlog) {
    if (isLiveBlog || isChatLiveBlog) {
      liveBlog = getLiveBlog(items, packageMetadata);
      isLiveBlogBreaking = liveBlog?.metadata?.showBreakingStyle ?? true;
    }
    // filter to remove any articles that are returned as null
    secondTeases = items.filter((item, index) => item && index > BLOG_INDEX);

    // TeaseLayout count for live blog based on count of items in column, not total length
    totalTeases = secondTeases.length;
  } else {
    // filter to remove any articles that are returned as null
    // And remove first article since it is pulled into the featured item
    const teases = items.filter((item, index) => item && index > FEATURED_ITEM_INDEX);

    // Split articles into two columns
    firstTeases = teases.slice(0, Math.floor(teases.length / 2));
    secondTeases = teases.slice(Math.floor(teases.length / 2), teases.length);
    indexOffset = firstTeases.length;
  }

  return (
    <div
      className={classNames(
        'pkg cover-spread',
        {
          'cover-spread--default': !isBlog,
          'cover-spread--blog': isBlog,
          'cover-spread--rail-adj': isRailAdjacent,
          'cover-spread--has-header': showBorderHeader !== false,
          'cover-spread--breaking': showBreakingStyle,
          'cover-spread--has-title': title,
        },
      )}
      data-test="cover-spread"
      data-testid="cover-spread"
      data-packageid={packageId}
    >
      <div
        className={classNames(
          'layout-grid-container',
          {
            'gutter-collapse': isRailAdjacent,
          },
        )}
      >
        {title
        && (
          <div className="layout-grid-item grid-col-12">
            <div className="cover-spread__headline__wrapper">
              <Title
                title={title}
                titleUrl={titleUrl}
                titleFontSize={titleFontSize}
                isRailAdjacent={isRailAdjacent}
              />
            </div>
          </div>
        )}

        <div className="layout-grid-item grid-col-12">
          <div className="layout-subgrid-container">
            <div
              className={classNames(
                'cover-spread__column-featured',
                'layout-subgrid-item grid-col-12 grid-col-8-m',
                {
                  'grid-col-12-l': isRailAdjacent,
                  'grid-col-8-l': !isRailAdjacent,
                },
                'grid-col-6-xl',
              )}
            >
              <Featured
                content={featuredItem}
                isRailAdjacent={isRailAdjacent}
                packageMetadata={packageMetadata}
                packageSize={packageSize}
              />
            </div>

            <div
              className={classNames(
                'cover-spread__column-one',
                'layout-subgrid-item grid-col-12 grid-col-4-m',
                {
                  'grid-col-4-l': !isRailAdjacent,
                  'grid-col-6-l': isRailAdjacent,
                },
                'grid-col-3-xl',
              )}
            >
              {isBlog && (
                <div
                  className={classNames({
                    'cover-spread__blog': isBlog,
                    'cover-spread__blog--breaking': isLiveBlogBreaking,
                    'cover-spread__blog--chatStyle': isChatLiveBlog,
                  })}
                >
                  {((isLiveBlog || isChatLiveBlog) && liveBlog) && (
                    <LiveBlog
                    // must pass package object as 'content',
                    // so subcomponent knows it's not the root package
                      content={{
                        ...content,
                        items: [{
                          ...liveBlog,
                          metadata: {
                            ...liveBlog.metadata,
                            showDek: false, // dek not supported, override
                          },
                        }],
                      }}
                      liveBlogStyle={subType === 'chatLiveBlog' ? 'chatStyle' : undefined}
                    />
                  )}
                  {(isShowBlog && showBlogContent) && <ShowBlog content={showBlogContent} />}
                </div>
              )}
              {firstTeases.length > 0 && (
                <TeaseLayout
                  columnType="__column--1"
                  isRailAdjacent={isRailAdjacent}
                  layoutType={layoutType}
                  render={(layout) => (
                    firstTeases.map((tease, index) => (
                      <Tease
                        content={tease}
                        index={index}
                        isRailAdjacent={isRailAdjacent}
                        key={`cs-tease-${tease.id}`}
                        layout={layout}
                        layoutType={layoutType}
                        packageMetadata={packageMetadata}
                        packageSize={packageSize}
                        showInlineIcon
                      />
                    ))
                  )}
                  totalTeases={items.length}
                />
              )}
            </div>

            <div
              className={classNames(
                'cover-spread__column-two',
                'layout-subgrid-item grid-col-12',
                {
                  'grid-col-12-m': !isBlog,
                  'grid-col-8-m': isBlog,
                  'grid-col-12-l': !isRailAdjacent,
                  'grid-col-6-l': isRailAdjacent,
                },
                'grid-col-3-xl',
              )}
            >
              <TeaseLayout
                columnType="__column--2"
                isRailAdjacent={isRailAdjacent}
                layoutType={layoutType}
                render={(layout) => (
                  secondTeases.map((tease, index) => (
                    <Tease
                      content={tease}
                      index={indexOffset + index}
                      isRailAdjacent={isRailAdjacent}
                      key={`cs-tease-${tease.id}`}
                      layout={layout}
                      layoutType={layoutType}
                      packageMetadata={packageMetadata}
                      packageSize={packageSize}
                      showInlineIcon
                    />
                  ))
                )}
                totalTeases={totalTeases}
              />
            </div>
          </div>
          {showBottomBorder && (<hr className="pkg-bottom-border" />)}
        </div>
      </div>
    </div>
  );
};

CoverSpread.propTypes = {
  content: ContentPropType.isRequired,
};

export { CoverSpread };
