import React from 'react';
import PropTypes from 'prop-types';
import { layoutContext as LayoutContextPropType } from 'lib/CustomPropTypes';

const PackageHeader = ({
  className,
  packageIndex,
  children,
}, context) => {
  const { isFirstLayoutWithPackages } = context;
  const isFirstPackage = isFirstLayoutWithPackages && packageIndex === 0;
  const Tag = !isFirstPackage ? 'h2' : 'h1';

  return (
    <Tag className={className} data-testid="package-header">
      { children }
    </Tag>
  );
};

PackageHeader.propTypes = {
  className: PropTypes.string,
  packageIndex: PropTypes.number,
  children: PropTypes.node,
};

PackageHeader.defaultProps = {
  className: '',
  packageIndex: null,
  children: null,
};

PackageHeader.contextTypes = {
  ...LayoutContextPropType,
};
export default PackageHeader;
