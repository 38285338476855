import React from 'react';
import classNames from 'classnames';
import i18next from 'i18next';

import { LinkIfHref } from 'components/Link/LinkIfHref';
import ButtonHoverAnimation from 'components/ButtonHoverAnimation';
import TitleLogo from 'components/TitleLogo';
import { NotchedHorizontalRule } from 'components/NotchedHorizontalRule';

import {
  package as PackagePropType,
  packageContext as PackageContextPropType,
  layoutContext as LayoutContextPropType,
} from 'lib/CustomPropTypes';
import {
  aLaCarte,
  insertPaidContent,
} from 'lib/paidContentUtils';

import { Item } from './Item';

import styles from './styles.module.scss';

function ALaCarte(props, context = {}) {
  const { railContext } = context;

  const { content } = props;
  const { id, items = [], metadata: packageMetadata } = content;

  // In case of API error, not a valid case
  if (!items || !items.length) return null;

  const {
    description,
    logoUrl,
    seeAllUrl,
    showFeaturedFirstItem,
    title,
    titleUrl,
  } = packageMetadata || {};

  const isInRail = railContext !== null;

  // Use this flag below to swap between shrink sizing and static sizing
  // This can be utilized when Tease List is migrated to this component
  const isLatestStories = false;

  const seeAllText = packageMetadata.seeAllText || i18next.t('See All');

  const hasHeader = title || description;

  const hasHeaderTop = hasHeader || logoUrl;

  let filtered = items
    // filter out any null content
    .filter((item) => item)
    // map package metadata with content metadata
    .map((item) => ({
      ...item,
      metadata: {
        ...packageMetadata,
        ...item.metadata,
      },
    }));

  // Insert paidContent tease
  filtered = insertPaidContent(filtered, aLaCarte, { isInRail, ...context });

  return (
    <div
      className={classNames('pkg alacarte', styles.alacarte, {
        // Modify styling based on placement in rail
        [styles.inRail]: isInRail,
        // Sizing strategy is global modifier as it determines flexbox behavior and height
        [styles.shrinkToFit]: isLatestStories,
      })}
      data-packageid={id}
    >
      <div className={styles.wrapper}>
        {hasHeaderTop && (
          <div className={styles.wrapperTop}>
            {hasHeader && (
              <NotchedHorizontalRule className={styles.topDecoration} />
            )}
            {(title || logoUrl) && (
              <h2 className={styles.title}>
                <LinkIfHref href={titleUrl}>
                  <TitleLogo logoUrl={logoUrl} title={title} />
                </LinkIfHref>
              </h2>
            )}
            {description && (
              <p className={styles.description}>
                {description}
              </p>
            )}
          </div>
        )}
        <div className={classNames(
          styles.content,
          { [styles.withoutButton]: !seeAllUrl && isInRail },
        )}
        >
          <div className={styles.itemsWrapper}>
            <ul className={styles.items}>
              {filtered.map((item, i) => (
                <Item
                  inRail={isInRail}
                  isFeatured={i === 0 && showFeaturedFirstItem}
                  item={item}
                  key={item.id}
                />
              ))}
            </ul>
          </div>
          {seeAllUrl && ( // only show button if URL is set
            <div className={styles.buttonWrapper}>
              <ButtonHoverAnimation
                additionalClasses={styles.button}
                size={isInRail ? 'normal' : 'small'}
                title={seeAllText}
                type="link"
                url={seeAllUrl}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ALaCarte.propTypes = {
  content: PackagePropType.isRequired,
};

ALaCarte.contextTypes = {
  adsDisabled: LayoutContextPropType.adsDisabled,
  isRailLayout: LayoutContextPropType.isRailLayout,
  pageRoute: LayoutContextPropType.pageRoute,
  railContext: PackageContextPropType.railContext,
  vertical: LayoutContextPropType.vertical,
};

export { ALaCarte };
