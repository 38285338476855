import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

/**
 * Functional component that expects three props. First is a days Map containing a list of days in
 * whatever order is required by the consumer. The next two are react useState hooks for checking
 * and setting the current selected day.
 */
const DayPicker = ({ days, selectedDay, setSelectedDay }) => {
  const renderedDays = [...days.values()].map((day, idx) => {
    const dayCx = classnames(
      styles.dayPickerDay,
      { [styles.dayPickerActive]: selectedDay === day },
    );

    const handleButtonClick = () => setSelectedDay(day);

    return (
      <div
        key={day}
        role="button"
        className={dayCx}
        onClick={handleButtonClick}
        onKeyDown={handleButtonClick}
        tabIndex={idx}
      >
        <p>{day}</p>
      </div>
    );
  });

  return (
    <div className={styles.dayPicker}>
      {renderedDays}
    </div>
  );
};

DayPicker.propTypes = {
  days: PropTypes.instanceOf(Map).isRequired,
  selectedDay: PropTypes.string.isRequired,
  setSelectedDay: PropTypes.func.isRequired,
};

export { DayPicker };
