import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { navigation as navigationType } from 'lib/CustomPropTypes';

import styles from './styles.module.scss';

const Navigation = (props) => {
  const { navigation, styleOverride } = props;

  const wrapperClasses = classNames(
    styles.headerPackageNavigation,
    styleOverride.linkListWrapper,
  );

  if (navigation?.length) {
    return (
      <div className={wrapperClasses}>
        <ul className={styleOverride.linkList}>
          {navigation.map((item) => (
            <li
              key={item.linkTitle}
              className={styleOverride.linkListItem}
            >
              <a href={item.linkUrl}>
                {item.linkTitle}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};

Navigation.propTypes = {
  navigation: navigationType,
  styleOverride: PropTypes.shape({
    linkListWrapper: PropTypes.string,
    linkList: PropTypes.string,
    linkListItem: PropTypes.string,
  }),
};

Navigation.defaultProps = {
  navigation: [],
  styleOverride: PropTypes.shape({
    linkListWrapper: null,
    linkList: null,
    linkListItem: null,
  }),
};

export default Navigation;
