import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import { isVideoLive } from 'lib/videoUtils';
import Link from 'components/Link';
import VideoPlayer from 'components/VideoPlayer';
import { InlineBlinkingLiveDot } from 'components/InlineBlinkingLiveDot';

function FeaturedVideo(props) {
  // Video play status
  const [hasClickedToPlay, setHasClickedToPlay] = React.useState(false);

  const { t } = useTranslation();

  const {
    block,
    content: {
      computedValues: {
        headline: computedHeadline,
      } = {},
      id,
      item,
      metadata: {
        flag,
      } = {},
    },
  } = props;

  const headline = computedHeadline || (item?.headline?.primary);
  const isLiveVideo = isVideoLive(item);
  const showFlag = isLiveVideo || flag;

  return (
    <div
      className={`${block}__video`}
      data-contentid={id}
    >
      <div className={`${block}__player`}>
        <VideoPlayer
          centerPlayButtonAtMobile
          hasClickedToPlay={hasClickedToPlay}
          replay
          video={item}
        />
      </div>
      <h2 className={`${block}__headline`}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          className={`${block}__headline-link`}
          onClick={() => { setHasClickedToPlay(true); }}
        >
          {showFlag && (
            <span
              className={classNames(
                `${block}__flag`,
                { [`${block}__flag--live`]: isLiveVideo },
              )}
              data-testid={`${block}__flag`}
            >
              {isLiveVideo && (
                <InlineBlinkingLiveDot className={`${block}__flag-dot`} data-testid={`${block}__flag-dot`} />
              )}
              {flag || t('LIVE')}
              &nbsp;
              {'/ '}
            </span>
          )}
          <span className={`${block}__headline-text`}>
            {headline}
          </span>
        </Link>
      </h2>
    </div>
  );
}

FeaturedVideo.propTypes = {
  block: PropTypes.string,
  content: ContentPropType.isRequired,
};

FeaturedVideo.defaultProps = {
  block: null,
};

export { FeaturedVideo };
