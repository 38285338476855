export const TEASE_SIZES = {
  S: 'S',
  M: 'M',
  M1: 'M1', // 1 Medium in Column
  M2: 'M2', // 2 Medium in Column
  L: 'L',
  XL: 'XL',
};

export const MAIN_ART_TEASE_VALID_SIZES = [
  TEASE_SIZES.S,
  TEASE_SIZES.M,
  TEASE_SIZES.M1,
  TEASE_SIZES.M2,
  TEASE_SIZES.L,
  TEASE_SIZES.XL,
];

export const SMORGASBORD_TEASE_VALID_SIZES = [
  TEASE_SIZES.M,
  TEASE_SIZES.M1,
  TEASE_SIZES.M2,
  TEASE_SIZES.L,
  TEASE_SIZES.XL,
];

export const SMORGASBORD_COMPONENT_TYPES = {
  VISUAL: 'Visual',
  NO_VISUAL: 'NoVisual',
  PLAYMAKER: 'Playmaker',
};

export const SMORGASBORD_TEASE_VISUAL_VARIANTS = {
  PLAYMAKER: 'Playmaker',
  VIDEO: 'Video',
  ARTICLE: 'Article',
  LIVEBLOG: 'LiveBlog',
  IMAGE: 'Image',
  PLAY: 'Play',
  DOT: 'Dot',
  NONE: 'None',
};

export const SMORGASBORD_PLAYMAKER_DEFAULTS = {
  PLAYMAKER_FLAG: 'Live',
  PLAYMAKER_PLAY_BEHAVIOR: 'autoplay',
};
